import React from 'react'
import RcSelect, { SelectProps as RcSelectProps } from 'rc-select'
import { useFormContext, Controller, RegisterOptions } from 'react-hook-form'
import cn from 'classnames'
import { OptionData } from 'rc-select/lib/interface'
import { useFormatMessage } from '@babylon/intl'
import { Icon } from '@babylon/medkit'
import { useFieldContext } from '../Field'

import messages from './messages'
import styles from './styles.module.scss'

export interface SelectProps extends RcSelectProps<string> {
  name?: string
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  multiple?: boolean
  readOnly?: boolean
  loadingLabel?: React.ReactNode
}

const Select = ({
  multiple,
  name: propName,
  rules,
  readOnly,
  loadingLabel,
  placeholder,
  ...props
}: SelectProps) => {
  const fm = useFormatMessage()
  const control = useFormContext()?.control
  const { name: contextName } = useFieldContext()
  const name = propName || contextName
  if (control && !name) {
    throw new Error('Select requires a name')
  }

  const getByValue = (value: string) =>
    props.options?.find((item) => value === item.value)

  return control ? (
    <Controller
      render={({ onChange, value }) =>
        readOnly ? (
          <div className={cn(styles.select, styles['select-read-only'])}>
            {props.loading ? (
              <span>{loadingLabel || fm(messages.loading)}</span>
            ) : multiple ? (
              value
                .map(getByValue)
                .map((option: OptionData) => (
                  <span key={option?.value}>{option?.label}</span>
                ))
            ) : (
              <span>
                {props.options?.find((item) => value === item.value)?.label}
              </span>
            )}
          </div>
        ) : (
          <RcSelect
            prefixCls={styles.select}
            value={value ?? undefined}
            onChange={onChange}
            mode={multiple ? 'multiple' : undefined}
            placeholder={
              <span>
                <Icon
                  icon="NavigationSearch"
                  title="Search icon"
                  width={14}
                  height={14}
                  className={styles.iconWrapper}
                  iconClassName={styles.icon}
                />{' '}
                {placeholder || fm(messages.selectOne)}
              </span>
            }
            {...props}
          />
        )
      }
      name={name || ''}
      control={control}
      rules={rules}
    />
  ) : (
    <RcSelect
      prefixCls={styles.select}
      mode={multiple ? 'multiple' : undefined}
      {...props}
    />
  )
}

export default Select
