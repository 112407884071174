import * as React from 'react'

const Pin = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.207 20.829a1.75 1.75 0 01-2.474-.06C6.424 16.243 4.25 12.691 4.25 10a7.75 7.75 0 0115.5 0c0 2.692-2.174 6.244-6.483 10.768l-.06.06zM18.25 10a6.25 6.25 0 10-12.5 0c0 2.188 2.01 5.47 6.069 9.734a.25.25 0 00.353.008C16.24 15.471 18.25 12.188 18.25 10zM12 12a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm0-1.5a1 1 0 100-2 1 1 0 000 2z" />
  </svg>
)

export default Pin
