import * as React from 'react'

const Monitor = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M6.991 11.14l-.552 1.178a.75.75 0 01-.68.432H3.778a.75.75 0 110-1.5h1.506L6.338 9a.75.75 0 011.366.02l1.72 3.947 2.212-5.778c.249-.65 1.173-.64 1.407.016l2.714 7.612 1.17-3.083a.75.75 0 01.701-.484H20.2a.75.75 0 110 1.5h-2.053l-1.71 4.505c-.247.652-1.173.642-1.407-.015l-2.72-7.62-2.142 5.596a.75.75 0 01-1.388.032L6.99 11.14z" />
  </svg>
)

export default Monitor
