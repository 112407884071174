import ReactGA from 'react-ga'

const tracking = {
  pageview(...args) {
    ReactGA.pageview(...args)
  },
  modalview(...args) {
    ReactGA.modalview(...args)
  },
  event(...args) {
    ReactGA.event(...args)
  },
  exception(...args) {
    ReactGA.exception(...args)
  },
  set(...args) {
    ReactGA.set(...args)
  },
}

export default tracking
