import * as React from 'react'

const Image = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M1.934 16.414V3.75c0-.968.788-1.759 1.759-1.759h12.663c.969 0 1.76.789 1.76 1.76v12.663c0 .968-.79 1.759-1.76 1.759H3.693c-.969 0-1.76-.789-1.76-1.76zm1.5 0c0 .141.118.259.259.259h12.663c.142 0 .26-.118.26-.26V3.75a.262.262 0 00-.26-.259H3.693a.262.262 0 00-.26.26v12.663zm10.192-6.608l-2.192 2.63c-.563.675-1.612.935-2.424.6l-.596-.244c-.232-.095-.637-.014-.819.168l-2.564 2.57a.75.75 0 11-1.062-1.06l2.564-2.57c.61-.61 1.658-.821 2.451-.496l.596.245c.207.085.559-.001.701-.173l2.295-2.753c.706-.848 1.846-.702 2.328.283l2.77 5.665a.75.75 0 01-1.348.658l-2.7-5.523zm6.153-1.014a.75.75 0 01.137-1.494l.237.022c.96.087 1.67.936 1.582 1.896l-.997 10.92a1.745 1.745 0 01-1.896 1.579l-11.91-1.087a.75.75 0 01.136-1.494l11.91 1.087a.245.245 0 00.267-.222l.996-10.92a.247.247 0 00-.224-.266l-.238-.021zM7.75 7a.75.75 0 10-1.5 0 .75.75 0 001.5 0zm1.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zM3 15.75v-1.5h14.682v1.5H3z" />
  </svg>
)

export default Image
