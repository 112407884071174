import * as React from 'react'

const EmailFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.72 3.977h16.556c.966 0 1.75.783 1.75 1.75v12.59a1.75 1.75 0 01-1.75 1.75H3.72a1.75 1.75 0 01-1.75-1.75V5.728c0-.967.784-1.75 1.75-1.75zm15.637 1.675l-7.32 7.192-7.405-7.192A.75.75 0 003.569 6.71l7.937 7.726a.75.75 0 001.063 0L20.42 6.71a.75.75 0 10-1.063-1.058z" />
  </svg>
)

export default EmailFilled
