import React from 'react'
import { Link } from 'react-router-dom'

import { Spinner } from '@babylon/medkit'

import { cn } from '@/utils'

interface ButtonProps {
  type?: 'button' | 'submit' | 'reset'
  to?: string
  className?: string
  'data-testid'?: string
  children?: React.ReactNode
  busy?: boolean
}

const Button = ({
  type = 'button',
  className,
  to,
  busy,
  children,
  ...props
}: ButtonProps & React.HTMLProps<HTMLButtonElement>) => {
  const button = (
    <button type={type} {...props} className={cn('bl-button', className)}>
      {busy ? <Spinner /> : children}
    </button>
  )
  return to ? <Link to={to}>{button}</Link> : button
}

export default Button
