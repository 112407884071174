import * as React from 'react'

const Map = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.697 6.484a.25.25 0 00-.17.237v12.398a.25.25 0 00.32.24l4.708-1.373a1.75 1.75 0 01.943-.01l5.35 1.438a.25.25 0 00.154-.008l5.352-2.059a.25.25 0 00.16-.233V4.716a.25.25 0 00-.312-.242l-4.829 1.243a1.75 1.75 0 01-.772.023L9.15 4.672a.25.25 0 00-.13.008L3.698 6.484zm-.481-1.42L8.54 3.26a1.75 1.75 0 01.898-.06l5.45 1.068a.25.25 0 00.11-.004l4.829-1.243a1.75 1.75 0 012.186 1.695v12.398a1.75 1.75 0 01-1.122 1.633l-5.352 2.059a1.75 1.75 0 01-1.082.056l-5.349-1.437a.25.25 0 00-.135.001L4.267 20.8a1.75 1.75 0 01-2.24-1.68V6.72c0-.75.478-1.417 1.189-1.658zM8.25 3.75h1.5v15.5h-1.5V3.75zm6 .5h1.5v15.5h-1.5V4.25z" />
  </svg>
)

export default Map
