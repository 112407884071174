import * as React from 'react'

const SecurityFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.44 14.281v.967a.524.524 0 01-.526.522.524.524 0 01-.525-.522v-.967a1.445 1.445 0 01-.889-1.34c0-.788.625-1.441 1.414-1.441.79 0 1.415.653 1.415 1.442 0 .601-.365 1.125-.889 1.34zM8.755 9.403h-1.25a.436.436 0 00-.438.435V16.6c0 .24.195.435.437.435h8.955a.436.436 0 00.437-.435V9.84a.436.436 0 00-.437-.436h-1.214C15.244 7.533 13.802 6 12 6S8.756 7.533 8.755 9.403zm1.5 0C10.256 8.342 11.048 7.5 12 7.5c.952 0 1.744.842 1.745 1.903h-3.49zm2.33-6.984c1.615 1.962 4.213 2.233 7.087.27a.75.75 0 011.173.62v11.725l-.028.204c-.286 1.017-1.172 2.465-2.973 3.881-1.457 1.145-3.324 2.072-5.646 2.708a.75.75 0 01-.396 0c-2.322-.636-4.19-1.563-5.646-2.708-1.802-1.416-2.687-2.864-2.973-3.881l-.028-.204V3.308a.75.75 0 011.173-.619c2.89 1.976 5.472 1.693 7.085-.27a.75.75 0 01.58-.273c.238 0 .45.1.593.273z" />
  </svg>
)

export default SecurityFilled
