import * as React from 'react'

const Membership = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.761 3.468a.25.25 0 00-.25.25v16.55c0 .138.112.25.25.25h16.55a.25.25 0 00.25-.25V3.718a.25.25 0 00-.25-.25H3.76zm0-1.5h16.55c.966 0 1.75.784 1.75 1.75v16.55a1.75 1.75 0 01-1.75 1.75H3.76a1.75 1.75 0 01-1.75-1.75V3.718c0-.966.783-1.75 1.75-1.75zm9.1 5.371a3.669 3.669 0 011.966-.589c1.855 0 3.423 1.625 3.423 3.52 0 2.286-1.172 4.06-3.078 5.355-.65.442-1.342.796-2.033 1.074a8.528 8.528 0 01-.949.326.75.75 0 01-.38 0 10.745 10.745 0 01-2.981-1.4C6.921 14.33 5.75 12.556 5.75 10.27c0-1.935 1.479-3.519 3.423-3.519.748 0 1.464.217 2.064.617.298.198.561.438.781.71.238-.284.523-.533.842-.738zm-.281 7.968a9.265 9.265 0 001.748-.923c1.531-1.04 2.422-2.388 2.422-4.115 0-1.077-.908-2.019-1.923-2.019-.395 0-.803.125-1.158.352a2.06 2.06 0 00-.796.917c-.092.22-.087.209-.101.253a.526.526 0 00-.022.178c0 1-1.5 1-1.5 0a.632.632 0 00-.022-.197 2.896 2.896 0 00-.095-.254 1.909 1.909 0 00-.727-.884 2.218 2.218 0 00-1.233-.365c-1.092 0-1.923.89-1.923 2.02 0 1.726.89 3.074 2.422 4.114A9.245 9.245 0 0012 15.518a8.78 8.78 0 00.58-.211z" />
  </svg>
)

export default Membership
