import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './index.scss'

const PopoverCard = ({ withDecoration, children, className }) => (
  <div
    className={cn(
      'core-popover',
      className,
      withDecoration && 'core-popover--with-decoration'
    )}
  >
    {children}
  </div>
)

Text.defaultProps = {
  withDecoration: false,
}

Text.propTypes = {
  withDecoration: PropTypes.bool,
}
export default PopoverCard
