import * as React from 'react'

const Smoking = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.517 18.274v1.981c0 .15.108.26.243.26h16.492c.125 0 .243-.12.243-.26v-1.981c0-.15-.108-.26-.243-.26H3.76c-.125 0-.243.12-.243.26zm-1.5 0c0-.966.787-1.76 1.743-1.76h16.492c.967 0 1.743.784 1.743 1.76v1.981a1.76 1.76 0 01-1.743 1.76H3.76a1.746 1.746 0 01-1.743-1.76v-1.981zM7.75 16.75h1.5v4.5h-1.5v-4.5zm9.508-10.025c-.594-1.559-.138-3.42 1.112-4.525a.795.795 0 111.055 1.191c-.752.666-1.038 1.829-.68 2.767.185.484.527.933.89 1.408.403.529.82 1.076 1.09 1.75.703 1.767.016 3.978-1.567 5.032a.794.794 0 01-.883-1.324c.965-.643 1.4-2.042.971-3.119-.188-.472-.523-.91-.876-1.374-.416-.544-.845-1.107-1.112-1.806zm-2.321-4.316a.75.75 0 011.304.74c-.921 1.623-.71 3.422.71 5.508a.75.75 0 11-1.24.844c-1.72-2.527-2.003-4.929-.774-7.092z" />
  </svg>
)

export default Smoking
