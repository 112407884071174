import * as React from 'react'

const Book = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11.25 6.298a.75.75 0 00-.75-.75H3.826a.25.25 0 00-.25.25v11.615h6.246c.447 0 .884.133 1.256.382l.172.116V6.298zm-7.424-2.25H10.5a2.25 2.25 0 012.25 2.25v12.435c.172.14.14.982-.425.982s-.71-.047-.98-.228c-.563-.383-1.071-.574-1.523-.574H2.826a.75.75 0 01-.75-.75V5.798c0-.966.783-1.75 1.75-1.75zm9.084 13.858l.129-.086a1.75 1.75 0 01.976-.298h6.15a.25.25 0 00.25-.25v-.544h-6.21c-.781 0-1.287.525-1.295 1.178zm7.504-2.678v-9.32a.25.25 0 00-.25-.25H12.99a.25.25 0 00-.25.25v9.7c.417-.24.915-.38 1.466-.38h6.208zm-9.144 2.888a.752.752 0 01-.02-.172c0-.66-.508-1.194-1.296-1.194H3a.75.75 0 110-1.5h6.954c.487 0 .931.108 1.316.3V5.906c0-.966.784-1.75 1.75-1.75h7.174c.967 0 1.75.784 1.75 1.75v11.365a1.75 1.75 0 01-1.75 1.75h-6.149c-.05 0-.468-.022-.68.17a3.932 3.932 0 01-.345.285c-.215.145-.189.217-.448.217-.105 0-.227.024-.325 0-.572-.137-.977-.687-.977-1.302v-.276z" />
  </svg>
)

export default Book
