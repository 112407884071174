/* eslint jsx-a11y/no-noninteractive-element-interactions: 0 */

import React, { Component } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import { formatResults } from './utils'
import SearchIconSvg from '../../icons/search-icon.svg'
import './index.scss'

class GPPracticePostcodeLookupField extends Component {
  state = {
    postcode: '',
    results: [],
    loading: false,
    focussed: false,
    typingTimeout: null,
    showNoResultsMessage: false,
    showValidationMessage: false,
  }

  wrapperRef = React.createRef()

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutsideElement)
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutsideElement)
  }

  /**
   * Handle when input is focussed
   */
  handleInputFocus = () => {
    this.setState({
      focussed: true,
    })
  }

  /**
   * Handle keypress event on input
   */
  handlePostcodeInputKeyPress = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      await this.handleFetchPostcodeResults()

      return false
    }

    return undefined
  }

  /**
   * Handle fetch postcode results
   */
  handleFetchPostcodeResults = async () => {
    const { fetchPostcodeResults } = this.props
    const { loading, postcode } = this.state

    if (loading || typeof fetchPostcodeResults !== 'function') {
      return undefined
    }

    if (!this.isPostcodeValid(postcode)) {
      if (postcode.length > 0) {
        return this.setState({
          results: [],
          showValidationMessage: true,
        })
      }

      return this.setState({
        results: [],
      })
    }

    this.setState(
      {
        loading: true,
        results: [],
      },
      async () => {
        try {
          const results = await fetchPostcodeResults(postcode)

          this.setState({
            results: formatResults(results),
            loading: false,
          })
        } catch {
          this.setState({
            loading: false,
            showNoResultsMessage: true,
          })
        }
      }
    )

    return undefined
  }

  /**
   * Handle postcode lookup input change
   *
   * @param {SyntheticEvent} event React input event
   */
  handlePostcodeInputchange = async (event) => {
    const { typingTimeout } = this.state

    this.setState(
      {
        postcode: event.currentTarget.value,
        showNoResultsMessage: false,
        showValidationMessage: false,
      },
      async () => {
        if (typingTimeout) {
          clearTimeout(typingTimeout)
        }

        this.setState({
          typingTimeout: setTimeout(async () => {
            await this.handleFetchPostcodeResults()
          }, 600),
        })
      }
    )
  }

  /**
   * Handle when result item is clicked
   *
   * @param {Object} result Result item object
   */
  handleLookupResultItemClick = (result) => {
    const { onChange } = this.props

    this.setState({
      focussed: false,
    })

    if (typeof onChange === 'function') {
      onChange(result)
    }
  }

  /**
   * Handle click outside of wrapper element
   *
   * @param {SyntheticEvent} event React event object
   */
  handleClickOutsideElement = (event) => {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({
        focussed: false,
      })
    }
  }

  /**
   * Check if popup is open or not
   *
   * @returns {Boolean}
   */
  isPopupOpen = () => {
    const {
      results,
      loading,
      focussed,
      showNoResultsMessage,
      showValidationMessage,
    } = this.state

    return (
      focussed &&
      (results.length > 0 ||
        showNoResultsMessage ||
        showValidationMessage ||
        loading)
    )
  }

  /**
   * Check if the entered postcode is valid
   *
   * @returns {Boolean}
   */
  isPostcodeValid = () => {
    const { postcode } = this.state
    const { postcodeValidationRegex } = this.props
    const regexp = new RegExp(postcodeValidationRegex)

    return regexp.test(postcode)
  }

  /**
   * Check if state has some results
   *
   * @returns {Boolean}
   */
  hasResults = () => {
    const { results } = this.state

    return results && results.length > 0
  }

  /**
   * Render label above input box
   */
  renderLabel = () => {
    const { id, label, labelInfoText } = this.props

    if (!label) {
      return false
    }

    return (
      <label
        className="core-ui-gp-practice-postcode-lookup__label"
        htmlFor={id}
      >
        <span className="core-ui-gp-practice-postcode-lookup__labelText">
          {label}
        </span>

        {labelInfoText && (
          <span className="core-ui-gp-practice-postcode-lookup__labelInfoText">
            {labelInfoText}
          </span>
        )}
      </label>
    )
  }

  render() {
    const {
      id,
      placeholder,
      loadingMessage,
      noResultsFoundMessage,
      invalidPostcodeMessage,
    } = this.props

    const {
      results,
      loading,
      focussed,
      postcode,
      showNoResultsMessage,
      showValidationMessage,
    } = this.state

    return (
      <div className="core-ui-gp-practice-postcode-lookup">
        {this.renderLabel()}

        <div
          ref={this.wrapperRef}
          className={cn('core-ui-gp-practice-postcode-lookup__wrapper', {
            'core-ui-gp-practice-postcode-lookup__wrapper--focussed': focussed,
            'core-ui-gp-practice-postcode-lookup__wrapper--popup-open': this.isPopupOpen(),
          })}
        >
          <div className="core-ui-gp-practice-postcode-lookup__inputWrapper">
            <img
              src={SearchIconSvg}
              alt="Search icon"
              className="core-ui-gp-practice-postcode-lookup__inputIcon"
            />

            <input
              id={id}
              type="text"
              placeholder={placeholder}
              value={postcode}
              className="core-ui-gp-practice-postcode-lookup__input"
              onChange={this.handlePostcodeInputchange}
              onFocus={this.handleInputFocus}
              onKeyPress={this.handlePostcodeInputKeyPress}
            />
          </div>

          {this.isPopupOpen() && (
            <div className="core-ui-gp-practice-postcode-lookup__resultsWrapper">
              {!loading && this.hasResults() > 0 && (
                <ul className="core-ui-gp-practice-postcode-lookup__resultList">
                  {results.map(
                    ({ display: { name, postcode, address } }, index) => (
                      <li
                        key={`${name}-${postcode}-${address}`}
                        className="core-ui-gp-practice-postcode-lookup__resultItem"
                        data-testid="dropdown-option"
                        onClick={() =>
                          this.handleLookupResultItemClick(results[index])
                        }
                      >
                        <span
                          className={cn(
                            'core-ui-gp-practice-postcode-lookup__resultText',
                            'core-ui-gp-practice-postcode-lookup__resultText--name'
                          )}
                        >
                          {name}
                        </span>

                        <span
                          className={cn(
                            'core-ui-gp-practice-postcode-lookup__resultText',
                            'core-ui-gp-practice-postcode-lookup__resultText--postcode'
                          )}
                        >
                          {postcode}
                        </span>

                        <span
                          className={cn(
                            'core-ui-gp-practice-postcode-lookup__resultText',
                            'core-ui-gp-practice-postcode-lookup__resultText--address'
                          )}
                        >
                          {address}
                        </span>
                      </li>
                    )
                  )}
                </ul>
              )}

              {!loading && !this.hasResults() && showNoResultsMessage && (
                <span className="core-ui-gp-practice-postcode-lookup__message">
                  {noResultsFoundMessage}
                </span>
              )}

              {!loading && !this.hasResults() && showValidationMessage && (
                <span className="core-ui-gp-practice-postcode-lookup__message">
                  {invalidPostcodeMessage}
                </span>
              )}

              {loading && (
                <div className="core-ui-gp-practice-postcode-lookup__loaderWrapper">
                  <div className="core-ui-gp-practice-postcode-lookup__loader" />
                  <span className="core-ui-gp-practice-postcode-lookup__loadingMessage">
                    {loadingMessage}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

GPPracticePostcodeLookupField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  labelInfoText: PropTypes.string,
  placeholder: PropTypes.string,
  loadingMessage: PropTypes.string,
  invalidPostcodeMessage: PropTypes.string,
  noResultsFoundMessage: PropTypes.string,
  postcodeValidationRegex: PropTypes.string,
  fetchPostcodeResults: PropTypes.func,
}

GPPracticePostcodeLookupField.defaultProps = {
  label: 'GP Practice Lookup',
  labelInfoText: 'Results displayed are within a 2 miles radius',
  placeholder: 'Enter Postcode',
  loadingMessage: 'Loading...',
  invalidPostcodeMessage: 'Please enter a valid postcode.',
  noResultsFoundMessage: 'No results were found.',
  postcodeValidationRegex:
    '^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? [0-9][A-Za-z]{2}|[Gg][Ii][Rr] 0[Aa]{2})$',
}

export default GPPracticePostcodeLookupField
