import * as React from 'react'

const Referral = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M20.101 20.62c-.569-3.41-2.56-5.028-6.143-5.028-3.583 0-5.624 1.622-6.283 5.029H20.1zm-14.046.662c.567-4.726 3.269-7.19 7.903-7.19 4.641 0 7.293 2.474 7.743 7.208a.75.75 0 01-.747.82H6.8a.75.75 0 01-.745-.838zm.865-7.902H2.786a.75.75 0 110-1.5H6.86l-.856-.856a.75.75 0 011.06-1.06l2.167 2.166a.75.75 0 010 1.06l-2.096 2.097a.75.75 0 01-1.06-1.061l.845-.846zm7.021-.472c-2.366 0-4.28-1.94-4.28-4.33 0-2.388 1.914-4.328 4.28-4.328 2.366 0 4.28 1.94 4.28 4.329 0 2.388-1.914 4.329-4.28 4.329zm0-1.5c1.533 0 2.78-1.264 2.78-2.83 0-1.564-1.247-2.828-2.78-2.828-1.532 0-2.78 1.264-2.78 2.829 0 1.565 1.248 2.829 2.78 2.829z" />
  </svg>
)

export default Referral
