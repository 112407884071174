import React, { useState } from 'react'
import cx from 'classnames'

import { Icon, LinkButton } from '@babylon/medkit'

import styles from './styles.module.scss'

interface AccordionItemProps {
  title: string
  isOpen?: boolean
  className?: string
  'data-testid'?: string
  children?: React.ReactNode
}

const AccordionItem = ({
  title,
  isOpen: isInitiallyOpen = false,
  className,
  children,
  'data-testid': dataTestId,
}: AccordionItemProps) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div
      className={cx(styles.item, className)}
      data-testid={dataTestId}
      aria-expanded={isOpen}
    >
      <LinkButton onClick={handleClick}>
        <div className={styles.title}>
          {title}
          <Icon
            className={styles.icon}
            icon={isOpen ? 'ArrowUp' : 'ArrowDown'}
            height={30}
            width={30}
            title="expand"
          />
        </div>
      </LinkButton>
      {isOpen && <div className={styles.content}>{children}</div>}
    </div>
  )
}

export default AccordionItem
