import { defineMessages } from 'react-intl'

const baseId = 'components.search'

export default defineMessages({
  searchLabel: {
    id: `${baseId}.search`,
    defaultMessage: 'Search list',
  },
})
