import * as React from 'react'

const SpecialistCircleFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 2.4c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6-4.3-9.6-9.6-9.6zm0 2.88c1.86 0 3.36 1.5 3.36 3.36 0 1.86-1.5 3.36-3.36 3.36-1.86 0-3.36-1.5-3.36-3.36 0-1.86 1.5-3.36 3.36-3.36zm-.563 8.184v.966a.562.562 0 101.126 0v-.966c2.174.173 5.648 1.284 5.677 3.18a7.505 7.505 0 01-4.723 3.194c-.098-.357-.314-.832-.356-1.036l-.562-2.776c-.122-.602-.98-.602-1.103 0l-.562 2.776c-.042.208-.256.698-.345 1.057a7.503 7.503 0 01-4.829-3.216c.029-1.896 3.497-3.006 5.678-3.18z" />
  </svg>
)

export default SpecialistCircleFilled
