import * as React from 'react'

const VoucherFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M20 5.235c.967 0 1.75.783 1.75 1.75V9.22a1.75 1.75 0 01-1.2 1.661c-.405.134-.593.444-.593 1.118 0 .693.205 1.035.645 1.197a1.75 1.75 0 011.148 1.643v2.21A1.75 1.75 0 0120 18.8H4a1.75 1.75 0 01-1.75-1.75v-2.199c0-.74.465-1.4 1.162-1.648.385-.137.576-.477.576-1.203 0-.718-.185-1.046-.557-1.174A1.75 1.75 0 012.25 9.17V6.985c0-.967.784-1.75 1.75-1.75h16zm-7.49 3.484c-.18.117-.346.253-.494.405a2.78 2.78 0 00-.453-.383 2.885 2.885 0 00-1.62-.491c-1.532 0-2.693 1.263-2.693 2.795 0 1.76.888 3.125 2.324 4.116.832.574 1.657.91 2.233 1.064a.75.75 0 00.386 0c.576-.154 1.4-.49 2.233-1.064 1.436-.991 2.324-2.356 2.324-4.116 0-1.503-1.227-2.795-2.694-2.795-.542 0-1.082.167-1.545.469zm-.168 5.867a6.508 6.508 0 01-.342.129 6.498 6.498 0 01-1.574-.789c-1.064-.734-1.676-1.675-1.676-2.88 0-.731.52-1.296 1.194-1.296.286 0 .556.083.779.234.21.142.369.338.454.56.054.139.057.146.064.172.007.023.009.04.009.094 0 1 1.5 1 1.5 0a.22.22 0 01.007-.076c.008-.024.005-.018.068-.17.094-.228.272-.437.504-.588.225-.146.482-.226.727-.226.62 0 1.194.604 1.194 1.295 0 1.206-.612 2.147-1.676 2.881a6.49 6.49 0 01-1.232.66z" />
  </svg>
)

export default VoucherFilled
