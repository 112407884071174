import * as React from 'react'

const Info = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zm0-1.5a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5zM12 7.5a1 1 0 100-2 1 1 0 000 2zm.75 9.25H14a.75.75 0 110 1.5h-4a.75.75 0 110-1.5h1.25v-6.5H10a.75.75 0 010-1.5h2a.75.75 0 01.75.75v7.25z" />
  </svg>
)

export default Info
