import * as React from 'react'

const ShareAndroid = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M7.602 11.838l-.653-1.35 9.553-4.617.653 1.35-9.553 4.617zm-.278.482l10.079 4.828-.648 1.353-10.079-4.828.648-1.353zm10.937-3.824a2.75 2.75 0 110-5.5 2.75 2.75 0 010 5.5zm0-1.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm.048 13.953a2.75 2.75 0 110-5.5 2.75 2.75 0 010 5.5zm0-1.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM5.653 14.787a2.75 2.75 0 110-5.5 2.75 2.75 0 010 5.5zm0-1.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z" />
  </svg>
)

export default ShareAndroid
