import * as React from 'react'

const HouseFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M7.75 21.325H5.533a2.25 2.25 0 01-2.25-2.257v-8.582h-.5c-.722 0-1.027-.918-.451-1.35l9.236-6.932a.75.75 0 01.9 0l9.236 6.932c.577.432.27 1.35-.45 1.35h-.434v8.582a2.25 2.25 0 01-2.24 2.257h-2.33v.018h-8.5v-.018zm5.413-1.482v-5.606h-2.377v5.606h2.377z" />
  </svg>
)

export default HouseFilled
