import * as React from 'react'

const Brain = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21.75 11.21a3.89 3.89 0 01-.313 1.528l.001.103c0 1.87-1.332 3.448-3.12 3.847l-.173 1.793a1.411 1.411 0 01-1.409 1.269h-1.77a1.43 1.43 0 01-.99-.396l-2.23-2.15a4.033 4.033 0 01-3.292-1.231c-.346.071-.7.107-1.059.107-2.835 0-5.145-2.266-5.145-5.061 0-2.304 1.581-4.299 3.802-4.886a4.027 4.027 0 013.246-1.637 4.03 4.03 0 011.352.23 4.039 4.039 0 011.905-.476 4.026 4.026 0 012.732 1.057 4.05 4.05 0 01.974-.118c1.808 0 3.36 1.165 3.848 2.839a3.94 3.94 0 011.64 3.182zm-2.687-2.078a.75.75 0 01-.346-.49c-.233-1.138-1.246-1.953-2.457-1.953a2.56 2.56 0 00-.92.17.75.75 0 01-.843-.215 2.527 2.527 0 00-1.94-.894c-.513 0-1.006.15-1.423.433a.75.75 0 01-.741.057 2.542 2.542 0 00-1.095-.244 2.529 2.529 0 00-2.153 1.196.75.75 0 01-.49.34C4.969 7.87 3.75 9.33 3.75 11.019c0 1.96 1.632 3.56 3.645 3.56.38 0 .75-.055 1.105-.166a.75.75 0 01.829.273 2.532 2.532 0 002.53.989.75.75 0 01.664.196l2.47 2.379h1.667l.222-2.293a.75.75 0 01.702-.677c1.318-.077 2.354-1.151 2.354-2.439 0-.06-.003-.123-.008-.191a.75.75 0 01.075-.387c.16-.328.244-.688.244-1.053 0-.84-.452-1.633-1.186-2.078zm-8.986-3.498a.75.75 0 111.482.233c-.265 1.677.156 2.54 1.283 2.878a.75.75 0 01-.431 1.437c-1.92-.576-2.706-2.189-2.334-4.548zm-.265 9.14a.75.75 0 01-1.494-.13c.147-1.69-.333-2.522-1.48-2.78a.75.75 0 11.329-1.464c1.956.44 2.852 1.994 2.645 4.374zm8.554 1.618a.75.75 0 11-1.494-.13c.147-1.69-.333-2.522-1.48-2.78a.75.75 0 11.329-1.464c1.956.44 2.852 1.994 2.645 4.374zm-5.816.602a.75.75 0 01-1.183-.923l1.187-1.52a.75.75 0 011.182.923l-1.186 1.52zm5.488-6.788a.75.75 0 01-.505-1.412l1.402-.5a.75.75 0 11.504 1.412l-1.401.5z" />
  </svg>
)

export default Brain
