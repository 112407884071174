import { useContext, useState } from 'react'

import { ModalStackContext } from '@/components/ModalStack'

function useModal(Component) {
  const { createModal } = useContext(ModalStackContext)
  const [modal] = useState(() => createModal(Component))

  return modal
}

export default useModal
