import * as React from 'react'

const DownloadFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10.806 11.25l2.724-2.72a.75.75 0 00-1.06-1.06l-4 3.993a.75.75 0 000 1.06l4 4.007a.75.75 0 101.06-1.06l-2.715-2.72h6.317a.75.75 0 100-1.5h-6.326zM12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zM6.75 8.5v7a.75.75 0 101.5 0v-7a.75.75 0 00-1.5 0z" />
  </svg>
)

export default DownloadFilled
