import * as React from 'react'

const Ar = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M9.482 15.302l-.197-.62a1 1 0 00-.953-.698h-1.51a1 1 0 00-.952.693l-.203.63a1 1 0 01-.952.693h-.16a.631.631 0 01-.596-.839L6.37 8.217a1 1 0 01.945-.672h.657a1 1 0 01.945.672l2.388 6.878a.681.681 0 01-.644.905h-.226a1 1 0 01-.953-.698zm-1.947-6l-1.06 3.317h2.214L7.64 9.303h-.105zm7.111-.327v2.601H16.2c.89 0 1.412-.48 1.412-1.295 0-.802-.55-1.306-1.43-1.306h-1.535zm0 4.684v1.456a.885.885 0 11-1.77 0v-6.57a1 1 0 011-1h2.552c1.857 0 3 1.031 3 2.713 0 1.078-.563 2.015-1.535 2.402l1.31 2.456a.601.601 0 01-.531.884h-.392a1 1 0 01-.89-.543l-1.017-1.979a1 1 0 00-.89-.542h-.113c-.4 0-.724.324-.724.723z" />
  </svg>
)

export default Ar
