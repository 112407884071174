import { useCallback, useContext, useMemo } from 'react'

import { CurrentUserContext } from './core'

export const useRootPartner = (): [
  Array<string>,
  (fullPath: string | undefined) => boolean
] => {
  const currentUser = useContext(CurrentUserContext)
  const rootPartnerIds = useMemo(() => currentUser?.partnerIds || [], [
    currentUser?.partnerIds,
  ])

  const isPermitted = useCallback(
    (fullPath) =>
      fullPath
        ? fullPath
            .split('.')
            .some((partnerId: string) => rootPartnerIds.includes(partnerId))
        : true,

    [rootPartnerIds]
  )

  return [rootPartnerIds, isPermitted]
}
