import * as React from 'react'

const ArrowSwitch = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18.329 16.356H9.25a.75.75 0 010-1.5h8.7l-2.229-2.218a.75.75 0 011.058-1.064l3.705 3.687a.75.75 0 010 1.062l-3.704 3.699a.75.75 0 11-1.06-1.062l2.609-2.604zM6.052 8.058h9.213a.75.75 0 010 1.5H6.078l2.407 2.402a.75.75 0 11-1.06 1.062L3.72 9.323a.75.75 0 01.001-1.062l3.705-3.687a.75.75 0 111.058 1.064l-2.432 2.42z" />
  </svg>
)

export default ArrowSwitch
