import { UTAG } from '@babylon/tracking/tealium'

declare global {
  interface Window {
    utag: UTAG
  }
}

const injectScript = (src: string) =>
  new Promise<UTAG>((resolve, reject) => {
    const script = document.createElement('script')
    script.async = true
    script.type = 'text/javascript'
    script.onload = () => {
      resolve(window.utag)
    }
    script.onerror = () => {
      reject()
    }
    script.src = src

    const head = document.getElementsByTagName('head')[0]
    head.appendChild(script)
  })

export { injectScript }
