import * as React from 'react'

const Location = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11.25 19.2a7.253 7.253 0 01-6.454-6.39H2.675a.75.75 0 110-1.5H4.78a7.252 7.252 0 016.469-6.534V2.734a.75.75 0 111.5 0v2.042a7.252 7.252 0 016.469 6.533h2.106a.75.75 0 010 1.5h-2.121a7.253 7.253 0 01-6.454 6.39v2.083a.75.75 0 11-1.5 0V19.2zm.75-1.462a5.75 5.75 0 100-11.5 5.75 5.75 0 000 11.5zm0-1.45a4.25 4.25 0 110-8.5 4.25 4.25 0 010 8.5zm0-1.5a2.75 2.75 0 100-5.5 2.75 2.75 0 000 5.5z" />
  </svg>
)

export default Location
