import * as React from 'react'

const ChildCircle = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8.65 19.541a8.221 8.221 0 003.35.709 8.223 8.223 0 003.255-.667c-1.078-.413-2.37-.673-3.355-.673-.942 0-2.19.242-3.25.631zm-1.594-.936c1.397-.73 3.381-1.195 4.844-1.195 1.515 0 3.546.49 4.958 1.259a8.25 8.25 0 10-9.801-.064zM12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zm0-16.5c3.18 0 5.75 2.57 5.75 5.75s-2.57 5.75-5.75 5.75S6.25 14.18 6.25 11 8.82 5.25 12 5.25zm0 1.5A4.243 4.243 0 007.75 11 4.243 4.243 0 0012 15.25 4.243 4.243 0 0016.25 11 4.243 4.243 0 0012 6.75zm.334 5.58a.75.75 0 111.114 1.005c-.422.468-.929.735-1.488.735-.58 0-1.107-.272-1.537-.759a.75.75 0 111.123-.994c.163.184.296.253.414.253.093 0 .217-.065.374-.24zM14 11a1 1 0 110-2 1 1 0 010 2zm-3-1a1 1 0 11-2 0 1 1 0 012 0z" />
  </svg>
)

export default ChildCircle
