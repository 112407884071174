import { createRandomToken } from './utils'

const STORAGE_KEY_SUFFIX = ':request-id-generator-token'
const DEFAULT_SEPARATOR = ';'
const DEFAULT_APPLICATION_NAME = 'unknown'

interface Options {
  app?: string
  separator?: string
}

class RequestIdGenerator {
  separator: string

  name: string

  storageKey: string

  extension: Function | null

  constructor(options: Options = {}) {
    const { app, separator } = options

    this.separator = separator || DEFAULT_SEPARATOR
    this.name = `app=${app || DEFAULT_APPLICATION_NAME}`
    this.storageKey = this.name + STORAGE_KEY_SUFFIX
    this.extension = null
  }

  userToken = () => {
    const cachedUserToken = localStorage.getItem(this.storageKey)

    if (!cachedUserToken || cachedUserToken.startsWith('user=')) {
      const token = createRandomToken()
      localStorage.setItem(this.storageKey, token)

      return token
    }

    return cachedUserToken
  }

  sessionToken = () => {
    const cachedSessionToken = sessionStorage.getItem(this.storageKey)

    if (!cachedSessionToken || cachedSessionToken.startsWith('session=')) {
      const token = createRandomToken()
      sessionStorage.setItem(this.storageKey, token)

      return token
    }

    return cachedSessionToken
  }

  eventToken = () => {
    const token = createRandomToken()

    return token
  }

  /**
   * Result of this function will be appended to each request id
   *
   * @param {function} extension
   */
  extend = (extension: Function) => {
    if (typeof extension !== 'function') {
      throw new Error('RequestIdGenerator: extension method requires function')
    }

    this.extension = extension
  }

  /**
   * Generates a random request id
   */
  generate = () => {
    const { userToken, sessionToken, eventToken, name, extension } = this
    const chunks = [
      name,
      `user=${userToken()}`,
      `session=${sessionToken()}`,
      `event=${eventToken()}`,
    ]

    if (extension) {
      chunks.push(extension())
    }

    return chunks.join(this.separator)
  }
}

export default RequestIdGenerator
