import * as React from 'react'

const ArrowCircleUpFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M7.6 12.655l3.87 3.875a.75.75 0 101.06-1.06l-2.715-2.72h6.213a.75.75 0 100-1.5H9.806l2.724-2.72a.75.75 0 00-1.06-1.06l-3.9 3.893a.75.75 0 00.03 1.292zM12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75z" />
  </svg>
)

export default ArrowCircleUpFilled
