import React from 'react'
import { CSSTransition } from 'react-transition-group'

import styles from './styles.module.scss'

interface ModalAnimationProps {
  children?: React.ReactNode
  show: boolean
  onHide: () => void
}

const ModalAnimation = ({
  children,
  show = true,
  onHide,
}: ModalAnimationProps) => (
  <CSSTransition
    in={show}
    timeout={200}
    unmountOnExit
    onExited={onHide}
    classNames={{
      appear: styles.enter,
      enter: styles.enter,
      appearActive: styles.enterActive,
      enterActive: styles.enterActive,
      exit: styles.exit,
      exitActive: styles.exitActive,
    }}
  >
    {children}
  </CSSTransition>
)

export default ModalAnimation
