import React from 'react'

import { useCurrentUser } from '@/components/CurrentUser'
import createExperiences from './createExperiences'
import ExperiencesContext from './ExperiencesContext'

interface ExperiencesProviderProps {
  experiences?: {
    [key: string]: string
  }
  children: React.ReactNode
}

const ExperiencesProvider = ({
  experiences = {},
  children,
}: ExperiencesProviderProps) => {
  const { isPermitted } = useCurrentUser()

  return (
    <ExperiencesContext.Provider
      value={{
        ...createExperiences({ isPermitted }),
        ...experiences,
      }}
    >
      {children}
    </ExperiencesContext.Provider>
  )
}
export default ExperiencesProvider
