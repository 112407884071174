import React from 'react'

import { PersistentStoreContext } from './core'

function PersistentStore({ persistentStore, children }) {
  return (
    <PersistentStoreContext.Provider value={persistentStore}>
      {children}
    </PersistentStoreContext.Provider>
  )
}

export default PersistentStore
