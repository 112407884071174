/* eslint-disable react/no-array-index-key */

import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { getPaginationItemsWithDots } from './utils'
import PaginationArrowSVG from '../../../icons/PaginationArrowSVG'

import './index.scss'

const Pagination = ({
  currentPage,
  totalPages,
  pageDelta,
  onPrevClick,
  onNextClick,
  onPageClick,
}) => {
  if (totalPages === 0) {
    return false
  }

  const seperator = '...'

  const pageNumbers = getPaginationItemsWithDots(
    currentPage,
    totalPages,
    seperator,
    pageDelta
  )

  const clickHandler = (pageNumber) => {
    if (pageNumber !== seperator && typeof onPageClick === 'function') {
      onPageClick(pageNumber)
    }
  }

  return (
    <div className="core-ui-pagination">
      <div className="core-ui-pagination__wrapper">
        {currentPage !== 1 && (
          <button className="core-ui-pagination__button" onClick={onPrevClick}>
            <PaginationArrowSVG
              alt="Arrow"
              className={cn(
                'core-ui-pagination__button-icon',
                'core-ui-pagination__button-icon--prev'
              )}
            />
          </button>
        )}

        {pageNumbers.map((number, index) => (
          <button
            key={index}
            onClick={() => clickHandler(number)}
            className={cn('core-ui-pagination__button', {
              'core-ui-pagination__button--selected': number === currentPage,
              'core-ui-pagination__button--seperator': number === seperator,
            })}
          >
            {number}
          </button>
        ))}

        {currentPage !== totalPages && (
          <button className="core-ui-pagination__button" onClick={onNextClick}>
            <PaginationArrowSVG
              alt="Arrow"
              className={cn(
                'core-ui-pagination__button-icon',
                'core-ui-pagination__button-icon--next'
              )}
            />
          </button>
        )}
      </div>
    </div>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  pageDelta: PropTypes.number,
  onPageClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
}

Pagination.defaultProps = {
  pageDelta: 2,
}

export default Pagination
