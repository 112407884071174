import * as React from 'react'

const Selfie = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16.918 6.08a.75.75 0 110-1.5c.967 0 1.75.783 1.75 1.75v13.938a1.75 1.75 0 01-1.75 1.75H7.086a1.75 1.75 0 01-1.75-1.75V6.33c0-.967.784-1.75 1.75-1.75a.75.75 0 010 1.5.25.25 0 00-.25.25v13.938c0 .138.112.25.25.25h9.832a.25.25 0 00.25-.25V6.33a.25.25 0 00-.25-.25zm-4.118.774a.796.796 0 01-.8.792.796.796 0 01-.8-.792c0-.437.358-.792.8-.792.442 0 .8.355.8.792zm-.05-2.375a.75.75 0 11-1.5 0V3.296a.75.75 0 111.5 0v1.183zm-1.82.261a.75.75 0 11-1.06 1.061l-.805-.804a.75.75 0 011.06-1.06l.805.803zm3.176 1.084a.75.75 0 11-1.012-1.107L14 3.89a.75.75 0 011.012 1.107l-.906.828zM10.5 19.75a.75.75 0 110-1.5h3a.75.75 0 110 1.5h-3zm-1.096-2.957a.75.75 0 11-1.49-.178c.289-2.42 1.717-3.733 4.086-3.733 2.376 0 3.782 1.323 4.012 3.752a.75.75 0 01-1.494.14c-.157-1.668-.927-2.392-2.518-2.392-1.598 0-2.396.734-2.596 2.41zM12 13.143c-1.328 0-2.397-1.093-2.397-2.434 0-1.34 1.07-2.434 2.397-2.434 1.328 0 2.397 1.093 2.397 2.434 0 1.34-1.07 2.434-2.397 2.434zm0-1.5c.492 0 .897-.414.897-.934s-.405-.934-.897-.934-.897.414-.897.934.405.934.897.934z" />
  </svg>
)

export default Selfie
