import React, { useReducer, useCallback, useMemo } from 'react'
import { useQuery } from 'react-apollo'
import { useHistory } from 'react-router-dom'
import { DocumentNode } from 'graphql'
import cx from 'classnames'

import { useFormatMessage } from '@babylon/intl'

import Loader from '@/components/Loader'
import Error from '@/components/Error'
import { Search } from '@/ui'
import { useUrlSearchParams } from '@/utils'

import Pagination from './Pagination'
import PageSize from './PageSize'
import Sort from './Sort'
import messages from './messages'
import styles from './TableQuery.module.scss'
import { PageSizeOptionsType } from '@/types'

const DEFAULT_PAGE = 1
const DEFAULT_PAGE_SIZE = 20
const DEFAULT_SORT = '-created_at'
const DEFAULT_SEARCH = ''

export interface TableQueryDisplayParamsType {
  search: string
  sort: string
  pageSize: number
  page: number
}

interface HeaderProps {
  name: string
  label: string
  sortable?: boolean
  wrap?: boolean
}

interface RenderTableHeaderProps {
  headers: HeaderProps[]
  sort: string
  onSort: (newState: any) => any
}

const renderTableHeader = ({
  headers,
  sort,
  onSort,
}: RenderTableHeaderProps) => (
  <tr>
    {headers.map(({ name, label, sortable }) => (
      <th key={name}>
        <div>
          {sortable && <Sort name={name} sort={sort} onChange={onSort} />}
          {label}
        </div>
      </th>
    ))}
  </tr>
)

const reducer = (state: any, newState: any) => ({ ...state, ...newState })

export const useTableQueryDisplayParams = () => {
  const urlSearchParams = useUrlSearchParams()
  const urlPageSize = urlSearchParams.get('pageSize')
  const urlPage = urlSearchParams.get('page')

  return {
    sort: urlSearchParams.get('sort') || DEFAULT_SORT,
    pageSize:
      urlPageSize && Number.isSafeInteger(+urlPageSize)
        ? +urlPageSize
        : DEFAULT_PAGE_SIZE,
    page: urlPage && Number.isSafeInteger(+urlPage) ? +urlPage : DEFAULT_PAGE,
    search: urlSearchParams.get('search') || DEFAULT_SEARCH,
  }
}

interface TableQueryProps {
  headers: HeaderProps[]
  query: DocumentNode
  resultsPath?: string
  variables?: { [key: string]: any }
  rowComponent: (
    props: any
  ) => { [key: string]: React.ReactElement | string | undefined }
  className?: string
  pageSizeOptions?: PageSizeOptionsType[]
  'data-testid'?: string
  searchable?: boolean
  showListSize?: boolean
  listType?: string
}

export default ({
  headers,
  query,
  resultsPath = '',
  variables = {},
  rowComponent,
  className,
  pageSizeOptions,
  showListSize,
  'data-testid': dataTestId = 'tableQuery',
  searchable = false,
  listType,
}: TableQueryProps) => {
  const fm = useFormatMessage()
  const history = useHistory()
  const urlSearchParams = useUrlSearchParams()

  const [state, dispatch] = useReducer(reducer, {
    ...useTableQueryDisplayParams(),
  })
  const { page, pageSize, sort, search } = state
  const { loading, error, data, refetch } = useQuery(query, {
    errorPolicy: 'all',
    variables: {
      ...variables,
      query: search,
      offset: (page - 1) * pageSize,
      limit: pageSize,
      sort,
    },
  })

  const numberFormatter = new Intl.NumberFormat()

  const { items = [], totalItems } =
    (data &&
      resultsPath.split('.').reduce((acc, path) => acc?.[path] || acc, data)) ||
    {}

  const SearchField: React.ReactElement = useMemo(
    () =>
      searchable ? (
        <div data-testid="tableQuerySearchContainer">
          <Search
            defaultValue={search}
            onChange={({ search }) => updateTableQuery({ search })}
            placeholder={fm(messages.searchPlaceholder)}
            className={styles.searchInput}
          />
        </div>
      ) : (
        <></>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchable]
  )

  const updateTableQuery = useCallback(
    (newState) => {
      dispatch(newState)

      const {
        page: pageParam,
        pageSize: pageSizeParam,
        sort: sortParam,
        search: searchParam,
      }: TableQueryDisplayParamsType = {
        ...state,
        ...newState,
      }

      urlSearchParams.set('page', String(pageParam))
      urlSearchParams.set('pageSize', String(pageSizeParam))
      urlSearchParams.set('sort', sortParam)
      if (searchable) {
        urlSearchParams.set('search', searchParam)
      }
      history.push({
        search: urlSearchParams.toString(),
      })

      return refetch({
        ...variables,
        query: searchParam,
        offset: (pageParam - 1) * pageSizeParam,
        limit: pageSizeParam,
        sort: sortParam,
      })
    },
    [state, urlSearchParams, history, refetch, variables, searchable]
  )

  const rows = items.map((item: any) => rowComponent(item))
  const fullRow = useCallback(
    (content) => (
      <tr>
        <td colSpan={headers.length}>{content}</td>
      </tr>
    ),
    [headers]
  )

  return (
    <>
      {showListSize &&
        (data ? (
          <label htmlFor="searchLabel" className={styles.label}>
            {fm(messages.searchLabel, {
              count: numberFormatter.format(totalItems),
              type: totalItems === 1 ? listType : `${listType}s`,
            })}
          </label>
        ) : (
          <Loader />
        ))}
      {SearchField}
      <table className={cx(styles.table, className)} data-testid={dataTestId}>
        {headers.length > 0 && (
          <thead>
            {renderTableHeader({ headers, sort, onSort: updateTableQuery })}
          </thead>
        )}
        <tbody>
          {loading && fullRow(<Loader />)}
          {!loading && (
            <>
              {error &&
                fullRow(
                  <Error error={error}>{fm(messages.loadingError)}</Error>
                )}
              {!rows.length &&
                fullRow(<div>{fm(messages.noItemsMessage)}</div>)}
              {rows.length > 0 &&
                rows.map((props: any) => (
                  <tr key={props.id}>
                    {headers.map(({ name, wrap }) => (
                      <td key={name} className={cx({ [styles.wrap]: wrap })}>
                        {props[name]}
                      </td>
                    ))}
                  </tr>
                ))}
            </>
          )}
        </tbody>
      </table>
      <div className={styles.pageActions}>
        {totalItems > 0 && (
          <>
            <PageSize
              pageSize={pageSize}
              onChange={updateTableQuery}
              options={pageSizeOptions}
            />
            <Pagination
              page={page}
              totalItems={totalItems}
              pageSize={pageSize}
              onChange={updateTableQuery}
            />
          </>
        )}
      </div>
    </>
  )
}
