import * as React from 'react'

const CameraFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17.915 5.894H20c.966 0 1.75.784 1.75 1.75V19A1.75 1.75 0 0120 20.75H4A1.75 1.75 0 012.25 19V7.644c0-.966.784-1.75 1.75-1.75h2.157l2.008-2.103a1.75 1.75 0 011.266-.541h5.157c.47 0 .921.19 1.25.525l2.077 2.12zM12 17.25a4.75 4.75 0 100-9.5 4.75 4.75 0 000 9.5zm0-1.5a3.25 3.25 0 100-6.5 3.25 3.25 0 000 6.5z" />
  </svg>
)

export default CameraFilled
