import * as React from 'react'

const AudioClipFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M15.86 12.006h-.508c-1.153 0-2.053.673-2.053 1.923s.9 1.922 2.053 1.922c.562 0 .798-.014 1.099-.104.572-.171.954-.611.954-1.247v-1.561a.77.77 0 00.015-.153V7.817a.78.78 0 00-1.117-.704L11.527 9.4a.78.78 0 00-.443.704v3.046h-.493c-1.153 0-2.053.673-2.053 1.922 0 1.25.9 1.923 2.053 1.923.562 0 .798-.014 1.099-.104.572-.171.954-.612.954-1.247v-5.05l3.216-1.539v2.95zM2.146 18.255V5.775c0-.963.788-1.745 1.755-1.745h16.198c.968 0 1.755.78 1.755 1.744v12.48c0 .963-.788 1.745-1.755 1.745H3.901a1.75 1.75 0 01-1.755-1.744zm1.5 0c0 .133.017.244.038.244h2.128c.02 0 .038-.113.038-.244V5.775c0-.134-.017-.245-.038-.245H3.684c-.021 0-.038.113-.038.244v12.48z" />
  </svg>
)

export default AudioClipFilled
