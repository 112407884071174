import * as React from 'react'

const BookFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.293 5.917v-.01c0-.966.784-1.75 1.75-1.75h6.15c.966 0 1.75.784 1.75 1.75v11.365a1.75 1.75 0 01-1.75 1.75h-6.15c-.05 0-.468-.022-.68.17a3.932 3.932 0 01-.345.285c-.215.145-.189.217-.448.217-.039 0-.08.004-.121.006a.5.5 0 01-.124.015c-.565 0-.71-.047-.98-.228-.563-.383-1.071-.574-1.523-.574H2.826a.75.75 0 01-.75-.75V5.798c0-.966.783-1.75 1.75-1.75h6.249a2.25 2.25 0 012.218 1.869zm-2.183 10.61H3.826a.25.25 0 00-.25.25v.636h6.246c.447 0 .884.133 1.256.382l.172.116c-.115-.923-.495-1.384-1.14-1.384zm4.106 0c-.645 0-1.025.461-1.14 1.384l.172-.116a2.25 2.25 0 011.256-.382h6.246v-.636a.25.25 0 00-.25-.25h-6.284z" />
  </svg>
)

export default BookFilled
