import React, { useState, useEffect, Fragment, useCallback } from 'react'
import { IntlProvider, useIntl } from 'react-intl'

import BabylonLocaleContext from '../BabylonLocaleContext'
import defaultFormats from '../defaultFormats'
import { getLocaleWithDashes } from '../utils'

const BabylonIntl = ({
  children,
  initialLocale,
  defaultLocale = 'en-GB',
  getMessagesOnLocaleChange,
  formats,
  loadingComponent: LoadingComponent,
  initialMessages = {},
  timeZone,
  onError,
  textComponent = Fragment,
}) => {
  const [loading, setLoading] = useState(
    typeof getMessagesOnLocaleChange === 'function'
  )
  const [locale, setLocale] = useState(initialLocale)
  const [messages, setMessages] = useState(initialMessages)

  useEffect(() => {
    if (typeof getMessagesOnLocaleChange === 'function') {
      const fetchMessages = async () => {
        setMessages(await getMessagesOnLocaleChange(locale))
        setLoading(false)
      }
      fetchMessages()
    }
  }, [getMessagesOnLocaleChange, locale])

  const changeLocale = useCallback(
    (nextLocale) => {
      if (locale !== nextLocale) {
        setLoading(true)
        setLocale(nextLocale)
      }
    },
    [setLoading, setLocale, locale]
  )

  // if intl context already exists higher in the component tree
  // we should return early by rendering children
  try {
    const intl = useIntl()

    if (intl) {
      // This triggers fetching the messages of the component that's trying to
      // use higher level intl context.
      intl.setLocale(locale)

      return children
    }
  } catch (err) {
    // do nothing
  }

  if (loading) {
    return LoadingComponent ? <LoadingComponent /> : null
  }

  const localeWithDashes = getLocaleWithDashes(locale)

  return (
    <BabylonLocaleContext.Provider
      value={{
        locale: localeWithDashes,
        changeLocale,
      }}
    >
      <IntlProvider
        locale={localeWithDashes}
        key={locale}
        messages={messages}
        formats={formats}
        defaultLocale={defaultLocale}
        defaultFormats={defaultFormats}
        timeZone={timeZone}
        onError={onError}
        textComponent={textComponent}
      >
        {children}
      </IntlProvider>
    </BabylonLocaleContext.Provider>
  )
}

export default BabylonIntl
