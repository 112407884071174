import React from 'react'
import cn from 'classnames'
import { ArrowDown } from '@babylon/icons'
import styles from './index.module.scss'
import { IMedkitComponent } from '../..'
import BaseInput, { BaseInputProps } from '../BaseInput/BaseInput'
import { selectTheme } from './theme'

export const Select: IMedkitComponent<Props, typeof selectTheme> = ({
  className,
  inputClassName,
  options,
  placeholder,
  endAdornment,
  endAdornmentClassName,
  ...props
}: Props) => (
  <BaseInput
    {...props}
    className={cn(styles.Select, className)}
    inputClassName={cn(styles.Select__select, inputClassName)}
    component="select"
    endAdornment={
      endAdornment || <ArrowDown className={styles.Select__iconImage} />
    }
    endAdornmentClassName={endAdornmentClassName || styles.Select__icon}
  >
    {placeholder ? (
      <option value="" disabled selected>
        {placeholder}
      </option>
    ) : (
      <option hidden disabled value="" />
    )}
    {options.map(({ value, label, key }) => (
      <option key={key || value} value={value}>
        {label}
      </option>
    ))}
  </BaseInput>
)

Select.theme = selectTheme

export type SelectOption = {
  label: string
  value: string
  key?: string
}

export interface Props extends BaseInputProps<HTMLSelectElement> {
  options: SelectOption[]
}

export default Select
