import * as React from 'react'

const VideoFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M15.988 14.123V9.837a.71.71 0 01.367-.623l4.541-2.51a.712.712 0 011.057.624v9.428c0 .55-.594.892-1.07.617l-4.54-2.634a.712.712 0 01-.355-.616zM3.768 5.277h9.287c.967 0 1.75.784 1.75 1.75v10.019a1.75 1.75 0 01-1.75 1.75H3.767a1.75 1.75 0 01-1.75-1.75V7.027c0-.966.784-1.75 1.75-1.75z" />
  </svg>
)

export default VideoFilled
