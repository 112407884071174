import * as React from 'react'

const AppointmentCancelledFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.777 4.75a.25.25 0 00-.25.25v14c0 .138.112.25.25.25h16.55a.25.25 0 00.25-.25V5a.25.25 0 00-.25-.25H3.777zm0-1.5h16.55c.966 0 1.75.784 1.75 1.75v14a1.75 1.75 0 01-1.75 1.75H3.777A1.75 1.75 0 012.027 19V5c0-.966.784-1.75 1.75-1.75zM2.75 9.75v-1.5h18.5v1.5H2.75zm4.19-6.949a.75.75 0 111.5 0v2.987a.75.75 0 01-1.5 0V2.8zm8.567 0a.75.75 0 111.5 0v3.04a.75.75 0 11-1.5 0v-3.04zm-2.52 9.65a.75.75 0 111.061 1.062l-3.535 3.535a.75.75 0 11-1.061-1.06l3.535-3.536zm1.061 3.536a.75.75 0 11-1.06 1.061l-3.536-3.535a.75.75 0 111.06-1.061l3.536 3.535zM3 4h18v5H3V4z" />
  </svg>
)

export default AppointmentCancelledFilled
