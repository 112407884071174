import React from 'react'
import cn from 'classnames'
import { useFormContext, Controller, RegisterOptions } from 'react-hook-form'

import { useFieldContext } from '../Field'

import './style.scss'

interface ImageInputProps extends React.HTMLProps<HTMLInputElement> {
  name?: string
  accept?: string
  button?: React.ReactNode
  disabled?: boolean
  className?: string
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
}

const DefaultFileInputButton = () => (
  <div className="image-input-default-button">
    <svg width="24" height="24">
      <g>
        <path d="M2 4.394a.25.25 0 00-.25.25V16c0 .138.112.25.25.25h16a.25.25 0 00.25-.25V4.644a.25.25 0 00-.25-.25h-2.4a.75.75 0 01-.536-.225l-2.297-2.344a.25.25 0 00-.179-.075H7.431a.25.25 0 00-.18.077L5.02 4.162a.75.75 0 01-.543.232H2zm13.915-1.5H18c.966 0 1.75.784 1.75 1.75V16A1.75 1.75 0 0118 17.75H2A1.75 1.75 0 01.25 16V4.644c0-.966.784-1.75 1.75-1.75h2.157L6.165.791A1.75 1.75 0 017.431.25h5.157c.47 0 .921.19 1.25.525l2.077 2.12zM10 14.25a4.75 4.75 0 110-9.5 4.75 4.75 0 010 9.5zm0-1.5a3.25 3.25 0 100-6.5 3.25 3.25 0 000 6.5z" />
      </g>
    </svg>
    <span>Select</span>
  </div>
)

const ImageInput = ({
  button = <DefaultFileInputButton />,
  rules,
  className,
  ...rest
}: ImageInputProps) => {
  const { control } = useFormContext()
  const { name: contextName } = useFieldContext()
  const name = rest.name || contextName
  if (!name) {
    throw new Error('ImageInput requires a name')
  }
  return (
    <Controller
      render={({ onChange, value }) => (
        <div
          className={cn(
            'image-input',
            { 'image-input-empty': !value },
            className
          )}
        >
          <img
            src={value ? (value as string) : undefined}
            alt={name}
            data-testid="ImageInput.preview"
          />
          <input
            type="file"
            onChange={({ currentTarget }) => {
              const file = currentTarget.files?.[0]
              const reader = new FileReader()
              const handleLoad = () => onChange(reader.result as string)
              reader.addEventListener('load', handleLoad, false)
              file && reader.readAsDataURL(file)
            }}
            data-testid="ImageInput.input"
            {...rest}
          />
          <div>{button}</div>
        </div>
      )}
      name={name}
      control={control}
      rules={rules}
    />
  )
}

export default ImageInput
