import * as React from 'react'

const NotificationBadge = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 2.141c.568 0 1.115.152 1.593.434a.75.75 0 01-.763 1.292A1.626 1.626 0 0012 3.64c-.806 0-1.485.582-1.605 1.354a.75.75 0 01-.482.589c-2.323.855-3.892 3.043-3.892 5.525v4.304c0 .135.002.251.008.477.013.53.013.857-.025 1.12h11.977c-.032-.253-.03-.568-.013-1.073.008-.246.011-.376.011-.524v-4.004a.75.75 0 111.5 0v4.004c0 .17-.003.311-.012.573-.02.631-.01.854.042.985.014.035.022.04.264.04 1 0 1 1.5 0 1.5H4.193c-1 0-1-1.5 0-1.5.249 0 .264-.009.284-.058.056-.141.068-.375.052-1.025a19.009 19.009 0 01-.008-.515v-4.304c0-2.98 1.797-5.62 4.493-6.78A3.123 3.123 0 0112 2.14zm1.335 17.53a.75.75 0 011.224.867A3.13 3.13 0 0112 21.858a3.129 3.129 0 01-2.576-1.342.75.75 0 111.232-.855 1.63 1.63 0 001.344.698c.54 0 1.033-.26 1.335-.687zM18 9.25a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z" />
  </svg>
)

export default NotificationBadge
