import React from 'react'

import { cn } from '@/utils'

import { Button } from '..'

import styles from './styles.module.scss'

interface DefaultHeaderProps {
  title?: string
  closeButtonLabel?: React.ReactNode
  onClose?: () => void
}

const DefaultHeader = ({
  title,
  closeButtonLabel,
  onClose,
}: DefaultHeaderProps) => (
  <header>
    {title && <h1>{title}</h1>}
    {closeButtonLabel && onClose && (
      <Button className="transparent" onClick={onClose}>
        {closeButtonLabel}
      </Button>
    )}
  </header>
)

interface WindowProps {
  title?: string
  children?: React.ReactNode
  className?: string
  header?: React.ReactNode
  closeButtonLabel?: React.ReactNode
  onClose?: () => void
}

const Window = ({
  title,
  children,
  className,
  header,
  ...headerArgs
}: WindowProps) => (
  <div className={cn(styles.window, 'babylon-ui', 'bl-window', className)}>
    {header || <DefaultHeader title={title} {...headerArgs} />}
    <article>{children}</article>
  </div>
)

export default Window
