import * as React from 'react'

const RegulatoryFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17.897 7.662l.656.795a1.75 1.75 0 010 2.228l-.656.795a.25.25 0 00-.056.18l.09 1.048A1.75 1.75 0 0116.6 14.56l-.873.21a.25.25 0 00-.16.122l-.453.811a1.75 1.75 0 01-2.255.738l-.754-.344a.25.25 0 00-.208 0l-.753.347a1.75 1.75 0 01-2.259-.736l-.452-.81a.25.25 0 00-.16-.121l-.872-.211a1.75 1.75 0 01-1.333-1.852l.092-1.053a.25.25 0 00-.057-.181l-.656-.795a1.75 1.75 0 01-.002-2.227l.659-.8a.25.25 0 00.056-.181L6.069 6.43a1.75 1.75 0 011.337-1.854l.867-.207a.25.25 0 00.16-.121l.453-.81a1.75 1.75 0 012.255-.739l.755.345a.25.25 0 00.208 0l.755-.345a1.75 1.75 0 012.255.738l.453.812a.25.25 0 00.16.121l.873.211a1.75 1.75 0 011.332 1.852l-.091 1.047a.25.25 0 00.056.181zm-6.064 5.375c1.763 0 3.185-1.462 3.185-3.254 0-1.793-1.422-3.255-3.185-3.255-1.764 0-3.186 1.462-3.186 3.255 0 1.792 1.422 3.254 3.186 3.254zm0-1.5c-.926 0-1.686-.781-1.686-1.754 0-.974.76-1.755 1.686-1.755.925 0 1.685.781 1.685 1.755 0 .973-.76 1.754-1.685 1.754zm-1.439 10.145a.75.75 0 01-1.062-.68l-.004-2.335a.75.75 0 01.422-.676l1.918-.933c.207-.1.45-.1.657.001l1.909.932a.75.75 0 01.42.673l.005 2.335a.75.75 0 01-1.062.683l-1.601-.732-1.602.732z" />
  </svg>
)

export default RegulatoryFilled
