import { useFormatMessage } from '@babylon/intl'

import messages from './messages'

function useGQLPermissionsErrorParser() {
  const fm = useFormatMessage()

  return (errors) => {
    const isForbidden =
      errors.findIndex((error) => error?.extensions?.code === 'FORBIDDEN') >= 0

    return isForbidden ? [fm(messages.forbiddenError)] : []
  }
}

export default useGQLPermissionsErrorParser
