import { useCallback, useContext, useMemo } from 'react'

import { UserRolesContext } from './core'
import { useCurrentUser } from '@/components/CurrentUser'

const defaultPermissions = {
  view: false,
  edit: false,
  create: false,
  delete: false,
}

function getContextPermissions({ userRoles, context, userRoleIds }) {
  return userRoleIds
    .filter((roleId) => userRoles[roleId])
    .reduce(
      (acc, roleId) => ({
        ...acc,
        ...(userRoles[roleId].permissions[context] || {}),
      }),
      { ...defaultPermissions, ...userRoles.default.permissions[context] }
    )
}

function getAllContexts(userRoles) {
  const contexts = new Set()

  // Gather all possible contexts from all roles.
  Object.values(userRoles)
    .reduce(
      (acc, { permissions: rolePermissions }) =>
        acc.concat(Object.keys(rolePermissions)),
      []
    )
    .forEach((context) => contexts.add(context))

  return Array.from(contexts)
}

function usePermissions() {
  const user = useCurrentUser()
  const userRoles = useContext(UserRolesContext)
  const userRoleIds = user.roles.map(({ key }) => key)

  const permissions = useMemo(() => {
    const contexts = getAllContexts(userRoles)

    return contexts.reduce((acc, context) => {
      acc[context] = getContextPermissions({
        userRoles,
        context,
        userRoleIds,
      })

      return acc
    }, {})
  }, [userRoleIds, userRoles])

  return useCallback(
    (requiredPermissions) =>
      Object.keys(requiredPermissions).reduce(
        (contextAcc, context) =>
          contextAcc &&
          requiredPermissions[context].some(
            (action) => (permissions[context] || {})[action]
          ),
        true
      ),
    [permissions]
  )
}

export default usePermissions
