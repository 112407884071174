import * as React from 'react'

const UserFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.906 21.297C4.498 16.432 7.203 14 12.02 14s7.46 2.432 7.93 7.297H3.905zM12 11a4 4 0 110-8 4 4 0 010 8z" />
  </svg>
)

export default UserFilled
