import * as React from 'react'

const PharmacyFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M6.948 18.25A9.242 9.242 0 012.75 10.5V9.25a.75.75 0 01.021-1.5h12.18l5.31-5.282a.75.75 0 111.059 1.064L17.078 7.75h4.114a.75.75 0 01.058 1.498V10.5a9.242 9.242 0 01-4.198 7.75h.167a.75.75 0 110 1.5H6.743a.75.75 0 110-1.5h.205zm5.802-3.816h1.433a.75.75 0 000-1.5H12.75v-1.433a.75.75 0 10-1.5 0v1.433H9.817a.75.75 0 100 1.5h1.433v1.433a.75.75 0 001.5 0v-1.433z" />
  </svg>
)

export default PharmacyFilled
