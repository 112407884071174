import { defineMessages } from 'react-intl'

const baseId = 'shared.error'

const messages = defineMessages({
  errorHeading: {
    id: `${baseId}.error_heading`,
    defaultMessage: 'An error occurred!',
  },
  networkError: {
    id: `${baseId}.network_error`,
    defaultMessage: 'Network error, please refresh and try again',
  },
})

export default messages
