import React from 'react'

import Logo from '@/components/Logo'
import Navigation from '@/components/Navigation'
import ActionsMenu from '@/components/ActionsMenu'
import { useSetShowHeader } from '@/components/Layout'

import styles from './styles.module.scss'

export default () => {
  const { showHeader } = useSetShowHeader()

  return (
    <header className={styles.header}>
      <Logo />
      {showHeader && (
        <>
          <Navigation />
          <ActionsMenu />
        </>
      )}
    </header>
  )
}
