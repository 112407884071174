import React from 'react'
import { Link } from 'react-router-dom'

import { BabylonLogo } from '@babylon/medkit'
import { Experience, Variant } from '@/components/Experiences'
import EmedLogoSvg from './assets/emed-logo.svg'
import TelusLogoSvg from './assets/telus-logo.svg'

import styles from './styles.module.scss'

export default () => (
  <div className={styles.logo}>
    <Link className={styles.logoLink} to="/">
      <Experience name="appLogo">
        <Variant name="default">
          <BabylonLogo logoSvg={EmedLogoSvg} altText="eMed" />
        </Variant>
        <Variant name="telus">
          <BabylonLogo logoSvg={TelusLogoSvg} altText="Telus" />
        </Variant>
      </Experience>
    </Link>
  </div>
)
