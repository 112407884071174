import * as React from 'react'

const TickCircle = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zm0-1.5a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5zm4.262-12.018a.75.75 0 011.057 1.065l-7.29 7.235a.75.75 0 01-1.06-.002l-2.5-2.5a.75.75 0 011.061-1.06l1.972 1.971 6.76-6.709z" />
  </svg>
)

export default TickCircle
