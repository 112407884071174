import * as React from 'react'

const Bargraph = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21.063 16.603h.041a.75.75 0 010 1.5H2.896a.75.75 0 010-1.5h.042v-3.074a.75.75 0 01.75-.75h2.374a.75.75 0 01.75.75v3.074h.875V9.706a.75.75 0 01.75-.75h2.376a.75.75 0 01.75.75v6.897h.874v-5.368a.75.75 0 01.75-.75h2.376a.75.75 0 01.75.75v5.368h.875V5.883a.75.75 0 01.75-.75h2.375a.75.75 0 01.75.75v10.72zm-1.5 0v-9.97h-.875v9.97h.875zm-5.625 0h.874v-4.618h-.874v4.618zm-3.876 0v-6.147h-.874v6.147h.874zm-5.624 0h.875v-2.324h-.875v2.324z" />
  </svg>
)

export default Bargraph
