import React, { useContext } from 'react'

import { Close } from '@babylon/icons'
import styles from './modalCloseButton.module.scss'
import useModalActions from './useModalActions'
import ModalContext from './ModalContext'

const ModalCloseButton = () => {
  const { closeModal } = useModalActions()
  const {
    state: { closeButtonText, onDismiss },
  } = useContext(ModalContext)

  const handleClick = () => {
    if (onDismiss) {
      onDismiss()
    }

    closeModal()
  }

  return (
    <button
      type="button"
      className={styles.modalCloseButton}
      onClick={handleClick}
      data-testid="modalCloseButton"
    >
      <span className={styles.modalCloseButton__text}>{closeButtonText}</span>
      <Close aria-hidden className={styles.modalCloseButton__icon} />
    </button>
  )
}

export default ModalCloseButton
