import React, { ReactChild, forwardRef, MutableRefObject } from 'react'
import cn from 'classnames'
import { FieldInputProps } from 'formik'

import { IMedkitForwardRefRenderFunction, HelpText } from '../..'
import { baseInputTheme } from './theme'
import Label from './Label'
import { getActualId } from './helpers'
import styles from './BaseInput.module.scss'
import Errors from '../ErrorMessages/ErrorsMessages'

export const BaseInput: IMedkitForwardRefRenderFunction<
  | HTMLInputElement
  | HTMLTextAreaElement
  // TODO - address this 'any' - requires restricting the 'component' prop
  | any,
  BaseInputProps<any>,
  typeof baseInputTheme
> = (
  {
    className,
    value,
    id,
    label,
    labelProps,
    name,
    disabled,
    children,
    error,
    helpText,
    component: Component = 'input',
    endAdornment,
    endAdornmentClassName,
    inputClassName,
    placeholder,
    ErrorMessages,
    fieldRef,
    fieldName,
    errors,
    'aria-describedby': ariaDescribedby,
    ...props
  }: BaseInputProps<any>,
  ref?
) => {
  const idBase = name || label
  const actualId = getActualId({ id, idBase })

  const refToUse = ref || fieldRef

  const helpTextId = actualId && helpText ? `${actualId}-help` : undefined
  const describedby =
    helpTextId && ariaDescribedby
      ? `${helpTextId} ${ariaDescribedby}`
      : helpTextId || ariaDescribedby

  return (
    <div
      className={cn(
        styles.BaseInput,
        {
          [styles.BaseInput_error]: error,
          [styles.BaseInput_endAdornment]: endAdornment,
          [styles.BaseInput_disabled]: disabled,
        },
        className
      )}
    >
      <div className={styles.BaseInput__container}>
        {label && <Label htmlFor={actualId} label={label} {...labelProps} />}
        {helpText && <HelpText id={helpTextId}>{helpText}</HelpText>}
        {!errors?.length && ErrorMessages && <ErrorMessages />}
        {!!errors?.length && (
          <Errors fieldName={fieldName} formErrors={errors} />
        )}
        <Component
          id={actualId}
          name={name}
          ref={refToUse}
          className={cn(styles.BaseInput__input, inputClassName)}
          value={value}
          aria-invalid={error}
          disabled={disabled}
          placeholder={placeholder}
          aria-describedby={describedby}
          {...props}
        >
          {children}
        </Component>
        {endAdornment && (
          <div
            className={cn(
              styles.BaseInput__adornment,
              styles.BaseInput__adornment_right,
              endAdornmentClassName
            )}
          >
            {endAdornment}
          </div>
        )}
      </div>
    </div>
  )
}

BaseInput.theme = baseInputTheme

export interface BaseInputProps<T> extends React.HTMLProps<T> {
  /**
   * Input label
   * If not provided you should provided your own custom label and use placeholder and id props instead
   */
  label?: string
  labelProps?: any
  /** Input value */
  value?: string
  /** Error message associated with input. "true" if error state without message */
  error?: boolean
  helpText?: string
  /** Make input disabled */
  disabled?: boolean
  /** Add icon or text to end of input box */
  endAdornment?: ReactChild
  /** Classes added to endAdornment */
  endAdornmentClassName?: string
  /** Classes added to input component */
  inputClassName?: string
  /**
   * Component type to use
   * @ignore
   */
  component?: 'input' | 'select' | 'textarea' | React.FC
  /**
   * Custom ref that supports multiple element types (e.g. select+input)
   * @ignore
   */
  ref?: React.Ref<T>
  field?: FieldInputProps<any>
  placeholder?: string
  errors?: string[]
  fieldName?: string
  ErrorMessages?: React.FC<any>

  fieldRef?: MutableRefObject<HTMLInputElement | null>
}

export default forwardRef<any, BaseInputProps<any>>(BaseInput)
