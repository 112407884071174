import React, { ReactNodeArray } from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import { IMedkitComponent } from '..'
import { alertTheme } from './theme'

export type AlertType = 'info' | 'warning' | 'error' | 'positive'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: string | ReactNodeArray | React.ReactChild | React.ReactChild[]
  type?: AlertType
  icon?: React.ReactChild
  className?: string
  loading?: boolean
  [prop: string]: any
}

const Alert: IMedkitComponent<Props, typeof alertTheme> = ({
  children,
  type = 'info',
  icon,
  className,
  loading,
  ...props
}: Props) => {
  const classNames = cn(
    styles.alert,
    styles[`alert--${type}`],
    {
      [styles['alert--loading']]: loading,
    },
    className
  )
  const role = type === 'error' ? 'alert' : 'status'

  return (
    <div className={classNames} role={role} {...props}>
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.text}>{children}</span>
    </div>
  )
}

Alert.theme = alertTheme

export default Alert
