import * as React from 'react'

const SpecialistCircle = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zm0-1.5a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5zm0-14.22a2.606 2.606 0 00-2.61 2.61A2.606 2.606 0 0012 11.25a2.606 2.606 0 002.61-2.61A2.606 2.606 0 0012 6.03zm-.563 8.183c-2.064.16-4.51 1.148-4.878 2.175-.021.06-.313.78-.384.957l-.025.063c-.099.25-.178.456-.235.613-.028.078-.066.2-.065.115l-1.5-.023c.004-.224.09-.463.406-1.258l.026-.065c.085-.214.356-.882.365-.907.635-1.773 3.656-3.009 6.268-3.174A4.106 4.106 0 017.89 8.64 4.105 4.105 0 0112 4.53a4.105 4.105 0 014.11 4.11 4.106 4.106 0 01-3.524 4.069c2.693.169 5.79 1.466 6.317 3.33.008.03.187.443.469 1.068l-1.368.616c-.345-.765-.504-1.134-.545-1.276-.294-1.042-2.785-2.07-4.896-2.234v.217a.562.562 0 11-1.126 0v-.217zm-.503 4.589l.562-2.776c.122-.602.981-.602 1.103 0l.562 2.776c.071.348.646 1.478.29 1.478h-2.78c-.356 0 .192-1.13.263-1.478z" />
  </svg>
)

export default SpecialistCircle
