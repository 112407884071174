import React from 'react'
import ReactDOM from 'react-dom'
import './dotenv'
import { setAuthConfig } from '@babylon/babylon-auth'

import './styles/styles.scss'
import App from './components/App'

setAuthConfig({
  tokenRefreshPoint: 2 / 30, // 2 mins (assuming a 30 min token)
})

ReactDOM.render(<App />, document.getElementById('root'))
