import * as React from 'react'

const VideoCircle = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M15.444 14.102c-.245-.224-.444-.68-.444-1.008v-1.77c0-.333.2-.775.454-.997l1.092-.951c.25-.219.454-.13.454.208v5.336c0 .333-.2.421-.444.198l-1.112-1.016zM7.9 8.5h5.2a.9.9 0 01.9.9v5.2a.9.9 0 01-.9.9H7.9a.9.9 0 01-.9-.9V9.4a.9.9 0 01.9-.9zM12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zm0-1.5a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5z" />
  </svg>
)

export default VideoCircle
