import * as React from 'react'

const SpeakerOffFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.629 21.503a.79.79 0 01-1.114-1.119L20.346 2.637a.79.79 0 011.114 1.119L3.63 21.503zm13.59-10.838a.75.75 0 011.303-.743c1.143 2.004.526 4.338-1.557 5.851a.75.75 0 01-.882-1.213c1.487-1.08 1.885-2.583 1.136-3.895zM7.174 8.496l6.133-6.103a.79.79 0 011.346.56v2.06c0 .655-.006 1.244-.258 1.817s-.533.896-1.06 1.417c-1.959 1.936-4.61 4.712-6.16 6.164-.547.511-.644.564-1.316.85-.448.19-.961.271-1.54.243a2.15 2.15 0 01-2.154-2.147v-2.714a2.15 2.15 0 012.153-2.147h2.856zm6.69 4.492a.79.79 0 01.79.79v7.27a.79.79 0 01-1.344.563l-3.68-3.616a.79.79 0 01-.01-1.117c.306-.31 3.808-3.89 4.244-3.89z" />
  </svg>
)

export default SpeakerOffFilled
