import * as React from 'react'

const CoreSortUp = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 23 19"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8 8H2a1 1 0 00-1 1 .9.9 0 001 1h6a.9.9 0 001-1 1 1 0 00-1-1zm0-8H2a1 1 0 00-1 1 .9.9 0 001 1h6a.9.9 0 001-1 1 1 0 00-1-1zm5.3 5.7a1 1 0 001.4 0L17 3.4v14.3a1 1 0 102 0V3.4l2.3 2.3a1 1 0 001.4-1.4l-4-4a1 1 0 00-1.4 0l-4 4a1 1 0 000 1.4zM2 18h11a.9.9 0 001-1 1 1 0 00-1-1H2a1 1 0 00-1 1 .9.9 0 001 1z" />
  </svg>
)

export default CoreSortUp
