import * as React from 'react'

const Notification = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.335 19.672a.75.75 0 011.224.866A3.13 3.13 0 0112 21.858a3.129 3.129 0 01-2.576-1.342.75.75 0 111.232-.855 1.63 1.63 0 001.344.698c.54 0 1.033-.26 1.335-.687zm6.144-8.563v4.304c0 .17-.003.311-.012.573-.02.631-.01.854.042.985.014.035.022.04.264.04v1.5H4.193v-1.5c.249 0 .264-.009.284-.058.056-.141.068-.375.052-1.025a19.009 19.009 0 01-.008-.515v-4.304c0-2.98 1.797-5.62 4.493-6.78A3.123 3.123 0 0112 2.14a3.12 3.12 0 012.983 2.187c2.697 1.162 4.496 3.802 4.496 6.78zm.294 5.902c1 0 1 1.5 0 1.5-.817 0-1.398-.332-1.658-.985a1.987 1.987 0 01-.134-.515h1.792zm-1.805-1.074c.008-.246.011-.376.011-.524v-4.304c0-2.48-1.571-4.67-3.895-5.525a.75.75 0 01-.482-.588A1.615 1.615 0 0012 3.64c-.806 0-1.485.582-1.605 1.354a.75.75 0 01-.482.589c-2.323.855-3.892 3.043-3.892 5.525v4.304c0 .135.002.251.008.477.013.53.013.857-.025 1.12h11.977c-.032-.253-.03-.568-.013-1.073zM5.87 17.511c-.265.66-.851 1-1.676 1-1 0-1-1.5 0-1.5h1.811c-.025.18-.068.332-.135.5z" />
  </svg>
)

export default Notification
