import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppointmentCategoryDetails } from '@/domains/AppointmentConfiguration/types'

interface DeleteAppointmentCategoriesData {
  categories: AppointmentCategoryDetails[]
  originalCategories: AppointmentCategoryDetails[]
  categoriesToDelete: AppointmentCategoryDetails[]
}
const initialState: DeleteAppointmentCategoriesData = {
  categories: [],
  originalCategories: [],
  categoriesToDelete: [],
}

export const deleteAppointmentCategories = createSlice({
  name: 'deleteAppointmentCategories',
  initialState,
  reducers: {
    setAppointmentCategories: (
      state,
      action: PayloadAction<AppointmentCategoryDetails[]>
    ) => {
      return {
        ...state,
        categories: [...action.payload],
        originalCategories: [...action.payload],
      }
    },
    resetAppointmentCategories: (state) => {
      return {
        ...state,
        categories: [...state.originalCategories],
        categoriesToDelete: [],
      }
    },
    removeAppointmentCategory: (
      state,
      action: PayloadAction<AppointmentCategoryDetails>
    ) => {
      return {
        ...state,
        categories: state.categories.filter(
          (category) => category.uuid !== action.payload.uuid
        ),
        categoriesToDelete: [...state.categoriesToDelete, action.payload],
      }
    },
    clearAppointmentCategories: (state) => {
      return {
        ...state,
        categories: [],
        categoriesToDelete: [],
      }
    },
  },
})
