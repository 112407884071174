import React, { useState, useRef, useCallback } from 'react'
import cn from 'classnames'

import { useFormatMessage } from '@babylon/intl'
import { Icon, Input } from '@babylon/medkit'

import messages from './messages'
import styles from './Search.module.scss'

interface SearchProps {
  defaultValue?: string
  onChange: (value: any) => void
  placeholder?: string
  className?: string
}

export default ({
  defaultValue = '',
  onChange,
  placeholder,
  className,
}: SearchProps) => {
  const fm = useFormatMessage()
  const timeout = useRef<any>()
  const [query, setQuery] = useState(defaultValue)
  const handleSearch = useCallback(
    ({ target: { value = '' } = {} }) => {
      setQuery(value)

      if (timeout.current) {
        clearTimeout(timeout.current)
      }

      timeout.current = setTimeout(() => {
        onChange({
          search: value,
          page: 1,
        })
      }, 400)
    },
    [timeout, onChange]
  )

  return (
    <div className={cn(styles.search, className)}>
      <Input
        id="search"
        value={query}
        placeholder={placeholder || fm(messages.searchLabel)}
        onChange={handleSearch}
        autoComplete="off"
      />
      {query ? (
        <div
          className={styles.icon}
          onClick={() => handleSearch({ target: { value: '' } })}
        >
          <Icon icon="NavigationClose" title="Close" height={20} />
        </div>
      ) : (
        <div className={styles.icon}>
          <Icon icon="NavigationSearch" title="Search" height={20} />
        </div>
      )}
    </div>
  )
}
