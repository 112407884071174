import * as React from 'react'

const HospitalFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18.068 5.988h1.271a.75.75 0 01.75.75V21a.75.75 0 01-.75.75h-5.886a.75.75 0 01-.75-.75v-2.217H11.41V21a.75.75 0 01-.75.75H4.783a.75.75 0 01-.75-.75V6.738a.75.75 0 01.75-.75h3.961c.438 0-.486 3.82 0 3.82h6.697c.433 0-.28-3.82 0-3.82h2.627zm-9.703 6.018h-.6a.75.75 0 000 1.5h.6a.75.75 0 100-1.5zm3.966 0h-.6a.75.75 0 000 1.5h.6a.75.75 0 100-1.5zm3.874 0h-.6a.75.75 0 000 1.5h.6a.75.75 0 100-1.5zm-7.828 2.531h-.6a.75.75 0 000 1.5h.6a.75.75 0 100-1.5zm3.94 0h-.6a.75.75 0 000 1.5h.6a.75.75 0 100-1.5zm3.922 0h-.6a.75.75 0 000 1.5h.6a.75.75 0 100-1.5zM9.25 3.75v5.5h5.5v-5.5h-5.5zm-.75-1.5h7a.75.75 0 01.75.75v7a.75.75 0 01-.75.75h-7a.75.75 0 01-.75-.75V3a.75.75 0 01.75-.75zm4.25 4.927v.539a.75.75 0 11-1.5 0v-.54h-.6a.75.75 0 110-1.5h.6V5a.75.75 0 111.5 0v.677h.615a.75.75 0 010 1.5h-.615z" />
  </svg>
)

export default HospitalFilled
