import * as React from 'react'

const PhoneFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M15.308 14.692a1.75 1.75 0 011.851-.491l2.347.777a1.75 1.75 0 011.2 1.661v3.577a.75.75 0 01-.824.747c-9.689-.97-15.235-6.245-16.47-15.675A1.75 1.75 0 015.149 3.31h2.456c.71 0 1.35.43 1.62 1.087l.995 2.43a1.75 1.75 0 01-.478 1.99l-.7.603a.25.25 0 00-.07.28c1.03 2.625 2.91 4.525 5.678 5.725l.66-.733z" />
  </svg>
)

export default PhoneFilled
