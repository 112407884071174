import { envVar } from '@babylon/babylon-env'
import {
  initialise as initialiseTealium,
  UTAG,
} from '@babylon/tracking/tealium'

import { injectScript } from './helpers'
import { regionToIsoCode } from './constants'

let initPromise: Promise<UTAG>
let tealiumInitialised = false
const TEALIUM_URL = envVar('TEALIUM_URL')

const regionIsoCode = regionToIsoCode[envVar('DEPLOYMENT_REGION')]

export const initialiseTracking = async (): Promise<void> => {
  if (!TEALIUM_URL || tealiumInitialised) {
    return
  }

  if (!initPromise) {
    initPromise = injectScript(TEALIUM_URL)
  }

  try {
    const utag: UTAG = await initPromise
    initialiseTealium(utag, regionIsoCode)
    tealiumInitialised = true
  } catch (e) {
    console.error('Cannot initialise tealium')
  }
}
