import * as React from 'react'

const Voucher = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.75 6.985V9.17a.25.25 0 00.169.236c1.046.36 1.57 1.287 1.57 2.593 0 1.31-.526 2.242-1.573 2.616a.25.25 0 00-.166.235v2.2c0 .137.112.25.25.25h16a.25.25 0 00.25-.25v-2.21a.25.25 0 00-.164-.235c-1.075-.394-1.629-1.32-1.629-2.605 0-1.278.55-2.188 1.621-2.542a.25.25 0 00.172-.237V6.985a.25.25 0 00-.25-.25H4a.25.25 0 00-.25.25zM20 5.235c.967 0 1.75.783 1.75 1.75V9.22a1.75 1.75 0 01-1.2 1.661c-.405.134-.593.444-.593 1.118 0 .693.205 1.035.645 1.197a1.75 1.75 0 011.148 1.643v2.21A1.75 1.75 0 0120 18.8H4a1.75 1.75 0 01-1.75-1.75v-2.199c0-.74.465-1.4 1.162-1.648.385-.137.576-.477.576-1.203 0-.718-.185-1.046-.557-1.174A1.75 1.75 0 012.25 9.17V6.985c0-.967.784-1.75 1.75-1.75h16zm-7.49 3.484a2.849 2.849 0 011.546-.469c1.467 0 2.694 1.292 2.694 2.795 0 1.76-.888 3.125-2.324 4.116-.832.574-1.657.91-2.233 1.064a.75.75 0 01-.386 0c-.576-.154-1.4-.49-2.233-1.064-1.436-.991-2.324-2.356-2.324-4.116 0-1.532 1.161-2.795 2.694-2.795.586 0 1.148.173 1.619.491.165.112.317.24.453.383a2.85 2.85 0 01.495-.405zm-.168 5.867a6.498 6.498 0 001.232-.66c1.064-.734 1.676-1.675 1.676-2.88 0-.692-.573-1.296-1.194-1.296-.245 0-.502.08-.727.226-.232.151-.41.36-.504.587-.063.153-.06.147-.068.171a.22.22 0 00-.007.076c0 1-1.5 1-1.5 0a.297.297 0 00-.009-.094 2.17 2.17 0 00-.064-.172 1.208 1.208 0 00-.454-.56 1.385 1.385 0 00-.78-.234c-.674 0-1.193.565-1.193 1.295 0 1.206.612 2.147 1.676 2.881a6.498 6.498 0 001.574.789 6.16 6.16 0 00.342-.129z" />
  </svg>
)

export default Voucher
