import { defineMessages } from 'react-intl'

const baseId = 'timezone_update'

const messages = defineMessages({
  cancelLabel: {
    id: `${baseId}.cancel_label`,
    defaultMessage: 'Cancel',
  },
  continueLabel: {
    id: `${baseId}.continue_label`,
    defaultMessage: 'Go to settings',
  },
  updateTimezoneHeading: {
    id: `${baseId}.update_timezone_heading`,
    defaultMessage: 'Change time zone to {timezone}?',
  },
  detectedDifferentTimezoneMessage: {
    id: `${baseId}.detected_different_timezone_message`,
    defaultMessage: 'We have detected you may be in a different time zone.',
  },
  updateTimezoneMessage: {
    id: `${baseId}.update_timezone_message`,
    defaultMessage: 'You can update your time zone in the My Account settings.',
  },
})

export default messages
