import React, { lazy, Suspense } from 'react'

export default (path: string) => {
  const Component = lazy(() => import(`../${path}`))

  return (props: any) => (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  )
}
