import * as React from 'react'

const Test = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8.65 12.955h6.553l-1.664-1.673V4.455h-3.277v6.82l-.21.217-1.402 1.463zm-1.414 1.473l-3.365 3.51a.75.75 0 00.532 1.277h15.223a.75.75 0 00.519-1.292l-3.486-3.504a.75.75 0 01-.232.036H7.434a.75.75 0 01-.198-.027zm1.526-9.973h-.487a.75.75 0 110-1.5h7.381a.75.75 0 110 1.5h-.617v6.208l6.163 6.196a2.25 2.25 0 01-1.576 3.856H4.403a2.25 2.25 0 01-1.606-3.825l5.965-6.219V4.455z" />
  </svg>
)

export default Test
