import { defineMessages } from 'react-intl'

const baseId = 'select_component'

export default defineMessages({
  selectOne: {
    id: `${baseId}.select_one`,
    defaultMessage: 'Select one',
  },
  loading: {
    id: `${baseId}.loading`,
    defaultMessage: 'Loading...',
  },
})
