import IModalState, { ModalQueueItem } from './modalState'
import { ModalProps } from './Modal.types'

export enum ModalAction {
  OPEN_MODAL,
  CLOSE_MODAL,
  UPDATE_MODAL_PROPS,
}

export interface IModalAction {
  type: ModalAction
  payload?: {
    component?: React.ReactChild
    modalProps?: ModalProps
    replace?: boolean
    closeButtonText?: string
    backButtonText?: string
  }
}

const defaultModalProps: ModalProps = {
  isDismissable: true,
  onDismiss: () => {},
  goBack: undefined,
}

const getStateFromQueue = (newQueue: ModalQueueItem[]): IModalState => ({
  component: newQueue[0].component,
  modalQueue: newQueue,
  isModalOpen: true,
  ...defaultModalProps,
  ...newQueue[0].props,
})

export default (state: IModalState, action: IModalAction) => {
  /* eslint-disable no-case-declarations */
  switch (action.type) {
    case ModalAction.OPEN_MODAL:
      const { component, modalProps, replace } = action.payload || {}

      let modalQueue: ModalQueueItem[] = []

      if (replace) {
        const newQueue = state.modalQueue.slice(1)
        modalQueue = [
          {
            component,
            props: modalProps,
          },
          ...newQueue,
        ]
      } else {
        modalQueue = [
          ...state.modalQueue,
          {
            component,
            props: modalProps,
          },
        ]
      }

      return {
        ...state,
        ...getStateFromQueue(modalQueue),
      }

    case ModalAction.CLOSE_MODAL:
      const newQueue = state.modalQueue.slice(1)

      if (newQueue.length === 0) {
        return {
          ...state,
          isModalOpen: false,
          component: undefined,
          modalQueue: newQueue,
        }
      }

      return {
        ...state,
        ...getStateFromQueue(newQueue),
      }

    case ModalAction.UPDATE_MODAL_PROPS:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
  // eslint-enable
}
