import * as React from 'react'

const Back = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M6.593 12.75l4.644 4.708a.75.75 0 01-1.068 1.054l-5.927-6.01a.75.75 0 010-1.054l5.928-5.99a.75.75 0 011.066 1.054L6.548 11.25h12.624a.75.75 0 110 1.5H6.592z" />
  </svg>
)

export default Back
