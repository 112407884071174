import * as React from 'react'

const NotificationBadgeFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14.416 3.278a4.5 4.5 0 005.015 6.99 7.3 7.3 0 01.048.84v4.305c0 .17-.003.311-.012.573-.02.631-.01.854.042.985.014.035.022.04.264.04v1.5H4.193v-1.5c.249 0 .264-.009.284-.058.056-.141.068-.375.052-1.025a19.009 19.009 0 01-.008-.515v-4.304c0-2.98 1.797-5.62 4.493-6.78A3.123 3.123 0 0112 2.14c.967 0 1.84.44 2.416 1.137zm5.357 13.733c1 0 1 1.5 0 1.5-.817 0-1.398-.332-1.658-.985a1.987 1.987 0 01-.134-.515h1.792zm-13.904.5c-.265.66-.851 1-1.676 1-1 0-1-1.5 0-1.5h1.811c-.025.18-.068.332-.135.5zM18 9.25a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5zm-4.665 10.422a.75.75 0 011.224.866A3.13 3.13 0 0112 21.858a3.129 3.129 0 01-2.576-1.342.75.75 0 111.232-.855 1.63 1.63 0 001.344.698c.54 0 1.033-.26 1.335-.687z" />
  </svg>
)

export default NotificationBadgeFilled
