import React from 'react'
import cx from 'classnames'

import { BabylonIcon } from '@babylon/icons'
import { Icon } from '@babylon/medkit'

import styles from './styles.module.scss'

const defaultIcons: Dictionary<BabylonIcon> = {
  error: 'AlertsAlert',
  alert: 'AlertsInformationFill',
  success: 'NavigationConfirm',
}

type Props = {
  id?: string
  type?: 'error' | 'alert' | 'success'
  icon?: BabylonIcon
  title?: React.ReactNode
  message?: React.ReactNode | Array<React.ReactNode>
  action?: React.ReactNode
  className?: ClassNameType
  'data-testid'?: string
}

function Card({
  id,
  type,
  icon = undefined,
  title,
  message = [],
  action,
  className,
  'data-testid': dataTestId,
}: Props) {
  const iconName = icon || (type && defaultIcons[type])
  const messageArray: Array<React.ReactNode> = Array.isArray(message)
    ? message
    : [message]

  return (
    <div
      id={id}
      className={cx(styles.card, type && styles[type], className)}
      data-testid={dataTestId}
    >
      {iconName && (
        <Icon
          icon={iconName}
          title="Card icon"
          className={styles.iconWrapper}
          iconClassName={styles.icon}
        />
      )}
      <div className={styles.mainContent}>
        {title && <h3 className={styles.title}>{title}</h3>}
        {messageArray.map((element, i) => (
          <p key={i} className={styles.paragraph}>
            {element}
          </p>
        ))}
      </div>
      {action && <div className={styles.action}>{action}</div>}
    </div>
  )
}

export default Card
