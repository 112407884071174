import * as React from 'react'

const ArrowUp = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17.437 15.498a.75.75 0 101.054-1.068l-6.01-5.928a.75.75 0 00-1.054 0l-5.99 5.929a.75.75 0 101.054 1.066l5.464-5.407 5.482 5.408z" />
  </svg>
)

export default ArrowUp
