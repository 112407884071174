import * as React from 'react'

const Security = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.44 14.281c.524-.214.889-.738.889-1.34 0-.788-.625-1.441-1.415-1.441s-1.414.653-1.414 1.442c0 .601.365 1.125.889 1.34v.966a.525.525 0 101.051 0v-.967zM8.755 9.403C8.756 7.533 10.198 6 12 6s3.244 1.533 3.245 3.403h1.214c.241 0 .437.195.437.435V16.6c0 .24-.196.435-.437.435H7.504a.436.436 0 01-.437-.435V9.84c0-.241.195-.436.437-.436h1.25zm1.5 0h3.49C13.744 8.342 12.952 7.5 12 7.5c-.952 0-1.744.842-1.745 1.903zm2.33-6.984c1.615 1.962 4.213 2.233 7.087.27a.75.75 0 011.173.62v11.725l-.028.204c-.286 1.017-1.172 2.465-2.973 3.881-1.457 1.145-3.324 2.072-5.646 2.708a.75.75 0 01-.396 0c-2.322-.636-4.19-1.563-5.646-2.708-1.802-1.416-2.687-2.864-2.973-3.881l-.028-.204V3.308a.75.75 0 011.173-.619c2.89 1.976 5.472 1.693 7.085-.27a.75.75 0 01.58-.273c.238 0 .45.1.593.273zm-7.93 2.21v10.289c.062.173.192.456.413.817.456.745 1.114 1.497 2.015 2.205 1.257.988 2.879 1.804 4.917 2.385 2.038-.581 3.66-1.397 4.917-2.385.9-.708 1.56-1.46 2.015-2.205.22-.361.351-.644.413-.817V4.628C16.557 6.054 13.881 5.749 12 3.985c-1.878 1.765-4.546 2.08-7.345.644z" />
  </svg>
)

export default Security
