import { DialogContent, DialogOverlay } from '@reach/dialog'
import React, { FC, useContext, useRef } from 'react'
import className from 'classnames'

import ScrollToTop from '../ScrollToTop'

import ModalCloseButton from './ModalCloseButton'
import ModalBackButton from './ModalBackButton'
import ModalContext from './ModalContext'
import useModalActions from './useModalActions'

import '@reach/dialog/styles.css'
import styles from './index.module.scss'

const cx = className.bind(styles)

const Modal: FC = () => {
  const componentRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const { state } = useContext(ModalContext)
  const {
    isModalOpen,
    isDismissable,
    onDismiss,
    goBack,
    role = 'dialog',
  } = state
  const { closeModal } = useModalActions()

  return (
    <>
      {isModalOpen && (
        <DialogOverlay
          className={styles.dialogOverlay}
          initialFocusRef={componentRef}
          onDismiss={() => {
            if (onDismiss) {
              onDismiss()
            }

            if (isDismissable) {
              closeModal()
            }
          }}
        >
          <ScrollToTop refToScroll={contentRef}>
            <DialogContent
              role={role}
              aria-labelledby="modal-title"
              aria-describedby="modal-content"
              ref={contentRef}
              className={styles.dialogContent}
            >
              {(goBack || isDismissable) && (
                <div className={styles.modalHeader}>
                  {goBack && (
                    <div
                      className={cx(
                        styles.modalHeader__button,
                        styles.modalHeader__backBtn
                      )}
                    >
                      <ModalBackButton goBack={goBack} />
                    </div>
                  )}
                  {isDismissable && (
                    <div
                      className={cx(
                        styles.modalHeader__button,
                        styles.modalHeader__closeBtn
                      )}
                    >
                      <ModalCloseButton />
                    </div>
                  )}
                </div>
              )}
              <div ref={componentRef}>{state.component}</div>
            </DialogContent>
          </ScrollToTop>
        </DialogOverlay>
      )}
    </>
  )
}

export default Modal
