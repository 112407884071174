import React, { ReactNode } from 'react'
import RCTooltip from 'rc-tooltip'

import 'rc-tooltip/assets/bootstrap_white.css'

import './Tooltip.scss'

type RCTooltipProps = React.ComponentProps<typeof RCTooltip>

export interface IconProps {
  height?: number | string
  width?: number | string
}

export interface TooltipProps
  extends Omit<RCTooltipProps, 'overlay'>,
    IconProps {
  message?: ReactNode
  info?: ReactNode
}

const Icon = ({ width = '1em', height = '1em' }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
    role="img"
    className="core-ui-tooltip__svg"
    height={height}
    width={width}
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="10.5" cy="10.5" r="10.5" fill="currentColor" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M11.96 6.32c0 .382-.14.699-.42.95-.28.25-.612.376-.998.376-.39 0-.725-.13-1.005-.39-.28-.261-.42-.573-.42-.937 0-.358.14-.668.42-.928.28-.26.615-.391 1.005-.391.386 0 .718.13.998.39.28.261.42.57.42.93zM13 14.264V16H8v-1.735h1.233v-4.037H8V8.463h3.798v5.802H13z"
      />
    </g>
  </svg>
)

const Tooltip = ({
  children,
  message,
  overlayStyle,
  trigger = ['hover'],
  info,
  placement = 'top',
  height,
  width,
  ...rest
}: TooltipProps) => (
  <RCTooltip
    transitionName="tooltip-fade"
    mouseEnterDelay={0.05}
    mouseLeaveDelay={0}
    trigger={trigger}
    overlay={info || message}
    {...rest}
    placement={placement}
    overlayStyle={overlayStyle}
  >
    {(info && (
      <span className="core-ui-tooltip__icon-wrapper">
        <Icon height={height} width={width} />
      </span>
    )) ||
      children}
  </RCTooltip>
)

export default Tooltip
