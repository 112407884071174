import * as React from 'react'

const AlertFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3 20.25a.75.75 0 01-.643-1.136l9-15a.75.75 0 011.286 0l9 15A.75.75 0 0121 20.25H3zm8.947-2.85a.937.937 0 00.676-.277.938.938 0 00.277-.676.93.93 0 00-.277-.67.938.938 0 00-.676-.277.93.93 0 00-.67.277.93.93 0 00-.277.67c0 .259.095.494.277.676a.93.93 0 00.67.277zm.803-3.355v-3.892a.75.75 0 10-1.5 0v3.892a.75.75 0 101.5 0z" />
  </svg>
)

export default AlertFilled
