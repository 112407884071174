class PermissionError extends Error {
  errors: Array<string>

  constructor(errors: Array<string>, message: string = 'Permission denied') {
    super(message)
    this.name = 'PermissionError'
    this.errors = errors
  }
}

export default PermissionError
