import * as React from 'react'

const AiFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14.24 14.457l.235 3.219a2.25 2.25 0 11-1.49.191l-.239-3.26a3.728 3.728 0 01-1.682-.598l-4.667 4.656a2.25 2.25 0 11-1.194-.927L10 12.95a3.733 3.733 0 01-.55-2.834L6.03 8.651a2.25 2.25 0 11.699-1.333l3.313 1.42a3.746 3.746 0 013.082-1.612c.766 0 1.479.23 2.073.624l2.54-2.547a2.25 2.25 0 11.928 1.194l-2.41 2.415a3.75 3.75 0 01.447 3.19l1.586.654a2.25 2.25 0 11-.65 1.354l-1.67-.69a3.753 3.753 0 01-1.729 1.137z" />
  </svg>
)

export default AiFilled
