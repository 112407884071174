import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { envFlag } from '@babylon/babylon-env'
import { usePermissions } from '@/components/hooks'
import asyncComponent from '@/components/AsyncComponent'

const RootPartners = asyncComponent('routes/RootPartners')
const Partners = asyncComponent('routes/Partners')
const UserManagement = asyncComponent('routes/UserManagement')
const MemberManagement = asyncComponent('routes/MemberManagement')
const Reports = asyncComponent('routes/Reports')
const Privacy = asyncComponent('routes/Privacy')
const Notice = asyncComponent('routes/Privacy/Notice')
const FAQ = asyncComponent('routes/FAQ')
const HelpAndSupport = asyncComponent('routes/HelpAndSupport')
const NotFound = asyncComponent('routes/NotFound')
const CreateOffering = asyncComponent('domains/MembershipPlans/CreateOffering')
const EditConfiguration = asyncComponent(
  'domains/AppointmentConfiguration/EditConfiguration'
)
const ViewConfiguration = asyncComponent(
  'domains/AppointmentConfiguration/ViewConfiguration'
)
const DataContract = asyncComponent('routes/DataContracts')

const Routes = () => {
  const isPermitted = usePermissions()
  const accessPartners = isPermitted({ partner: ['access'] })
  const createOffering = isPermitted({ plan: ['create'] })
  const viewUserManagement = isPermitted({ userManagement: ['view'] })
  const createMemberManagement = isPermitted({ memberManagement: ['create'] })
  const viewReports = isPermitted({ reports: ['view'] })
  const viewPrivacy = isPermitted({ privacy: ['view'] })
  const viewHelpAndSupport = isPermitted({ helpAndSupport: ['view'] })
  const viewDataContracts = isPermitted({ dataContracts: ['view'] })
  const editAppointmentConfiguration = isPermitted({
    appointmentConfiguration: ['edit'],
  })
  const viewAppointmentConfiguration = isPermitted({
    appointmentConfiguration: ['view'],
  })

  return (
    <Switch>
      {accessPartners && <Route exact path="/" component={RootPartners} />}
      {createOffering && (
        <Route
          exact
          path="/partners/:id/:tab/create-offering"
          component={CreateOffering}
        />
      )}
      {editAppointmentConfiguration && (
        <Route
          exact
          path="/partners/:partnerId/appointment-configuration/edit/:configurationId"
          component={EditConfiguration}
        />
      )}
      {viewAppointmentConfiguration && (
        <Route
          exact
          path="/partners/:partnerId/appointment-configuration/view/:configurationId"
          component={ViewConfiguration}
        />
      )}
      {accessPartners && (
        <Route path="/partners/:id/:tab?" component={Partners} />
      )}
      {viewUserManagement && (
        <Route path="/user-management/:userId?" component={UserManagement} />
      )}
      {createMemberManagement && (
        <Route path="/member-management" component={MemberManagement} />
      )}
      {envFlag('FEATURE_REPORTS') && viewReports && (
        <Route path="/reports" component={Reports} />
      )}
      {envFlag('FEATURE_DATA_CONTRACTS') && viewDataContracts && (
        <Route path="/data-contracts" component={DataContract} />
      )}
      {viewPrivacy && [
        <Route
          key="privacy-list"
          exact
          path="/privacy/:app?"
          component={Privacy}
        />,
        <Route
          key="privacy-notice"
          path="/privacy/:app/:id"
          component={Notice}
        />,
      ]}
      {viewHelpAndSupport && [
        envFlag('FEATURE_FAQ_PAGE') && (
          <Route path="/faq" key="faq" component={FAQ} />
        ),
        <Route
          path="/help-and-support"
          key="help-and-support"
          component={HelpAndSupport}
        />,
      ]}
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
