import NumberEncoder from './NumberEncoder'

const BASE_62_CHARSET =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
const START_OF_2018 = 1514678400000

export const base62 = new NumberEncoder(BASE_62_CHARSET)

export const createRandomToken = () => {
  const time = Date.now() - START_OF_2018
  const random = Math.round(Math.random() * Number.MAX_SAFE_INTEGER)

  return base62.encode(time) + base62.encode(random)
}
