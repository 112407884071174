import React from 'react'

interface VariantProps {
  // the name prop is used in the Experience component
  // eslint-disable-next-line react/no-unused-prop-types
  name: string
  children: React.ReactNode
}

const Variant = ({ children }: VariantProps) => <>{children}</>

export default Variant
