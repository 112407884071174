import * as React from 'react'

const TestFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M6.65 10.955h6.553l-1.664-1.673V2.455H8.262v6.82l-.21.217-1.402 1.463zm.112-8.5h-.487a.75.75 0 110-1.5h7.381a.75.75 0 110 1.5h-.617v6.208l6.163 6.196a2.25 2.25 0 01-1.576 3.856H2.403A2.25 2.25 0 01.797 14.89l5.965-6.219V2.455z" />
  </svg>
)

export default TestFilled
