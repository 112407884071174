import genTheme from '../utils/genTheme'

const genVar = genTheme('alert')

export const alertTheme = {
  info: genVar('info'),
  onInfo: genVar('on-info'),
  warning: genVar('warning'),
  onWarning: genVar('on-warning'),
  error: genVar('error'),
  onError: genVar('on-error'),
  positive: genVar('positive'),
  onPositive: genVar('on-positive'),
}
