import * as React from 'react'

const Family = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17 12.75a2.75 2.75 0 110-5.5 2.75 2.75 0 010 5.5zm0-1.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm1.1 3.898a.75.75 0 01.8-1.268c1.307.825 2.868 3.612 3.105 5.465a.75.75 0 01-.743.845h-2.334a.75.75 0 110-1.5h1.39c-.448-1.385-1.48-3.075-2.219-3.542zm-3.007 3.547c-.55-2.889-2.378-4.26-5.665-4.26s-5.162 1.374-5.793 4.26h11.458zm-13.09.655c.535-4.218 3.084-6.416 7.425-6.416 4.348 0 6.85 2.208 7.276 6.436a.75.75 0 01-.747.825H2.747a.75.75 0 01-.745-.845zm7.409-7.284c-2.227 0-4.044-1.743-4.044-3.908 0-2.166 1.817-3.908 4.044-3.908 2.226 0 4.044 1.742 4.044 3.908 0 2.165-1.818 3.908-4.044 3.908zm0-1.5c1.412 0 2.544-1.086 2.544-2.408 0-1.323-1.132-2.408-2.544-2.408C8 5.75 6.868 6.835 6.868 8.158c0 1.322 1.132 2.408 2.544 2.408z" />
  </svg>
)

export default Family
