import * as React from 'react'

const NavigationSearch = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M22.716 21.348l-3.964-3.967c-.33-.33-.35-.84-.086-1.224 2.592-3.77 2.213-8.97-1.139-12.322A9.644 9.644 0 0010.686 1C7.71 1 4.735 2.363 2.778 5.09c-2.37 3.302-2.37 7.879 0 11.181 1.958 2.727 4.932 4.09 7.907 4.09a9.645 9.645 0 005.517-1.727c.37-.257.875-.189 1.194.13l3.952 3.952a.965.965 0 001.368 0 .967.967 0 000-1.369zm-12.031-2.923a7.689 7.689 0 01-5.474-2.268 7.695 7.695 0 01-2.267-5.476c0-2.07.805-4.014 2.267-5.477a7.69 7.69 0 015.474-2.268 7.69 7.69 0 015.474 2.268 7.695 7.695 0 012.268 5.477 7.695 7.695 0 01-2.268 5.476 7.69 7.69 0 01-5.474 2.268z" />
  </svg>
)

export default NavigationSearch
