import * as React from 'react'

const ReferralFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M6.92 13.38H2.786a.75.75 0 110-1.5H6.86l-.856-.856a.75.75 0 011.06-1.06l2.167 2.166a.75.75 0 010 1.06l-2.096 2.097a.75.75 0 01-1.06-1.061l.845-.846zm-.865 7.902c.567-4.726 3.269-7.19 7.903-7.19 4.641 0 7.293 2.474 7.743 7.208a.75.75 0 01-.747.82H6.8a.75.75 0 01-.745-.838zm7.886-8.374c-2.366 0-4.28-1.94-4.28-4.33 0-2.388 1.914-4.328 4.28-4.328 2.366 0 4.28 1.94 4.28 4.329 0 2.388-1.914 4.329-4.28 4.329z" />
  </svg>
)

export default ReferralFilled
