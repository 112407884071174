import { envVar, envFlag } from '@babylon/babylon-env'

type DeploymentRegionType = 'uk' | 'ca' | 'us'

const createRoutesExperiences = (isPermitted: (path: string) => boolean) => ({
  ...(isPermitted('partner.view') ? { viewPartnerRoute: 'default' } : {}),
  ...(isPermitted('userManagement.view')
    ? { viewUserManagementRoute: 'default' }
    : {}),
  ...(isPermitted('partner.view') && isPermitted('memberManagement.create')
    ? { viewMemberManagementRoute: 'default' }
    : {}),

  ...(envFlag('FEATURE_DATA_CONTRACTS') && isPermitted('dataContracts.view')
    ? { viewDataContractsRoute: 'default' }
    : {}),
  ...(envFlag('FEATURE_REPORTS') && isPermitted('reports.view')
    ? { viewReportsRoute: 'default' }
    : {}),
  ...(isPermitted('privacy.view') ? { viewPrivacyRoute: 'default' } : {}),
  ...(isPermitted('helpAndSupport.view')
    ? { viewHelpAndSupportRoute: 'default' }
    : {}),
})

const createExperiences = ({
  isPermitted,
}: {
  isPermitted: (path: string) => boolean
}) => {
  const deploymentRegion: DeploymentRegionType = envVar('DEPLOYMENT_REGION')
  const isThisCanada = deploymentRegion === 'ca'
  const telusOrDefault = isThisCanada ? 'telus' : 'default'

  return {
    appLogo: telusOrDefault,
    // Add main navigation related experiments
    ...(isPermitted('partner.view') ? { viewPartner: 'default' } : {}),
    ...(isPermitted('partner.access') ? { accessPartner: 'default' } : {}),
    ...(isPermitted('userManagement.view')
      ? { viewUserManagement: 'default' }
      : {}),
    ...(isPermitted('memberManagement.create')
      ? { createMemberManagement: 'default' }
      : {}),
    ...(envFlag('FEATURE_DATA_CONTRACTS') && isPermitted('dataContracts.view')
      ? { viewDataContract: 'default' }
      : {}),
    ...(envFlag('FEATURE_REPORTS') && isPermitted('reports.view')
      ? { viewReports: 'default' }
      : {}),
    // Add routes experiences
    ...createRoutesExperiences(isPermitted),
    // Add Partner members experiences
    membersHelpAndSupport: telusOrDefault,
    membersCompanyName: telusOrDefault,
    footerName: telusOrDefault,
  }
}

export default createExperiences
