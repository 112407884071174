import React, { PropsWithChildren } from 'react'

import { captureException } from '@sentry/browser'
import { PageErrorMessage } from '@babylon/cce-shared-components'

interface Props {
  FallbackComponent?: React.ComponentType
}

type ErrorBoundaryProps = PropsWithChildren<Props>
type ErrorBoundaryState = {
  hasError: boolean
  defaultFallBackComponent: React.ComponentType
}

const logException = (exception: Error) => {
  captureException(exception)
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, defaultFallBackComponent: PageErrorMessage }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    logException(error)
  }

  render() {
    const { hasError } = this.state
    const { defaultFallBackComponent } = this.state
    const {
      children,
      FallbackComponent = defaultFallBackComponent,
    } = this.props
    return hasError ? <FallbackComponent /> : children
  }
}

export default ErrorBoundary
