import React, { FunctionComponent } from 'react'

const PaginationArrowSVG: FunctionComponent = ({ children, ...rest }) => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" {...rest}>
    <title>Arrow</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M10.4868545,13 C10.3741832,13 10.2603383,12.9582965 10.165272,12.8748895 L5.19130637,8.43216459 C5.07041952,8.32399617 5,8.16109191 5,7.98776178 C5,7.81573488 5.07041952,7.65152739 5.19130637,7.54466219 L10.1441461,3.12539547 C10.364794,2.92860713 10.6875501,2.96770415 10.8647726,3.21271215 C11.041995,3.45772016 11.0067853,3.81610953 10.7861374,4.01289787 L6.33092898,7.98776178 L10.808437,11.9873871 C11.0290848,12.1841755 11.0642946,12.5425648 10.8870721,12.7875728 C10.7849638,12.9270189 10.6370828,13 10.4868545,13 Z"
        fill="#000"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default PaginationArrowSVG
