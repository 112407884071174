import gql from 'graphql-tag'

export const CurrentUserQuery = gql`
  query currentPortalUser {
    currentPortalUser {
      id
      uuid
      fullName
      email
      timezone
      roles {
        id
        name
        key
      }
      partnerIds
    }
  }
`
