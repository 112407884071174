import React from 'react'
import PropTypes from 'prop-types'

const DropdownDisplayValue = ({ text, ...rest }) => {
  return (
    <abbr
      title={text}
      className="core-dropdown-select__display-value"
      {...rest}
    >
      {text}
    </abbr>
  )
}

DropdownDisplayValue.propTypes = {
  text: PropTypes.node,
}

export default DropdownDisplayValue
