export const capitalize = (value) =>
  `${value.slice(0, 1).toUpperCase()}${value.slice(1)}`

export const kebabCase = (value) =>
  value
    .replace(/[^a-zA-Z0-9]/g, ' ')
    .trim()
    .replace(/[\s]+/g, '-')
    .replace(/([A-Z])+/g, ($1) => `-${$1}`)
    .replace(/([0-9])+/g, ($1) => `-${$1}`)
    .replace(/-+/g, '-')
    .toLowerCase()
    .replace(/^-/, '')
