import * as React from 'react'

const Alert = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3 20.25a.75.75 0 01-.643-1.136l9-15a.75.75 0 011.286 0l9 15A.75.75 0 0121 20.25H3zm1.325-1.5h15.35L12 5.958 4.325 18.75zm7.622-1.35a.93.93 0 01-.67-.277.938.938 0 01-.277-.676.93.93 0 01.277-.67.93.93 0 01.67-.277c.259 0 .494.095.676.277a.93.93 0 01.277.67.937.937 0 01-.277.676.938.938 0 01-.676.277zm.803-3.355a.75.75 0 11-1.5 0v-3.892a.75.75 0 111.5 0v3.892z" />
  </svg>
)

export default Alert
