import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { cn } from '@/utils'
import { useFieldContext } from '../Field'
import styles from './styles.module.scss'

export interface ToggleSwitchProps {
  className?: string
  name?: string
  checked?: boolean
  readOnly?: boolean
  labelId?: string
  renderLabel?: (checked: boolean) => React.ReactNode
}

const ToggleSwitch = ({
  className,
  name: propName,
  readOnly,
  renderLabel,
  ...props
}: ToggleSwitchProps) => {
  const control = useFormContext()?.control
  const { name: contextName } = useFieldContext()
  const name = contextName || propName
  if (control && !name) {
    throw new Error('ToggleSwitch requires a name')
  }

  return control ? (
    <Controller
      render={({ onChange, value }) => (
        <div className={cn(styles.toggleSwitch, className)}>
          <label>
            {!readOnly && (
              <>
                <input
                  type="checkbox"
                  onChange={(e) => onChange(e.target.checked)}
                  checked={!!value}
                  value={value}
                  data-testid="toggleSwitchInput"
                  id={props.labelId}
                />
                <div className={styles.toggleSwitchSlider} />
              </>
            )}
            {renderLabel?.(value)}
          </label>
        </div>
      )}
      name={name || ''}
      control={control}
    />
  ) : (
    <div className={cn(styles.toggleSwitch, className)}>
      <label>
        {!readOnly && (
          <>
            <input type="checkbox" {...props} />
            <div className={styles.toggleSwitchSlider} />
          </>
        )}
        {renderLabel?.(props.checked || false)}
      </label>
    </div>
  )
}

export default ToggleSwitch
