import * as React from 'react'

const BargraphFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21.063 16.603h.041a.75.75 0 010 1.5H2.896a.75.75 0 010-1.5h.042v-3.074a.75.75 0 01.75-.75h2.374a.75.75 0 01.75.75v3.074h.875V9.706a.75.75 0 01.75-.75h2.376a.75.75 0 01.75.75v6.897h.874v-5.368a.75.75 0 01.75-.75h2.376a.75.75 0 01.75.75v5.368h.875V5.883a.75.75 0 01.75-.75h2.375a.75.75 0 01.75.75v10.72z" />
  </svg>
)

export default BargraphFilled
