// The following is required due to passing all props to the components and the
// list is very long, so we can't really do one by one.
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, RefForwardingComponent, useRef } from 'react'
import { Field, FieldProps, FieldInputProps, FieldValidator } from 'formik'

import ErrorMessages from '../ErrorMessages'
import useFocusOnError from '../BaseInput/useFocusOnError'

export interface WithFieldProps {
  name: string
  validate?: FieldValidator
  validateMessage?: string
  required?: boolean
}
export interface WithValidationFieldsProps {
  errors?: string[]
  field?: FieldInputProps<any>
  error?: boolean | string
}

export interface InputProps {
  name?: string
  value?: any
}

const requiredValidation = (value?: string | string[]) =>
  !value || (Array.isArray(value) && value.length === 0)

const fieldValidation = async (
  validationFunc?: FieldValidator,
  value?: string | string[]
) => {
  if (validationFunc) {
    const error = await validationFunc(value)

    if (error) {
      return error
    }
  } else {
    return requiredValidation(value)
  }

  return undefined
}

const withValidationFields = <P extends InputProps>(
  Component: FC<P> | RefForwardingComponent<P, any>
):
  | FC<P & WithValidationFieldsProps>
  | RefForwardingComponent<P & WithValidationFieldsProps, any> => (
  props: P & WithValidationFieldsProps
) => {
  const { name, value, field, error, errors } = props
  let checked

  if (field) {
    checked = Array.isArray(field.value)
      ? field.value.includes(value)
      : field.checked
  }

  const fieldName = field ? field.name : name

  return (
    <Component
      // input elements need field to be spread but SelectionControls
      // cannot be spread. So little duplication here.
      field={field}
      {...field}
      checked={checked}
      error={error ? Boolean(error) : undefined}
      errors={errors}
      fieldName={fieldName}
      {...props}
      aria-describedby={error && fieldName ? `${fieldName}-error` : undefined}
      ErrorMessages={() => (
        <ErrorMessages fieldName={fieldName} formErrors={errors} />
      )}
    />
  )
}

const withField = <P extends InputProps>(
  Component: FC<P>
): FC<WithFieldProps & P> => (props) => {
  const ComponentWithValidationFields = withValidationFields(Component) as FC<
    P & WithValidationFieldsProps
  >
  const { name, required } = props
  const { validate, validateMessage, ...inputProps } = props

  return (
    <Field
      name={name}
      validate={
        validate || required
          ? (value: any) => fieldValidation(validate, value)
          : undefined
      }
    >
      {({
        field,
        meta: { touched, error },
        form: { status = {} },
      }: FieldProps<any>) => {
        const fieldStatus: string[] | undefined = status[name]
        const hasStatus = fieldStatus && fieldStatus.length > 0
        const hasError = Boolean(touched && (error || hasStatus))
        const fieldRef = useRef(null)

        const fieldErrors: string[] = []
        useFocusOnError({ name, fieldRef })

        if (hasError) {
          if (error) {
            fieldErrors.push(validateMessage || error)
          } else if (hasStatus) {
            fieldErrors.push(...(fieldStatus as string[]))
          }
        }

        return (
          <ComponentWithValidationFields
            error={hasError}
            errors={fieldErrors}
            field={field}
            fieldRef={fieldRef}
            {...(inputProps as P)}
          />
        )
      }}
    </Field>
  )
}

export default withField
export { withValidationFields }
