import * as React from 'react'

const Plus = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.75 12.75v6.513a.75.75 0 11-1.5 0V12.75H4.74a.75.75 0 110-1.5h6.51V4.713a.75.75 0 111.5 0v6.537h6.568a.75.75 0 110 1.5H12.75z" />
  </svg>
)

export default Plus
