import * as React from 'react'

const Delete = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M4.817 20.114V5.27a.75.75 0 01.75-.75h12.771a.75.75 0 01.75.75v14.843c0 .953-.79 1.74-1.752 1.74H6.57a1.75 1.75 0 01-1.752-1.74zm1.5-14.093v14.093c0 .128.114.24.252.24h10.767c.135 0 .252-.117.252-.24V6.02H6.318zm-2.624 0a.75.75 0 110-1.5h16.583a.75.75 0 010 1.5H3.693zm4.08 2.72a.75.75 0 011.5 0v8.52a.75.75 0 11-1.5 0V8.74zm3.477.023a.75.75 0 111.5 0v8.48a.75.75 0 01-1.5 0v-8.48zm3.5-.034a.75.75 0 111.5 0v8.56a.75.75 0 11-1.5 0V8.73zM9.003 6.02a.75.75 0 01-.75-.75v-1.5c0-.968.78-1.753 1.745-1.753h4.038c.96 0 1.746.788 1.746 1.754v1.5a.75.75 0 01-.75.75h-6.03zm.75-1.5h4.529v-.75a.255.255 0 00-.246-.253H9.998a.249.249 0 00-.245.254v.75z" />
  </svg>
)

export default Delete
