import * as React from 'react'

const Locator = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M19.543 3.5c.642-.313 1.316.364 1.001 1.005l-7.87 16.026c-.303.615-1.203.537-1.394-.12L9.6 14.645a.25.25 0 00-.168-.17l-5.837-1.758a.75.75 0 01-.112-1.392L19.543 3.5zm-13.68 8.335l4.003 1.206a1.75 1.75 0 011.175 1.186l1.138 3.907 6.055-12.328-12.37 6.029z" />
  </svg>
)

export default Locator
