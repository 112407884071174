import * as React from 'react'

const StarFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11.896 17.102l-3.304 2.362a1.68 1.68 0 01-2.57-1.898l1.251-3.747a.18.18 0 00-.066-.204l-3.24-2.295a1.68 1.68 0 01.974-3.05l4.04.008a.18.18 0 00.171-.123l1.253-3.806a1.68 1.68 0 013.19 0l1.253 3.806a.18.18 0 00.171.123l4.04-.009a1.68 1.68 0 01.974 3.05l-3.24 2.296a.18.18 0 00-.066.204l1.251 3.747a1.68 1.68 0 01-2.57 1.898l-3.303-2.362a.18.18 0 00-.21 0z" />
  </svg>
)

export default StarFilled
