import React, { useContext } from 'react'
import ReactDOM from 'react-dom'

import sidebarContext from './sidebarContext'
import styles from './styles.module.scss'

interface SidebarProps {
  children?: React.ReactNode
}

function Sidebar({ children }: SidebarProps) {
  const { show, root } = useContext(sidebarContext)

  return show
    ? ReactDOM.createPortal(
        <div className={styles.sidebar} data-testid="sidebar">
          {children}
        </div>,
        root as HTMLElement
      )
    : null
}

export default Sidebar
