import * as React from 'react'

const BrainFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21.75 11.21a3.89 3.89 0 01-.313 1.528l.001.103c0 1.87-1.332 3.448-3.12 3.847l-.173 1.793a1.411 1.411 0 01-1.409 1.269h-1.77a1.43 1.43 0 01-.99-.396l-2.23-2.15a4.033 4.033 0 01-3.292-1.231c-.346.071-.7.107-1.059.107-2.835 0-5.145-2.266-5.145-5.061 0-2.304 1.581-4.299 3.802-4.886a4.027 4.027 0 013.246-1.637 4.03 4.03 0 011.352.23 4.039 4.039 0 011.905-.476 4.026 4.026 0 012.732 1.057 4.05 4.05 0 01.974-.118c1.808 0 3.36 1.165 3.848 2.839a3.94 3.94 0 011.64 3.182z" />
  </svg>
)

export default BrainFilled
