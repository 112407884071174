import * as React from 'react'

const NavigationRefresh = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#prefix__clip0)">
      <path d="M23.707 10.293a.999.999 0 00-1.414 0l-2.326 2.326c.013-.206.033-.41.033-.62 0-5.616-4.631-10.151-10.283-9.995C4.499 2.148.149 6.498.004 11.716-.153 17.369 4.383 22 10 22a9.958 9.958 0 007.244-3.12 1.01 1.01 0 00-.014-1.408l-.002-.002a1 1 0 00-1.437.03 7.976 7.976 0 01-7.368 2.347c-3.128-.603-5.654-3.115-6.266-6.241C1.157 8.495 5.064 4 10 4c4.59 0 8.29 3.886 7.982 8.54l-.02.009-2.255-2.255a.999.999 0 10-1.414 1.414l4 4a.999.999 0 001.414 0l4-4a1 1 0 000-1.415z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default NavigationRefresh
