function flattenList(list) {
  if (!Array.isArray(list)) {
    return undefined
  }

  return list.map((message) => {
    if (typeof message !== 'object' || message === null) {
      return message
    }

    return (
      message.base ||
      (Object.values(message).find((messageProp) =>
        Array.isArray(messageProp)
      ) || [])[0] ||
      null
    )
  })
}

function flattenMessages(messagesByField, content = {}, preserveArray = false) {
  if (typeof messagesByField !== 'object' || messagesByField === null) {
    return undefined
  }

  return Object.entries(messagesByField).reduce((acc, [field, messages]) => {
    if (Array.isArray(messages)) {
      acc[field] = preserveArray
        ? messages
        : `${content[field] || ''} ${messages[0]}`

      return acc
    }

    if (typeof messages === 'string') {
      acc[field] = `${content[field] || ''} ${messages}`

      return acc
    }

    if (typeof messages === 'object' && messages !== null) {
      const { base, list } = messages

      if (base || list) {
        acc[field] = {
          base: Array.isArray(base) ? base[0] : base,
          list: flattenList(list),
        }
      } else {
        acc[field] = flattenMessages(messages, content[field])
      }
    }

    return acc
  }, {})
}

function getGQLValidationErrors({
  errors = [],
  content = {},
  preserveArray = false,
}) {
  const errorMessages = errors
    .filter((error) => error?.extensions?.code === 'BAD_USER_INPUT')
    .map((error) => error?.extensions?.exception?.invalidInput || {})
    .reduce((acc, messages) => ({ ...acc, ...messages }), {})

  return Object.entries(errorMessages).reduce((acc, [key, messages]) => {
    acc[key] = flattenMessages(messages, content[key], preserveArray)

    return acc
  }, {})
}

export default getGQLValidationErrors
