import React, { SyntheticEvent, KeyboardEvent } from 'react'
import classnames from 'classnames'

import Button from '../../foundation/Button'

interface Props {
  isOpen?: boolean
  onClick?: (event: SyntheticEvent) => void
  onKeyUp?: (event: KeyboardEvent<Element>) => void
}

const KebabMenuButton = ({ isOpen = false, onClick, onKeyUp }: Props) => (
  <Button
    className={classnames('core-ui-kebab__button', {
      'core-ui-kebab__button--active': isOpen,
    })}
    intent="secondary"
    aria-haspopup="true"
    aria-expanded={isOpen ? 'true' : 'false'}
    onClick={onClick}
    onKeyUp={onKeyUp}
    testId="kebab-menu-toggle"
  >
    <svg
      className="core-ui-kebab__button__svg"
      width={4}
      height={16}
      viewBox="0 0 4 16"
    >
      <path
        className="core-ui-kebab__button__icon"
        fillRule="evenodd"
        d="M2 4a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
      />
    </svg>
  </Button>
)

export default KebabMenuButton
