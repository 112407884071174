/* eslint-disable camelcase */
import React, { useLayoutEffect, useRef } from 'react'
import { differenceInMinutes } from 'date-fns'
import useTokenInfo from './use-token-info'
import useUserActivityNotifier from './use-user-activity-notifier'
import { getAuthConfig, refreshKongToken, logout } from '../auth-utils'
import { getLastActivityTime, setLastActivityTime } from './last-activity-time'
import { getTokenInfo } from './helpers'

const UNEXPECTED_LOGOUT_THRESHOLD_MINS = 10

type ErrorHandler = (err: Error) => void

interface PortalLoginProvider {
  children: React.ReactNode
  onError?: ErrorHandler
  onAutoLogout?: (isUnexpectedLogout: boolean) => void
}

const createPortalLoginProvider = (
  handleTokenRefresh: typeof refreshKongToken
) => ({ children, onError = () => {}, onAutoLogout }: PortalLoginProvider) => {
  const { kongTokenValid, account_maintenance_required } = useTokenInfo()
  const isFirstActivity = useRef(true)

  useUserActivityNotifier(async () => {
    setLastActivityTime(new Date())
    const {
      kongTokenValid: updatedKongTokenValid,
      refreshTokenValid,
    } = getTokenInfo()

    if (!updatedKongTokenValid && !refreshTokenValid) {
      return
    }

    if (!isFirstActivity.current) {
      try {
        await handleTokenRefresh('portal')
      } catch (err) {
        onError(err)
      }
    } else {
      isFirstActivity.current = false
    }
  }, getAuthConfig().userActivityThrottle)

  const shouldLogout = !kongTokenValid || account_maintenance_required
  useLayoutEffect(() => {
    if (shouldLogout) {
      const lastActivityTime = getLastActivityTime()
      // If the user is logged out but they were recently active, it is an unexpected logout
      // This will help with fixing the ongoing unexpected autologout issues
      const isUnexpectedLogout = lastActivityTime
        ? differenceInMinutes(new Date(), lastActivityTime) <
          UNEXPECTED_LOGOUT_THRESHOLD_MINS
        : false

      if (onAutoLogout) {
        onAutoLogout(isUnexpectedLogout)
      }

      logout({
        unexpectedLogout: isUnexpectedLogout,
        autoLogout: true,
        redirect: true,
      })
    }
  }, [shouldLogout, onAutoLogout])

  if (shouldLogout) {
    return null
  }

  return <>{children}</>
}

export const createTestablePortalLoginProvider = (
  handleTokenRefresh: typeof refreshKongToken
) => createPortalLoginProvider(handleTokenRefresh)

export default createPortalLoginProvider(refreshKongToken)
