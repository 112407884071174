import React from 'react'
import cx from 'classnames'
import { capitalize } from '@/utils'

import styles from './styles.module.scss'

type TagType = 'green' | 'red' | 'grey' | 'darkGrey'

interface TagProps {
  value: string
  type: TagType
  className?: ClassNameType
}

export default ({ value, type = 'green', className, ...props }: TagProps) => (
  <div className={cx(styles.tag, styles[type], className)} {...props}>
    {capitalize(value)}
  </div>
)
