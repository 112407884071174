import * as React from 'react'

const VideoOffFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M15.988 14.123V11.08c0-.581.075-.436.226-.74.118-.24.759-.959 1.224-1.452.269-.284.693-.656.797-.713l2.661-1.47a.712.712 0 011.057.624v9.428c0 .55-.594.892-1.07.617l-4.54-2.634a.712.712 0 01-.355-.616zM2.927 18.431a.55.55 0 01-.408-.149 1.694 1.694 0 01-.502-1.236V7.027c0-.966.784-1.75 1.75-1.75h9.288c.803 0 1.479.54 1.685 1.276.043.15.057.185.024.357a.593.593 0 01-.185.338L3.239 18.282c-.103.1-.16.15-.312.15zm11.878-6.259v5.126a1.75 1.75 0 01-1.75 1.75H7.682c-.126 0-.147-.106-.094-.159l6.908-6.807c.17-.167.31-.044.31.09zm5.763-9.881a.75.75 0 011.063 1.057L3.351 21.736a.75.75 0 01-1.063-1.058l18.28-18.387z" />
  </svg>
)

export default VideoOffFilled
