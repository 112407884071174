import React, { Component } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import './index.scss'

export const Context = React.createContext({})

class Checkbox extends Component {
  static defaultProps = {
    checkboxFirst: true,
  }

  getBaseClassName = () => {
    const { type } = this.props

    switch (type) {
      case 'pill':
        return 'core-checkbox-pill'
      case 'switch':
        return 'core-checkbox-switch'
      default:
        return 'core-checkbox'
    }
  }

  getClassName = () => {
    const { disabled, className, indeterminate, inline } = this.props
    const baseClassName = this.getBaseClassName()

    return cn(
      baseClassName,
      {
        [`${baseClassName}--checked`]: this.getChecked(),
        [`${baseClassName}--disabled`]: disabled,
        [`${baseClassName}--indeterminate`]: indeterminate,
        [`${baseClassName}--inline`]: inline,
      },

      className
    )
  }

  getChecked = () => {
    const checked = Array.isArray(this.context.value)
      ? this.context.value.includes(this.props.value)
      : this.props.checked

    return checked || false
  }

  handleChange = (event) => {
    const onChange = this.context.onChange || this.props.onChange

    if (onChange) {
      onChange(event)
    }
  }

  handleOnBlur = (event) => {
    const onBlur = this.context.onBlur || this.props.onBlur

    if (onBlur) {
      onBlur(event)
    }
  }

  render() {
    const {
      disabled,
      name,
      value,
      testId,
      id,
      children,
      checkboxFirst,
      type,
    } = this.props
    // TODO - generate id if no id given, can use nanoid
    const baseClassName = this.getBaseClassName()
    const className = this.getClassName()
    const checked = this.getChecked()

    return (
      <label className={className} htmlFor={id}>
        <input
          id={id}
          type="checkbox"
          className="core-checkbox__input"
          name={name}
          value={value}
          disabled={disabled}
          checked={checked}
          onChange={this.handleChange}
          onBlur={this.handleOnBlur}
          data-testid={testId}
        />
        {type === 'pill' ? (
          children
        ) : (
          <>
            {checkboxFirst && <div className={`${baseClassName}__element`} />}
            <div>{children}</div>
            {!checkboxFirst && <div className={`${baseClassName}__element`} />}
          </>
        )}
      </label>
    )
  }
}

Checkbox.contextType = Context

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
  inline: PropTypes.bool,
  indeterminate: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  checkboxFirst: PropTypes.bool,
  type: PropTypes.string,
}

export default Checkbox
