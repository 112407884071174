import React from 'react'
import cn from 'classnames'
import { BabylonIcon } from '@babylon/icons'
import { IMedkitComponent } from '..'
import { iconTheme } from './theme'
import classes from './icon.module.scss'

export interface IIconProps {
  /** Extra class name(s) to be applied to the component */
  className?: string
  iconClassName?: string
  /** Title fo the icon */
  title: string
  /** Height of the icon */
  height?: number
  /** Width of the icon */
  width?: number
  /** Name of the icon */
  icon: BabylonIcon
  /** Fill colour of the icon */
  fill?: string
}

const Icon: IMedkitComponent<IIconProps, typeof iconTheme> = ({
  className,
  iconClassName,
  title,
  fill,
  icon,
  height,
  width,
}) => {
  // TODO: Should be imported properly via '@babylon/icons/Activity'. However,
  // using workspaces completely ignores what this package exports. Hence, we
  // Need to use it this way, until it's published as an npm module.
  const IconComponent = require('@babylon/icons')[icon]

  // This is done via styles instead of fill attribute because the fill prop was
  // getting replaced with undefined and that caused black icons. Also, this way
  // it's possible to theme the icons easily using css variables.
  const styles = { fill }

  return (
    // aria-label is used instead of figcaption to prevent extra markup and css
    // to hide the figcaption element.
    <div className={cn(classes.IconWrapper, className)}>
      {IconComponent ? (
        <IconComponent
          className={cn(classes.Icon, iconClassName)}
          style={styles}
          title={title}
          width={width}
          height={height}
          aria-label={title}
          focusable={false}
        />
      ) : (
        `${icon} not found.`
      )}
    </div>
  )
}

Icon.theme = iconTheme

Icon.defaultProps = {
  className: '',
  fill: iconTheme.fill,
  height: 24,
  width: 24,
}

export default Icon
