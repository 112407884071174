import * as React from 'react'

const DoctorFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.943 3.934a.75.75 0 011.43.316v4.548a6.194 6.194 0 01-5.12 6.1c.003.506.01 1.093.016 1.178.195 2.62 1.735 4.347 4.129 4.347 2.557 0 4.147-1.993 4.147-4.875v-.707a2.751 2.751 0 111.5.008v.7c0 3.644-2.177 6.374-5.647 6.374-3.247 0-5.376-2.387-5.625-5.737-.009-.122-.016-.69-.02-1.21a6.193 6.193 0 01-5.764-6.178V4.25a.75.75 0 011.447-.276 2.076 2.076 0 01.053 3.881v.943a4.692 4.692 0 109.384 0v-.907a2.076 2.076 0 01.07-3.957z" />
  </svg>
)

export default DoctorFilled
