import * as React from 'react'

const FilesAttachment = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21.596 3.423a4.763 4.763 0 00-6.791 0L5.227 13.13a3.28 3.28 0 000 4.589c.605.613 1.409.95 2.264.95.856 0 1.66-.337 2.263-.95l7.14-7.237a1.007 1.007 0 000-1.411.975.975 0 00-1.393 0l-7.14 7.236a1.247 1.247 0 01-1.741 0 1.248 1.248 0 01-.36-.882c0-.334.127-.647.36-.883l9.577-9.708a2.809 2.809 0 014.006 0 2.9 2.9 0 010 4.059l-9.666 9.796a4.396 4.396 0 01-6.268 0 4.487 4.487 0 01-1.299-3.176c0-1.2.461-2.328 1.298-3.177l7.314-7.413a1.007 1.007 0 000-1.411.975.975 0 00-1.393 0l-7.313 7.413A6.494 6.494 0 001 15.513c0 1.734.666 3.364 1.876 4.59A6.335 6.335 0 007.403 22a6.34 6.34 0 004.527-1.897l9.666-9.796c1.872-1.898 1.872-4.986 0-6.884z" />
  </svg>
)

export default FilesAttachment
