import { useEffect, FC, RefObject } from 'react'

interface Props {
  children: any
  refToScroll?: RefObject<HTMLDivElement>
  variableToWatch?: any
}

const ScrollToTop: FC<Props> = ({ children, variableToWatch, refToScroll }) => {
  useEffect(() => {
    if (!refToScroll) {
      window.scrollTo(0, 0)
    } else {
      // Just to make sure we have a ref. Sometimes it requires to happen in the
      // next thread, depending on how libraries set their refs.
      setTimeout(() => {
        if (refToScroll.current) {
          refToScroll.current.scrollTop = 0
        }
      })
    }
  }, [refToScroll, variableToWatch])

  return children
}

export default ScrollToTop
