import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { useFormatMessage } from '@babylon/intl'
import { ButtonSimple, ButtonVariant } from '@babylon/medkit'

import { StepType } from './StepForm'
import styles from './styles.module.scss'
import messages from './messages'

interface SummaryStepProps {
  steps: StepType[]
  busy: boolean
  setActiveStepIndex: Dispatch<SetStateAction<number>>
}

const SummaryStep = ({ steps, busy, setActiveStepIndex }: SummaryStepProps) => {
  const fm = useFormatMessage()
  const { getValues } = useFormContext()
  const formValues = getValues()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {steps.map((step, index) => {
        return (
          <div key={step.label} className={styles.step}>
            <div className={styles.summaryComponent}>
              {React.cloneElement(step.summary, {
                formValues,
              })}
            </div>
            <div className={styles.editButton}>
              <ButtonSimple
                key="back"
                onClick={() => {
                  setActiveStepIndex(index)
                }}
                variant={ButtonVariant.secondary}
                disabled={busy}
              >
                {fm(messages.editStepLabel)}
              </ButtonSimple>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default SummaryStep
