import * as React from 'react'

const ArrowUpFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.373 9.728l3.508 3.637a.5.5 0 01-.36.848H8.505a.5.5 0 01-.36-.848l3.508-3.637a.5.5 0 01.72 0z" />
  </svg>
)

export default ArrowUpFilled
