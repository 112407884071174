import * as React from 'react'

const PhoneCircle = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zm0-1.5a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5zm1.505-6.856a.753.753 0 01.856-.255l1.084.403c.331.122.555.47.555.86v1.852c0 .23-.177.41-.381.387-4.478-.502-7.042-3.234-7.612-8.117A1.014 1.014 0 018 8.406c0-.5.362-.906.809-.906h1.135c.328 0 .624.222.749.563l.46 1.258a.98.98 0 01-.221 1.031l-.324.312a.14.14 0 00-.032.145c.476 1.359 1.345 2.343 2.624 2.964l.305-.38z" />
  </svg>
)

export default PhoneCircle
