import * as React from 'react'

const FilesPrescription = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3 3v18a1 1 0 001 1h16a1 1 0 001-1V3a1 1 0 00-1-1H4a1 1 0 00-1 1zm6 4h2V5h2v2h2v2h-2v2h-2V9H9V7zm9 12H6v-1h12v1zm0-4H6v-1h12v1z" />
  </svg>
)

export default FilesPrescription
