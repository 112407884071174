import React, { FormEventHandler } from 'react'
import { Controller, useFormContext, RegisterOptions } from 'react-hook-form'

import { Checkbox as MedkitCheckbox } from '@babylon/medkit'

import { useFieldContext } from '../Field'
import styles from './styles.module.scss'

interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  value?: string | number
  checked?: boolean
  ref?: React.Ref<HTMLInputElement>
  onChange?: FormEventHandler<HTMLInputElement>
  helpText?: string
}

const MedkitCheckboxWrapper = React.forwardRef(
  (
    { name: propName, rules, onChange: propOnChange, ...props }: CheckboxProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const control = useFormContext()?.control
    const { name: contextName } = useFieldContext()
    const name = propName || contextName
    if (control && !name) {
      throw new Error('MedkitCheckboxtWrapper requires a name')
    }

    return control ? (
      <Controller
        control={control}
        name={name || ''}
        rules={rules}
        render={({ onChange, value }) => (
          <MedkitCheckbox
            onChange={(e) => {
              onChange(e.target.checked)

              if (propOnChange) {
                propOnChange(e)
              }
            }}
            checked={!!value}
            ref={ref}
            className={styles.checkbox}
            {...props}
          />
        )}
      />
    ) : (
      <MedkitCheckbox
        ref={ref}
        className={styles.checkbox}
        onChange={propOnChange}
        {...props}
      />
    )
  }
)

export default MedkitCheckboxWrapper
