import React from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import BaseInput, { BaseInputProps } from '../BaseInput/BaseInput'
import { IMedkitComponent } from '../..'
import { textareaTheme } from './theme'

export const Textarea: IMedkitComponent<IProps, typeof textareaTheme> = ({
  className,
  label,
  ...props
}) => (
  <BaseInput
    {...props}
    label={label}
    className={cn(styles.Textarea, className)}
    inputClassName={cn(styles.Textarea__textarea, {
      [styles.Textarea__textarea_withLabel]: label,
    })}
    component="textarea"
  />
)

Textarea.theme = textareaTheme

export interface IProps extends BaseInputProps<HTMLTextAreaElement> {}

export default Textarea
