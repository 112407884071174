import * as React from 'react'

const MicroOff = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14.237 20.493a.75.75 0 110 1.5h-4.49a.75.75 0 010-1.5h4.49zm-10.608 1.01a.79.79 0 01-1.114-1.119L20.346 2.637a.79.79 0 011.114 1.119L3.63 21.503zm6.621-10.048a.75.75 0 11-1.5 0V5.5a3.25 3.25 0 016.5 0v.726a.75.75 0 11-1.5 0V5.5a1.75 1.75 0 00-3.5 0v5.955zm3.237.968a.75.75 0 111.273.793c-.187.301-.422.57-.696.795a.75.75 0 01-.953-1.159c.148-.121.275-.266.376-.429zm-2.237 4.328a.75.75 0 111.5 0v4.49a.75.75 0 01-1.5 0v-4.49zm5-6.383a.75.75 0 111.5 0v1.274a5.75 5.75 0 01-7.25 5.552.75.75 0 11.39-1.448 4.256 4.256 0 005.36-4.104v-1.274zm-8.241 2.738a.75.75 0 11-1.409.516 5.74 5.74 0 01-.35-1.98V8a.75.75 0 011.5 0v3.642c0 .506.088 1 .259 1.464z" />
  </svg>
)

export default MicroOff
