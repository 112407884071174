import { defineMessages } from 'react-intl'

const baseId = 'user_menu'

const messages = defineMessages({
  profileIconTitle: {
    id: `${baseId}.profile_icon_title`,
    defaultMessage: 'Profile',
  },
  rolesLabel: {
    id: `${baseId}.roles_label`,
    defaultMessage: 'Role(s)',
  },
  myAccountLabel: {
    id: `${baseId}.my_account_label`,
    defaultMessage: 'My account',
  },
  logoutLabel: {
    id: `${baseId}.logout_label`,
    defaultMessage: 'Logout',
  },
})

export default messages
