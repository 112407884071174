import * as React from 'react'

const Language = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zm0-1.5a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5zM4.488 18v-1.5h15.03V18H4.487zM14.696 7.604H9.349a11.036 11.036 0 00-.695 3.646h6.736a11.036 11.036 0 00-.694-3.646zm-.685-1.5a13.213 13.213 0 00-1.67-2.379.75.75 0 111.13-.987 14.429 14.429 0 012.222 3.366h3.824v1.5h-3.232c.383 1.182.584 2.403.606 3.646h3.95v1.5h-4.01c-.274 2.814-1.446 5.714-3.505 8.537a.75.75 0 01-1.304-.168.75.75 0 01-1.304.167c-2.06-2.822-3.23-5.722-3.504-8.536h-4.05v-1.5h3.99c.02-1.243.222-2.464.605-3.646h-3.27v-1.5H8.35a14.429 14.429 0 012.223-3.366.75.75 0 011.13.987 13.213 13.213 0 00-1.67 2.38h3.977zM8.72 12.75c.272 2.501 1.341 5.093 3.209 7.652.039.053.07.11.092.167a.751.751 0 01.092-.167c1.868-2.559 2.938-5.15 3.21-7.652H8.72z" />
  </svg>
)

export default Language
