import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

import useModalContext from './useModalContext'

import styles from './styles.module.scss'

interface ModalProps {
  children?: React.ReactNode
}

const Modal = ({ children }: ModalProps) => {
  const { root } = useModalContext()

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  })

  return ReactDOM.createPortal(
    <div className={styles.modal}>{children}</div>,
    root as HTMLElement
  )
}

export default Modal
