import { defineMessages } from 'react-intl'

const baseId = 'tree_select_component'

export default defineMessages({
  loading: {
    id: `${baseId}.loading`,
    defaultMessage: 'Loading...',
  },
})
