import React from 'react'

import { BabylonIcon } from '@babylon/icons'
import { Alert, Icon } from '@babylon/medkit'

import { SnackbarTypeType } from './createSnackbarBag'
import styles from './styles.module.scss'

const iconsByType: Dictionary<BabylonIcon> = {
  error: 'AlertsAlert',
  alert: 'AlertsInformationFill',
  success: 'NavigationConfirm',
}

export interface SnackbarProps {
  type: SnackbarTypeType
  message: string
  onClose: () => void
}

const Snackbar = ({ type, message, onClose }: SnackbarProps) => (
  <div className={styles.snackbar}>
    <Alert
      icon={
        <Icon
          icon={iconsByType[type]}
          title="Snackbar icon"
          className={styles.iconWrapper}
          iconClassName={styles.icon}
        />
      }
      type={type === 'success' ? 'positive' : 'error'}
      className={styles.message}
    >
      {message}
    </Alert>
    <button className={styles.close} onClick={onClose}>
      <Icon icon="NavigationClose" title="Close Snackbar" fill="#ffffff" />
    </button>
  </div>
)

export default Snackbar
