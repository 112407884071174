import * as React from 'react'

const FamilyFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17 12.75a2.75 2.75 0 110-5.5 2.75 2.75 0 010 5.5zm1.1 2.398a.75.75 0 01.8-1.268c1.307.825 2.868 3.612 3.105 5.465a.75.75 0 01-.743.845h-2.334a.75.75 0 110-1.5h1.39c-.448-1.385-1.48-3.075-2.219-3.542zM2.001 19.35c.536-4.218 3.085-6.416 7.426-6.416 4.348 0 6.85 2.208 7.276 6.436a.75.75 0 01-.747.825H2.747a.75.75 0 01-.745-.845zm7.41-7.284c-2.227 0-4.044-1.743-4.044-3.908 0-2.166 1.817-3.908 4.044-3.908 2.226 0 4.044 1.742 4.044 3.908 0 2.165-1.818 3.908-4.044 3.908z" />
  </svg>
)

export default FamilyFilled
