import React from 'react'
import cx from 'classnames'

import styles from './styles.module.scss'

interface PopoverItemProps {
  className?: string
  children: React.ReactNode
  'data-testid'?: string
}

const PopoverItem = ({
  className,
  children,
  'data-testid': dataTestId,
}: PopoverItemProps) => (
  <div className={cx(styles.popoverItem, className)} data-testid={dataTestId}>
    {children}
  </div>
)

export default PopoverItem
