import * as React from 'react'

const CallMissedFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8.817 8.722v1.185a.75.75 0 01-1.5 0V6.843a.75.75 0 01.75-.75h2.964a.75.75 0 110 1.5H9.809l2.095 2.095 5.468-5.468a.75.75 0 111.061 1.06l-5.999 5.999a.744.744 0 01-.53.22.744.744 0 01-.53-.22L8.817 8.722zm9.154 10.861c-.225 0-.451-.043-.668-.133l-1.466-.605a1.756 1.756 0 01-1.071-1.784l.019-.208c-1.845-.79-3.733-.797-5.746-.02l.009.172a1.75 1.75 0 01-.954 1.655l-1.353.688a1.747 1.747 0 01-2.031-.323L3.274 17.59c-.197-.198-.291-.484-.271-.825.01-.184.072-.642.414-.913 5.464-4.345 11.196-4.365 17.032-.056a1.252 1.252 0 01.142 1.89l-1.384 1.383a1.745 1.745 0 01-1.236.514z" />
  </svg>
)

export default CallMissedFilled
