import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import './index.scss'

const FilterSummaryHeading = ({ heading, metaItems = [] }) => (
  <div className="core-ui-filter-summary-heading">
    <div className="core-ui-filter-summary-heading__wrapper">
      <span className="core-ui-filter-summary-heading__heading">{heading}</span>

      {metaItems.length > 0 && (
        <ul className="core-ui-filter-summary-heading__meta">
          {metaItems.map(({ key, value }) => (
            <li className="core-ui-filter-summary-heading__metaItem" key={key}>
              <span
                className={cn(
                  'core-ui-filter-summary-heading__metaText',
                  'core-ui-filter-summary-heading__metaText--number'
                )}
              >
                {value}
              </span>

              <span className="core-ui-filter-summary-heading__metaText">
                {key}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  </div>
)

FilterSummaryHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  metaItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
}

FilterSummaryHeading.defaultProps = {
  metaItems: [],
}

export default FilterSummaryHeading
