import React from 'react'
import cx from 'classnames'

import styles from './styles.module.scss'

interface AccordionProps {
  title: string
  className?: string
  'data-testid'?: string
  children?: React.ReactNode
}

const Accordion = ({
  title,
  className,
  'data-testid': dataTestId,
  children,
}: AccordionProps) => {
  return (
    <div className={cx(styles.accordion, className)} data-testid={dataTestId}>
      <h3>{title}</h3>
      {children}
    </div>
  )
}

export default Accordion
