import * as React from 'react'

const Locker = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M4.639 20.601v-9.077c0-.967.779-1.753 1.744-1.753h11.234c.969 0 1.744.785 1.744 1.753v9.077c0 .967-.779 1.753-1.744 1.753H6.383a1.745 1.745 0 01-1.744-1.753zm1.5 0c0 .144.108.253.244.253h11.234a.248.248 0 00.244-.253v-9.077a.245.245 0 00-.244-.253H6.383a.248.248 0 00-.244.253v9.077zm9.389-10.83V7.75c0-1.994-1.582-3.604-3.528-3.604S8.472 5.756 8.472 7.75v2.02h7.056zM6.972 7.75c0-2.816 2.248-5.104 5.028-5.104 2.78 0 5.028 2.288 5.028 5.104v2.77a.75.75 0 01-.75.75H7.722a.75.75 0 01-.75-.75V7.75zm5.01 7.75c0 .01.009.018.018.018.01 0 .018-.009.018-.018 0-.011-.007-.018-.018-.018s-.018.007-.018.018zm.768 1.874v1.272a.75.75 0 11-1.5 0v-1.272a2.017 2.017 0 111.5 0z" />
  </svg>
)

export default Locker
