import { createIntl, useIntl } from 'react-intl'

const useFormatMessageAndCount = (() => {
  const messageBucket = {}
  const idBucket = {}
  const groupBucket = {}

  return () => {
    try {
      const intl = useIntl()

      if (!intl.formatMessage.isTweaked) {
        const el = document.createElement('div')
        el.style.position = 'fixed'
        el.style.border = '1px solid red'
        el.style.background = '#fff'
        el.style.padding = '5px'
        el.style.borderRadius = '5px'
        el.style.top = '10px'
        el.style.right = '10px'
        el.style.zIndex = '100000'
        el.style.pointerEvents = 'none'
        el.style.opacity = '0.7'
        window.i18nShowMessageKeys = () => {
          document.body.innerHTML = `<pre>${window.i18nGroups.join('\n')}</pre>`
        }

        document.body.appendChild(el)

        const originalFormatMessage = intl.formatMessage
        intl.formatMessage = (...args) => {
          const { id } = args[0]
          const group = id.replace(/\.[^.]*$/, '.')
          const msg = args[0].defaultMessage
          idBucket[id] = true
          messageBucket[msg] = true
          groupBucket[group] = true
          window.i18nIdCount = Object.keys(idBucket).length
          window.i18nMessageCount = Object.keys(messageBucket).length
          window.i18nGroups = Object.keys(groupBucket).sort()

          el.innerHTML = `${window.i18nMessageCount}/${window.i18nIdCount}`

          return originalFormatMessage(...args)
        }
        intl.formatMessage.isTweaked = true
      }

      return intl.formatMessage
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(
          'useFormatMessage requires BabylonIntlContext. Please wrap this component with BabylonIntl'
        )
      }

      const intl = createIntl({ locale: 'en-GB' })

      return intl.formatMessage
    }
  }
})()

export default useFormatMessageAndCount
