import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import { cn } from '@/utils'

import messages from './messages'

import styles from './styles.module.scss'

interface LoaderProps {
  className?: string
}

const Loader = ({ className }: LoaderProps) => {
  const fm = useFormatMessage()

  return (
    <div data-testid="loader" className={cn(styles.loader, className)}>
      <span>{fm(messages.loadingHeading)}</span>
      <div className={styles.ldsEllipsis}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export default Loader
