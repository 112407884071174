import validator from 'validator'

const validate = (fn: (message: string, ...args: any) => boolean) => (
  message: string,
  ...args: any
) => (value: string | undefined) => {
  if (value === undefined) return message
  return fn(value, ...args) || message
}

const negate = (fn: (message: string, ...args: any) => boolean) => (
  message: string,
  ...args: any
) => !fn(message, ...args)

export const isURL = validate(validator.isURL)
export const isRequired = validate(
  (v: string, options = { ignore_whitespace: true }) => {
    if (Array.isArray(v)) {
      return !!v.length
    }
    return !validator.isEmpty(v, options)
  }
)
export const isLength = validate(validator.isLength)
export const isNotIn = validate(negate(validator.isIn))
export const isEmail = validate(validator.isEmail)

export const isHexColour = validate(validator.isHexColor)

export const rules = (validate: any) => ({ validate })

export const required = (msg: string) => rules({ required: isRequired(msg) })
