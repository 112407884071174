import * as React from 'react'

const Box = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M4.112 6.549l7.644 3.184c.798.333 1.39 1.225 1.39 2.085v8.28c0 1.098-.96 1.732-1.967 1.312l-7.643-3.185c-.798-.332-1.39-1.224-1.39-2.085V7.86c0-1.098.959-1.731 1.966-1.311zm-.466 1.43v8.161c0 .256.229.601.466.7l7.534 3.14v-8.162c0-.256-.23-.601-.467-.7L3.646 7.98zm9.976 3.1c-.24.109-.476.477-.476.739v8.121l6.732-3.06c.24-.108.476-.477.476-.739V8.02l-6.732 3.06zm3.36-3.175l1.871-.85-6.285-3.05c-.284-.137-.847-.135-1.133.006l-1.298.64 6.845 3.254zm-1.786.812L8.42 5.494 3.492 7.92a.75.75 0 11-.663-1.345l7.944-3.91c.7-.346 1.75-.35 2.45-.01l7.906 3.835a.74.74 0 01.34.335c.239.248.385.605.385 1.035v8.28c0 .85-.58 1.753-1.355 2.105l-6.877 3.126a1.794 1.794 0 01-.185.072l-.351.158a1.75 1.75 0 01-1.385.02l-2.487-1.026a.75.75 0 11.572-1.387l1.86.768v-8.158c0-.175.025-.353.07-.528l-2.301-.897a.75.75 0 01.545-1.398l2.384.93a.25.25 0 00.194-.005l2.01-.91a.766.766 0 01.007-.003l.641-.291zm3.087 2.343c-.25.1-.5.306-.71.573a2.783 2.783 0 00-.491.97c-.053.192-.082.284-.082.477 0-.195-.027-.266-.077-.42-.09-.274-.251-.476-.46-.582-.21-.107-.47-.116-.749-.006-.709.28-1.214 1.24-1.214 2.119 0 2.299 2.503 2.81 2.503 2.81s2.5-1.69 2.497-4.639c-.001-.955-.564-1.559-1.217-1.302z" />
  </svg>
)

export default Box
