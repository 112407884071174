import { FormikErrors } from 'formik'
import React, { FC, ReactNode } from 'react'
import cx from 'classnames'

import Tooltip from '../../candidates/Tooltip'
import Label from '../Label'
import Text from '../../foundation/Text'

import './FormField.scss'

export interface FormFieldProps {
  children?: ReactNode
  className?: string
  displayError?: boolean
  errorMessage?: string | FormikErrors<any> | undefined
  id?: string
  label?: ReactNode | string
  tooltipText?: string
  'data-testid'?: string
}

const FormField: FC<FormFieldProps> = ({
  children,
  className,
  id,
  label,
  displayError,
  errorMessage,
  tooltipText,
  'data-testid': dataTestId,
}) => (
  <div className={cx('core-form-field', className)}>
    {label && (
      <Label htmlFor={id}>
        <>
          {label}
          {tooltipText && (
            <>
              {': '}
              <Tooltip
                info={tooltipText}
                placement="right"
                overlayStyle={{ width: 300 }}
              />
            </>
          )}
        </>
      </Label>
    )}

    <div data-testid={dataTestId}>{children}</div>

    {errorMessage && displayError && (
      <Text
        className="core-form-field__error"
        tag="div"
        size="medium"
        color="error"
      >
        {errorMessage}
      </Text>
    )}
  </div>
)

export default FormField
