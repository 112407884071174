import React from 'react'
import { Controller, useFormContext, RegisterOptions } from 'react-hook-form'

import { Input as MedkitInput } from '@babylon/medkit'

import { useFieldContext } from '../Field'

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  value?: string
  ref?: React.Ref<HTMLInputElement>
}

const MedkitInputWrapper = React.forwardRef(
  (
    { name: propName, rules, ...props }: InputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const control = useFormContext()?.control
    const { name: contextName } = useFieldContext()
    const name = propName || contextName
    if (control && !name) {
      throw new Error('MedkitInputWrapper requires a name')
    }

    return control ? (
      <Controller
        control={control}
        name={name || ''}
        rules={rules}
        render={({ onChange, value }) => (
          <MedkitInput onChange={onChange} value={value} ref={ref} {...props} />
        )}
      />
    ) : (
      <MedkitInput ref={ref} {...props} />
    )
  }
)

export default MedkitInputWrapper
