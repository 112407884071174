import cn from 'classnames'
import styles from '../link.module.scss'

type IGetLinkClassNames = (props: {
  isStandaloneLink?: boolean
  className?: string
}) => string

const getLinkClassNames: IGetLinkClassNames = ({
  isStandaloneLink,
  className = '',
}) =>
  cn(styles.Link, {
    [styles.LinkStandalone]: isStandaloneLink,
    [className]: className,
  })

export default getLinkClassNames
