import * as React from 'react'

const Stop = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M7 6.75a.25.25 0 00-.25.25v10c0 .138.112.25.25.25h10a.25.25 0 00.25-.25V7a.25.25 0 00-.25-.25H7zm0-1.5h10c.966 0 1.75.784 1.75 1.75v10A1.75 1.75 0 0117 18.75H7A1.75 1.75 0 015.25 17V7c0-.966.784-1.75 1.75-1.75z" />
  </svg>
)

export default Stop
