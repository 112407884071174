import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { BrowserRouter } from 'react-router-dom'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/browser'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import {
  logout,
  PortalLoginProvider,
  getCsrfHeaders,
} from '@babylon/babylon-auth'
import { envUrl, envVar } from '@babylon/babylon-env'
import { PageErrorMessage } from '@babylon/cce-shared-components'

import { ModalProvider } from '@babylon/medkit'
import Layout, { LayoutRenderer } from '@/components/Layout'
import I18n from '@/components/I18n'
import ModalStack, { createModalStack } from '@/components/ModalStack'
import { ModalStack as ModalStackNew } from '@/ui'
import SnackbarBag, { createSnackbarBag } from '@/components/SnackbarBag'
import PersistentStore, {
  createPersistentStore,
} from '@/components/PersistentStore'
import ErrorBoundary from '@/components/ErrorBoundary'
import UserRoles from '@/components/UserRoles'
import userRoles from '@/data/userRoles'
import CurrentUser, { CurrentUserConsumer } from '@/components/CurrentUser'
import { ExperiencesProvider } from '@/components/Experiences'
import { requestIdGenerator } from '@/utils'
import { initialiseTracking } from '../../tracking/initialise'
import { store, persistor } from '../../redux/store'

import { version } from '../../../package.json'

const errorLink = onError((error) => {
  const { graphQLErrors, networkError } = error

  if (graphQLErrors) {
    graphQLErrors
      .filter(
        (graphQLError) => graphQLError?.extensions?.code !== 'BAD_USER_INPUT'
      )
      .map(({ message, locations, path }) =>
        /* eslint-disable-next-line no-console */
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )
  }

  if (networkError) {
    /* eslint-disable-next-line no-console */
    console.error(`[Network error]: ${networkError}`)

    if (networkError.statusCode === 401) {
      logout()
    }
  }
})

export const sharedHeadersLink = setContext(async () => ({
  headers: {
    'babylon-request-id': requestIdGenerator.generate(),
    ...getCsrfHeaders(),
  },
}))

const graphqlMiddlewareHeadersLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'X-Platform': 'portal',
      'apollographql-client-name': 'Partner Portal',
      'apollographql-client-version': process.env.REACT_APP_VERSION,
    },
  }))

  return forward(operation)
})

const graphqlMiddlewareHttpLink = new HttpLink({
  uri: `${envUrl('GRAPHQL_URL')}/api`,
  credentials: 'include',
})

const platformGatewayHttpLink = new HttpLink({
  uri: envUrl('PLATFORM_GATEWAY_URL'),
  credentials: 'include',
})

const graphqlMiddlewareLink = ApolloLink.from([
  errorLink,
  sharedHeadersLink,
  graphqlMiddlewareHeadersLink,
  graphqlMiddlewareHttpLink,
])

const platformGatewayLink = ApolloLink.from([
  errorLink,
  sharedHeadersLink,
  platformGatewayHttpLink,
])

const apolloClient = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'platform-gateway',
    platformGatewayLink,
    graphqlMiddlewareLink
  ),
  cache: new InMemoryCache(),
})

const modalStack = createModalStack('root-modal')
const snackbarBag = createSnackbarBag()
const persistentStore = createPersistentStore({
  defaultState: {
    app: {
      version,
    },
    updateTimezone: {
      seen: false,
    },
    // This is to support migrations in the future.
    // It's an often overlooked detail, which tends
    // to become a massive pain in the ass
    // further down the line.
    persistentStoreVersion: '1.0.0',
    privacy: {
      expanded: [],
    },
  },
})

ReactGA.initialize(envVar('GA_TRACKING_ID'))
Sentry.init({
  dsn: envVar('SENTRY_DSN'),
  environment: envVar('SENTRY_ENVIRONMENT'),
  level: 'info',
})

if (envVar('TEALIUM_URL')) {
  initialiseTracking()
}

export default () => (
  <PortalLoginProvider>
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary FallbackComponent={PageErrorMessage}>
            <CurrentUser>
              <ExperiencesProvider>
                <BrowserRouter>
                  <PersistentStore persistentStore={persistentStore}>
                    <I18n>
                      <ModalStackNew>
                        <ModalStack modalStack={modalStack}>
                          <UserRoles userRoles={userRoles}>
                            <Layout>
                              <SnackbarBag snackbarBag={snackbarBag}>
                                <ModalProvider>
                                  <LayoutRenderer />
                                </ModalProvider>
                              </SnackbarBag>
                            </Layout>
                            <CurrentUserConsumer>
                              {(user) =>
                                Sentry.configureScope((scope) => {
                                  scope.setUser({
                                    id: user.uuid,
                                    roles: user.roles
                                      .map(({ key }) => key)
                                      .join(','),
                                    userToken: requestIdGenerator.userToken(),
                                    sessionToken: requestIdGenerator.sessionToken(),
                                  })
                                })
                              }
                            </CurrentUserConsumer>
                          </UserRoles>
                          <ModalStack.Renderer />
                        </ModalStack>
                      </ModalStackNew>
                    </I18n>
                  </PersistentStore>
                </BrowserRouter>
              </ExperiencesProvider>
            </CurrentUser>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </PortalLoginProvider>
)
