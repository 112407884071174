import * as React from 'react'

const Ai = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M20.625 4.5a.75.75 0 10-1.499-.001.75.75 0 001.499.001zm1.5 0a2.25 2.25 0 11-4.501-.001 2.25 2.25 0 014.501.001zm-6.75 6.376a2.25 2.25 0 10-4.5 0 2.25 2.25 0 004.5 0zm1.5 0a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM5.25 7a.75.75 0 10-1.5 0 .75.75 0 001.5 0zm1.5 0a2.25 2.25 0 11-4.499.001A2.25 2.25 0 016.75 7zm-1.5 12.876a.75.75 0 10-1.5 0 .75.75 0 001.5 0zm1.5 0a2.25 2.25 0 11-4.499.001 2.25 2.25 0 014.499-.001zm8 0a.75.75 0 10-1.5 0 .75.75 0 001.5 0zm-1.765-2.009l-.285-3.893 1.496-.11.279 3.812a2.25 2.25 0 11-1.49.191zm-7.16 1.37l-1.06-1.063 5.715-5.701 1.06 1.062-5.716 5.701zm9.956-9.949l-1.062-1.06 3.454-3.461 1.061 1.059-3.453 3.462zM5.217 8.303l.59-1.379 4.855 2.08-.59 1.38-4.855-2.081zm13.49 4.527l-.572 1.386-2.713-1.121.573-1.387 2.713 1.122zm1.918 1.42a.75.75 0 10-1.5 0 .75.75 0 001.5 0zm1.5 0a2.25 2.25 0 11-4.501 0 2.25 2.25 0 014.501 0z" />
  </svg>
)

export default Ai
