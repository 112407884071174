import * as React from 'react'

const NavigationDown = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21.716 8.663l-9.03 9.051a.969.969 0 01-1.373 0L2.284 8.663a.976.976 0 010-1.377.969.969 0 011.374 0L12 15.649l8.342-8.363a.969.969 0 011.374 0 .976.976 0 010 1.377z" />
  </svg>
)

export default NavigationDown
