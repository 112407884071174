import { defineMessages } from 'react-intl'

const baseId = 'shared.navigation'

const messages = defineMessages({
  partnersLabel: {
    id: `${baseId}.partners_label`,
    defaultMessage: 'Partners',
  },
  userManagementLabel: {
    id: `${baseId}.user_management_label`,
    defaultMessage: 'User management',
  },
  memberManagementLabel: {
    id: `${baseId}.member_management_label`,
    defaultMessage: 'Member management',
  },
  dataContractLabel: {
    id: `${baseId}.data_contract_label`,
    defaultMessage: 'Data Contracts',
  },
  reportsLabel: {
    id: `${baseId}.reports_label`,
    defaultMessage: 'Reports',
  },
})

export default messages
