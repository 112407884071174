import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TenancyPartner, Contract } from '@babylon/graphql-middleware-types'

interface State {
  partnerId?: string
  contracts: Contract[]
}

const initialState: State = {
  partnerId: undefined,
  contracts: [],
}

export const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    reset: () => initialState,
    store: (state, { payload }: PayloadAction<TenancyPartner>) => {
      state.partnerId = payload.id
      state.contracts =
        payload.contractsByPlan?.map((contract) => contract.items).flat() ?? []
    },
  },
})
