import React, {
  Component,
  ReactNode,
  InputHTMLAttributes,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  RefObject,
} from 'react'
import cn from 'classnames'

import Spinner from '../Spinner'

import './index.scss'

export type IntentType = 'warning' | 'error' | 'success'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string
  className?: string
  disabled?: boolean
  intent?: IntentType
  icon?: ReactNode
  loading?: boolean
  fill?: boolean
  placeholder?: string
  validate?: (prop: any) => string | null
  onEnterKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  inputRef?: RefObject<HTMLInputElement>
}

class Input extends Component<InputProps> {
  public static defaultProps = {
    type: 'text',
    disabled: false,
  }

  public static LoadingComponent = Spinner

  state = {
    focused: false,
    hovered: false,
  }

  getClassName = () => {
    const { focused, hovered } = this.state
    const { className, disabled, intent, fill } = this.props

    return cn(
      'core-input',
      intent ? `core-input--${intent}` : undefined,
      {
        'core-input--hover': hovered,
        'core-input--disabled': disabled,
        'core-input--focused': focused,
        'core-input--fill': fill,
      },
      className
    )
  }

  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props

    if (onChange) {
      onChange(event)
    }
  }

  handleInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    const { onFocus } = this.props

    this.setState({ focused: true })

    if (onFocus) {
      onFocus(event)
    }
  }

  handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { onBlur } = this.props

    this.setState({ focused: false })

    if (onBlur) {
      onBlur(event)
    }
  }

  handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { onEnterKeyDown, onKeyDown } = this.props

    if (onEnterKeyDown && (event.which === 13 || event.keyCode === 13)) {
      onEnterKeyDown(event)
    }

    if (onKeyDown) {
      onKeyDown(event)
    }
  }

  handleMouseEnter = () => {
    this.setState({ hovered: true })
  }

  handleMouseLeave = () => {
    this.setState({ hovered: false })
  }

  render() {
    const {
      id,
      icon,
      loading,
      name,
      type,
      value,
      placeholder,
      fill,
      style,
      autoComplete,
      onEnterKeyDown, // Dont include this in the `rest` object
      inputRef,
      required,
      ...rest
    } = this.props

    const prefixIcon = icon ? (
      <div className="core-input__prefix-icon">{icon}</div>
    ) : null

    const suffixIcon = loading ? (
      <div className="core-input__suffix-icon">
        <Input.LoadingComponent size="small" />
      </div>
    ) : null

    return (
      <div
        className={this.getClassName()}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={style}
      >
        {prefixIcon}
        <input
          {...rest}
          type={type}
          id={id}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={this.handleInputChange}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputBlur}
          onKeyDown={this.handleKeyDown}
          autoComplete={autoComplete}
          ref={inputRef}
          required={required}
        />
        {suffixIcon}
      </div>
    )
  }
}

export default Input
