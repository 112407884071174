import * as React from 'react'

const PauseFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M7.763 5.055H9.06c.966 0 1.75.784 1.75 1.75v10.34a1.75 1.75 0 01-1.75 1.75H7.763a1.75 1.75 0 01-1.75-1.75V6.805c0-.966.784-1.75 1.75-1.75zm7.236 0h1.217c.966 0 1.75.784 1.75 1.75v10.34a1.75 1.75 0 01-1.75 1.75h-1.217a1.75 1.75 0 01-1.75-1.75V6.805c0-.966.784-1.75 1.75-1.75z" />
  </svg>
)

export default PauseFilled
