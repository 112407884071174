import React, { useState, useContext, useEffect } from 'react'

import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { SnackbarBagContext } from './core'
import Snackbar from './Snackbar'

import styles from './styles.module.scss'

function SnackbarBagRenderer() {
  const snackbarBag = useContext(SnackbarBagContext)
  const [snackbars, setSnackbars] = useState(snackbarBag.all())

  useEffect(
    () =>
      snackbarBag.addListener((newSnackbarList) =>
        setSnackbars(newSnackbarList)
      ),
    [snackbarBag]
  )

  return (
    <TransitionGroup className={styles.snackbarBag}>
      {snackbars?.map((snackbar) => (
        <CSSTransition
          key={snackbar.id}
          timeout={200}
          classNames={styles.snackbar}
        >
          <Snackbar
            type={snackbar.type}
            message={snackbar.message}
            onClose={() => snackbar.remove(snackbar)}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
}

export default SnackbarBagRenderer
