import * as React from 'react'

const LanguageFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21.722 12.75a9.69 9.69 0 01-1.07 3.75H15.95a14.91 14.91 0 00.881-3.75h4.89zm0-1.5h-4.83a12.497 12.497 0 00-.606-3.646h4.42a9.69 9.69 0 011.016 3.646zM8.72 12.75h6.603a13.685 13.685 0 01-.983 3.75H9.704a13.685 13.685 0 01-.983-3.75zm-.067-1.5c.025-1.25.257-2.47.695-3.646h5.348c.437 1.175.669 2.396.694 3.646H8.654zm-6.376 1.5h4.936a14.91 14.91 0 00.881 3.75H3.348a9.69 9.69 0 01-1.07-3.75zm0-1.5a9.69 9.69 0 011.017-3.646h4.464a12.497 12.497 0 00-.606 3.646H2.278zM19.686 18a9.738 9.738 0 01-6.575 3.687c.145-.24.355-.593.396-.65A20.886 20.886 0 0015.311 18h4.375zm-9.272 0h3.217a20.06 20.06 0 01-1.37 2.201c-.02.028-.099.159-.188.309a7.167 7.167 0 01-.287-.307A20.063 20.063 0 0110.414 18zm-6.1 0h4.42a20.87 20.87 0 001.79 3.016c.05.07.448.482.65.7A9.736 9.736 0 014.313 18zm8.86-15.68a9.74 9.74 0 016.592 3.784h-4.072a14.244 14.244 0 00-1.906-2.993c-.081-.098-.42-.546-.614-.791zm.838 3.784h-3.979a13.06 13.06 0 011.47-2.144l.54-.653c.183.234.502.655.571.739a13.03 13.03 0 011.398 2.058zm-3.09-3.795l-.592.715a14.283 14.283 0 00-1.979 3.08H4.234a9.74 9.74 0 016.689-3.795z" />
  </svg>
)

export default LanguageFilled
