import React, { HTMLProps } from 'react'
import cx from 'classnames'
import { Icon } from '@babylon/medkit'
import { BabylonIcon } from '@babylon/icons'
import styles from './styles.module.scss'

interface KebabMenuProps extends HTMLProps<HTMLDivElement> {
  children: React.ReactElement | React.ReactNodeArray
  icon?: BabylonIcon
  label?: string
  className?: string
  title?: string
}

const KebabMenu = ({
  children,
  icon,
  label,
  className,
  title,
  ...props
}: KebabMenuProps) => (
  <div className={cx(styles.kebab, className)} {...props}>
    <div className={styles.menuContainer}>
      {label && <span>{label}</span>}
      <Icon
        icon={icon || 'NavigationMoreVertical'}
        title={title || 'Ellipsis icon'}
      />
    </div>
    <div className={styles.kebabItems}>{children}</div>
  </div>
)

export default KebabMenu
