import cn from 'classnames'
import styles from '../button.module.scss'

type IGetButtonClassNames = (props: {
  variant: string
  halfWidth: boolean
  fullWidth: boolean
  isLoading?: boolean
  className?: string
}) => string

const getButtonClassNames: IGetButtonClassNames = ({
  variant,
  halfWidth,
  fullWidth,
  isLoading,
  className,
}) =>
  cn(styles.ButtonGeneric, styles[`Button${variant}`], className, {
    [styles.ButtonFullWidth]: fullWidth,
    [styles.ButtonHalfWidth]: halfWidth,
    [styles.ButtonIsLoading]: isLoading,
  })

export default getButtonClassNames
