import get from 'lodash/fp/get'
import React, { FC } from 'react'
import { Field, FieldProps } from 'formik'
import { format, parse } from 'date-fns'

import DateRangePicker, {
  ChangeObject,
  DateRangePickerProps,
} from '../../foundation/DateRangePicker'
import FormField from '../FormField'

import './index.scss'

type SomeDateRangePickerProps = Omit<
  DateRangePickerProps,
  'startPlaceholderText' | 'endPlaceholderText'
>

export interface FormikDateRangePickerProps extends SomeDateRangePickerProps {
  id: string
  name: string
  label?: string
  validate?: (value: any) => undefined | string | Promise<any>
  stateFormat?: string
  startPlaceholderText?: string
  endPlaceholderText?: string
}

const FormikDateRangePicker: FC<FormikDateRangePickerProps> = ({
  id,
  name,
  label,
  validate,
  stateFormat,
  startPlaceholderText = '',
  endPlaceholderText = '',
  ...props
}) => (
  <Field name={name} validate={validate}>
    {({
      form: { errors, touched, setFieldValue, setFieldTouched },
      field: { name, value = {} },
    }: FieldProps) => {
      let { startDate, endDate } = value
      const fieldName = get(name)
      const displayError = fieldName(touched) && !!fieldName(errors)
      const errorMessage = fieldName(errors)
      const intent = displayError ? 'error' : undefined

      if (startDate && stateFormat) {
        startDate = parse(startDate, stateFormat, new Date())
      }

      if (endDate && stateFormat) {
        endDate = parse(endDate, stateFormat, new Date())
      }

      const formatDate = (date: Date | null): Date | string | null => {
        if (date && stateFormat) {
          return format(date, stateFormat)
        }

        return null
      }

      const handleDateRangeChange = ({
        startDate,
        endDate,
      }: ChangeObject): void => {
        setFieldValue(
          name,
          {
            startDate: stateFormat ? formatDate(startDate) : startDate,
            endDate: stateFormat ? formatDate(endDate) : endDate,
          },
          true
        )
      }

      const handleBlur = (): void => {
        setFieldTouched(name, true, true)
      }

      return (
        <FormField
          id={id}
          label={label}
          errorMessage={errorMessage}
          displayError={displayError}
        >
          <div className="core-ui-formik-date-range-picker">
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              intent={intent}
              startPlaceholderText={startPlaceholderText}
              endPlaceholderText={endPlaceholderText}
              onChange={handleDateRangeChange}
              onBlur={handleBlur}
              {...props}
            />
          </div>
        </FormField>
      )
    }}
  </Field>
)

export default FormikDateRangePicker
