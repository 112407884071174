import React, { ReactNode } from 'react'
import cx from 'classnames'
import styles from './styles.module.scss'

interface CellProps {
  title: string | ReactNode
  message?: string | ReactNode
  description?: string | ReactNode
  className?: ClassNameType
  'data-testid'?: string
}

export default ({
  title,
  message,
  description,
  className,
  'data-testid': dataTestId,
}: CellProps) => (
  <div className={cx(styles.cell, className)} data-testid={dataTestId}>
    <div className={styles.title} data-testid={`${dataTestId}Title`}>
      {title}
    </div>
    {message && <div data-testid={`${dataTestId}Message`}>{message}</div>}
    {description && (
      <div
        className={styles.description}
        data-testid={`${dataTestId}Description`}
      >
        {description}
      </div>
    )}
  </div>
)
