import React, { useCallback } from 'react'
import cx from 'classnames'
import { ApolloQueryResult, ApolloError } from 'apollo-client'

import { useFormatMessage } from '@babylon/intl'

import Loader from '@/components/Loader'
import Error from '@/components/Error'

import Pagination from './Pagination'
import PageSize from './PageSize'
import Sort from './Sort'
import messages from './messages'
import styles from './TableQuery.module.scss'
import { PageSizeOptionsType } from '@/types'

interface HeaderProps {
  name: string
  label: string | React.ReactElement
  sortable?: boolean
  wrap?: boolean
}

interface TableParamsProps {
  page: number
  pageSize: number
  sort: string
}

interface TableProps {
  headers: HeaderProps[]
  resultsPath?: string
  rowComponent: (
    props: any
  ) => { [key: string]: React.ReactElement | string | undefined }
  tableParams: TableParamsProps
  loading?: boolean
  error?: ApolloError
  data?: any
  onUpdate: (newState: any) => Promise<ApolloQueryResult<any>> | void
  className?: string
  pageSizeOptions?: PageSizeOptionsType[]
  'data-testid'?: string
}

interface renderTableHeaderProps {
  headers: HeaderProps[]
  sort: string
  onSort: (newState: any) => any
}

const renderTableHeader = ({
  headers,
  sort,
  onSort,
}: renderTableHeaderProps) => (
  <tr>
    {headers.map(({ name, label, sortable }) => (
      <th key={name}>
        <div>
          {sortable && <Sort name={name} sort={sort} onChange={onSort} />}
          {label}
        </div>
      </th>
    ))}
  </tr>
)

export default ({
  headers,
  resultsPath = '',
  rowComponent,
  tableParams,
  loading,
  error,
  data,
  onUpdate,
  className,
  pageSizeOptions,
  'data-testid': dataTestId = 'displayTable',
}: TableProps) => {
  const fm = useFormatMessage()

  const { items = [], totalItems } =
    (data &&
      resultsPath.split('.').reduce((acc, path) => acc?.[path] || acc, data)) ||
    {}

  const rows = items.map((item: any) => rowComponent(item))
  const fullRow = useCallback(
    (content) => (
      <tr>
        <td colSpan={headers.length}>{content}</td>
      </tr>
    ),
    [headers]
  )

  return (
    <>
      <table className={cx(styles.table, className)} data-testid={dataTestId}>
        {headers.length > 0 && (
          <thead>
            {renderTableHeader({
              headers,
              sort: tableParams.sort,
              onSort: onUpdate,
            })}
          </thead>
        )}
        <tbody>
          {loading && fullRow(<Loader />)}
          {!loading && (
            <>
              {error &&
                fullRow(
                  <Error error={error}>{fm(messages.loadingError)}</Error>
                )}
              {!rows.length &&
                fullRow(<div>{fm(messages.noItemsMessage)}</div>)}
              {rows.length > 0 &&
                rows.map((props: any) => (
                  <tr
                    key={props.id}
                    className={cx({
                      [styles.disabled]: 'select' in props && !props.select,
                    })}
                    data-testid={`${dataTestId}-row`}
                  >
                    {headers.map(({ name, wrap }: any) => (
                      <td key={name} className={cx({ [styles.wrap]: wrap })}>
                        {props[name]}
                      </td>
                    ))}
                  </tr>
                ))}
            </>
          )}
        </tbody>
      </table>
      <div className={styles.pageActions}>
        {totalItems > 0 && (
          <>
            <PageSize
              pageSize={tableParams.pageSize}
              onChange={onUpdate}
              options={pageSizeOptions}
            />
            <Pagination
              page={tableParams.page}
              totalItems={totalItems}
              pageSize={tableParams.pageSize}
              onChange={onUpdate}
            />
          </>
        )}
      </div>
    </>
  )
}
