import * as React from 'react'

const TextConsultationFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect x={3.75} y={3.75} width={16.5} height={16.5} rx={1.25} />
    <rect x={7} y={11} width={2} height={2} rx={0.5} />
    <rect x={7} y={15} width={2} height={2} rx={0.5} />
    <rect x={7} y={7} width={10} height={2} rx={0.5} />
    <rect x={10} y={11} width={7} height={2} rx={0.5} />
    <rect x={10} y={15} width={7} height={2} rx={0.5} />
  </svg>
)

export default TextConsultationFilled
