class NumberEncoder {
  charset: string[]

  constructor(charset: string | string[]) {
    if (!charset) {
      throw new Error('Encoder: encoder requires charset as a parameter')
    }

    this.charset = Array.isArray(charset) ? charset : charset.split('')
  }

  /**
   * Encodes number into base x
   */
  encode = (number: number): string => {
    const { charset } = this
    const { length } = charset

    // @ts-ignore
    let int = parseInt(number)
    let string = ''

    if (int !== number) {
      throw new Error('Encoder: encoder works only with integer numbers')
    }

    while (int > 0) {
      string = charset[int % length] + string
      int = Math.floor(int / length)
    }

    return string
  }

  /**
   * TODO: Decode string into base 10 number
   */
}

export default NumberEncoder
