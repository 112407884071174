/* eslint-disable jsx-a11y/label-has-associated-control */
import 'focus-visible/dist/focus-visible'
import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import { IMedkitComponent, HelpText } from '../..'
import { checkboxTheme } from './theme'

export const Checkbox: IMedkitComponent<
  CheckboxProps,
  typeof checkboxTheme
> = ({
  children,
  disabled,
  checked,
  onChange,
  className,
  error,
  label,
  testHandle,
  ErrorMessages,
  variant,
  helpText,
  id,
  ...props
}: CheckboxProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (checked) {
      inputRef?.current?.focus()
    }
  }, [checked, inputRef])

  return (
    <div>
      {ErrorMessages && <ErrorMessages className={styles.Checkbox__error} />}
      <label
        className={cn(
          styles.Checkbox,
          variant ? styles[`Checkbox_${variant}`] : undefined,
          {
            [styles.Checkbox_checked]: checked,
            [styles.Checkbox_disabled]: disabled,
            [styles.Checkbox_error]: error,
          },
          className
        )}
        data-testid={testHandle}
        htmlFor={id}
      >
        <div className={styles.Checkbox__wrapper}>
          <input
            ref={inputRef}
            aria-checked={checked}
            aria-label={label}
            className={styles.Checkbox__input}
            type="checkbox"
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            id={id}
            {...props}
          />
          <div className={styles.Checkbox__box} />
        </div>
        {/*
          aria-hidden only when a label is provided, this forces the
          screen reader to only read the aria-label on the input above.
          Otherwise the screen reader, reads the label twice.
          If no label is provided, then the default screen reader behaviour continues to work.
        */}
        <div className={styles.Checkbox__label} aria-hidden={!!label}>
          {children && <div>{children}</div>}
          {helpText && <HelpText>{helpText}</HelpText>}
        </div>
      </label>
    </div>
  )
}

Checkbox.theme = checkboxTheme

export interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  /** Label content for checkbox */
  children?: React.ReactNode
  /** Change look of textbox to disabled */
  disabled?: boolean
  /** Mark checkbox as checked */
  checked?: boolean
  /** Function called when checkbox clicked */
  onChange?: (e: any) => any
  error?: boolean | string
  testHandle?: string
  ErrorMessages?: React.FC<any>
  helpText?: string
  variant?: 'bordered'
  label?: string
}

Checkbox.defaultProps = {
  disabled: false,
  checked: false,
  onChange: () => {},
}

export default Checkbox
