import * as React from 'react'

const Food = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8.756 11.98v8.329a.75.75 0 11-1.5 0v-8.336a3.75 3.75 0 01-3.241-3.715V3.765a.75.75 0 011.5 0v4.493a2.25 2.25 0 001.74 2.192V3.81a.75.75 0 111.5 0v6.653a2.25 2.25 0 001.8-2.205V3.765a.75.75 0 111.5 0v4.493a3.75 3.75 0 01-3.3 3.723zm6.994 8.257c0 1-1.5 1-1.5 0V3.757c0-.46.41-.81.863-.741 1.79.274 3.234 1.505 4.323 3.61.537 1.038.766 2.507.715 4.403v2.328a.75.75 0 01-.75.75H15.75v6.13zm2.901-7.63v-1.598c.045-1.685-.147-2.92-.547-3.694-.677-1.307-1.46-2.147-2.354-2.558v7.85h2.901z" />
  </svg>
)

export default Food
