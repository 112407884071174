import React, {
  createRef,
  KeyboardEvent,
  PureComponent,
  ReactNode,
  RefObject,
} from 'react'
import classnames from 'classnames'
import onClickOutside from 'react-onclickoutside'
import Card from '../../foundation/Card'
import KebabMenuButton from './KebabMenuButton'
import './KebabMenu.scss'

export interface KebabMenuItemProps {
  className: string
  role: string
  tabIndex: number
  onKeyUp: (event: KeyboardEvent<Element>) => void
}

export interface KebabMenuRenderOptions {
  menuItemProps: KebabMenuItemProps
  closeMenu?: () => void
}

interface Props {
  children: (o: KebabMenuRenderOptions) => ReactNode
  trackingEvent?: () => void
  menuItemSelector?: keyof HTMLElementTagNameMap
  className?: string
  containerClassName?: string
}

interface State {
  currentIndex: number
  expanded: boolean
}

const KEYS = {
  DOWN: 40,
  ESC: 27,
  UP: 38,
}

class KebabMenu extends PureComponent<Props, State> {
  menuRef: RefObject<HTMLDivElement> = createRef()

  menuItems?: NodeList | []

  state = {
    expanded: false,
    currentIndex: -1,
  }

  getMenuItems() {
    if (!this.menuItems) {
      const { menuItemSelector = '[role="menuitem"]' } = this.props
      const { current: menu } = this.menuRef
      this.menuItems = menu ? menu.querySelectorAll(menuItemSelector) : []
    }
    return this.menuItems || []
  }

  closeMenu = () => {
    this.setState({
      currentIndex: -1,
      expanded: false,
    })
  }

  handleClickOutside = () => {
    this.closeMenu()
  }

  handleButtonClick = () => {
    const { expanded } = this.state
    const { trackingEvent } = this.props
    this.setState({
      expanded: !expanded,
    })

    if (trackingEvent) {
      trackingEvent()
    }
  }

  handleMenuKeyUp = (e: KeyboardEvent) => {
    const { keyCode } = e
    const { currentIndex, expanded } = this.state

    if (keyCode === KEYS.ESC) {
      this.closeMenu()
    }

    if (expanded && (keyCode === KEYS.DOWN || keyCode === KEYS.UP)) {
      const menuItems = this.getMenuItems()
      const numMenuItems = menuItems.length
      let newIndex = currentIndex

      if (keyCode === KEYS.DOWN) {
        newIndex =
          currentIndex >= numMenuItems - 1 || currentIndex < 0
            ? 0
            : currentIndex + 1
      } else if (keyCode === KEYS.UP) {
        newIndex = currentIndex <= 0 ? numMenuItems - 1 : currentIndex - 1
      }

      this.setState(
        {
          currentIndex: newIndex,
        },
        () => {
          const menuItem = menuItems[newIndex]
          if (menuItem) {
            ;(menuItem as HTMLElement).focus()
          }
        }
      )
    }
  }

  renderMenuItems() {
    const { expanded } = this.state
    const { children, className } = this.props

    const menuItemProps = {
      className: 'core-ui-kebab__menu-item',
      'data-testid': 'kebab-menu-item',
      role: 'menuitem',
      tabIndex: -1,
      onKeyUp: this.handleMenuKeyUp,
    }

    const menuClassName = classnames('core-ui-kebab__menu', className, {
      'core-ui-kebab__menu--opened': expanded,
    })

    return (
      <div className={menuClassName} role="menu">
        <Card padding="sm">
          {children({
            menuItemProps,
            closeMenu: this.closeMenu,
          })}
        </Card>
      </div>
    )
  }

  render() {
    const { expanded } = this.state
    const { containerClassName } = this.props

    return (
      <div
        className={classnames('core-ui-kebab', containerClassName)}
        data-testid="kebab-menu"
        ref={this.menuRef}
      >
        <KebabMenuButton
          isOpen={expanded}
          onClick={this.handleButtonClick}
          onKeyUp={this.handleMenuKeyUp}
        />
        {this.renderMenuItems()}
      </div>
    )
  }
}

export default onClickOutside(KebabMenu)
