import * as React from 'react'

const PrivacyFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M5.672 13.35l.364.208a628.996 628.996 0 012.645 1.526l.052.03a.21.21 0 00.316-.182V6c0-.592.51-1.07 1.137-1.07.623 0 1.1.472 1.1 1.07v5.202a.258.258 0 00.516 0V4.125c0-.598.502-1.13 1.111-1.13s1.104.53 1.104 1.13v7.056a.28.28 0 10.559 0V4.875c0-.546.466-.967 1.036-.967.572 0 1.056.423 1.056.967v6.283a.302.302 0 10.605 0V7.125c0-.689.416-1.172 1.1-1.172.694 0 1.177.504 1.177 1.172v9.493c0 2.728-1.598 4.171-4.476 4.171h-3.481a2.742 2.742 0 01-1.962-.82L4.942 15.41l-.373-.363-.134-.227.033.087a.973.973 0 00.036.062 2.051 2.051 0 01-.108-.173 1.381 1.381 0 01-.085-1.21c.122-.316.312-.48.65-.472.188.004.415.072.513.13l.159.065c.003.02.014.027.04.041z" />
  </svg>
)

export default PrivacyFilled
