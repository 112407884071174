/**
 * Convert locale with underscores to locales with dashes
 *
 * @param {String} locale in underscore format
 * @returns {String} locale in dash format
 *
 * @author Christian Allen
 */
const getLocaleWithDashes = (locale) => locale.replace('_', '-')

export default getLocaleWithDashes
