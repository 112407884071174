import React, { Component } from 'react'
import cn from 'classnames'
import PropType from 'prop-types'
import autosize from 'autosize'

import Spinner from '../../foundation/Spinner'

import './index.scss'

class Textarea extends Component {
  state = {
    focused: false,
    hovered: false,
  }

  myRef = undefined

  constructor() {
    super()
    this.myRef = React.createRef()
  }

  componentDidMount() {
    const { autoresize } = this.props

    if (this.myRef && autoresize) {
      autosize(this.myRef.current)
    }
  }

  getClassName = () => {
    const { focused, hovered } = this.state
    const { className, disabled, intent, fill, autoresize } = this.props

    return cn(
      'core-textarea',
      intent && `core-textarea--${intent}`,
      {
        'core-textarea--hover': hovered,
        'core-textarea--disabled': disabled,
        'core-textarea--focused': focused,
        'core-textarea--fill': fill,
        'core-textarea--autoresize': autoresize,
      },
      className
    )
  }

  handleFocus = (event) => {
    const { onFocus } = this.props

    this.setState({ focused: true })

    if (onFocus) {
      onFocus(event)
    }
  }

  handleBlur = (event) => {
    const { onBlur } = this.props

    this.setState({ focused: false })

    if (onBlur) {
      onBlur(event)
    }
  }

  handleMouseEnter = () => {
    this.setState({ hovered: true })
  }

  handleMouseLeave = () => {
    this.setState({ hovered: false })
  }

  handleChange = (event) => {
    const { onChange, minRows, maxRows } = this.props

    const previousRows = event.target.rows

    const currentRows = Math.max(
      event.target.value.split(/\n/g).length,
      minRows
    )

    if (currentRows < minRows) {
      event.target.rows = minRows
    }

    if (currentRows === previousRows) {
      event.target.rows = currentRows
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows
      event.target.scrollTop = event.target.scrollHeight
    }

    const nextRows = currentRows < maxRows ? currentRows : maxRows

    if (this.state.rows !== nextRows) {
      this.setState({
        rows: nextRows,
      })
    }

    if (onChange) {
      onChange(event)
    }
  }

  render() {
    const {
      id,
      icon,
      loading,
      name,
      testId,
      value,
      autoresize,
      onChange,
      placeholder,
      // Prevent warnings for invalid textarea attributes
      fill, // eslint-disable-line no-unused-vars
      minRows, // eslint-disable-line no-unused-vars
      maxRows, // eslint-disable-line no-unused-vars
      ...rest
    } = this.props

    const prefixIcon = icon ? (
      <div className="core-textarea__prefixIcon">{icon}</div>
    ) : null

    const suffixIcon = loading ? (
      <div className="core-textarea__suffixIcon">
        <Textarea.LoadingComponent size={14} />
      </div>
    ) : null

    return (
      <div
        className={this.getClassName()}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {prefixIcon}
        <textarea
          ref={this.myRef}
          id={id}
          name={name}
          placeholder={placeholder}
          value={value}
          rows={autoresize ? this.state.rows : null}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          data-testid={testId}
          {...rest}
        />
        {suffixIcon}
      </div>
    )
  }
}

Textarea.LoadingComponent = Spinner

Textarea.defaultProps = {
  type: 'text',
  minRows: 2,
  maxRows: Number.MAX_SAFE_INTEGER,
}

Textarea.propTypes = {
  id: PropType.string,
  name: PropType.string,
  className: PropType.string,
  disabled: PropType.bool,
  intent: PropType.oneOf(['warning', 'error', 'success']),
  icon: PropType.node,
  loading: PropType.bool,
  fill: PropType.bool,
  placeholder: PropType.string,
  autoresize: PropType.bool,
  minRows: PropType.number,
  maxRows: PropType.number,
  testId: PropType.string,
}

export default Textarea
