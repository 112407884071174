import * as React from 'react'

const Email = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.72 5.477a.25.25 0 00-.25.25v12.59c0 .139.112.25.25.25h16.556a.25.25 0 00.25-.25V5.728a.25.25 0 00-.25-.25H3.72zm0-1.5h16.556c.966 0 1.75.783 1.75 1.75v12.59a1.75 1.75 0 01-1.75 1.75H3.72a1.75 1.75 0 01-1.75-1.75V5.728c0-.967.784-1.75 1.75-1.75zm16.294.858a.75.75 0 011.063 1.058l-8.508 8.543a.75.75 0 01-1.063 0L2.998 5.893a.75.75 0 111.063-1.058l7.976 8.01 7.977-8.01z" />
  </svg>
)

export default Email
