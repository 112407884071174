import * as React from 'react'

const TickCircleFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 2.4c5.3 0 9.6 4.3 9.6 9.6s-4.3 9.6-9.6 9.6-9.6-4.3-9.6-9.6S6.7 2.4 12 2.4zm3.769 5.241L9.79 13.775l-1.743-1.803a.648.648 0 00-.938 0 .702.702 0 000 .97l2.21 2.286a.648.648 0 00.937.002l6.446-6.615a.703.703 0 00.004-.97.648.648 0 00-.938-.004z" />
  </svg>
)

export default TickCircleFilled
