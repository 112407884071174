import React from 'react'
import cn from 'classnames'

import './Spinner.scss'

interface Props {
  size?: 'small' | 'medium' | 'large'
  color?: string
  strokeWidth?: number
  centered?: boolean
  className?: string
  testid?: string
}

const Spinner = ({
  color: stroke,
  centered,
  size = 'medium',
  strokeWidth = 3,
  className,
  testid,
}: Props) => (
  <div
    data-testid={testid}
    className={cn(
      'core-spinner',
      `core-spinner--${size}`,
      centered && 'core-spinner--centered',
      className
    )}
  >
    <div className="core-spinner__body">
      <svg viewBox="0 0 32 32" width="100%" height="100%">
        <circle
          cx="16"
          cy="16"
          fill="none"
          r="14"
          strokeWidth={strokeWidth}
          className="core-spinner__background-circle"
          style={{ stroke }}
        />
        <circle
          cx="16"
          cy="16"
          fill="none"
          r="14"
          strokeWidth={strokeWidth}
          className="core-spinner__foreground-circle"
          style={{ stroke }}
        />
      </svg>
    </div>
  </div>
)

export default Spinner
