import * as React from 'react'

const PharmacyDelivery = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10.95 17.265a2.88 2.88 0 00-1.879-.658H6.238a.75.75 0 010-1.5h2.833c1.189 0 2.151.41 2.962 1.107.183.158.347.32.519.506.07.076.271.303.303.336.06.063.106.134.14.209h.929c3.541 0 6.821 1.42 6.821 3.321a.75.75 0 01-.75.75H6.233a.75.75 0 110-1.5h12.335c-.935-.558-2.724-1.071-4.644-1.071H9.329a.75.75 0 110-1.5h1.621zm1.24-13.797a.25.25 0 00-.25.25v8.05c0 .138.111.25.25.25h8.12a.25.25 0 00.25-.25v-8.05a.25.25 0 00-.25-.25h-8.12zm0-1.5h8.12c.967 0 1.75.784 1.75 1.75v8.05a1.75 1.75 0 01-1.75 1.75h-8.12a1.75 1.75 0 01-1.75-1.75v-8.05c0-.966.783-1.75 1.75-1.75zm3.414 5.24V6a.75.75 0 111.5 0v1.208h1.208a.75.75 0 110 1.5h-1.208v1.208a.75.75 0 01-1.5 0V8.708h-1.208a.75.75 0 010-1.5h1.208zM2.764 21.991a.75.75 0 01-.75-.75v-6a.75.75 0 01.75-.75H6a.75.75 0 01.75.75v6a.75.75 0 01-.75.75H2.763zm.75-1.5H5.25v-4.5H3.513v4.5zm.89-1.634a.417.417 0 00-.404.429c0 .236.181.428.405.428a.418.418 0 00.405-.428c0-.237-.183-.429-.405-.429" />
  </svg>
)

export default PharmacyDelivery
