import React, { CSSProperties, ReactNode } from 'react'
import cn from 'classnames'

import { colors } from '../../../theme'

import './Heading.scss'

interface Props {
  align?: 'left' | 'center' | 'right'
  className?: string
  children: ReactNode
  color?: keyof typeof colors
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  margin?: boolean
  style?: CSSProperties
}

const Heading = ({
  level: Component = 'h1',
  align,
  margin,
  className,
  style,
  color,
  ...props
}: Props) => (
  <Component
    className={cn(
      'core-heading',
      align && `core-text--align-${align}`,
      !margin && 'core-heading--no-margin',
      className
    )}
    style={{
      color: color ? colors[color] : undefined,
      ...style,
    }}
    {...props}
  />
)

export default Heading
