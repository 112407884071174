import * as React from 'react'

const CombinedShape = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14.995 3.468a.75.75 0 110-1.5h5.316c.966 0 1.75.784 1.75 1.75v5.333a.75.75 0 01-1.5 0V3.718a.25.25 0 00-.25-.25h-5.316zm5.566 11.569a.75.75 0 111.5 0v5.231a1.75 1.75 0 01-1.75 1.75h-5.334a.75.75 0 010-1.5h5.334a.25.25 0 00.25-.25v-5.231zM9.02 20.518a.75.75 0 110 1.5H3.76a1.75 1.75 0 01-1.75-1.75V15.04a.75.75 0 111.5 0v5.228c0 .138.112.25.25.25h5.26zM4.528 3.468L8.54 7.49a.75.75 0 01-1.062 1.06L3.51 4.573v4.528a.75.75 0 11-1.5 0V3.718c0-.966.783-1.75 1.75-1.75h5.243a.75.75 0 010 1.5H4.528zm10.939 13.026a.75.75 0 011.065-1.057l4.5 4.535a.75.75 0 01-1.064 1.056l-4.501-4.534zm1.09-7.887a.75.75 0 01-1.073-1.049l4.48-4.582a.75.75 0 111.072 1.048l-4.48 4.583zM4.027 21.033a.75.75 0 01-1.054-1.066l4.485-4.434A.75.75 0 118.513 16.6l-4.486 4.433z" />
  </svg>
)

export default CombinedShape
