import * as React from 'react'

const CoreDelete = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M20 5h-4V4a3 3 0 00-3-3h-2a3 3 0 00-3 3v1H4a1 1 0 000 2v13a3 3 0 003 3h10a3 3 0 003-3V7a1 1 0 000-2zM9 19H8V9h1v10zm5-14h-4V4c0-.551.449-1 1-1h2c.551 0 1 .449 1 1v1zm2 14h-1V9h1v10z" />
  </svg>
)

export default CoreDelete
