const mergeInflight = <A, T>(
  fn: (...args: A[]) => Promise<T>
): ((...args: A[]) => Promise<T>) => {
  let inFlightRequest: Promise<T> | null = null

  return async (...args) => {
    if (!inFlightRequest) {
      inFlightRequest = fn(...args)
    }
    try {
      const result = await inFlightRequest
      inFlightRequest = null
      return result
    } catch (err) {
      inFlightRequest = null
      throw err
    }
  }
}

export default mergeInflight
