import { tz } from 'moment-timezone'

export default () =>
  tz
    .names()
    .filter((timezoneName) => timezoneName.includes('/'))
    .sort((a, b) => tz(a).utcOffset() - tz(b).utcOffset())
    .map((timezoneName) => ({
      label: `GMT${tz(timezoneName).format('Z')} ${timezoneName}`,
      value: timezoneName,
      offset: tz(timezoneName).utcOffset(),
    }))
