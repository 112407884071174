import * as React from 'react'

const Collapse = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8.324 16.736L4.03 21.03a.75.75 0 01-1.06-1.06l4.253-4.254H2.76a.75.75 0 110-1.5h5.315c.967 0 1.75.784 1.75 1.75V21.3a.75.75 0 01-1.5 0v-4.563zm7.352-9.472L19.97 2.97a.75.75 0 011.06 1.06l-4.203 4.204h4.358a.75.75 0 110 1.5h-5.26a1.75 1.75 0 01-1.75-1.75V2.755a.75.75 0 111.5 0v4.509zm.924 8.276l4.43 4.43a.75.75 0 01-1.06 1.06l-4.254-4.254v4.397a.75.75 0 01-1.5 0V15.79c0-.967.783-1.75 1.75-1.75h5.243a.75.75 0 010 1.5H16.6zM7.193 8.254L2.97 4.03a.75.75 0 011.06-1.06l4.278 4.277V2.772a.75.75 0 111.5 0v5.232a1.75 1.75 0 01-1.75 1.75H2.724a.75.75 0 010-1.5h4.47z" />
  </svg>
)

export default Collapse
