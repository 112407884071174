import React, { useCallback } from 'react'
import { RegisterOptions, useFormContext } from 'react-hook-form'

import { cn } from '@/utils'

import { useFieldContext } from '../Field'
import styles from './styles.module.scss'

interface TextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  resize?: string
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
}

// This component is deprecated, please use MedkitTextareaWrapper
const TextArea = React.forwardRef(
  ({ className, rules, ...props }: TextAreaProps, ref: any) => {
    const { register } = useFormContext() ?? {}
    const { name } = useFieldContext()
    const fnRef = useCallback(
      (instance: HTMLTextAreaElement | null) => {
        register?.(rules || {})(instance)
        if (typeof ref === 'function') {
          ref(instance)
        } else if (ref && typeof ref === 'object') {
          ref.current = instance
        }
      },
      [ref, register, rules]
    )

    return (
      <textarea
        name={name}
        ref={fnRef}
        className={cn(styles.textArea, className, {
          [`resize-${props.resize}`]: props.resize,
        })}
        {...props}
      />
    )
  }
)

export default TextArea
