import * as React from 'react'

const SpeakerOff = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.629 21.503a.79.79 0 01-1.114-1.119L20.346 2.637a.79.79 0 011.114 1.119L3.63 21.503zm13.59-10.838a.75.75 0 011.303-.743c1.143 2.004.526 4.338-1.557 5.851a.75.75 0 01-.882-1.213c1.487-1.08 1.885-2.583 1.136-3.895zM7.174 8.496l6.133-6.103a.79.79 0 011.346.56v4.024a.79.79 0 11-1.579 0V4.852L8.057 9.845a.79.79 0 01-.557.23H4.318a.571.571 0 00-.574.568v2.714c0 .312.257.568.574.568h1.54a.79.79 0 010 1.579h-1.54a2.15 2.15 0 01-2.153-2.147v-2.714a2.15 2.15 0 012.153-2.147h2.856zm5.9 10.67v-5.388a.79.79 0 111.58 0v7.27a.79.79 0 01-1.344.563l-3.68-3.616a.79.79 0 111.107-1.126l2.337 2.296z" />
  </svg>
)

export default SpeakerOff
