import * as React from 'react'

const AccountMonitor = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M7 22h4V3a1 1 0 00-1-1H8a1 1 0 00-1 1v19zm-6 0h4V9a1 1 0 00-1-1H2a1 1 0 00-1 1v13zM19 9v13h4V9a1 1 0 00-1-1h-2a1 1 0 00-1 1zm-6 13h4v-8a1 1 0 00-1-1h-2a1 1 0 00-1 1v8z" />
  </svg>
)

export default AccountMonitor
