import * as React from 'react'

const ArrowDown = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17.437 8.502a.75.75 0 111.054 1.068l-6.01 5.928a.75.75 0 01-1.054 0l-5.99-5.929A.75.75 0 116.49 8.503l5.464 5.407 5.482-5.408z" />
  </svg>
)

export default ArrowDown
