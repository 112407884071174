import React, { Component, SyntheticEvent, FocusEvent } from 'react'
import cn from 'classnames'
// NOTE: we don't depend directly on `popper.js`, but need to access it since
// `react-datepicker` depends on a type from that lib without re-exporting it.
// eslint-disable-next-line import/no-extraneous-dependencies
import { Modifiers as PopperModifiers } from 'popper.js'
import DatePicker, { DatePickerProps } from '../DatePicker'

import RightArrowSVG from '../../../icons/RightArrowSVG'

import 'react-datepicker/dist/react-datepicker.css'

import './index.scss'

const popperModifiers: PopperModifiers = {
  flip: {
    enabled: false,
  },
  preventOverflow: {
    enabled: true,
    escapeWithReference: true,
    boundariesElement: 'viewport',
  },
}

export interface ChangeObject {
  startDate: Date | null
  endDate: Date | null
}

/*
 * onChange is omitted from DatePickerProps because DateRangePickerProps has
 * the same prop with a different (incompatible) function signature.
 */
export interface DateRangePickerProps
  extends Omit<DatePickerProps, 'onChange'> {
  startDate?: Date
  endDate?: Date
  startPlaceholderText?: string
  endPlaceholderText?: string
  hasError?: boolean
  fill?: boolean
  dateFormat?: string
  minDate?: Date
  maxDate?: Date
  monthsShown?: number
  locale?: string
  intent?: 'warning' | 'error' | 'success' | null
  onChange?: (object: ChangeObject) => void
  onFocus?: (event: SyntheticEvent) => void
  onBlur?: (event: SyntheticEvent) => void
}

class DateRangePicker extends Component<DateRangePickerProps> {
  public static defaultProps = {
    startDate: null,
    endDate: null,
    startPlaceholderText: 'Start date',
    endPlaceholderText: 'End date',
    hasError: false,
    monthsShown: 2,
    fill: false,
  }

  state = {
    focused: false,
  }

  handleChangeStart = (startDate: Date | null) => {
    const { endDate, onChange } = this.props

    if (typeof onChange === 'function') {
      onChange({
        startDate,
        endDate: endDate || null,
      })
    }
  }

  handleChangeEnd = (endDate: Date | null) => {
    const { startDate, onChange } = this.props

    if (typeof onChange === 'function') {
      onChange({
        startDate: startDate || null,
        endDate,
      })
    }
  }

  handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    const { onFocus } = this.props

    this.setState({
      focused: true,
    })

    if (typeof onFocus === 'function') {
      onFocus(event)
    }
  }

  handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { onBlur } = this.props

    this.setState({
      focused: false,
    })

    if (typeof onBlur === 'function') {
      onBlur(event)
    }
  }

  render() {
    const { focused } = this.state

    const {
      startDate,
      endDate,
      startPlaceholderText,
      endPlaceholderText,
      hasError,
      fill,
      dateFormat,
      minDate,
      maxDate,
      monthsShown,
      locale,
      'data-testid': dataTestId,
      id,
      ...rest
    } = this.props

    return (
      <div
        className={cn('core-date-range', {
          'core-date-range--focused': focused,
          'core-date-range--hasError': hasError,
          'core-date-range--fill': fill,
        })}
      >
        <DatePicker
          {...rest}
          data-testid={dataTestId}
          locale={locale}
          dateFormat={dateFormat}
          startDate={startDate}
          endDate={endDate}
          className="core-date-range__input"
          onChange={this.handleChangeStart}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          monthsShown={monthsShown}
          popperPlacement="bottom-start"
          popperModifiers={popperModifiers}
          popperClassName="core-date-range__popper"
          // props above are the same in both date pickers
          minDate={minDate}
          maxDate={maxDate || endDate}
          selected={startDate}
          placeholderText={startPlaceholderText}
          selectsStart
          id={id}
          containerId={id && `${id}-container`}
        />
        <div className="core-date-range__separator">
          <RightArrowSVG
            alt="Right arrow"
            className="core-date-range__separator__icon"
          />
        </div>
        <DatePicker
          {...rest}
          data-testid={dataTestId}
          locale={locale}
          dateFormat={dateFormat}
          startDate={startDate}
          endDate={endDate}
          className="core-date-range__input"
          onChange={this.handleChangeEnd}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          monthsShown={monthsShown}
          popperPlacement="bottom-start"
          popperModifiers={popperModifiers}
          popperClassName="core-date-range__popper"
          // props above are the same in both date pickers
          minDate={startDate}
          maxDate={maxDate}
          selected={endDate}
          placeholderText={endPlaceholderText}
          selectsEnd
          id={id && `${id}-range-end`}
          containerId={id && `${id}-range-end-container`}
        />
      </div>
    )
  }
}

export default DateRangePicker
