import { defineMessages } from 'react-intl'

const baseId = 'preview'

const messages = defineMessages({
  close: {
    id: `${baseId}.close`,
    defaultMessage: 'Close',
  },
})

export default messages
