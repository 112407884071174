import * as React from 'react'

const CreditCard = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M5 6.5a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h14a.5.5 0 00.5-.5V7a.5.5 0 00-.5-.5H5zM5 5h14a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2zM4 8h16v2.5H4V8zm11.4 5.25h2.1a.75.75 0 01.75.75v1.5a.75.75 0 01-.75.75h-2.1a.75.75 0 01-.75-.75V14a.75.75 0 01.75-.75z" />
  </svg>
)

export default CreditCard
