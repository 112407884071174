import { useState, useMemo } from 'react'

import { QuickEditButtonsMode } from './QuickEditButtons'

const useQuickEditButtons = (defaultMode = QuickEditButtonsMode.VIEW) => {
  const [mode, setMode] = useState(defaultMode)

  return useMemo(
    () => ({
      mode,
      setMode,
      readOnly: mode === QuickEditButtonsMode.VIEW,
    }),
    [mode, setMode]
  )
}

export default useQuickEditButtons
