export const toggleTheme = {
  backgroundColor: '--medkit-toggle-background-color',
  borderColor: '--medkit-toggle-border-color',
  borderWeight: '--medkit-toggle-border-weight',
  borderRadius: '--medkit-toggle-border-radius',
  padding: '--medkit-toggle-padding',
  size: '--medkit-toggle-size',
  disabledBackgroundColor: '--medkit-toggle-disabled-background-color',
  switchBackgroundColor: '--medkit-toggle-switch-background-color',
  switchSize: '--medkit-toggle-switch-size',
}
