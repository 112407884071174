function getType(value) {
  const jsType = typeof value

  return Array.isArray(value)
    ? 'array'
    : value === null
    ? 'null'
    : jsType === 'number' && isNaN(value)
    ? 'nan'
    : jsType
}

export default getType
