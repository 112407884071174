import * as React from 'react'

const HealthReport = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17.121 19.6V3.69a.296.296 0 00-.292-.282H4.218a.29.29 0 00-.293.282V19.2c0 .71.577 1.287 1.288 1.287h12.084a2.305 2.305 0 01-.176-.886zm2.465 2.311H5.213A2.712 2.712 0 012.5 19.2V3.69c0-.944.774-1.707 1.718-1.707h12.61a1.72 1.72 0 011.718 1.707v4.195h1.485c.946 0 1.713.767 1.713 1.713v10a2.312 2.312 0 01-1.97 2.287.713.713 0 01-.188.025zm-1.04-2.311a.886.886 0 001.773 0V9.599a.287.287 0 00-.288-.288h-1.485V19.6zm-4.02-6.35a.75.75 0 110 1.5H6.517a.75.75 0 110-1.5h8.01zm0 3a.75.75 0 110 1.5H6.517a.75.75 0 110-1.5h8.01zm-3.369-9.158a.75.75 0 01.869-1.223c.32.228.597.51.816.833a.75.75 0 11-1.24.843 1.791 1.791 0 00-.445-.453zm.677 1.863a.75.75 0 011.444.404c-.392 1.405-1.7 2.391-3.205 2.391-1.83 0-3.323-1.45-3.323-3.25 0-1.45.976-2.706 2.368-3.114a.75.75 0 01.422 1.44c-.763.223-1.29.901-1.29 1.674 0 .96.812 1.75 1.823 1.75.834 0 1.55-.54 1.76-1.295z" />
  </svg>
)

export default HealthReport
