import { sanitizeUrl } from '@braintree/sanitize-url'
import React, { AriaAttributes, ComponentType, ReactNode } from 'react'
import cn from 'classnames'

import Spinner from '../Spinner'

import './Button.scss'

interface Props
  extends AriaAttributes,
    Pick<
      React.HTMLProps<HTMLButtonElement | HTMLAnchorElement | HTMLElement>,
      'onClick' | 'onKeyUp'
    > {
  icon?: ReactNode
  intent?: 'primary' | 'secondary' | 'error' | 'warning' | 'success' | 'link'
  fill?: boolean
  component?: ComponentType
  loading?: boolean
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  href?: string
  inline?: boolean
  target?: string
  rel?: string
  children?: ReactNode
  className?: string
  style?: any
  testId?: String
  dataTestId?: String
}

const Button = ({
  children,
  icon: prefixIcon,
  intent = 'primary',
  fill,
  className,
  style,
  component,
  loading,
  type = 'button',
  disabled = false,
  href: unsafeHref,
  inline = false,
  target,
  rel = 'noopener',
  onClick,
  onKeyUp,
  testId,
  dataTestId,
  ...other
}: Props) => {
  const isLink = Boolean(unsafeHref)
  const Component = component || (isLink ? 'a' : 'button')
  const disabledOrLoading = disabled || loading
  const buttonProps = isLink
    ? { target, href: sanitizeUrl(unsafeHref), rel }
    : { type, disabled: disabledOrLoading }
  const buttonClass = cn(
    'core-button',
    intent && `core-button--${intent}`,
    fill && 'core-button--fill',
    disabledOrLoading && 'core-button--disabled',
    inline && 'core-button--inline',
    className
  )

  return (
    <Component
      {...buttonProps}
      onClick={onClick}
      onKeyUp={onKeyUp}
      className={buttonClass}
      style={style}
      data-testid={dataTestId || testId}
      {...other}
    >
      {prefixIcon}
      {children && <span>{children}</span>}
      {loading ? (
        <Spinner size="small" testid="core-ui-button-spinner" />
      ) : null}
    </Component>
  )
}

export default Button
