import * as React from 'react'

const Subscription = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M15.281 3.446H5.717a.244.244 0 00-.254.245v16.513c0 .128.117.245.25.245h6.31a.75.75 0 110 1.5h-6.31c-.962 0-1.75-.79-1.75-1.745V3.691c0-.967.78-1.745 1.754-1.745h9.877c.2 0 .393.08.534.223l3.74 3.786a.75.75 0 01.215.527v4.325a.75.75 0 11-1.5 0V6.79l-3.302-3.344zm1.85 9.307a3.138 3.138 0 011.682-.503c1.595 0 2.937 1.392 2.937 3.016 0 1.922-.984 3.411-2.578 4.495a8.926 8.926 0 01-2.482 1.164.75.75 0 01-.38 0 8.926 8.926 0 01-2.481-1.165c-1.595-1.083-2.579-2.572-2.579-4.494 0-1.657 1.269-3.016 2.937-3.016.639 0 1.251.186 1.764.527.21.14.4.304.566.487.18-.193.386-.365.613-.51zm-1.918-6.141h3.668a.712.712 0 110 1.425H14.5a.712.712 0 01-.713-.712V3.158a.712.712 0 111.425 0v3.454zm1.71 12.649c.481-.193.961-.44 1.405-.741 1.219-.828 1.922-1.892 1.922-3.254 0-.808-.683-1.516-1.437-1.516a1.64 1.64 0 00-.874.266 1.55 1.55 0 00-.599.689c-.072.173-.07.165-.079.195a.302.302 0 00-.011.105c0 1-1.5 1-1.5 0a.388.388 0 00-.013-.123 2.368 2.368 0 00-.074-.197 1.425 1.425 0 00-.543-.66 1.679 1.679 0 00-.933-.275c-.816 0-1.437.665-1.437 1.516 0 1.362.703 2.426 1.922 3.254a7.446 7.446 0 001.828.897c.113-.037.256-.09.424-.156z" />
  </svg>
)

export default Subscription
