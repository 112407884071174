/**
 * Format postcode lookup results received from fetch function
 *
 * @param {Array} results Array of results
 * @returns {Array} Array of formatted results
 *
 * @author Tameem Safi
 */
export const formatResults = (results) => {
  if (!results || !Array.isArray(results)) {
    return []
  }

  return results.map((result) => ({
    display: {
      name: result.name,
      postcode: result.address_post_code,
      address: result.address_string,
    },
    raw: {
      ...result,
    },
  }))
}

export default {
  formatResults,
}
