import * as React from 'react'

const Control = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.445 20.02a2.25 2.25 0 11.008-1.5h8.79a.75.75 0 010 1.5h-8.798zm4.33-7.27a2.25 2.25 0 11.038-1.5h4.424a.75.75 0 010 1.5h-4.462zM9.498 5.576a2.25 2.25 0 11.031-1.5h11.69a.75.75 0 010 1.5H9.498zm-5.944-1.5a.75.75 0 110 1.5h-.806a.75.75 0 110-1.5h.806zm7.282 7.174a.75.75 0 010 1.5H2.782a.75.75 0 110-1.5h8.054zM6.55 18.52a.75.75 0 010 1.5H2.733a.75.75 0 110-1.5H6.55zm.843-12.989a.75.75 0 100-1.5.75.75 0 000 1.5zm7.28 7.164a.75.75 0 10.001-1.5.75.75 0 000 1.5zm-4.345 7.313a.75.75 0 100-1.5.75.75 0 000 1.5z" />
  </svg>
)

export default Control
