import React from 'react'
import PropTypes from 'prop-types'
import { Pagination, DropdownSelect } from '@babylon/core-ui'
import { FilterSummaryHeading } from '..'

import './index.scss'

const FilterSummary = ({
  heading,
  activeCount,
  activeLabel,
  filteredCount,
  filteredLabel,
  metaItems = [],
  currentPage,
  totalPages,
  rowsPerPage,
  rowsPerPageLabel,
  rowOptions,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const allMetaItems = [...metaItems]

  if (activeCount > 0) {
    allMetaItems.push({
      key: activeLabel,
      value: String(activeCount),
    })
  }

  if (filteredCount > 0) {
    allMetaItems.push({
      key: filteredLabel,
      value: String(filteredCount),
    })
  }

  const handlePrevClick = () => {
    if (typeof onPageChange === 'function') {
      onPageChange(currentPage - 1)
    }
  }

  const handleNextClick = () => {
    if (typeof onPageChange === 'function') {
      onPageChange(currentPage + 1)
    }
  }

  const handlePageClick = (pageNumber) => {
    if (typeof onPageChange === 'function') {
      onPageChange(pageNumber)
    }
  }

  const handleRowsPerPageChange = (option) => {
    if (typeof onRowsPerPageChange === 'function') {
      onRowsPerPageChange(option.key)
    }
  }

  return (
    <div className="core-ui-filter-summary">
      <div className="core-ui-filter-summary__wrapper">
        <div className="core-ui-filter-summary__left">
          <FilterSummaryHeading heading={heading} metaItems={allMetaItems} />
        </div>

        {totalPages > 1 && (
          <div className="core-ui-filter-summary__right">
            <div
              className="core-ui-filter-summary__rowsPerPageWrapper"
              data-testid="rows-per-page-filter"
            >
              <span className="core-ui-filter-summary__rowsPerPageText">
                {rowsPerPageLabel}
              </span>

              <DropdownSelect
                selectedOption={{
                  key: rowsPerPage,
                  value: rowsPerPage,
                }}
                options={rowOptions.map((number) => ({
                  key: number,
                  value: number,
                }))}
                onChange={handleRowsPerPageChange}
              />
            </div>

            <div className="core-ui-filter-summary__pagination">
              <Pagination
                pageDelta={2}
                currentPage={currentPage}
                totalPages={totalPages}
                onPrevClick={handlePrevClick}
                onNextClick={handleNextClick}
                onPageClick={handlePageClick}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

FilterSummary.propTypes = {
  heading: PropTypes.string.isRequired,
  activeCount: PropTypes.number,
  activeLabel: PropTypes.string,
  filteredCount: PropTypes.number,
  filteredLabel: PropTypes.string,
  metaItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number,
  rowsPerPageLabel: PropTypes.string,
  rowOptions: PropTypes.arrayOf(PropTypes.number),
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
}

FilterSummary.defaultProps = {
  rowsPerPage: 20,
  rowOptions: [10, 20, 50, 100],
  metaItems: [],
  rowsPerPageLabel: 'Show rows per page',
  activeLabel: 'active',
  filteredLabel: 'filtered',
}

export default FilterSummary
