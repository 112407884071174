import { MutableRefObject, useRef, useEffect } from 'react'
import { useFormikContext, FormikValues } from 'formik'

const useFocusOnError = ({
  fieldRef,
  name,
}: {
  fieldRef: MutableRefObject<HTMLInputElement | null>
  name?: string
}) => {
  const formik = useFormikContext<FormikValues>() || {}
  const prevSubmitCountRef = useRef(formik.submitCount)
  const validErrorKeys = Object.keys(formik.values || {})
  const firstErrorKey = Object.keys(formik.errors || {}).filter((key) =>
    validErrorKeys.includes(key)
  )[0]

  useEffect(() => {
    if (prevSubmitCountRef.current !== formik.submitCount && !formik.isValid) {
      if (firstErrorKey === name) {
        fieldRef?.current?.focus()
      }

      prevSubmitCountRef.current = formik.submitCount
    }
  }, [formik.submitCount, formik.isValid, firstErrorKey, fieldRef, name])
}

export default useFocusOnError
