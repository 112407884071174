import React, { useCallback } from 'react'
import cx from 'classnames'

import { useFormatMessage } from '@babylon/intl'

import Loader from '@/components/Loader'

import messages from './messages'
import styles from './TableQuery.module.scss'

interface HeaderProps {
  name: string
  label: string | React.ReactElement
  sortable?: boolean
  wrap?: boolean
}

interface TableProps {
  headers: HeaderProps[]
  rowComponent: (
    props: any
  ) => { [key: string]: React.ReactElement | string | undefined }
  loading?: boolean
  data?: any
  className?: string
  'data-testid'?: string
}

interface renderTableHeaderProps {
  headers: HeaderProps[]
}

const renderTableHeader = ({ headers }: renderTableHeaderProps) => (
  <tr>
    {headers.map(({ name, label }) => (
      <th key={name}>
        <div>{label}</div>
      </th>
    ))}
  </tr>
)

export default ({
  headers,
  rowComponent,
  loading,
  data,
  className,
  'data-testid': dataTestId = 'displayTable',
}: TableProps) => {
  const fm = useFormatMessage()

  const rows = data.map((item: any) => rowComponent(item))
  const fullRow = useCallback(
    (content) => (
      <tr>
        <td colSpan={headers.length}>{content}</td>
      </tr>
    ),
    [headers]
  )

  return (
    <>
      <table className={cx(styles.table, className)} data-testid={dataTestId}>
        {headers.length > 0 && (
          <thead>
            {renderTableHeader({
              headers,
            })}
          </thead>
        )}
        <tbody>
          {loading && fullRow(<Loader />)}
          {!loading && (
            <>
              {!rows.length &&
                fullRow(<div>{fm(messages.noItemsMessage)}</div>)}
              {rows.length > 0 &&
                rows.map((props: any) => {
                  return (
                    <tr
                      key={props.id}
                      className={cx({
                        [styles.disabled]: 'select' in props && !props.select,
                      })}
                      data-testid={`${dataTestId}-row`}
                    >
                      {headers.map(({ name, wrap }: any) => (
                        <td key={name} className={cx({ [styles.wrap]: wrap })}>
                          {props[name]}
                        </td>
                      ))}
                    </tr>
                  )
                })}
            </>
          )}
        </tbody>
      </table>
    </>
  )
}
