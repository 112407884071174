import * as React from 'react'

const MembershipFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.761 1.968h16.55c.966 0 1.75.784 1.75 1.75v16.55a1.75 1.75 0 01-1.75 1.75H3.76a1.75 1.75 0 01-1.75-1.75V3.718c0-.966.783-1.75 1.75-1.75zm9.1 5.371a3.71 3.71 0 00-.843.738 3.503 3.503 0 00-.781-.71c-.6-.4-1.316-.617-2.064-.617-1.944 0-3.423 1.584-3.423 3.52 0 2.286 1.172 4.06 3.078 5.355a10.763 10.763 0 002.982 1.4.75.75 0 00.38 0 10.745 10.745 0 002.981-1.4c1.907-1.296 3.079-3.069 3.079-5.356 0-1.894-1.568-3.519-3.423-3.519a3.67 3.67 0 00-1.967.589zm-.281 7.968a8.78 8.78 0 01-.58.211 8.78 8.78 0 01-.58-.211 9.265 9.265 0 01-1.748-.923C8.14 13.344 7.25 11.996 7.25 10.27c0-1.129.831-2.019 1.923-2.019.452 0 .879.13 1.233.365.335.224.589.533.727.884.077.197.081.208.095.254a.632.632 0 01.022.197c0 1 1.5 1 1.5 0 0-.088.006-.13.022-.178.014-.044.01-.034.101-.253a2.06 2.06 0 01.796-.917 2.171 2.171 0 011.158-.352c1.015 0 1.923.942 1.923 2.02 0 1.726-.89 3.074-2.422 4.114a9.265 9.265 0 01-1.748.923z" />
  </svg>
)

export default MembershipFilled
