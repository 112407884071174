import * as React from 'react'

const NavigationForwardArrow = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.293 3.293a.999.999 0 000 1.414L18.586 11H3a1 1 0 000 2h15.586l-6.293 6.293a.999.999 0 101.414 1.414l8-8a.997.997 0 000-1.414l-8-8a.999.999 0 00-1.414 0z" />
  </svg>
)

export default NavigationForwardArrow
