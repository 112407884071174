import * as React from 'react'

const CoreSettings = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21.625 13.495c.075-.489.126-.985.126-1.495 0-.51-.051-1.006-.126-1.495l1.486-1.14a1 1 0 00.315-1.174l-.653-1.575a.998.998 0 00-1.053-.608l-1.856.244a9.82 9.82 0 00-2.115-2.115l.244-1.856a.998.998 0 00-.608-1.053L15.81.576a.997.997 0 00-1.174.315l-1.14 1.486A9.696 9.696 0 0012 2.25c-.51 0-1.006.051-1.495.126L9.366.89A.999.999 0 008.192.576l-1.575.653a.996.996 0 00-.608 1.052l.244 1.856a9.82 9.82 0 00-2.115 2.115l-1.856-.244a.998.998 0 00-1.053.608L.577 8.191a.998.998 0 00.315 1.174l1.486 1.14A9.696 9.696 0 002.25 12c0 .51.05 1.006.126 1.495L.89 14.635a1 1 0 00-.315 1.174l.653 1.575a1 1 0 001.053.608l1.856-.244a9.82 9.82 0 002.115 2.115l-.244 1.856c-.06.448.19.88.608 1.053l1.575.652a.997.997 0 001.174-.315l1.14-1.486c.489.076.985.127 1.495.127.51 0 1.006-.05 1.495-.126l1.14 1.486a.998.998 0 001.174.315l1.575-.652a.998.998 0 00.608-1.053l-.244-1.856a9.797 9.797 0 002.115-2.115l1.856.244a.998.998 0 001.053-.608l.653-1.575a.997.997 0 00-.315-1.174l-1.486-1.14zM12 18a6 6 0 110-12 6 6 0 010 12z" />
  </svg>
)

export default CoreSettings
