import * as React from 'react'

const Gift = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11.067 6.24c-.2-.136-.394-.278-.585-.427l-.067-.06C9.168 4.507 9.057 3.39 9.97 2.48c.915-.908 2.028-.8 3.332.511.467.596.869 1.24 1.198 1.918.337-.689.754-1.351 1.256-1.986 1.245-1.244 2.357-1.35 3.272-.443.915.909.802 2.026-.445 3.272l-.067.06c-.19.149-.385.291-.585.427h2.693a1.5 1.5 0 011.5 1.5v1.625a1.5 1.5 0 01-1.25 1.48v4.895c0 .76-.616 1.375-1.375 1.375l-4.943-.002a.76.76 0 01-.114 0L12 17.112a.75.75 0 110-1.5h1.75v-4.747H9.625V13.5a.75.75 0 11-1.5 0v-2.656a1.5 1.5 0 01-1.25-1.479V7.74a1.5 1.5 0 011.5-1.5h2.692zm4.183 9.373l4.125.002v-4.75H15.25v4.748zm-1.5-6.248V7.74H8.375v1.625h5.375zm1.5 0h5.375V7.74H15.25v1.625zM8.707 17.95a1.75 1.75 0 00-1.582-1.001H3.5v3.5h10.832a1.75 1.75 0 00-1.582-1H9.625a.76.76 0 01-.055-.002.76.76 0 01-.07.003H7a.75.75 0 110-1.5h1.707zm4.043-.001a3.25 3.25 0 013.25 3.25.75.75 0 01-.75.75H2.75a.75.75 0 01-.75-.75v-5a.75.75 0 01.75-.75h4.375a3.251 3.251 0 013.163 2.5h2.462zm5.221-14.404c-.278-.276-.454-.26-1.094.373a9.13 9.13 0 00-1.116 1.83 9.13 9.13 0 001.795-1.088c.67-.675.683-.848.415-1.115zM11.443 4.66c.557.432 1.16.797 1.798 1.089a8.844 8.844 0 00-1.059-1.764c-.7-.7-.875-.716-1.153-.44-.268.267-.256.44.414 1.115z" />
  </svg>
)

export default Gift
