import { useIntl, createIntl } from 'react-intl'

import useFormatMessageAndCount from './useFormatMessageAndCount'

const useFormatMessage =
  process.env.REACT_APP_COUNT_I18N_MESSAGES === 'true'
    ? useFormatMessageAndCount
    : () => {
        // This try-catch block maintains backwards compatibility for apps that rely
        // on the default message being returned when no intl context exists.
        // It should be removed once those apps are updated
        try {
          const intl = useIntl()

          return intl.formatMessage
        } catch (err) {
          if (process.env.NODE_ENV !== 'production') {
            console.error(
              'useFormatMessage requires BabylonIntlContext. Please wrap this component with BabylonIntl'
            )
          }

          const intl = createIntl({ locale: 'en-GB' })

          return intl.formatMessage
        }
      }

export default useFormatMessage
