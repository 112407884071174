import React from 'react'

import { PortalUser as GQLPortalUser } from '@babylon/graphql-middleware-types'

export type CurrentPortalUser = Partial<GQLPortalUser> & {
  isPermitted: (path: string) => boolean
}

export const CurrentUserContext = React.createContext<CurrentPortalUser>({
  isPermitted: () => false,
})

export const CurrentUserConsumer = CurrentUserContext.Consumer
