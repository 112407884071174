import * as React from 'react'

const BirthdayFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14.38 6.317c0 1.326-1.06 2.41-2.38 2.41-1.314 0-2.38-1.086-2.38-2.41 0-.451.12-.88.334-1.223l1.393-2.463a.75.75 0 011.306 0l1.377 2.436c.23.37.35.799.35 1.25zm4.195 7.848v-2.7a.25.25 0 00-.25-.25H5.667a.25.25 0 00-.25.25v2.697a2.028 2.028 0 002.305-.43l.888-.888a.75.75 0 011.065.005l.872.887c.77.784 2.146.782 2.917-.003l.88-.887a.75.75 0 011.067.003l.872.887a2.024 2.024 0 002.292.43zm1.5.315a.781.781 0 01.008.113v5.657a1.76 1.76 0 01-1.758 1.746H5.675c-.966 0-1.758-.78-1.758-1.746v-8.786c0-.966.783-1.75 1.75-1.75h6.329l-.75.75V8.288h1.5v2.926h-1.5V8.288h1.5v2.176l-.75-.75h6.33c.966 0 1.75.784 1.75 1.75v3.016z" />
  </svg>
)

export default BirthdayFilled
