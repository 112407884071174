import React from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

export interface Step {
  id: string
  label: React.ReactNode
}

interface StepperProps {
  steps: Step[]
  currentStep: string
}

const Stepper = ({ steps, currentStep }: StepperProps) => {
  const currentIdx = steps.findIndex(({ id }) => id === currentStep)

  return (
    <ul data-testid="stepper" className={cn(styles.stepper, 'bl-stepper')}>
      {steps.map(({ id, label }, idx) => (
        <li
          key={id}
          className={cn({
            [styles.completed]: idx < currentIdx,
            [styles.active]: idx === currentIdx,
          })}
        >
          <div>{idx + 1}</div>
          {label}
        </li>
      ))}
    </ul>
  )
}

export default Stepper
