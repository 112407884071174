import * as React from 'react'

const Feedback = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.406 7.292c0-.357.221-.578.578-.578h9.85a.628.628 0 100-1.256h-9.85c-1.05 0-1.834.784-1.834 1.834v13.77a.628.628 0 00.917.558l2.578-1.337h14.521c1.05 0 1.834-.784 1.834-1.834V9.905a.628.628 0 10-1.256 0v8.544c0 .356-.221.577-.578.577H5.492a.628.628 0 00-.29.07l-1.796.932z" />
    <path d="M12.37 13.834a.628.628 0 01-.755-.758l.518-2.165a2.84 2.84 0 01.653-1.048l6.132-6.13a1.114 1.114 0 011.592 0l1.307 1.306a1.116 1.116 0 01-.009 1.599l-6.225 6.023c-.228.229-.499.387-.966.62l-.136.05-2.11.503zm.703-1.46l1.047-.248c.295-.15.49-.267.583-.36l6.131-5.933-1.12-1.12-6.038 6.037a1.633 1.633 0 00-.337.507z" />
    <path d="M20.007 7.35l-1.91-1.909a.628.628 0 00-.888.888l1.91 1.91a.628.628 0 00.888-.888z" />
  </svg>
)

export default Feedback
