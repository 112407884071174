import { createContext, Dispatch, SetStateAction } from 'react'

interface LayoutContextType {
  showHeader: boolean
  setShowHeader: Dispatch<SetStateAction<boolean>>
}

export const LayoutContext = createContext<Partial<LayoutContextType>>({})

export const LayoutConsumer = LayoutContext.Consumer
