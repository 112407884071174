import React, { SyntheticEvent } from 'react'
import cn from 'classnames'

import CloseSVG from './CloseSVG'

import './index.scss'

export const presetStyles = [
  'clinical-blue',
  'clinical-green',
  'clinical-red',
  'clinical-orange',
  'highlight-violet',
  'green-on-white',
  'white-on-green',
  'white-on-orange',
  'white-on-light-grey',
  'white-on-type-grey',
  'white-on-black',
  'black-on-grey',
  'white-on-red',
  'reverse-grey',
  'light-teal',
  'confirmation-green',
] as const

export interface TagProps {
  children: React.ReactNode
  className?: string
  closable?: boolean
  color?: typeof presetStyles[number]
  margin?: boolean
  onClose?: (event: SyntheticEvent) => void
  style?: any
  uppercase?: boolean
  'data-testid'?: string
}

const Tag = ({
  children,
  className,
  closable,
  color = 'clinical-blue',
  margin = true,
  onClose,
  style,
  uppercase = true,
  'data-testid': dataTestId,
}: TagProps) => {
  const isPredefinedStyle = color ? presetStyles.includes(color) : false

  const classNames = cn(
    'core-tag',
    margin === false && 'core-tag--no-margin',
    uppercase === false && 'core-tag--no-uppercase',
    isPredefinedStyle
      ? `core-tag--style-${color}`
      : `core-tag--style-clinical-blue`,
    className
  )

  return (
    <span
      data-testid={dataTestId || 'tag'}
      className={classNames}
      style={style}
    >
      {children}
      {closable && (
        <button
          type="button"
          className="core-tag__close-button"
          onClick={onClose}
        >
          <CloseSVG className="core-tag__close-button__icon" />
        </button>
      )}
    </span>
  )
}

export default Tag
