import * as React from 'react'

const HealthReportFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M19.586 21.911H5.213A2.712 2.712 0 012.5 19.2V3.69c0-.944.774-1.707 1.718-1.707h12.61a1.72 1.72 0 011.718 1.707v4.195h1.485c.946 0 1.713.767 1.713 1.713v10a2.312 2.312 0 01-1.97 2.287.713.713 0 01-.188.025zm-1.04-2.311a.886.886 0 001.773 0V9.599a.287.287 0 00-.288-.288h-1.485V19.6zm-4.02-6.35H6.517a.75.75 0 100 1.5h8.01a.75.75 0 100-1.5zm0 3H6.517a.75.75 0 100 1.5h8.01a.75.75 0 100-1.5zm-3.369-9.158c.175.124.326.278.444.453a.75.75 0 101.241-.843 3.291 3.291 0 00-.816-.833.75.75 0 00-.869 1.223zm.677 1.863c-.211.755-.927 1.295-1.761 1.295-1.01 0-1.823-.79-1.823-1.75 0-.773.527-1.45 1.29-1.674a.75.75 0 00-.422-1.44C7.726 5.794 6.75 7.05 6.75 8.5c0 1.8 1.493 3.25 3.323 3.25 1.505 0 2.813-.986 3.205-2.391a.75.75 0 10-1.444-.404z" />
  </svg>
)

export default HealthReportFilled
