import { useMemo } from 'react'

import useRoles from './useRoles'

function useRoleNames() {
  const roles = useRoles()

  return useMemo(() => {
    return roles.map(({ name }) => name)
  }, [roles])
}

export default useRoleNames
