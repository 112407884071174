import { defineMessages } from 'react-intl'

const baseId = 'shared.loader'

const messages = defineMessages({
  loadingHeading: {
    id: `${baseId}.loading_heading`,
    defaultMessage: 'Loading',
  },
})

export default messages
