const buttonTheme = {
  colorOne: '--medkit-button-color-one',
  colorTwo: '--medkit-button-color-two',
  colorThree: '--medkit-button-color-three',
  colorFour: '--medkit-button-color-four',
  colorFive: '--medkit-button-color-five',
  colorSix: '--medkit-button-color-six',
  colorSeven: '--medkit-button-color-seven',
  outlineWeight: '--medkit-button-outline-weight',
  borderColor: '--medkit-button-border-color',
  borderRadius: '--medkit-button-border-radius',
  disabledColor: '--medkit-button-disabled-color',
  lineHeight: '--medkit-button-line-height',
  primaryBackground: '--medkit-button-primary-background-color',
  primaryBackgroundActive: '--medkit-button-primary-background-color-active',
  primaryBorderColor: '--medkit-button-primary-border-color',
  primaryBorderColorActive: '--medkit-button-primary-border-color-active',
  primaryTextColor: '--medkit-button-primary-text-color',
  secondaryBackground: '--medkit-button-secondary-background-color',
  secondaryBorderColor: '--medkit-button-secondary-border-color',
  secondaryBorderColorActive: '--medkit-button-secondary-border-color-active',
  secondaryTextColor: '--medkit-button-secondary-text-color',
}

export { buttonTheme }
