import React, { useMemo } from 'react'

import { logout } from '@babylon/babylon-auth'
import { useFormatMessage } from '@babylon/intl'
import { Icon } from '@babylon/medkit'

import { useCurrentUser } from '@/components/CurrentUser'
import { Popover } from '@/ui'
import { Experience, Variant } from '@/components/Experiences'

import messages from './messages'
import styles from './styles.module.scss'

function UserMenu() {
  const fm = useFormatMessage()
  const { roles, uuid } = useCurrentUser()

  const roleNamesElements = useMemo(
    () =>
      roles?.map(({ name }) => (
        <Popover.Item key={name} data-testid="roleName">
          {name}
        </Popover.Item>
      )),
    [roles]
  )

  return (
    <Popover className={styles.userMenu} data-testid="userMenu">
      <Popover.Trigger>
        <Icon
          icon="CoreSettings"
          title={fm(messages.profileIconTitle)}
          className={styles.actionMenuItem}
        />
      </Popover.Trigger>
      <Popover.Menu position="right" className={styles.menu}>
        <Popover.Item className={styles.rolesLabel}>
          {fm(messages.rolesLabel)}
        </Popover.Item>
        {roleNamesElements}
        <Popover.Divider />
        <Experience name="viewUserManagement">
          <Variant name="default">
            <Popover.Link
              to={`/user-management/${uuid}`}
              data-testid="myAccount"
            >
              {fm(messages.myAccountLabel)}
            </Popover.Link>
          </Variant>
        </Experience>
        <Popover.Action onClick={logout}>
          {fm(messages.logoutLabel)}
        </Popover.Action>
      </Popover.Menu>
    </Popover>
  )
}

export default UserMenu
