import * as React from 'react'

const IdFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M7.072 12.254c-1.337.462-2.16 1.61-2.39 3.352a.75.75 0 00.744.848h6.11a.75.75 0 00.749-.809c-.142-1.802-.954-2.98-2.345-3.424a2.683 2.683 0 001.268-2.277c0-1.49-1.224-2.694-2.729-2.694-1.505 0-2.729 1.203-2.729 2.694 0 .982.53 1.839 1.322 2.31zm-3.309-8.2H20.27c.966 0 1.75.783 1.75 1.75V18a1.75 1.75 0 01-1.75 1.75H3.763A1.75 1.75 0 012.013 18V5.804c0-.967.784-1.75 1.75-1.75zM18.33 8.076h-4.356a.75.75 0 100 1.5h4.356a.75.75 0 100-1.5zm.01 3h-4.366a.75.75 0 100 1.5h4.366a.75.75 0 000-1.5zm-2.784 3h-1.582a.75.75 0 100 1.5h1.582a.75.75 0 000-1.5zm-4.9.878H6.36c.342-.99 1.044-1.43 2.2-1.43 1.154 0 1.818.434 2.095 1.43zm-.948-5.01c0 .657-.547 1.195-1.229 1.195S7.25 10.602 7.25 9.944c0-.656.547-1.194 1.23-1.194.68 0 1.228.538 1.228 1.194z" />
  </svg>
)

export default IdFilled
