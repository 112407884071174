import { defineMessages } from 'react-intl'

const baseId = 'ui.drag_and_drop_component'

const messages = defineMessages({
  showLessLabel: {
    id: `${baseId}.show_less_label`,
    defaultMessage: 'Show less',
  },
  showMoreLabel: {
    id: `${baseId}.show_more_label`,
    defaultMessage: 'Show more',
  },
  fileSizeLabel: {
    id: `${baseId}.file_size_label`,
    defaultMessage: 'File size',
  },
  fileInvalidError: {
    id: `${baseId}.file_invalid_error`,
    defaultMessage: 'The selected file seems to be invalid',
  },
  fileInvalidInfo: {
    id: `${baseId}.file_invalid_info`,
    defaultMessage: 'Please try uploading another file.',
  },
  fileError: {
    id: `${baseId}.file_error`,
    defaultMessage: `{error, select,
      invalid {The selected file seems to be invalid}
      upload {Upload failed}
    }`,
  },
  fileInfo: {
    id: `${baseId}.file_info`,
    defaultMessage: `{error, select,
      invalid {Please try uploading another file.}
      upload {An error occurred during the upload. The file seems to be corrupted. Please try uploading another file.}
    }`,
  },
  dragAndDropErrorInvalidType: {
    id: `${baseId}.drag_and_drop_error_invalid_type`,
    defaultMessage:
      'Invalid file type. We can only accept the following: {formats}',
  },
  dragAndDropErrorTooBig: {
    id: `${baseId}.drag_and_drop_error_too_big`,
    defaultMessage:
      'The selected file is too big ({size}). We can only accept files of up to {maxSize}.',
  },
  dragAndDropInfo: {
    id: `${baseId}.drag_and_drop_info`,
    defaultMessage: 'Drag and Drop files here',
  },
  dragAndDropOr: {
    id: `${baseId}.or`,
    defaultMessage: 'or',
  },
  browseLabel: {
    id: `${baseId}.browse_label`,
    defaultMessage: 'Browse files from computer',
  },
  cancelLabel: {
    id: `${baseId}.cancel_label`,
    defaultMessage: 'Cancel',
  },
})

export default messages
