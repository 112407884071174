import React, { useContext } from 'react'

import { ArrowLeft } from '@babylon/icons'
import styles from './modalBackButton.module.scss'
import ModalContext from './ModalContext'

interface Props {
  goBack: () => void
}

const ModalBackButton = ({ goBack }: Props) => {
  const {
    state: { backButtonText },
  } = useContext(ModalContext)

  return (
    <button
      type="button"
      className={styles.modalBackButton}
      onClick={goBack}
      data-testid="modalBackButton"
    >
      <ArrowLeft className={styles.modalBackButton__icon} />
      <span className={styles.modalBackButton__text}>{backButtonText}</span>
    </button>
  )
}

export default ModalBackButton
