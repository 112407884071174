import * as React from 'react'

const ShieldFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.586 2.42c1.614 1.961 4.212 2.232 7.086.269a.75.75 0 011.173.62v11.725l-.028.204c-.286 1.017-1.172 2.465-2.973 3.881-1.457 1.145-3.324 2.072-5.646 2.708a.75.75 0 01-.396 0c-2.322-.636-4.19-1.563-5.646-2.708-1.802-1.416-2.687-2.864-2.973-3.881l-.028-.204V3.308a.75.75 0 011.173-.619c2.89 1.976 5.472 1.693 7.085-.27a.75.75 0 01.58-.273c.238 0 .45.1.593.273zm3.676 4.812l-6.76 6.71L7.53 11.97a.75.75 0 00-1.06 1.06l2.5 2.5a.75.75 0 001.058.002l7.291-7.235a.75.75 0 00-1.057-1.065z" />
  </svg>
)

export default ShieldFilled
