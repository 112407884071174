import React from 'react'
import cn from 'classnames'

import styles from './BaseInput.module.scss'
import { IMedkitComponent } from '../..'
import { labelTheme } from './theme'

export const Label: IMedkitComponent<IProps<any>, typeof labelTheme> = ({
  htmlFor,
  id,
  label,
  className,
}: IProps<any>) => (
  <label
    id={id}
    htmlFor={htmlFor || id}
    className={cn(styles.BaseInput__label, className)}
  >
    {label}
  </label>
)

Label.theme = labelTheme

export interface IProps<T> extends React.HTMLProps<T> {
  htmlFor?: string
  /**
   * Input label
   * If not provided you should provided your own custom label and use placeholder and id props instead
   */
  label: string
  id?: string
  className?: string
}

export default Label
