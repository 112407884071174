import {
  UTAG,
  DefaultProps,
  EventQueues,
  TealiumEvent,
  TealiumView,
} from './types'

let utag: UTAG
let region: string

const eventQueues: EventQueues = {
  view: [],
  event: [],
}

const getDefaultParams = (): DefaultProps => ({
  country: 'global',
  country_iso_code: region,
})

const track = <P>(
  trackParams: P,
  trackFunction: (params: P & DefaultProps) => void,
  queue: (P & DefaultProps)[]
) => {
  const params: P & DefaultProps = {
    ...trackParams,
    ...getDefaultParams(),
  }

  if (!utag || !region) {
    queue.push(params)
  } else {
    trackFunction.call(utag, params)
  }

  return params
}

const trackView = (viewParams: TealiumView) =>
  track<TealiumView>(viewParams, utag?.view, eventQueues.view)

const trackEvent = (eventParams: TealiumEvent) =>
  track<TealiumEvent>(eventParams, utag?.link, eventQueues.event)

const initialise = async (initUtag: UTAG, regionIsoCode: string) => {
  utag = initUtag
  region = regionIsoCode

  if (utag && region) {
    // Process the queue now that we have the utag and region ready
    eventQueues.view.map(trackView)
    eventQueues.view.length = 0

    eventQueues.event.map(trackEvent)
    eventQueues.event.length = 0
  }
}

export { initialise, trackView, trackEvent, UTAG }
