import React from 'react'
import ReactDatepicker, { ReactDatePickerProps } from 'react-datepicker'
import isValid from 'date-fns/isValid'
import cn from 'classnames'

import FormField, { FormFieldProps } from '../../form/FormField'
import './index.scss'

export { registerLocale } from 'react-datepicker'

const setIfValid = (date?: Date | null) => (isValid(date) ? date : null)
interface ContainerProps extends FormFieldProps {
  withFormikField?: boolean // Wrap the datepicker on a Formik field if there is an ID
}
const Container = ({
  children,
  id,
  withFormikField,
  ...rest
}: ContainerProps) => {
  if (!id || !withFormikField) {
    /*
     * Children is wrapped in fragment to satisfy TypeScript
     * cf. https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18051
     */
    return <div>{children}</div>
  }

  return (
    <FormField id={id} className="core-datepicker__container" {...rest}>
      {children}
    </FormField>
  )
}

type BaseProps = ReactDatePickerProps & ContainerProps
type ChildlessBaseProps = Omit<BaseProps, 'children'>

export interface DatePickerProps extends ChildlessBaseProps {
  dateFormat?: string
  endDate?: Date
  id?: string
  locale?: string
  startDate?: Date
  containerId?: string
  'data-testid'?: string
  icon?: React.ReactElement
}

const DatePicker = ({
  className,
  calendarClassName,
  popperClassName,
  dayClassName,
  wrapperClassName,
  inline,
  id,
  label,
  locale,
  showTimeSelect,
  dateFormat,
  startDate,
  endDate,
  selected,
  displayError,
  errorMessage,
  withFormikField = true,
  containerId,
  icon,
  ...rest
}: DatePickerProps) => (
  <Container
    displayError={displayError}
    errorMessage={errorMessage}
    id={containerId || id}
    label={label}
    withFormikField={withFormikField}
  >
    {icon && <div className="core-datepicker__icon">{icon}</div>}
    <ReactDatepicker
      id={id}
      wrapperClassName={cn('core-datepicker__wrapper', wrapperClassName)}
      calendarClassName={cn(
        'core-datepicker',
        displayError && inline && 'core-datepicker--error',
        calendarClassName
      )}
      className={cn(
        'core-datepicker__input',
        displayError && 'core-datepicker__input--error',
        icon && 'core-datepicker__input--icon',
        className
      )}
      popperClassName={cn('core-datepicker__popper', popperClassName)}
      dayClassName={dayClassName}
      locale={locale}
      showTimeSelect={showTimeSelect}
      dateFormat={dateFormat || (showTimeSelect ? 'PPp' : 'PP')} // setting this as a default prop doesnt work as wrapper components set it to undefined
      startDate={setIfValid(startDate)} // fixes a bug with react-datepicker which causes crash https://github.com/Hacker0x01/react-datepicker/issues/1565
      endDate={setIfValid(endDate)} // see above
      selected={setIfValid(selected)} // see above
      inline={inline}
      customInput={<input data-testid={rest['data-testid']} type="text" />}
      {...rest}
    />
  </Container>
)

export default DatePicker
