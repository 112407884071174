import React, { FC, ChangeEvent, FocusEvent } from 'react'
import { Field, FieldProps } from 'formik'
import get from 'lodash/fp/get'

import { Input } from '../../foundation'
import { InputProps } from '../../foundation/Input'
import FormField from '../FormField'

type SomeInputProps = Omit<InputProps, 'onChange' | 'onBlur' | 'name' | 'value'>

export interface FormikInputProps extends SomeInputProps {
  id?: string
  label?: string
  name: string
  placeholder?: string
  value?: string
  outerClassName?: string
  toolTipText?: string
  format?: (event: ChangeEvent<HTMLInputElement>) => void
  onChange?: (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue?: (field: string, value: any) => void
  ) => void
  onBlur?: (
    event: FocusEvent<HTMLInputElement>,
    setFieldValue?: (field: string, value: any) => void
  ) => void
}

export const integerFormatter = (event: ChangeEvent<HTMLInputElement>) => {
  event.target.value = event.target.value.replace(/\./, '')
  floatFormatter(event)
}

export const floatFormatter = (event: ChangeEvent<HTMLInputElement>) => {
  let i = 0
  event.target.value =
    event.type === 'blur'
      ? `${
          Number.isNaN(parseFloat(event.target.value))
            ? ''
            : parseFloat(event.target.value)
        }`
      : event.target.value
          .replace(/[^0-9-.]/g, '')
          .replace(/(?!^)-/g, '')
          .replace(/\./g, (m: string) => (!i++ ? m : ''))
}

const FormikInput: FC<FormikInputProps> = ({
  id,
  name,
  type,
  label,
  placeholder,
  validate,
  format,
  onChange,
  onBlur,
  outerClassName,
  toolTipText,
  ...rest
}) => (
  <Field name={name} validate={validate}>
    {({
      form: { errors, setFieldValue, touched },
      field: { value, onChange: onFieldChange, onBlur: onFieldBlur },
    }: FieldProps) => {
      const fieldName = get(name)
      const displayError = fieldName(touched) && !!fieldName(errors)
      const errorMessage = fieldName(errors)
      const intent = displayError ? 'error' : undefined
      // Overwriting input[type="number"] due to flaky browser support / styling
      const inputType = type === 'number' ? 'text' : type

      const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (format) format(event)
        onFieldChange(event)
        setFieldValue(name, event.target.value)
        if (onChange) onChange(event, setFieldValue)
      }

      const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
        if (format) format(event)
        setFieldValue(name, event.target.value)
        onFieldBlur(event)
        if (onBlur) onBlur(event, setFieldValue)
      }

      return (
        <FormField
          id={id}
          label={label}
          errorMessage={errorMessage}
          displayError={displayError}
          className={outerClassName}
          tooltipText={toolTipText}
        >
          <Input
            id={id}
            name={name}
            intent={intent}
            placeholder={placeholder}
            fill
            value={value == null || Number.isNaN(value) ? '' : value}
            onChange={(event) => handleChange(event)}
            onBlur={handleBlur}
            type={inputType}
            {...rest}
          />
        </FormField>
      )
    }}
  </Field>
)

export default FormikInput
