import React from 'react'
import cn from 'classnames'
import { IMedkitComponent } from '..'
import { spinnerTheme } from './theme'
import styles from './index.module.scss'

const Spinner: IMedkitComponent<IProps, typeof spinnerTheme> = ({
  className,
  center,
  size = 20,
  variant = 'default',
  ...props
}) => (
  <svg
    height={size}
    width={size}
    viewBox="0 0 20 20"
    aria-hidden
    className={center && styles.center}
    data-testid="loading"
  >
    <circle
      cx="10"
      cy="10"
      r="8"
      fill="transparent"
      strokeWidth="2"
      strokeLinecap="round"
      strokeDasharray="35"
      className={cn(styles.Spinner, className, {
        [styles.Spinner_primary]: variant === 'primary',
      })}
      {...props}
    />
  </svg>
)

interface IProps extends React.SVGProps<SVGCircleElement> {
  center?: boolean
  size?: number
  variant?: 'primary' | 'default'
}

Spinner.theme = spinnerTheme

export default Spinner
