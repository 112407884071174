import { format, utcToZonedTime } from 'date-fns-tz'
import isValid from 'date-fns/isValid'
import { enUS, enGB } from 'date-fns/locale'

export interface FormatDateTimeOptions {
  desiredTimeZone?: string
  twelveHourFormat?: boolean
}

const regionMap: Record<string, Locale> = {
  America: enUS,
  Europe: enGB,
}

const isJestRunner = () => process.env.JEST_WORKER_ID !== undefined

const getDateData = (
  date: Date | string,
  pattern: string,
  desiredTimeZone?: string
): string => {
  const utcTimeZone = 'Etc/UTC'

  if (!isValid(new Date(date))) {
    throw new Error('date is a required parameter and must be a valid date')
  }

  if (!desiredTimeZone && !isJestRunner()) {
    console.warn('No timezone provided, will use UTC as fallback timezone')
  }

  const zonedDate = utcToZonedTime(date, desiredTimeZone || utcTimeZone)

  const region = (desiredTimeZone || utcTimeZone).split('/')[0]
  const locale = regionMap[region]

  return format(zonedDate, pattern, {
    timeZone: desiredTimeZone || utcTimeZone,
    locale,
  })
}

export const formatDateTimeWithTimezone = (
  date: Date | string,
  { desiredTimeZone, twelveHourFormat = false }: FormatDateTimeOptions
): string => {
  const americaRegion = desiredTimeZone?.split('/')[0] === 'America'

  const pattern = `${americaRegion ? 'MMM dd' : 'dd MMM'} yyyy, ${
    twelveHourFormat ? 'hh:mma' : 'HH:mm'
  } zzz`

  return getDateData(date, pattern, desiredTimeZone)
}

export const formatDateTime = (
  date: Date | string,
  { desiredTimeZone, twelveHourFormat = false }: FormatDateTimeOptions
) => {
  const americaRegion = desiredTimeZone?.split('/')[0] === 'America'

  const pattern = `${americaRegion ? 'MMM dd' : 'dd MMM'} yyyy, ${
    twelveHourFormat ? 'hh:mma' : 'HH:mm'
  }`

  return getDateData(date, pattern, desiredTimeZone)
}

export const formatTimeWithTimezone = (
  date: Date | string,
  { desiredTimeZone, twelveHourFormat = false }: FormatDateTimeOptions
) => {
  const pattern = twelveHourFormat ? 'hh:mma zzz' : 'HH:mm zzz'

  return getDateData(date, pattern, desiredTimeZone)
}
