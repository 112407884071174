import React from 'react'
import cn from 'classnames'
import { Form } from 'formik'
import PropTypes from 'prop-types'

import {
  Button,
  FormikInput,
  FormikCheckbox,
  FormikDropdownSelect,
  FormikDateRangePicker,
} from '@babylon/core-ui'

import './index.scss'

const ConsultationsForGpsFiltersForm = ({
  isSubmitting,
  consumerNetworks = [],
  i18nMessages,
}) => (
  <Form>
    <div className="ConsultationsForGpsFiltersForm">
      <div className="ConsultationsForGpsFiltersForm__row">
        <div className="ConsultationsForGpsFiltersForm__column">
          <h2 className="ConsultationsForGpsFiltersForm__columnHeading">
            {i18nMessages.consultation}
          </h2>

          <div className="ConsultationsForGpsFiltersForm__dateField">
            <FormikDateRangePicker
              name="dates"
              id="dates"
              label={i18nMessages.dates}
              dateRangePickerProps={{
                small: true,
              }}
              fill
            />
          </div>

          <FormikInput
            id="consultation_id"
            name="consultation_id"
            label={i18nMessages.consultation_id}
          />

          {consumerNetworks && consumerNetworks.length > 0 && (
            <div className="ConsultationsForGpsFiltersForm__consumerNetworkField">
              <FormikDropdownSelect
                id="consumer_network"
                name="consumer_network"
                label={i18nMessages.consumerNetwork.label}
                placeholder={i18nMessages.consumerNetwork.placeholder}
                searchable
                options={consumerNetworks}
              />
            </div>
          )}
        </div>

        <div className="ConsultationsForGpsFiltersForm__column">
          <h2 className="ConsultationsForGpsFiltersForm__columnHeading">
            {i18nMessages.patient}
          </h2>

          <FormikInput
            id="family_name"
            name="family_name"
            label={i18nMessages.family_name}
          />

          <FormikInput
            id="given_name"
            name="given_name"
            label={i18nMessages.given_name}
          />

          <div className="ConsultationsForGpsFiltersForm__genderField">
            <FormikDropdownSelect
              id="gender"
              name="gender"
              label={i18nMessages.gender.label}
              placeholder={i18nMessages.gender.placeholder}
              options={[
                {
                  key: 'male',
                  value: i18nMessages.gender.male,
                },
                {
                  key: 'female',
                  value: i18nMessages.gender.female,
                },
              ]}
            />
          </div>
        </div>

        <div
          className={cn(
            'ConsultationsForGpsFiltersForm__column',
            'ConsultationsForGpsFiltersForm__column--withoutHeading'
          )}
        >
          <FormikInput
            id="patient_id"
            name="patient_id"
            label={i18nMessages.patient_id}
          />

          <FormikInput
            id="public_healthcare_id"
            name="public_healthcare_id"
            label={i18nMessages.healthcare_id}
          />
        </div>

        <div className="ConsultationsForGpsFiltersForm__column">
          <h2 className="ConsultationsForGpsFiltersForm__columnHeading">
            {i18nMessages.filters}
          </h2>

          <FormikCheckbox id="marked_as_sent" name="marked_as_sent">
            {i18nMessages.archived_appointments}
          </FormikCheckbox>
        </div>
      </div>

      <div className="ConsultationsForGpsFiltersForm__buttons">
        <div className="ConsultationsForGpsFiltersForm__button">
          <Button
            type="reset"
            intent="secondary"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            <span className="ConsultationsForGpsFiltersForm__buttonText">
              {i18nMessages.reset_filters}
            </span>
          </Button>
        </div>

        <div className="ConsultationsForGpsFiltersForm__button">
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            data-testid="apply-filters"
          >
            <span className="ConsultationsForGpsFiltersForm__buttonText">
              {i18nMessages.apply_filters}
            </span>
          </Button>
        </div>
      </div>
    </div>
  </Form>
)

ConsultationsForGpsFiltersForm.propTypes = {
  i18nMessages: PropTypes.shape({
    consultation: PropTypes.string.isRequired,
    dates: PropTypes.string.isRequired,
    consultation_id: PropTypes.string.isRequired,
    consumerNetwork: PropTypes.shape({
      label: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
    }),
    patient: PropTypes.string.isRequired,
    family_name: PropTypes.string.isRequired,
    given_name: PropTypes.string.isRequired,
    gender: PropTypes.shape({
      label: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      male: PropTypes.string.isRequired,
      female: PropTypes.string.isRequired,
    }),
    patient_id: PropTypes.string.isRequired,
    healthcare_id: PropTypes.string.isRequired,
    filters: PropTypes.string.isRequired,
    archived_appointments: PropTypes.string.isRequired,
    reset_filters: PropTypes.string.isRequired,
    apply_filters: PropTypes.string.isRequired,
  }).isRequired,
  isSubmitting: PropTypes.bool,
  consumerNetworks: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
}

ConsultationsForGpsFiltersForm.defaultProps = {
  isSubmitting: false,
  consumerNetworks: [],
}

export default ConsultationsForGpsFiltersForm
