import React from 'react'
import cx from 'classnames'

import styles from './styles.module.scss'

interface PopoverDividerProps {
  className?: string
}

const PopoverDivider = ({ className }: PopoverDividerProps) => (
  <div className={cx(styles.popoverDivider, className)} />
)

export default PopoverDivider
