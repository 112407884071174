import React, { useCallback } from 'react'
import { Select } from '@babylon/medkit'

import styles from './PageSize.module.scss'
import { TableQueryDisplayParamsType } from './TableQuery'
import { PageSizeOptionsType } from '@/types'

const DEFAULT_PAGE_SIZE_OPTIONS: PageSizeOptionsType[] = [
  { value: '10', label: '10' },
  { value: '20', label: '20' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
]

interface PageSizeProps {
  options?: PageSizeOptionsType[]
  pageSize: number
  onChange: (data: Partial<TableQueryDisplayParamsType>) => void
}

export default ({
  options = DEFAULT_PAGE_SIZE_OPTIONS,
  pageSize,
  onChange,
}: PageSizeProps) => {
  const handlePageSize = useCallback(
    (event) => {
      const newPageSize: String = event.target.value
      return (
        +newPageSize !== pageSize &&
        onChange &&
        onChange({ pageSize: +newPageSize, page: 1 })
      )
    },
    [pageSize, onChange]
  )

  return (
    <div className={styles.pageSize}>
      <Select
        label=""
        options={options}
        value={String(pageSize)}
        onChange={handlePageSize}
        data-testid="pageSize"
      />
    </div>
  )
}
