import React from 'react'

interface LinkRendererProps {
  href: string
  children: React.ReactNode
}

const LinkRenderer = ({ href, children }: LinkRendererProps) => (
  <a href={href} target="_blank" rel="noreferrer">
    {children}
  </a>
)

export default LinkRenderer
