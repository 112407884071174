import * as React from 'react'

const CloseCircleFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11.996 10.795L8.403 7.256A.9.9 0 007.14 8.538l3.573 3.52-3.573 3.52a.9.9 0 101.263 1.283l3.593-3.54 3.592 3.54a.9.9 0 101.264-1.282l-3.574-3.52 3.574-3.52a.9.9 0 00-1.264-1.283l-3.592 3.54zM12 2.4c5.3 0 9.6 4.3 9.6 9.6s-4.3 9.6-9.6 9.6-9.6-4.3-9.6-9.6S6.7 2.4 12 2.4z" />
  </svg>
)

export default CloseCircleFilled
