import * as React from 'react'

const AlertsInformationFill = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm0 17a1 1 0 01-1-1v-7a1 1 0 012 0v7a1 1 0 01-1 1zm0-10a1.25 1.25 0 110-2.5A1.25 1.25 0 0112 8z" />
  </svg>
)

export default AlertsInformationFill
