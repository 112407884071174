import React from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'

import { Textarea as MedkitTextarea } from '@babylon/medkit'

import { useFieldContext } from '../Field'

interface TextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  value?: string
  ref?: React.Ref<HTMLTextAreaElement>
}

const MedkitTextareaWrapper = ({
  rules,
  name: propName,
  ...props
}: TextareaProps) => {
  const control = useFormContext()?.control
  const { name: contextName } = useFieldContext()
  const name = propName || contextName
  if (control && !name) {
    throw new Error('MedkitInputWrapper requires a name')
  }

  return control ? (
    <Controller
      render={({ onChange, value }) => (
        <MedkitTextarea
          value={value ?? undefined}
          onChange={onChange}
          {...props}
        />
      )}
      name={name || ''}
      control={control}
      rules={rules}
    />
  ) : (
    <MedkitTextarea {...props} />
  )
}

export default MedkitTextareaWrapper
