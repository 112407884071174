import * as React from 'react'

const Privacy = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M19.05 16.833V7.34c0-.38-.248-.672-.677-.672-.401 0-.6.256-.6.672v4.033a.802.802 0 01-1.605 0V5.09c0-.242-.235-.467-.556-.467-.314 0-.536.217-.536.467v6.306a.78.78 0 11-1.56 0V4.34c0-.323-.27-.63-.603-.63-.335 0-.61.31-.61.63v7.077a.758.758 0 01-1.517 0V6.215c0-.31-.241-.571-.6-.571-.371 0-.637.276-.637.571v8.931a.71.71 0 01-1.066.616l-.499-.289A1782.53 1782.53 0 005.425 14l-.1-.056c-.046-.022-.046-.022.12.043l-.16-.065a.862.862 0 00-.336-.093c-.095-.002-.117.011-.172.153a.883.883 0 00.056.788c.046.083.088.136.085.134l5.061 4.922a2.25 2.25 0 001.613.678h3.482c2.569 0 3.976-1.192 3.976-3.67zm-.677-11.586c1.27 0 2.098.975 2.098 2.093v9.493c0 3.321-2.091 5.092-5.397 5.092h-3.481a3.659 3.659 0 01-2.617-1.093l-5.064-4.924a2.153 2.153 0 01-.323-.452 2.284 2.284 0 01-.137-1.988c.28-.722.857-1.075 1.529-1.06.38.009.738.13.995.272l.151.085.367.21a628.971 628.971 0 011.634.94v-7.7c0-1.068.89-1.992 2.058-1.992.248 0 .481.041.695.116 0-1.069.873-2.05 2.032-2.05.806 0 1.47.473 1.8 1.121.268-.133.573-.208.899-.208 1.093 0 1.977.848 1.977 1.888v.296a2.16 2.16 0 01.784-.139z" />
  </svg>
)

export default Privacy
