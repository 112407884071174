import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './index.scss'

const getClassName = ({ selected, disabled, className }) =>
  cn(
    'core-pill',
    selected && 'core-pill--selected',
    disabled && 'core-pill--disabled',
    className
  )

const handleClick = (id, onSelectPill, disabled) => {
  if (disabled) return
  onSelectPill(id)
}

const Pill = ({
  selected,
  disabled,
  style,
  onSelectPill,
  className,
  name,
  id,
  icon,
  dataTestId,
}) => (
  <div
    onClick={() => handleClick(id, onSelectPill, disabled)}
    role="button"
    tabIndex={0}
    style={style}
    className={getClassName({
      selected,
      disabled,
      className,
    })}
    data-testid={dataTestId}
  >
    {icon}
    <span>{name}</span>
  </div>
)

Pill.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.node.isRequired,
  icon: PropTypes.node,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

Pill.defaultProps = {
  disabled: false,
  selected: false,
  icon: null,
}

export default Pill
