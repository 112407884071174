import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useFieldContext } from '../Field'
import DragAndDropComponent from './DragAndDropComponent'

export type DragAndDropFileType = {
  name: string
  sizeInBytes: number
  base64: any
  file?: File
}

interface DragAndDropUploadProps {
  value?: DragAndDropFileType | null
  name?: string
  acceptedTypes?: string[]
  maxSizeInBytes?: number
  trackValidationError?: () => void
  className?: string
  'data-testid'?: string
  onCancel: () => void
}

function DragAndDropUpload({
  name: propName,
  acceptedTypes,
  maxSizeInBytes,
  trackValidationError,
  className,
  value = null,
  onCancel,
  'data-testid': dataTestId,
  ...props
}: DragAndDropUploadProps) {
  const control = useFormContext()?.control
  const { name: contextName } = useFieldContext()
  const name = propName || contextName
  if (control && !name) {
    throw new Error('DragAndDropUpload requires a name')
  }

  return control ? (
    <Controller
      render={({ onChange, value }) => (
        <DragAndDropComponent
          value={value}
          onChange={onChange}
          acceptedTypes={acceptedTypes}
          maxSizeInBytes={maxSizeInBytes}
          trackValidationError={trackValidationError}
          className={className}
          data-testid={dataTestId}
          onCancel={onCancel}
          {...props}
        />
      )}
      name={name || ''}
      control={control}
    />
  ) : (
    <DragAndDropComponent
      value={value}
      acceptedTypes={acceptedTypes}
      maxSizeInBytes={maxSizeInBytes}
      trackValidationError={trackValidationError}
      className={className}
      data-testid={dataTestId}
      onCancel={onCancel}
      {...props}
    />
  )
}

export default DragAndDropUpload
