import React, { useState, useEffect, useMemo, useCallback } from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'

import { LinkButton } from '@babylon/medkit'

import styles from './styles.module.scss'

interface TabConfig {
  label: string
  name: string
  component: React.ReactChild
  url?: string
}

interface TabProps {
  tabs: TabConfig[]
  activeTabName: string
}

const Tabs = ({ tabs, activeTabName }: TabProps) => {
  const history = useHistory()

  const [currentActiveTabName, setCurrentActiveTabName] = useState(
    activeTabName
  )
  const activeTabContent = useMemo(
    () => tabs.find((tab) => tab.name === currentActiveTabName)?.component,
    [currentActiveTabName, tabs]
  )

  const handleChange = useCallback(
    (name: string, url?: string) => {
      if (name !== currentActiveTabName) {
        setCurrentActiveTabName(name)

        if (url) {
          history.push(url)
        }
      }
    },
    [currentActiveTabName, history]
  )

  useEffect(() => {
    if (!activeTabContent) {
      setCurrentActiveTabName(tabs[0]?.name)

      if (tabs[0]?.url) {
        history.replace(tabs[0].url)
      }
    }
  }, [activeTabContent, history, tabs])

  useEffect(() => {
    if (activeTabName !== currentActiveTabName) {
      setCurrentActiveTabName(activeTabName)
    }
    // We want the effect to run only when activeTabName changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabName])

  return (
    <div className={styles.tabs}>
      <div className={styles.tabHeadings}>
        {tabs.map(({ name, label, url }) => (
          <LinkButton
            className={cx({
              [styles.activeTabHeading]: currentActiveTabName === name,
            })}
            onClick={() => handleChange(name, url)}
            key={name}
          >
            {label}
          </LinkButton>
        ))}
      </div>
      <div className={styles.activeTabContent}>{activeTabContent}</div>
    </div>
  )
}

export default Tabs
