import React from 'react'
import { envFlag } from '@babylon/babylon-env'

import { BabylonIntl } from '@babylon/intl'

async function getMessagesOnLocaleChange(locale) {
  try {
    const messagesData = await import(`../../messages/${locale}.json`)

    if (messagesData && messagesData.default) {
      return messagesData.default
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

const I18n = ({ children }) => {
  const initialLocale = envFlag('FEATURE_TEST_LOCALE') ? 'en_TEST' : 'en_GB'

  return (
    <BabylonIntl
      textComponent="span"
      initialLocale={initialLocale}
      getMessagesOnLocaleChange={getMessagesOnLocaleChange}
    >
      {children}
    </BabylonIntl>
  )
}

export default I18n
