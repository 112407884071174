import React, { useCallback } from 'react'
import { Icon } from '@babylon/medkit'

import styles from './Sort.module.scss'

interface SortProps {
  name: string
  sort: string
  onChange: (newState: any) => any
}

export default ({ name, sort, onChange }: SortProps) => {
  const handleSort = useCallback(
    ({ currentTarget: { dataset } }) =>
      onChange({
        sort: `${
          (sort.includes(dataset.name) && sort.charAt(0) !== '-' && '-') || ''
        }${name}`,
        page: 1,
      }),
    [sort, name, onChange]
  )

  const iconName = sort.includes(name)
    ? sort !== name
      ? 'CoreSortUp'
      : 'CoreSortDown'
    : 'CoreSort'

  return (
    <button
      type="button"
      className={styles.sort}
      data-name={name}
      data-testid="sortButton"
      onClick={handleSort}
    >
      <Icon icon={iconName} title="Sort icon" width={24} height={24} />
    </button>
  )
}
