import React from 'react'
import { SnackbarBagType } from './createSnackbarBag'

const SnackbarBagContext = React.createContext<SnackbarBagType>({
  add: ({ message, type }) => ({
    id: 0,
    message,
    type,
    hasTimeout: false,
    timeout: 0,
    cancelTimeout: () => {},
    startTimeout: () => {},
    remove: () => {},
  }),
  all: () => [],
  removeAll: () => {},
  addListener: () => () => {},
})

export { SnackbarBagContext }
