import * as React from 'react'

const FilesCopy = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.7 1.1H22a1.2 1.2 0 011.2 1.2v14.1a1.2 1.2 0 01-1.2 1.3h-2.7V6.8A1.9 1.9 0 0017.5 5h-12V2.3a1.2 1.2 0 011.2-1.2zM2 6h15.5a.9.9 0 01.8.8v14.4a.8.8 0 01-.8.8H2a.7.7 0 01-.7-.8V6.8A.8.8 0 012 6z"
    />
  </svg>
)

export default FilesCopy
