import React from 'react'

import { IMedkitComponent } from '..'
import babylonLogoTheme from './theme'

import BabylonLogoSvg from './babylon-logo.svg'
import styles from './index.module.scss'

export type BabylonLogoProps = {
  altText?: string
  appName?: string
  logoSvg?: string
}

export const BabylonLogo: IMedkitComponent<
  BabylonLogoProps,
  typeof babylonLogoTheme
> = ({
  altText = 'Babylon Health Logo',
  logoSvg = BabylonLogoSvg,
  appName,
}: BabylonLogoProps) => (
  <figure className={styles.babylonLogo}>
    <img className={styles.babylonLogoImage} src={logoSvg} alt={altText} />
    {appName && (
      <figcaption className={styles.babylonLogoAppName}>{appName}</figcaption>
    )}
  </figure>
)

BabylonLogo.theme = babylonLogoTheme

export default BabylonLogo
