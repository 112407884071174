import { defineMessages } from 'react-intl'

const baseId = 'components.table_query'

export default defineMessages({
  loadingError: {
    id: `${baseId}.loading_error`,
    defaultMessage: 'An error occurred while loading the data from the server!',
  },
  noItemsMessage: {
    id: `${baseId}.no_items_message`,
    defaultMessage: 'No items currently available',
  },
  searchPlaceholder: {
    id: `${baseId}.search_placeholder`,
    defaultMessage: 'Search',
  },
  searchLabel: {
    id: `${baseId}.search_label`,
    defaultMessage: `Search {count} {type}`,
  },
})
