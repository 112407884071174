import * as React from 'react'

const EditFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M6.637 19.694c.41-.096 1.045-.45 1.343-.748L20.105 6.805a.29.29 0 00.01-.404l-2.52-2.517a.29.29 0 00-.401.009L5.07 16.035c-.296.296-.653.937-.748 1.345l-.71 3.024 3.025-.71zm-4.675 1.503l.972-4.143c.157-.666.645-1.542 1.128-2.025L16.185 2.886a1.715 1.715 0 012.418-.01l2.519 2.518a1.714 1.714 0 01-.008 2.418L8.988 19.952c-.485.487-1.358.973-2.025 1.13l-4.144.972a.712.712 0 01-.857-.857zM17.813 9.882l-9.356 9.544a.713.713 0 01-1.084.917l-3.637-3.631a.712.712 0 01.768-1.166l9.668-9.322-.15-.151a.712.712 0 111.01-1.006l3.911 3.931a.712.712 0 11-1.01 1.005l-.12-.12z" />
  </svg>
)

export default EditFilled
