import React from 'react'

import { ModalStackContext } from './core'
import ModalStackRenderer from './ModalStackRenderer'

function ModalStack({ children, modalStack }) {
  return (
    <ModalStackContext.Provider value={modalStack}>
      {children}
    </ModalStackContext.Provider>
  )
}

ModalStack.Renderer = ModalStackRenderer

export default ModalStack
