import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ArrowSVG from '../../../../icons/ArrowSVG'
import DropdownSearch from './DropdownSearch'
import DropdownDisplayValue from './DropdownDisplayValue'
import DropdownClearButton from './DropdownClearButton'
import { optionPropType } from './DropdownOptions'

const DropdownInput = ({
  hasError,
  searchValue,
  searchable,
  selectedOption,
  placeholder,
  showClearButton,
  onClick,
  onSearchInputChange,
  onSearchInputFocus,
  onSearchInputBlur,
  onClearButtonClick,
  ...rest
}) => {
  const className = classnames('core-dropdown-select__input', {
    'core-dropdown-select__input--error': hasError,
  })
  return (
    <div
      role="button"
      tabIndex={0}
      className={className}
      data-testid="dropdown-input"
      onClick={onClick}
    >
      {searchable ? (
        <DropdownSearch
          value={searchValue}
          placeholder={placeholder}
          selectedText={selectedOption && selectedOption.value}
          onChange={onSearchInputChange}
          onFocus={onSearchInputFocus}
          onBlur={onSearchInputBlur}
          {...rest}
        />
      ) : (
        <DropdownDisplayValue
          text={selectedOption ? selectedOption.value : placeholder}
          {...rest}
        />
      )}

      {showClearButton ? (
        <DropdownClearButton onClick={onClearButtonClick} />
      ) : (
        <ArrowSVG className="core-dropdown-select__input-arrow" />
      )}
    </div>
  )
}

DropdownInput.propTypes = {
  hasError: PropTypes.bool,
  searchValue: PropTypes.string,
  searchable: PropTypes.bool,
  selectedOption: optionPropType,
  placeholder: PropTypes.string,
  showClearButton: PropTypes.bool,
  onClick: PropTypes.func,
  onSearchInputChange: PropTypes.func,
  onSearchInputFocus: PropTypes.func,
  onSearchInputBlur: PropTypes.func,
  onClearButtonClick: PropTypes.func,
}

export default DropdownInput
