import React, { useCallback, useMemo, useRef, useState } from 'react'
import cx from 'classnames'

import { PopoverContext } from './core'
import PopoverTrigger from './PopoverTrigger'
import PopoverMenu from './PopoverMenu'
import PopoverItem from './PopoverItem'
import PopoverAction from './PopoverAction'
import PopoverDivider from './PopoverDivider'
import PopoverLink from './PopoverLink'

import styles from './styles.module.scss'

interface PopoverProps {
  className?: string
  children?: React.ReactNode
  closeOnBlur?: boolean
  'data-testid'?: string
}

const Popover = ({
  children,
  className,
  closeOnBlur = true,
  'data-testid': dataTestId,
}: PopoverProps) => {
  const [isOpen, setIsOpen] = useState(false)
  // We use ref instead of state here, because setTimeout callback (further down)
  // creates a closure and a state variable at the point of running the callback
  // would contain a stale value.
  const hasBubbledFocusRef = useRef(false)
  const setHasBubbledFocus = useCallback(
    (hasFocus) => {
      hasBubbledFocusRef.current = hasFocus
    },
    [hasBubbledFocusRef]
  )
  const toggleIsOpen = useCallback(() => {
    setHasBubbledFocus(false)
    setIsOpen(!isOpen)
  }, [isOpen, setHasBubbledFocus])
  const handleBlur = useCallback(() => {
    if (!closeOnBlur) {
      return
    }

    // When a popover item is clicked within the popover container,
    // the sequence of events is as follows:
    // blur(container) => focus(element) => focus(container) => click(element)
    // This makes it difficult to figure out if the blur happened because
    // the user lost interest in the popover or because he clicked on an item.
    // However, both blur and focus events are pushed to the queue in response
    // to the same user action, so when we handle blur, focus is already in the queue.
    // Using `setTimeout` in the blur handler we are cheekily changing the handling sequence:
    // focus(element) => focus(container) => blur(container) => click(element)
    // This allows us to check bubbled focus in the offset blur handler.
    setTimeout(() => {
      if (!hasBubbledFocusRef.current) {
        setIsOpen(false)
      }
    }, 0)
  }, [closeOnBlur])
  const handleFocus = useCallback(() => {
    setHasBubbledFocus(true)
  }, [setHasBubbledFocus])
  const value = useMemo(
    () => ({
      isOpen,
      toggleIsOpen,
    }),
    [isOpen, toggleIsOpen]
  )

  return (
    <PopoverContext.Provider value={value}>
      <div
        className={cx(styles.popover, className, {
          [styles.isOpen]: isOpen,
        })}
        onFocus={handleFocus}
        onBlur={handleBlur}
        data-testid={dataTestId}
      >
        {children}
      </div>
    </PopoverContext.Provider>
  )
}

Popover.Action = PopoverAction
Popover.Item = PopoverItem
Popover.Trigger = PopoverTrigger
Popover.Menu = PopoverMenu
Popover.Divider = PopoverDivider
Popover.Link = PopoverLink

export default Popover
export {
  PopoverTrigger,
  PopoverMenu,
  PopoverAction,
  PopoverItem,
  PopoverContext,
  PopoverDivider,
  PopoverLink,
}
