import * as React from 'react'

const PinFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.207 20.829a1.75 1.75 0 01-2.474-.06C6.424 16.243 4.25 12.691 4.25 10a7.75 7.75 0 0115.5 0c0 2.692-2.174 6.244-6.483 10.768l-.06.06zM12 12a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
  </svg>
)

export default PinFilled
