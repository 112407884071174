import * as React from 'react'

const NavigationConfirm = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M22.706 4.295a.994.994 0 00-1.414 0L8.113 17.57l-5.406-5.444a.994.994 0 00-1.414 0 1.011 1.011 0 000 1.424l6.114 6.156a.994.994 0 001.414 0L22.706 5.719a1.011 1.011 0 000-1.424z" />
  </svg>
)

export default NavigationConfirm
