import * as React from 'react'

const ArrowLeft = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8.212 17.223a.75.75 0 11-1.068 1.054l-5.928-6.01a.75.75 0 010-1.054l5.929-5.99A.75.75 0 118.21 6.277L2.804 11.74l5.408 5.482z" />
  </svg>
)

export default ArrowLeft
