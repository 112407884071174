import * as React from 'react'

const Eye = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14.583 12c0-1.484-1.16-2.679-2.583-2.679-1.422 0-2.583 1.195-2.583 2.679 0 1.484 1.16 2.679 2.583 2.679 1.422 0 2.583-1.195 2.583-2.679zm1.5 0c0 2.304-1.823 4.179-4.083 4.179-2.26 0-4.083-1.875-4.083-4.179 0-2.304 1.823-4.179 4.083-4.179 2.26 0 4.083 1.875 4.083 4.179zm-12.214.37a17.766 17.766 0 001.914 1.837C7.777 15.847 9.886 16.82 12 16.82s4.221-.974 6.212-2.614A17.721 17.721 0 0020.446 12a17.721 17.721 0 00-2.234-2.207C16.222 8.153 14.114 7.18 12 7.18s-4.223.974-6.217 2.614A17.766 17.766 0 003.546 12c.093.11.201.234.323.37zM12 18.32c-2.518 0-4.932-1.115-7.17-2.956a19.253 19.253 0 01-2.642-2.654 8.565 8.565 0 01-.207-.265.75.75 0 010-.892 8.26 8.26 0 01.207-.265A19.253 19.253 0 014.83 8.635C7.068 6.794 9.482 5.68 12 5.68s4.93 1.115 7.166 2.957a19.208 19.208 0 012.638 2.654c.099.123.168.213.207.264a.75.75 0 010 .892 16.657 16.657 0 01-.77.926 19.268 19.268 0 01-2.075 1.992c-2.235 1.842-4.648 2.957-7.166 2.957z" />
  </svg>
)

export default Eye
