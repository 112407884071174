import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@babylon/core-ui'

import './index.scss'

const PageErrorMessage = ({ errorMessage, showReloadButton }) => {
  const reloadPage = () => {
    window.location.reload()
  }

  return (
    <div className="PageErrorMessage">
      <div className="PageErrorMessage__inner">
        <span className="PageErrorMessage__errorMessage">{errorMessage}</span>

        {showReloadButton && (
          <Button
            type="button"
            onClick={reloadPage}
            className="PageErrorMessage__reloadButton"
          >
            <span className="PageErrorMessage__reloadButtonText">
              Reload Page
            </span>
          </Button>
        )}
      </div>
    </div>
  )
}

PageErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
  showReloadButton: PropTypes.bool,
}

PageErrorMessage.defaultProps = {
  errorMessage: 'An error occurred while loading the portal',
  showReloadButton: true,
}

export default PageErrorMessage
