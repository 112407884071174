import * as React from 'react'

const Birthday = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.746 8.606v1.858l-.75-.75h6.33c.966 0 1.75.784 1.75 1.75v3.016a.781.781 0 01.007.113v5.657a1.76 1.76 0 01-1.758 1.746H5.675c-.966 0-1.758-.78-1.758-1.746v-8.786c0-.966.783-1.75 1.75-1.75h6.329l-.75.75V8.602a2.34 2.34 0 001.5.004v2.608h-1.5V8.602A2.414 2.414 0 019.62 6.317c0-.451.12-.88.334-1.223l1.393-2.463a.75.75 0 011.306 0l1.377 2.436c.23.37.35.799.35 1.25a2.407 2.407 0 01-1.634 2.289zm5.83 5.56v-2.702a.25.25 0 00-.25-.25H5.665a.25.25 0 00-.25.25v2.698a2.028 2.028 0 002.305-.43l.889-.888a.75.75 0 011.065.005l.872.887c.77.784 2.146.782 2.917-.003l.88-.887a.75.75 0 011.067.003l.872.887a2.024 2.024 0 002.292.43zm0 1.581a3.524 3.524 0 01-3.362-.96l-.34-.345-.343.345c-1.355 1.38-3.695 1.383-5.054 0l-.342-.348-.348.348a3.524 3.524 0 01-3.37.958v4.505c0 .132.115.246.258.246h12.65c.14 0 .258-.118.258-.246v-2.834h-.008v-1.669zM11.242 5.86a.851.851 0 00-.123.458c0 .501.4.91.88.91.484 0 .88-.405.88-.91a.884.884 0 00-.14-.485L12 4.523l-.757 1.336z" />
  </svg>
)

export default Birthday
