import * as React from 'react'

const Body = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M5.105 8.503l-.1.53a.25.25 0 00.167.284c1.861.61 3.356.964 4.472 1.065a.75.75 0 01.675.852l-1.252 8.87c0 .041.027.068.06.068h.648a.25.25 0 00.235-.165c.02-.057.038-.123.053-.2l.724-3.683c.15-.712.591-1.196 1.282-1.196.68 0 1.126.47 1.3 1.184.443 1.958.703 3.191.785 3.727-.001-.011-.001-.011.027.132a.25.25 0 00.245.2h.414a.307.307 0 00.304-.353c-.956-6.194-1.31-8.53-1.31-8.689a.75.75 0 01.683-.747c.98-.087 2.47-.442 4.447-1.068a.25.25 0 00.169-.29l-.111-.52a.252.252 0 00-.305-.19c-2.389.594-4.573.893-6.554.893-1.99 0-4.24-.301-6.754-.9a.252.252 0 00-.304.196zm-.4 2.24a1.75 1.75 0 01-1.174-1.99l.1-.53a1.75 1.75 0 012.046-1.392c.04.007.04.007.08.017 2.407.573 4.543.859 6.406.859 1.85 0 3.915-.282 6.192-.85a1.75 1.75 0 012.12 1.276l.014.058.11.52a1.75 1.75 0 01-1.183 2.033c-1.629.516-2.954.86-3.987 1.03.183 1.213.616 4.05 1.197 7.815a1.807 1.807 0 01-1.786 2.083h-.414a1.75 1.75 0 01-1.715-1.405l-.04-.203c-.063-.413-.258-1.353-.58-2.797l-.556 2.83c-.03.15-.068.29-.115.421a1.75 1.75 0 01-1.645 1.154h-.648a1.56 1.56 0 01-1.545-1.778l1.145-8.116c-1.111-.175-2.45-.52-4.022-1.035zm10.13-6.093c0 1.472-1.24 2.65-2.75 2.65s-2.75-1.178-2.75-2.65S10.575 2 12.085 2s2.75 1.178 2.75 2.65zm-1.5 0c0-.626-.552-1.15-1.25-1.15s-1.25.524-1.25 1.15c0 .626.552 1.15 1.25 1.15s1.25-.524 1.25-1.15z" />
  </svg>
)

export default Body
