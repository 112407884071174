import * as React from 'react'

const ChildFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.214 2.252C17.5 2.366 21.75 6.687 21.75 12c0 5.385-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12c0-4.156 2.6-7.705 6.263-9.108-.02.35.034.904.072 1.078.293 1.377 1.127 2.7 2.48 3.951a.75.75 0 001.018-1.101c-1.039-.961-1.687-1.92-1.963-2.891-.05-.176-.262-.946-.291-1.436.3-.069.605-.123.915-.163.01.328.094.736.122.804.419 1.029 1.148 2.024 2.178 2.976a.75.75 0 101.018-1.101c-.686-.635-1.202-1.268-1.552-1.905a7.423 7.423 0 01-.296-.852zM15.896 13c.853 0 1.543-.672 1.543-1.5 0-.83-.69-1.5-1.543-1.5s-1.544.67-1.544 1.5c0 .828.691 1.5 1.544 1.5zm-6.309-1.5c0-.83-.69-1.5-1.543-1.5-.852 0-1.544.67-1.544 1.5 0 .828.692 1.5 1.544 1.5.853 0 1.543-.672 1.543-1.5zm3.748 4.172a1.63 1.63 0 01-1.335.687 1.629 1.629 0 01-1.344-.698.75.75 0 00-1.232.855A3.129 3.129 0 0012 17.859c1.03 0 1.978-.5 2.56-1.32a.75.75 0 00-1.225-.867z" />
  </svg>
)

export default ChildFilled
