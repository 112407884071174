import * as React from 'react'

const Ambulance = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10.063 16.667c0-.657-.548-1.195-1.23-1.195-.682 0-1.229.538-1.229 1.195s.547 1.194 1.23 1.194c.681 0 1.229-.537 1.229-1.194zm1.5 0c0 1.491-1.225 2.694-2.73 2.694s-2.729-1.203-2.729-2.694c0-1.491 1.224-2.695 2.73-2.695 1.504 0 2.729 1.204 2.729 2.695zm7.208 0c0-.657-.548-1.195-1.23-1.195-.682 0-1.229.538-1.229 1.195s.547 1.194 1.23 1.194c.682 0 1.229-.537 1.229-1.194zM8.479 7.829H7.067c-.25 0-.583.224-.67.445l-.677 1.738c-.243.625-.817 1.433-1.328 1.873l-.248.213c-.254.219-.498.754-.498 1.095v2.853c0 .146.112.26.257.26h.759a.75.75 0 110 1.5h-.76a1.755 1.755 0 01-1.756-1.76v-2.853c0-.777.434-1.727 1.02-2.232l.247-.213c.326-.28.756-.885.91-1.28L5 7.73c.312-.801 1.202-1.4 2.067-1.4H8.48a1.749 1.749 0 011.741-1.691h9.891c.961 0 1.742.784 1.742 1.743v7.814a.75.75 0 01-1.5 0V6.382a.245.245 0 00-.242-.243h-9.89a.249.249 0 00-.243.246v6.278a.75.75 0 01-1.5 0V7.83zm11.792 8.838c0 1.491-1.225 2.694-2.73 2.694-1.504 0-2.729-1.203-2.729-2.694 0-1.491 1.225-2.695 2.73-2.695 1.504 0 2.729 1.204 2.729 2.695zm-7.578.36a.75.75 0 010-1.5h.88a.75.75 0 010 1.5h-.88zm3.519-6.46V12a.75.75 0 01-1.5 0v-1.433h-1.433a.75.75 0 110-1.5h1.433V7.635a.75.75 0 111.5 0v1.432h1.432a.75.75 0 010 1.5h-1.432zM11.75 3.75v.88h1.59v-.88h-1.59zM11 2.25h3.09a.75.75 0 01.75.75v2.38a.75.75 0 01-.75.75H11a.75.75 0 01-.75-.75V3a.75.75 0 01.75-.75z" />
  </svg>
)

export default Ambulance
