/* eslint-disable no-console */
import { IncomingHttpHeaders } from 'http'
import { isEmpty } from 'lodash'

type Fields = Record<string, any>
type Logger = (level: string, msg: string, fields: Fields) => void

const LEVELS = {
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
}

const cleanField = (str?: string) => (str ? str.replace(/[\n]+/gi, '') : str)

export const cleanFields = (fields: Fields): Fields =>
  Object.keys(fields).reduce(
    (acc, key) => ({
      ...acc,
      [key]: cleanValue(fields[key]),
    }),
    {}
  )

const cleanValue = (value: any): any => {
  const valueType = typeof value

  if (valueType === 'object' && Array.isArray(value)) {
    return value.map(cleanValue)
  }

  if (valueType === 'object' && value !== null) {
    return cleanFields(value)
  }

  if (valueType === 'string') {
    return cleanField(value)
  }

  return value
}

export const headerFields = (headers: IncomingHttpHeaders): Fields => ({
  'babylon-request-id': headers['babylon-request-id'] as string,
  'apollographql-client-name': headers['apollographql-client-name'] as string,
  operationName: headers['X-GraphQL-Operation-Name'] as string,
})

/**
 * Log a message with a given level and fields - should onyl be used for serverside code as it outputs JSON
 */
export const log = (level: string, message: string, fields: Fields = {}) => {
  const logMode =
    process?.env?.LOGGER || process?.env?.NODE_ENV || 'development'
  const timestamp = new Date().toISOString()
  const debugEnabled = process?.env?.DEBUG === 'true'
  if (level === LEVELS.DEBUG && !debugEnabled) {
    return
  }

  if (logMode === 'development') {
    const fieldsStr = isEmpty(fields)
      ? ''
      : `(${JSON.stringify(cleanFields(fields), null, 2)})`

    console.log(
      `${timestamp} [${level.toUpperCase()}] ${cleanField(
        message
      )} ${fieldsStr}`
    )

    return
  }

  console.log(
    JSON.stringify({
      level,
      timestamp,
      message: cleanField(message),
      ...cleanFields(fields),
    })
  )
}

export const createLogger = (logger: Logger) => ({
  debug: (msg: string, fields?: Fields) =>
    logger(LEVELS.DEBUG, msg, fields || {}),
  info: (msg: string, fields?: Fields) =>
    logger(LEVELS.INFO, msg, fields || {}),
  warn: (msg: string, fields?: Fields) =>
    logger(LEVELS.WARN, msg, fields || {}),
  error: (msg: string, fields?: Fields) =>
    logger(LEVELS.ERROR, msg, fields || {}),
})

export default createLogger(log)
