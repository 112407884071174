import * as React from 'react'

const MicroFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11.25 20.493v-3.15a5.751 5.751 0 01-5-5.7V8a.75.75 0 011.5 0v3.642a4.25 4.25 0 108.5 0V8a.75.75 0 111.5 0v3.642a5.751 5.751 0 01-5 5.702v3.149h1.487a.75.75 0 110 1.5h-4.49a.75.75 0 010-1.5h1.503zM12 2.25a3.25 3.25 0 013.25 3.25v6a3.25 3.25 0 01-6.5 0v-6A3.25 3.25 0 0112 2.25z" />
  </svg>
)

export default MicroFilled
