import * as React from 'react'

const CameraSwitchFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M5.611 12.793l.53-.573a.75.75 0 111.102 1.017l-1.597 1.73a.75.75 0 01-1.06.04L2.718 13.28a.75.75 0 011.019-1.1l.371.343c.047-3.618 2.63-6.917 6.266-7.775a7.826 7.826 0 017.67 2.434.75.75 0 01-1.123.994 6.326 6.326 0 00-6.202-1.968c-3.046.718-5.19 3.551-5.109 6.586zm13.936-2.317a.752.752 0 01.325.291l1.677 1.697a.75.75 0 01-1.066 1.054l-.427-.431c-.325 3.24-2.782 6.128-6.077 6.906a7.832 7.832 0 01-7.308-2.049.75.75 0 111.053-1.069 6.332 6.332 0 005.91 1.658c2.735-.646 4.765-3.106 4.946-5.792l-.597.576a.75.75 0 01-1.042-1.078l1.71-1.653a.75.75 0 01.896-.11zM14.3 9.692h.8a.7.7 0 01.7.7V14.6a.7.7 0 01-.7.7H8.9a.7.7 0 01-.7-.7v-4.208a.7.7 0 01.7-.7h.827l.777-.785a.7.7 0 01.497-.207h2.005a.7.7 0 01.491.201l.803.79zM12 13.687c.86 0 1.556-.671 1.556-1.5 0-.828-.697-1.5-1.556-1.5-.86 0-1.556.672-1.556 1.5 0 .829.697 1.5 1.556 1.5z" />
  </svg>
)

export default CameraSwitchFilled
