export const cardTheme = {
  font: '--medkit-font',
  borderRadius: '--medkit-size-card-border-radius',
  cardShadow: '--medkit-card-shadow',
  cardPadding: '--medkit-card-padding',
  background: '--medkit-card-background',
  imageHeight: '--medkit-card-image-height',
  titleColor: '--medkit-card-title-color',
  textColor: '--medkit-card-text-color',
}
