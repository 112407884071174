import {
  autologinInfo,
  kongTokenIsValid,
  refreshTokenIsValid,
} from '../auth-utils'

export const getTokenInfo = () => {
  const token = autologinInfo()
  const kongTokenValid = kongTokenIsValid('portal', token)
  const refreshTokenValid = refreshTokenIsValid('portal', token)

  return {
    kongTokenValid,
    refreshTokenValid,
    account_maintenance_required: token.account_maintenance_required,
  }
}

export default {
  getTokenInfo,
}
