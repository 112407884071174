import React, { useState, useMemo, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import cx from 'classnames'

import { Icon } from '@babylon/medkit'

import { RegionField } from '@/ui'

import { FilteredRegionType } from './Regions'
import styles from './RegionGroup.module.scss'

interface RegionGroupProps {
  id: string
  name: string
  subregions: FilteredRegionType[]
}

enum GroupStatusType {
  UNSELECTED = 'unselected',
  PARTIAL = 'partial',
  SELECTED = 'selected',
}

const RegionGroup = ({ id, name, subregions }: RegionGroupProps) => {
  const { watch, setValue } = useFormContext()

  const watchSubregions = watch(
    subregions.map(({ id: subregionsId }) => `regions.${subregionsId}`)
  )

  const selectedSubregionsCount = useMemo(
    () =>
      subregions.filter(
        (subregion) => watchSubregions[`regions.${subregion.id}`]
      ).length,
    [subregions, watchSubregions]
  )

  const getGroupStatus = useCallback(
    (selectedCount: number): GroupStatusType => {
      switch (selectedCount) {
        case subregions.length:
          return GroupStatusType.SELECTED
        case 0:
          return GroupStatusType.UNSELECTED
        default:
          return GroupStatusType.PARTIAL
      }
    },
    [subregions]
  )

  const groupStatus = useMemo(() => getGroupStatus(selectedSubregionsCount), [
    getGroupStatus,
    selectedSubregionsCount,
  ])

  const [isOpen, setIsOpen] = useState<boolean>(
    groupStatus !== GroupStatusType.UNSELECTED
  )

  const handleGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    subregions.map((subregion) =>
      setValue(`regions.${subregion.id}`, event.target.checked)
    )
  }

  const updateRegionValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedSubregionsCount = event.target.checked
      ? selectedSubregionsCount + 1
      : selectedSubregionsCount - 1

    setValue(`regions.${id}`, newSelectedSubregionsCount === subregions.length)
  }

  return (
    <div className={cx(styles.group)}>
      <RegionField
        id={id}
        name={name}
        className={cx({
          [styles.partial]: groupStatus === GroupStatusType.PARTIAL,
        })}
        onChange={handleGroupChange}
      />
      <div
        className={styles.toggle}
        data-testid="toggle"
        role="button"
        tabIndex={0}
        onClick={() => setIsOpen(!isOpen)}
        onKeyPress={() => setIsOpen(!isOpen)}
      >
        <Icon
          icon={isOpen ? 'ArrowUp' : 'ArrowDown'}
          height={30}
          width={30}
          title="expand"
        />
      </div>
      {isOpen && subregions.length > 0 && (
        <div className={styles.subregions}>
          {subregions.map(
            ({ id: subregionId, displayName, hide }) =>
              !hide && (
                <RegionField
                  key={subregionId}
                  id={subregionId}
                  onChange={updateRegionValue}
                  name={displayName}
                />
              )
          )}
        </div>
      )}
    </div>
  )
}

export default RegionGroup
