import * as React from 'react'

const PhoneDownFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14.182 13.975a1.75 1.75 0 011.846-.495l1.443.47a1.75 1.75 0 011.207 1.664v2.03c0 .627-.769 1.23-1.377 1.122-6.56-1.171-10.592-5.187-11.99-11.93a1.25 1.25 0 011.223-1.504h1.924a1.75 1.75 0 011.616 1.079l.608 1.463c.296.71.091 1.53-.503 2.02l-.161.132c.743 1.854 2.081 3.205 4.05 4.077l.114-.128zM6.78 6.54z" />
  </svg>
)

export default PhoneDownFilled
