import * as React from 'react'

const Layers = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11.987 20.957c-.333 0-.666-.096-.957-.285L3.8 15.948a1.734 1.734 0 01-.51-.511 1.734 1.734 0 01-.245-1.316c.097-.457.366-.85.758-1.104l.113-.075a.75.75 0 01.825 1.252l-.117.077a.26.26 0 00-.112.161.25.25 0 00.036.186c.019.03.044.056.073.075l7.23 4.723a.246.246 0 00.273-.001l7.192-4.724a.248.248 0 000-.417.75.75 0 11.82-1.255 1.74 1.74 0 01.756 1.819c-.094.458-.362.85-.752 1.107l-7.192 4.724a1.757 1.757 0 01-.961.288zm-.962-17.673a1.748 1.748 0 011.913.003l7.192 4.708a1.75 1.75 0 01.002 2.927l-7.19 4.724a1.75 1.75 0 01-1.919.002l-7.23-4.724a1.752 1.752 0 01.002-2.931l7.23-4.71zM4.613 9.25a.25.25 0 000 .418l7.23 4.724a.248.248 0 00.274 0l7.191-4.724a.25.25 0 000-.418l-7.192-4.708a.248.248 0 00-.273-.001L4.613 9.25z" />
  </svg>
)

export default Layers
