import React from 'react'

import { IMedkitComponent } from '..'

import { linkTheme } from './theme'
import getLinkClassNames from './utils/getLinkClassNames'

export const LinkButton: IMedkitComponent<
  LinkButtonProps,
  typeof linkTheme
> = ({
  children,
  className,
  isStandaloneLink,
  onClick,
  ...props
}: LinkButtonProps) => (
  <button
    className={getLinkClassNames({ isStandaloneLink, className })}
    onClick={onClick}
    type="button"
    {...props}
  >
    {children}
  </button>
)

LinkButton.theme = linkTheme

export interface LinkButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactChild | React.ReactChild[]
  isStandaloneLink?: boolean
  onClick?: () => void
}

export default LinkButton
