import * as React from 'react'

const Appointments = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.966 3H21a1 1 0 011 1v2H2V4a1 1 0 011-1h.034A.965.965 0 004 2.034V2a1 1 0 011.066-.998C5.598 1.036 6 1.5 6 2.034c0 .534.432.966.966.966h10.068A.965.965 0 0018 2.034V2a1 1 0 011.067-.998c.531.034.933.499.933 1.032 0 .534.432.966.966.966zM2 19a3 3 0 003 3h14a3 3 0 003-3V7H2v12zm14-6a3 3 0 110 6 3 3 0 010-6zm0 5a2 2 0 100-4 2 2 0 000 4z"
    />
  </svg>
)

export default Appointments
