import { getType } from '@/utils'

// This is a very primitive sanitiser, which ensures the types
// and lets array items slip through the cracks.
function sanitiseState(state, defaultState) {
  const defaultStateType = getType(defaultState)
  const stateType = getType(state)

  if (stateType !== defaultStateType) {
    return defaultState
  }

  // We leave arrays exposed to abuse here,
  // but there is no easy way to validate arrays
  // against the defaultState.
  // TODO: have state schema validation of some sort.
  // Most probably we'll never implement it.
  if (stateType !== 'object') {
    return state
  }

  return Object.fromEntries(
    Object.entries(defaultState).map(([key, item]) => [
      key,
      sanitiseState(state[key], item),
    ])
  )
}

function getInitialState({ name, defaultState }) {
  return Object.fromEntries(
    Object.entries(defaultState)
      // Try to JSON.parse each item. If failed - fall back to the default value.
      .map(([key, defaultItem]) => {
        const storeKey = `${name}:${key}`
        const itemJson = global.localStorage.getItem(storeKey)

        try {
          return [key, sanitiseState(JSON.parse(itemJson), defaultItem)]
        } catch {
          return [key, defaultItem]
        }
      })
  )
}

function createPersistentStore({
  name = 'defaultStore',
  defaultState = {},
} = {}) {
  const listeners = new Set()

  let state = getInitialState({ name, defaultState })

  const addListener = (callback) => {
    listeners.add(callback)

    return () => listeners.delete(callback)
  }

  const dispatch = () => {
    listeners.forEach((callback) => setTimeout(callback, 0, state))
  }

  const setState = (statePart) => {
    const newState = sanitiseState(statePart, state)
    const changedEntriesJson = Object.keys(statePart)
      // Filter out properties not present in the default state.
      .filter((key) => Object.prototype.hasOwnProperty.call(defaultState, key))
      // Map items to their JSON representation.
      .map((key) => [key, JSON.stringify(newState[key])])
      // Check if the entry has changed.
      .filter(([key, itemJson]) => JSON.stringify(state[key]) !== itemJson)

    if (!changedEntriesJson.length) {
      return
    }

    // Local storage needs to be in sync with the state.
    changedEntriesJson.forEach(([key, itemJson]) =>
      global.localStorage.setItem(`${name}:${key}`, itemJson)
    )

    state = newState

    dispatch()
  }

  const getState = () => state

  return {
    setState,
    getState,
    addListener,
  }
}

export default createPersistentStore
