import React from 'react'

import DateRangePicker, {
  DateRangePickerProps,
} from '../../foundation/DateRangePicker'

import './index.scss'

interface TimeRangePickerProps extends DateRangePickerProps {
  timeIntervals?: number
}

const LAST_MINUTE_DATE = (() => {
  const date = new Date()

  return new Date(date.setHours(23, 59))
})()

export default function TimeRangePicker({
  timeIntervals = 10,
  startDate,
  endDate,
  endPlaceholderText = 'End time',
  hasError,
  startPlaceholderText = 'Start time',
  onChange,
  'data-testid': dataTestId,
}: TimeRangePickerProps) {
  return (
    <DateRangePicker
      data-testid={dataTestId}
      dateFormat="HH:mm"
      timeFormat="HH:mm"
      endPlaceholderText={endPlaceholderText}
      hasError={hasError}
      injectTimes={[LAST_MINUTE_DATE]}
      onChange={onChange}
      startPlaceholderText={startPlaceholderText}
      showTimeSelect
      showTimeSelectOnly
      calendarClassName="core-time-picker"
      startDate={startDate}
      endDate={endDate}
      timeIntervals={timeIntervals}
    />
  )
}
