import * as React from 'react'

const PeopleInviteFriends = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14 11.5a4.75 4.75 0 004.75-4.75v-1a4.75 4.75 0 00-9.5 0v1A4.75 4.75 0 0014 11.5zm0 1.409c-1.59.01-3.026.231-4.286.651A6.485 6.485 0 0112 18.5a6.462 6.462 0 01-1.031 3.5H24c0-5.962-3.889-9.053-10-9.091zM5.5 13a5.5 5.5 0 100 11 5.5 5.5 0 100-11zm.353 8.354l-.707-.707L6.793 19H3v-1h3.793l-1.647-1.646.707-.707L8.707 18.5l-2.854 2.854z" />
  </svg>
)

export default PeopleInviteFriends
