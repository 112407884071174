import * as React from 'react'

const VideoOff = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.305 13.544a.75.75 0 011.5 0v3.502a1.75 1.75 0 01-1.75 1.75H9.621a.75.75 0 110-1.5h3.434a.25.25 0 00.25-.25v-3.502zm-9.788 3.502a.75.75 0 11-1.5 0V7.027c0-.966.784-1.75 1.75-1.75h9.288a1.745 1.745 0 011.377.67.75.75 0 01-1.18.926.245.245 0 00-.197-.096H3.767a.25.25 0 00-.25.25v10.019zm.112 4.457a.79.79 0 01-1.114-1.119L20.346 2.637a.79.79 0 011.114 1.119L3.63 21.503zm16.9-12.967l-1.826 1.008a.713.713 0 01-.689-1.247l2.882-1.593a.712.712 0 011.057.624v9.428c0 .55-.594.892-1.07.617l-4.54-2.634a.712.712 0 01-.355-.616v-3.252a.712.712 0 111.425 0v2.842l3.115 1.806V8.536z" />
  </svg>
)

export default VideoOff
