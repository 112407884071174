import React, { useState } from 'react'
import cn from 'classnames'
import { Eye, EyeClosed } from '@babylon/icons'
import styles from './index.module.scss'
import { IMedkitComponent } from '../..'
import BaseInput, { BaseInputProps } from '../BaseInput/BaseInput'
import { inputTheme } from './theme'

export const Input: IMedkitComponent<InputProps, typeof inputTheme> = ({
  className,
  inputClassName,
  type = 'text',
  endAdornment,
  field,
  name,
  label,
  value,
  ErrorMessages,
  ...props
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const ShowPassword = (
    <button
      className={cn(styles.Input__icon)}
      onClick={() => setShowPassword(!showPassword)}
      type="button"
      // TODO work out an i18n solution for the component library
      aria-label="Show password"
      aria-pressed={showPassword}
    >
      {showPassword ? (
        <Eye aria-hidden className={styles.Input__iconImage} />
      ) : (
        <EyeClosed aria-hidden className={styles.Input__iconImage} />
      )}
    </button>
  )
  const actualType = type === 'password' && showPassword ? 'text' : type

  return (
    <BaseInput
      {...props}
      label={label}
      value={value}
      name={name}
      className={cn(styles.Input, className)}
      inputClassName={cn(inputClassName)}
      type={actualType}
      endAdornment={type === 'password' ? ShowPassword : endAdornment}
      ErrorMessages={ErrorMessages}
    />
  )
}

Input.theme = inputTheme

export interface InputProps extends BaseInputProps<HTMLInputElement> {}

export default Input
