import { envVar } from '@babylon/babylon-env'

const deploymentRegion = envVar('DEPLOYMENT_REGION')
const env = envVar('ENVIRONMENT')

const regionToIsoCode: { [key: string]: string } = {
  uk: 'GBR',
  ca: 'CA',
  us: 'US',
}

const envToTealiumEnv: { [key: string]: string } = {
  localhost: 'dev',
  dev: 'dev',
  staging: 'qa',
  preprod: 'preprod',
  prod: 'prod',
}
const regionIsoCode = regionToIsoCode[deploymentRegion]
const appVersion = process.env.REACT_APP_VERSION

const getExtraProps = () => ({
  app_build: appVersion,
  app_environment: envToTealiumEnv[env],
  app_name: 'partner portal',
  country_iso_code: regionIsoCode,
  portal: 'monoweb',
})

export { getExtraProps, regionToIsoCode }
