import range from 'lodash/range'

/**
 * Get array of pagination numbers with seperator
 *
 * @param {Number} currentPageNumber Number of current page
 * @param {Number} totalPages Total pages
 * @param {String} seperator Seperator between pages when total pages is large
 * @param {Number} delta Delta to use for seperator (+/- current page number to show)
 * @returns {Array} Array of pagination items with sperator
 *
 * @see https://gist.github.com/kottenator/9d936eb3e4e3c3e02598
 */
export const getPaginationItemsWithDots = (
  currentPageNumber,
  totalPages,
  seperator = '...',
  delta = 2
) => {
  const left = currentPageNumber - delta
  const right = currentPageNumber + delta + 1
  const filterdPages = range(1, totalPages + 1).filter((i) => {
    return i === 1 || i === totalPages || (i >= left && i < right)
  })

  return filterdPages.reduce((prev, current, index) => {
    if (index > 0) {
      const prevIndexValue = filterdPages[index - 1]

      if (current - prevIndexValue === 2) {
        return [...prev, prevIndexValue + 1, current]
      } else if (current - prevIndexValue !== 1) {
        return [...prev, seperator, current]
      }
    }

    return [...prev, current]
  }, [])
}

export default {
  getPaginationItemsWithDots,
}
