import { useEffect } from 'react'

type NotifyCallback = () => void

const useUserActivityNotifier = (
  onNotify: NotifyCallback,
  throttle: number
) => {
  useEffect(() => {
    const onUserActivity = () => {
      removeUserActivityListeners()
      setTimeout(addUserActivityListeners, throttle)
      onNotify()
    }

    const addUserActivityListeners = () => {
      const options = { capture: true, once: true }
      document.addEventListener('click', onUserActivity, options)
      document.addEventListener('keydown', onUserActivity, options)
      document.addEventListener('mousemove', onUserActivity, options)
    }

    const removeUserActivityListeners = () => {
      const options = { capture: true }
      document.removeEventListener('click', onUserActivity, options)
      document.removeEventListener('keydown', onUserActivity, options)
      document.removeEventListener('mousemove', onUserActivity, options)
    }

    addUserActivityListeners()

    return () => {
      removeUserActivityListeners()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useUserActivityNotifier
