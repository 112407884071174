import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { envVar } from '@babylon/babylon-env'

import styles from './styles.module.scss'
import messages from './messages'

interface ErrorProps {
  children?: React.ReactNode
  error?: Error
}

const Error = ({ children, error }: ErrorProps) => {
  const fm = useFormatMessage()

  if (process.env.NODE_ENV !== 'test') {
    console.error(error)
  }

  const inProd = envVar('ENVIRONMENT') === 'prod'

  return (
    <div className={styles.error}>
      <>
        {inProd && (
          <p className={styles.errorText}>{fm(messages.networkError)}</p>
        )}
        {!inProd && (children || <p>{fm(messages.errorHeading)}</p>)}
        {!inProd && error && (
          <div className={styles.block}>{error.message}</div>
        )}
      </>
    </div>
  )
}

export default Error
