import * as React from 'react'

const HeartFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.086 6.052a5.896 5.896 0 011.742-1.818c.938-.633 2.03-.984 3.118-.984 2.908 0 5.367 2.683 5.367 5.785 0 3.918-1.914 6.967-5.064 9.22a17.743 17.743 0 01-4.537 2.326c-.21.071-.362.118-.45.142a.75.75 0 01-.399 0 8.889 8.889 0 01-.45-.142 17.743 17.743 0 01-4.537-2.326c-3.15-2.253-5.063-5.302-5.063-9.22 0-3.18 2.31-5.785 5.367-5.785 1.19 0 2.328.363 3.277 1.028a5.576 5.576 0 011.629 1.774z" />
  </svg>
)

export default HeartFilled
