import { defineMessages } from 'react-intl'

const baseId = 'shared.footer'

const messages = defineMessages({
  copywriteLabel: {
    id: `${baseId}.copywrite_label`,
    defaultMessage: '© Babylon {year}. ',
  },
  telusCopywriteLabel: {
    id: `${baseId}.telus_copywrite_label`,
    defaultMessage: 'TELUS Health MyCare {year}',
  },
  allRightsLabel: {
    id: `${baseId}.all_right_label`,
    defaultMessage: 'All rights reserved',
  },
})

export default messages
