import * as React from 'react'

const MediaVideoClip = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17 2v4h4l-4-4zm-1 0H4a1 1 0 00-1 1v18a1 1 0 001 1h16a1 1 0 001-1V7h-5V2zm-6 14v-6l5.196 3L10 16z" />
  </svg>
)

export default MediaVideoClip
