import React, { useState } from 'react'
import cn from 'classnames'

import { useFormatMessage } from '@babylon/intl'
import { Icon } from '@babylon/medkit'
import NavigationLink from '@/components/Navigation/NavigationLink'
import { Experience, Variant } from '@/components/Experiences'

import styles from './styles.module.scss'
import messages from './messages'

export default () => {
  const fm = useFormatMessage()
  const [isNavOpen, setNavOpen] = useState(false)

  const handleToggle = () => setNavOpen(!isNavOpen)

  return (
    <div className={cn(styles.nav, { [styles.open]: isNavOpen })}>
      {isNavOpen && (
        <div
          className={styles.overlay}
          role="button"
          tabIndex={0}
          onClick={handleToggle}
        />
      )}
      <div
        className={styles.toggle}
        role="button"
        tabIndex={0}
        onClick={handleToggle}
      >
        <Icon
          icon={(isNavOpen && 'NavigationClose') || 'NavigationMenu'}
          title="Main navigation"
        />
      </div>
      <nav className={styles.menu}>
        <Experience name="accessPartner">
          <Variant name="default">
            <NavigationLink
              to="/"
              onClick={handleToggle}
              isActive={(_, location: any) =>
                location.pathname === '/' ||
                location.pathname.startsWith('/partners/')
              }
              label={fm(messages.partnersLabel)}
            />
          </Variant>
        </Experience>
        <Experience name="viewUserManagement">
          <Variant name="default">
            <NavigationLink
              to="/user-management"
              onClick={handleToggle}
              label={fm(messages.userManagementLabel)}
            />
          </Variant>
        </Experience>
        <Experience name="createMemberManagement">
          <Variant name="default">
            <NavigationLink
              to="/member-management"
              onClick={handleToggle}
              label={fm(messages.memberManagementLabel)}
            />
          </Variant>
        </Experience>
        <Experience name="viewDataContract">
          <Variant name="default">
            <NavigationLink
              to="/data-contracts"
              onClick={handleToggle}
              label={fm(messages.dataContractLabel)}
            />
          </Variant>
        </Experience>
        <Experience name="viewReports">
          <Variant name="default">
            <NavigationLink
              to="/reports"
              onClick={handleToggle}
              label={fm(messages.reportsLabel)}
            />
          </Variant>
        </Experience>
      </nav>
    </div>
  )
}
