import * as React from 'react'

const Fingerprint = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17.493 5.096a.675.675 0 01-.332-.085C15.382 4.09 13.853 3.7 12.014 3.7c-1.837 0-3.57.44-5.142 1.309a.73.73 0 01-.982-.29.732.732 0 01.29-.985 12.058 12.058 0 015.834-1.484c2.051 0 3.848.456 5.812 1.472a.715.715 0 01.307.97.713.713 0 01-.64.404zM3.558 9.954a.726.726 0 01-.175-1.013 10.033 10.033 0 013.632-3.185c3.042-1.578 6.926-1.584 9.97-.01a10.067 10.067 0 013.63 3.164.727.727 0 01-.173 1.016.719.719 0 01-1.008-.174 8.633 8.633 0 00-3.113-2.716c-2.642-1.36-6.023-1.358-8.65.01a8.716 8.716 0 00-3.11 2.713.66.66 0 01-.586.326.744.744 0 01-.417-.13zm6.321 11.598a.683.683 0 01-.508-.217c-.913-.918-1.338-1.47-1.94-2.562-.671-1.204-1.023-2.67-1.023-4.245 0-2.962 2.514-5.37 5.597-5.37 3.082 0 5.596 2.408 5.596 5.37a.72.72 0 01-.722.725.719.719 0 01-.722-.725c0-2.158-1.86-3.92-4.152-3.92s-4.152 1.762-4.152 3.92c0 1.329.293 2.55.847 3.536.53.96.868 1.39 1.71 2.249a.741.741 0 01-.013 1.03.766.766 0 01-.518.209zm3.704-2.647c-1.476-1.006-2.357-2.641-2.357-4.377a.72.72 0 01.722-.725c.403 0 .722.321.722.725 0 1.259.641 2.444 1.724 3.175.625.426 1.36.631 2.258.631.244 0 .61-.033.937-.09a.72.72 0 01.84.59.722.722 0 01-.586.841c-.443.086-.87.119-1.19.119-1.176 0-2.217-.3-3.07-.889zm.98 2.818c-1.538-.428-2.559-1.009-3.623-2.06a7.202 7.202 0 01-2.123-5.135c0-1.68 1.419-3.043 3.16-3.043 1.74 0 3.159 1.363 3.159 3.043 0 .876.764 1.593 1.715 1.593.95 0 1.715-.717 1.715-1.593 0-3.44-2.955-6.238-6.6-6.238-2.59 0-4.955 1.449-6.018 3.686-.351.734-.532 1.6-.532 2.552 0 1.08.155 2.105.616 3.34a.713.713 0 01-.417.93.717.717 0 01-.93-.42 10.887 10.887 0 01-.705-3.85c0-1.174.224-2.246.667-3.185 1.299-2.74 4.175-4.513 7.32-4.513 4.434 0 8.043 3.444 8.043 7.689 0 1.68-1.418 3.043-3.16 3.043-1.74 0-3.159-1.363-3.159-3.043 0-.876-.764-1.594-1.715-1.594-.95 0-1.714.718-1.714 1.594 0 1.56.599 3.016 1.692 4.107.871.866 1.695 1.336 2.976 1.693a.719.719 0 01.512.874.711.711 0 01-.688.558.771.771 0 01-.19-.028z" />
  </svg>
)

export default Fingerprint
