import React from 'react'
import { ThemeProvider } from '@babylon/medkit'

const Layout = ({ children }) => {
  const theme = {}

  return (
    <ThemeProvider theme={theme} root>
      {children}
    </ThemeProvider>
  )
}

export default Layout
