import * as React from 'react'

const VideoCircleFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zm3.444-7.648l1.112 1.016c.245.223.444.135.444-.198V9.584c0-.339-.203-.427-.454-.208l-1.092.95c-.255.223-.454.665-.454.998v1.77c0 .328.199.784.444 1.008zM7.9 8.5a.9.9 0 00-.9.9v5.2a.9.9 0 00.9.9h5.2a.9.9 0 00.9-.9V9.4a.9.9 0 00-.9-.9H7.9z" />
  </svg>
)

export default VideoCircleFilled
