import React, { FormEventHandler } from 'react'
import cx from 'classnames'

import { Field, MedkitCheckboxWrapper } from '@/ui'

import styles from './RegionField.module.scss'

interface RegionFieldProps {
  id: string
  name: string
  className?: string
  onChange?: FormEventHandler<HTMLInputElement>
}

const RegionField = ({ id, name, className, onChange }: RegionFieldProps) => (
  <Field className={styles.regionField} name={`regions.${id}`} key={id}>
    <MedkitCheckboxWrapper
      id={id}
      className={cx(styles.regionCheckbox, className)}
      data-testid="checkbox"
      onChange={onChange}
    />
    <label htmlFor={id} className={styles.regionLabel}>
      {name}
    </label>
  </Field>
)

export default RegionField
