import React, { FormEventHandler } from 'react'
import { Controller, useFormContext, RegisterOptions } from 'react-hook-form'

import { Select as MedkitSelect } from '@babylon/medkit'

import { useFieldContext } from '../Field'

interface SelectOption {
  label: string
  value: string
  key?: string
}

interface SelectProps extends React.HTMLProps<HTMLSelectElement> {
  options: SelectOption[]
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  value?: string
  ref?: React.Ref<HTMLSelectElement>
  onChange?: FormEventHandler<HTMLSelectElement>
}

const MedkitSelectWrapper = React.forwardRef(
  (
    {
      name: propName,
      className,
      rules,
      onChange: propOnChange,
      ...props
    }: SelectProps,
    ref: React.Ref<HTMLSelectElement>
  ) => {
    const control = useFormContext()?.control
    const { name: contextName } = useFieldContext()
    const name = propName || contextName
    if (control && !name) {
      throw new Error('MedkitInputWrapper requires a name')
    }

    return control ? (
      <Controller
        control={control}
        name={name || ''}
        rules={rules}
        render={({ onChange, value }) => (
          <MedkitSelect
            onChange={(event) => {
              onChange(event)

              if (propOnChange) {
                propOnChange(event)
              }
            }}
            value={value}
            ref={ref}
            {...props}
          />
        )}
      />
    ) : (
      <MedkitSelect ref={ref} onChange={propOnChange} {...props} />
    )
  }
)

export default MedkitSelectWrapper
