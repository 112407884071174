import * as React from 'react'

const Attachment = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M9.051 15.011a.75.75 0 11-1.048-1.073l6.73-6.568a2.36 2.36 0 013.287 0c.92.897.919 2.359 0 3.257l-9.09 8.901a3.974 3.974 0 01-5.527 0 3.791 3.791 0 010-5.444l9.5-9.272c1.993-1.945 5.478-1.945 7.47 0 2.002 1.953 2.002 5.441 0 7.394l-6.73 6.568a.75.75 0 11-1.047-1.074l6.73-6.568c1.398-1.364 1.398-3.882-.001-5.247-1.41-1.376-3.964-1.376-5.374 0l-9.5 9.272a2.291 2.291 0 000 3.298 2.474 2.474 0 003.43 0l9.09-8.9a.77.77 0 00.001-1.112.86.86 0 00-1.191 0l-6.73 6.568z" />
  </svg>
)

export default Attachment
