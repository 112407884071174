import { useContext, useEffect } from 'react'
import { useForceUpdate } from '@/components/hooks'
import { PersistentStoreContext } from './core'

function usePersistentStore() {
  const persistentStore = useContext(PersistentStoreContext)
  const forceUpdate = useForceUpdate()

  useEffect(() => persistentStore.addListener(forceUpdate), [
    forceUpdate,
    persistentStore,
  ])

  return [persistentStore.getState(), persistentStore.setState]
}

export default usePersistentStore
