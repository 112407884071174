import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import moment from 'moment-timezone'

import { Window, Button } from '@/ui'

import messages from './messages'
import styles from './styles.module.scss'

interface Props {
  browserTimezone: string
  onCancel: () => void
  onContinue: () => void
}

function TimezoneUpdateDialog({
  browserTimezone,
  onCancel,
  onContinue,
}: Props) {
  const fm = useFormatMessage()
  const formattedBrowserTimezone = `(GMT ${moment(new Date())
    .tz(browserTimezone)
    .format('Z')}) ${browserTimezone}`

  return (
    <Window
      data-testid="timezoneUpdateDialog"
      className={styles.timezoneUpdateDialog}
    >
      <h2>
        {fm(messages.updateTimezoneHeading, {
          timezone: formattedBrowserTimezone,
        })}
      </h2>
      <p>{fm(messages.detectedDifferentTimezoneMessage)}</p>
      <p>{fm(messages.updateTimezoneMessage)}</p>
      <div className="bl-buttons bl-right">
        <Button
          onClick={onCancel}
          className="outline"
          data-testid="cancelConfirmation"
        >
          {fm(messages.cancelLabel)}
        </Button>
        <Button onClick={onContinue} data-testid="continueConfirmation">
          {fm(messages.continueLabel)}
        </Button>
      </div>
    </Window>
  )
}

export default TimezoneUpdateDialog
