import React from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, List } from 'react-virtualized'

const DropdownOptions = ({
  options,
  optionHeight,
  message,
  onOptionSelect,
}) => {
  const getOptionsListHeight = () => {
    const totalHeight = options.length * optionHeight
    return totalHeight > 200 ? 200 : totalHeight
  }

  const renderOption = ({ key, style, index }) => {
    const { value } = options[index]
    return (
      <div
        role="button"
        tabIndex={0}
        key={key}
        style={style}
        className="core-dropdown-select__option"
        onClick={() => {
          onOptionSelect(options[index])
        }}
        data-testid="dropdown-option"
      >
        <span className="core-dropdown-select__option-text">{value}</span>
      </div>
    )
  }

  return (
    <div
      className="core-dropdown-select__options-wrapper"
      data-testid="dropdown-options-wrapper"
    >
      {message && (
        <span className="core-dropdown-select__options-message">{message}</span>
      )}

      {!message && (
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              width={width}
              height={getOptionsListHeight()}
              rowHeight={optionHeight}
              rowCount={options.length}
              rowRenderer={renderOption}
              className="core-dropdown-select__options"
            />
          )}
        </AutoSizer>
      )}
    </div>
  )
}

export const optionPropType = PropTypes.shape({
  key: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  value: PropTypes.node,
})

DropdownOptions.propTypes = {
  options: PropTypes.arrayOf(optionPropType),
  optionHeight: PropTypes.number,
  message: PropTypes.string,
  onOptionSelect: PropTypes.func,
}

DropdownOptions.defaultProps = {
  optionHeight: 35,
  message: null,
}

export default DropdownOptions
