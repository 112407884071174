import * as React from 'react'

const Regulatory = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11.928 11.537c.926 0 1.686-.781 1.686-1.754 0-.974-.76-1.755-1.686-1.755-.926 0-1.686.781-1.686 1.755 0 .973.76 1.754 1.686 1.754zm-.095 1.5c-1.764 0-3.09-1.462-3.09-3.254 0-1.793 1.421-3.255 3.185-3.255s3.186 1.462 3.186 3.255c0 1.792-1.518 3.254-3.281 3.254zm6.064-5.375l.656.795a1.75 1.75 0 010 2.228l-.656.795a.25.25 0 00-.056.18l.09 1.048A1.75 1.75 0 0116.6 14.56l-.873.21a.25.25 0 00-.16.122l-.453.811a1.75 1.75 0 01-2.255.738l-.754-.344a.25.25 0 00-.208 0l-.753.347a1.75 1.75 0 01-2.259-.736l-.452-.81a.25.25 0 00-.16-.121l-.872-.211a1.75 1.75 0 01-1.333-1.852l.092-1.053a.25.25 0 00-.057-.181l-.656-.795a1.75 1.75 0 01-.002-2.227l.659-.8a.25.25 0 00.056-.181L6.069 6.43a1.75 1.75 0 011.337-1.854l.867-.207a.25.25 0 00.16-.121l.453-.81a1.75 1.75 0 012.255-.739l.755.345a.25.25 0 00.208 0l.755-.345a1.75 1.75 0 012.255.738l.453.812a.25.25 0 00.16.121l.873.211a1.75 1.75 0 011.332 1.852l-.091 1.047a.25.25 0 00.056.181zm-1.46-1.357a.25.25 0 00-.19-.265l-.873-.21a1.75 1.75 0 01-1.116-.848l-.454-.812a.25.25 0 00-.322-.105l-.755.345a1.75 1.75 0 01-1.454 0l-.755-.345a.25.25 0 00-.322.105l-.453.81a1.75 1.75 0 01-1.121.849l-.868.207a.25.25 0 00-.19.265l.09 1.046c.04.456-.1.91-.392 1.263l-.659.801a.25.25 0 000 .318l.657.796c.292.353.434.808.394 1.265l-.091 1.053a.25.25 0 00.19.265l.873.211a1.75 1.75 0 011.116.847l.452.81a.25.25 0 00.323.105l.754-.347a1.75 1.75 0 011.457-.002l.754.345a.25.25 0 00.322-.106l.454-.811a1.75 1.75 0 011.116-.847l.873-.211a.25.25 0 00.19-.265l-.09-1.047a1.75 1.75 0 01.393-1.265l.656-.795a.25.25 0 000-.318l-.656-.795a1.75 1.75 0 01-.393-1.265l.09-1.047zm-6.043 15.377a.75.75 0 01-1.062-.68l-.004-2.335a.75.75 0 01.422-.676l1.918-.933c.207-.1.45-.1.657.001l1.909.932a.75.75 0 01.42.673l.005 2.335a.75.75 0 01-1.062.683l-1.601-.732-1.602.732z" />
  </svg>
)

export default Regulatory
