import * as React from 'react'

const House = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10.786 20.575a.75.75 0 01-.75.75H5.533a2.25 2.25 0 01-2.25-2.257v-8.582h-.5c-.722 0-1.027-.918-.451-1.35l9.236-6.932a.75.75 0 01.9 0l9.236 6.932c.577.432.27 1.35-.45 1.35h-.434v8.582a2.25 2.25 0 01-2.24 2.257h-4.667a.75.75 0 01-.75-.75v-6.338h-2.377v6.338zm-1.5-.75v-6.338a.75.75 0 01.75-.75h3.877a.75.75 0 01.75.75v6.338h3.917c.405 0 .74-.339.74-.757V9.736c0-.155.047-.3.127-.419l-7.429-5.575-7.385 5.543c.094.125.15.281.15.45v9.333a.75.75 0 00.75.757h3.753zm-1.536 1.51v-1.5h8.5v1.5h-8.5z" />
  </svg>
)

export default House
