import * as React from 'react'

const PharmacyDeliveryFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M2.763 21.99a.75.75 0 01-.75-.75v-6a.75.75 0 01.75-.75h3.238a.75.75 0 01.75.75v6a.75.75 0 01-.75.75H2.763zm.75-1.5h1.738v-4.5H3.513v4.5zm.892-1.633a.417.417 0 00-.405.429c0 .236.181.428.405.428a.418.418 0 00.405-.428c0-.237-.183-.429-.405-.429m1.662-2.27a.75.75 0 01.171-1.48h2.833c1.189 0 2.151.41 2.962 1.107.183.158.347.32.519.506.07.076.271.303.303.336.07.075.123.16.157.251.282-.007.586-.02.912-.042 3.541 0 6.821 1.42 6.821 3.321a.75.75 0 01-.75.75H6.233a.75.75 0 110-1.5v-1.238c-.348 0-.403-1.525-.166-2.01zm9.537-7.879v1.208a.75.75 0 001.5 0V8.708h1.208a.75.75 0 100-1.5h-1.208V6a.75.75 0 00-1.5 0v1.208h-1.208a.75.75 0 000 1.5h1.208zm-3.414-6.74h8.12c.967 0 1.75.784 1.75 1.75v8.05a1.75 1.75 0 01-1.75 1.75h-8.12a1.75 1.75 0 01-1.75-1.75v-8.05c0-.966.783-1.75 1.75-1.75z" />
  </svg>
)

export default PharmacyDeliveryFilled
