import * as React from 'react'

const Child = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zm1.335-6.078a1.63 1.63 0 01-1.335.687 1.629 1.629 0 01-1.344-.698.75.75 0 00-1.232.855A3.129 3.129 0 0012 17.859c1.03 0 1.978-.5 2.56-1.32a.75.75 0 00-1.225-.867zM15.895 13c.854 0 1.544-.672 1.544-1.5 0-.83-.69-1.5-1.543-1.5s-1.544.67-1.544 1.5c0 .828.691 1.5 1.544 1.5zm-6.307-1.5c0-.83-.69-1.5-1.544-1.5-.852 0-1.544.67-1.544 1.5 0 .828.692 1.5 1.544 1.5.853 0 1.543-.672 1.543-1.5zM12 20.25a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5zM9.973 4.289c.05 1.009.466 2.088 1.263 3.258a.75.75 0 01-1.24.844c-1.037-1.523-1.552-3-1.528-4.407.015-.928 1.466-.485 1.505.305zm2.469-.81c.198.7.558 1.432 1.084 2.205a.75.75 0 01-1.24.844c-.79-1.16-1.276-2.293-1.453-3.39-.055-.343 1.507-.019 1.609.34z" />
  </svg>
)

export default Child
