import * as React from 'react'

const LogoutFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.389 5.23a.75.75 0 110-1.5h6.222a.75.75 0 01.75.75v15.04a.75.75 0 01-.75.75H12.39a.75.75 0 110-1.5V5.23zM5.223 20.251a.75.75 0 01-.584-.731V4.479a.75.75 0 01.584-.731l7-1.584a.75.75 0 01.916.732v18.208a.75.75 0 01-.916.732l-7-1.584zm.916-1.33l5.5 1.244V3.834l-5.5 1.244v13.844zm4.694-6.526c0 .655-.522 1.187-1.166 1.187A1.178 1.178 0 018.5 12.396c0-.656.523-1.188 1.167-1.188s1.166.532 1.166 1.188z" />
  </svg>
)

export default LogoutFilled
