import { useCallback, useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { getTokenInfo } from './helpers'
import { getAuthConfig } from '../auth-utils'

const useTokenInfo = () => {
  const [tokenInfo, setTokenInfo] = useState(getTokenInfo())

  const onUpdateToken = useCallback(() => {
    const updatedTokenInfo = getTokenInfo()

    if (!isEqual(tokenInfo, updatedTokenInfo)) {
      setTokenInfo(updatedTokenInfo)
    }
  }, [tokenInfo, setTokenInfo])

  useEffect(() => {
    const intervalId = setInterval(
      onUpdateToken,
      getAuthConfig().cookiePollTime
    )

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  })

  return tokenInfo
}

export default useTokenInfo
