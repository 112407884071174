import { useContext } from 'react'
import ModalContext from './ModalContext'
import { ModalAction, IModalAction } from './modalReducer'
import { ModalProps } from './Modal.types'

type OpenModal = (
  component: React.ReactChild,
  modalProps?: ModalProps,
  replace?: boolean
) => void
type CloseModal = () => void
type UseModalActions = () => {
  closeModal: CloseModal
  openModal: OpenModal
  isModalOpen: boolean
}

const useModalActions: UseModalActions = () => {
  const { dispatch, state } = useContext(ModalContext)
  const isModalOpen = state?.isModalOpen

  const closeModal: CloseModal = () => {
    const action: IModalAction = {
      type: ModalAction.CLOSE_MODAL,
    }
    dispatch(action)
  }

  const openModal: OpenModal = (
    component,
    modalProps = {},
    replace = false
  ) => {
    const action: IModalAction = {
      type: ModalAction.OPEN_MODAL,
      payload: {
        component,
        modalProps,
        replace,
      },
    }
    dispatch(action)
  }

  return { closeModal, openModal, isModalOpen }
}

export default useModalActions
