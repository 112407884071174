import React from 'react'
import { OptionData } from 'rc-select/lib/interface'
import RcTreeSelect, {
  TreeSelectProps as RcTreeSelectProps,
} from 'rc-tree-select'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import cn from 'classnames'

import { useFormatMessage } from '@babylon/intl'
import { Icon } from '@babylon/medkit'

import { useFieldContext } from '../Field'
import messages from './messages'
import styles from './styles.module.scss'

export interface TreeSelectProps extends RcTreeSelectProps {
  name?: string
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  readOnly?: boolean
  loadingLabel?: React.ReactNode
  multiple?: boolean
}

const TreeSelect = ({
  name: propName,
  rules,
  readOnly,
  loadingLabel,
  multiple,
  placeholder,
  showArrow = false,
  ...props
}: TreeSelectProps) => {
  const fm = useFormatMessage()
  const control = useFormContext()?.control
  const { name: contextName } = useFieldContext()
  const name = propName || contextName

  if (control && !name) {
    throw new Error('Select requires a name')
  }

  const getByValue = (value: string) =>
    props.treeData?.find((item) => value === item.value) || {
      // TODO: remove this ugly hack
      value,
      label: `-${value}`.replace(/-(.)/g, (_, c) => c.toUpperCase()),
    }

  return control ? (
    <Controller
      render={({ onChange, value }) =>
        readOnly ? (
          <div className={cn(styles.select, styles['select-read-only'])}>
            {props.loading && (
              <span>{loadingLabel || fm(messages.loading)}</span>
            )}
            {!props.loading &&
              multiple &&
              (typeof value[0] === 'string'
                ? value
                    .map(getByValue)
                    .map(({ value, label }: OptionData) => (
                      <span key={value}>{label}</span>
                    ))
                : value.map(({ value, label }: OptionData) => (
                    <span key={value}>{label}</span>
                  )))}
            {!props.loading && !multiple && (
              <span>{getByValue(value)?.label}</span>
            )}
          </div>
        ) : (
          <RcTreeSelect
            prefixCls={styles.select}
            value={value}
            onChange={onChange}
            showArrow={showArrow}
            placeholder={
              <span className={styles.placeholder}>
                {placeholder}
                <Icon
                  icon="NavigationSearch"
                  title="Search icon"
                  height={20}
                  className={styles.iconWrapper}
                  iconClassName={styles.icon}
                />
              </span>
            }
            {...props}
          />
        )
      }
      name={name || ''}
      control={control}
      rules={rules}
    />
  ) : (
    <RcTreeSelect
      prefixCls={styles.select}
      showArrow={showArrow}
      placeholder={
        <span
          className={cn(styles.placeholder, {
            [styles.placeholderWithoutText]: !placeholder,
          })}
        >
          {placeholder}
          <Icon
            icon="NavigationSearch"
            title="Search icon"
            height={20}
            className={styles.iconWrapper}
            iconClassName={styles.icon}
          />
        </span>
      }
      {...props}
    />
  )
}

export default TreeSelect
