import { useState, useCallback } from 'react'

const NO_PARAM = Symbol() as any

const useModal = <T = boolean>(
  defaultState: T = NO_PARAM
): [T, (state?: any) => void, () => void] => {
  const [state, setState] = useState<T>(
    defaultState === NO_PARAM ? (false as any) : defaultState
  )

  const show = useCallback(
    (state: T = NO_PARAM) =>
      setState(defaultState === NO_PARAM ? (true as any) : state),
    [defaultState]
  )

  const hide = useCallback(() => setState(false as any), [setState])

  return [state, show, hide]
}

export default useModal
