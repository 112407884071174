import * as React from 'react'

const Refresh = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M2.16 7.722c.386-3.295 2.85-6.18 6.215-6.975a7.826 7.826 0 017.67 2.434.75.75 0 01-1.123.994A6.326 6.326 0 008.72 2.207c-3.005.709-5.133 3.476-5.11 6.464L5.19 6.78a.75.75 0 111.151.961l-2.67 3.198a.75.75 0 01-1.199-.062L.15 7.417a.75.75 0 011.245-.836l.766 1.14zm15.391-1.245a.75.75 0 01.397.458l2.168 3.23a.75.75 0 11-1.245.836l-.896-1.334c-.543 2.999-2.899 5.595-5.996 6.326a7.832 7.832 0 01-7.308-2.049.75.75 0 111.053-1.069 6.332 6.332 0 005.91 1.658c2.635-.622 4.615-2.927 4.918-5.497l-1.476 1.768a.75.75 0 01-1.152-.961l2.672-3.199a.75.75 0 01.955-.167z" />
  </svg>
)

export default Refresh
