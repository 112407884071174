import React from 'react'
import cx from 'classnames'

import styles from './KebabMenuItem.module.scss'

interface KebabMenuItemProps {
  onClick?: () => void
  itemName?: string
  className?: string
  children?: React.ReactElement | React.ReactNodeArray
}

const KebabMenuItem: React.FC<KebabMenuItemProps> = ({
  onClick,
  itemName,
  className,
  children,
  ...rest
}) => (
  <div
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex={0}
    className={cx(styles.kebabMenuItem, className)}
    {...rest}
  >
    {children || itemName}
  </div>
)

export default KebabMenuItem
