import React from 'react'
import cn from 'classnames'

import { Icon } from '@babylon/medkit'

import styles from './styles.module.scss'
import { StepType } from './StepForm'

interface StepFormGuideProps {
  title: string
  steps: Partial<StepType>[]
  activeStepIndex: number
}

const StepFormGuide = ({
  title,
  steps,
  activeStepIndex,
}: StepFormGuideProps) => {
  return (
    <>
      <div className={styles.sidebarTitle}>{title}</div>
      <ol className={styles.stepper}>
        {steps.map((step, index) => (
          <li
            className={styles.stepperElement}
            aria-current={activeStepIndex === index ? 'step' : undefined}
            key={step.label}
          >
            <div className={styles.stepperContent}>
              <div
                className={cn(styles.stepperCircle, {
                  [styles.stepperCircle_active]: activeStepIndex >= index,
                })}
              >
                {activeStepIndex > index && (
                  <Icon
                    icon="TickCircleFilled"
                    title="Step"
                    width={48}
                    height={48}
                    iconClassName={styles.stepperCurrentStep}
                  />
                )}

                {activeStepIndex === index && (
                  <div className={styles.stepperActiveStep} />
                )}
              </div>
              <span className={styles.Step__label}>{step.label}</span>
            </div>
            {index < steps.length - 1 && (
              <div className={styles.stepperLineWrapper}>
                <div
                  className={cn(styles.stepperLine, {
                    [styles.stepperLine_active]: index < activeStepIndex,
                  })}
                />
              </div>
            )}
          </li>
        ))}
      </ol>
    </>
  )
}

export default StepFormGuide
