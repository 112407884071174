import * as React from 'react'

const SubscriptionFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M20.046 18.821v1.381c0 .943-.775 1.71-1.714 1.71H5.713A1.718 1.718 0 014 20.203V3.691c0-.946.763-1.707 1.717-1.707h8.07v5.341c0 .393.32.712.713.712h5.546v4.642a2.812 2.812 0 00-1.483-.429c-.59 0-1.177.18-1.683.503a3.194 3.194 0 00-.613.511 3.017 3.017 0 00-.566-.487 3.178 3.178 0 00-1.764-.527c-1.668 0-2.937 1.36-2.937 3.016 0 1.922.984 3.411 2.578 4.495a8.926 8.926 0 002.482 1.164.75.75 0 00.38 0 8.926 8.926 0 002.481-1.165c.42-.284.797-.597 1.125-.939zM15.212 1.984h.382c.19 0 .373.076.507.212l3.74 3.785a.712.712 0 01.205.5v.131h-4.833V1.984zm1.462 17.277a7.348 7.348 0 01-.424.156 7.446 7.446 0 01-1.828-.897c-1.219-.828-1.922-1.892-1.922-3.254 0-.85.621-1.516 1.437-1.516.343 0 .666.098.933.276.252.168.441.398.543.659.062.156.065.165.074.197.01.03.013.057.013.123 0 1 1.5 1 1.5 0 0-.059.003-.079.011-.105.01-.03.007-.022.08-.195a1.55 1.55 0 01.598-.689 1.64 1.64 0 01.874-.266c.754 0 1.437.708 1.437 1.516 0 1.362-.703 2.426-1.922 3.254a7.446 7.446 0 01-1.404.74z" />
  </svg>
)

export default SubscriptionFilled
