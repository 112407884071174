import React, { FunctionComponent } from 'react'

interface SvgProps {
  alt?: string
  className?: string
}

const RightArrowSVG: FunctionComponent<SvgProps> = ({ children, ...rest }) => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" {...rest}>
    <title>Right arrow</title>
    <g
      id="ic_datepicker-"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group" transform="translate(2.000000, 4.000000)" fill="#82888A">
        <rect id="Rectangle-5" x="0" y="4" width="12" height="1" />
        <path
          d="M10.863961,1.94974747 L11.863961,1.94974747 L11.863961,6.94974747 L6.86396103,6.94974747 L6.86396103,5.94974747 L10.863961,5.94974747 L10.863961,1.94974747 Z"
          id="Combined-Shape"
          transform="translate(9.363961, 4.449747) rotate(-45.000000) translate(-9.363961, -4.449747) "
        />
      </g>
    </g>
  </svg>
)

export default RightArrowSVG
