import React, { useCallback } from 'react'
import cx from 'classnames'
import copy from 'clipboard-copy'

import { Icon } from '@babylon/medkit'
import { useSnackbarBag } from '@/components/SnackbarBag'

import styles from './styles.module.scss'

interface OneClickCopyTextProps {
  value: string
  className?: string
  tooltipMessage: string
  successMessage: string
  errorMessage: string
}

interface SnackbarBagMessages {
  successMessage: string
  errorMessage: string
}

function OneClickCopyText({
  value,
  className,
  tooltipMessage,
  ...rest
}: OneClickCopyTextProps) {
  const snackbarBag = useSnackbarBag()
  const showSnackbarBag = useCallback(
    (action) =>
      snackbarBag.add({
        message: rest[`${action}Message` as keyof SnackbarBagMessages],
        type: action,
      }),
    [snackbarBag, rest]
  )
  const handleCopy = useCallback(() => {
    snackbarBag.removeAll()

    copy(value)
      .then(() => showSnackbarBag('success'))
      .catch(() => showSnackbarBag('error'))
  }, [snackbarBag, value, showSnackbarBag])

  return (
    <button
      type="button"
      className={styles.main}
      title={tooltipMessage}
      onClick={handleCopy}
    >
      <span className={cx(styles.value, className)}>{value}</span>
      <Icon
        icon="FilesCopy"
        title="Copy icon"
        width={12}
        height={12}
        className={styles.iconWrapper}
      />
    </button>
  )
}

export default OneClickCopyText
