import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppointmentCategoryDetails } from '@/domains/AppointmentConfiguration/types'
import { RootState } from '../store'

interface CreateAppointmentCategoriesData {
  categories: AppointmentCategoryDetails[]
  countForId: number
}
const initialState: CreateAppointmentCategoriesData = {
  categories: [],
  countForId: 0,
}

export const createAppointmentCategories = createSlice({
  name: 'createAppointmentCategories',
  initialState,
  reducers: {
    addAppointmentCategory: (
      state,
      action: PayloadAction<AppointmentCategoryDetails>
    ) => {
      return {
        ...state,
        countForId: state.countForId + 1,
        categories: [...state.categories, action.payload],
      }
    },
    removeAppointmentCategory: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        categories: state.categories.filter(
          (category) => category.uuid !== action.payload
        ),
      }
    },
    editAppointmentCategory: (
      state,
      action: PayloadAction<AppointmentCategoryDetails>
    ) => {
      const categoriesTemp = state.categories.filter(
        (category) => category.uuid !== action.payload.uuid
      )
      categoriesTemp.push(action.payload)
      return {
        ...state,
        categories: categoriesTemp,
      }
    },
    clearAppointmentCategories: (state) => {
      return {
        ...state,
        countForId: 0,
        categories: [],
      }
    },
  },
})

export const getAppointmentCategories = (state: RootState) =>
  state.createAppointmentCategories.categories
