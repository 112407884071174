import * as React from 'react'

const MicroOffFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16.25 10.1a.75.75 0 111.5 0v1.542a5.75 5.75 0 01-7.32 5.533.75.75 0 11.409-1.443 4.25 4.25 0 005.411-4.09V10.1zm-8.235 3.024a.75.75 0 11-1.406.523 5.711 5.711 0 01-.359-2.005V8a.75.75 0 011.5 0v3.642a4.249 4.249 0 00.265 1.482zm.735-1.226V5.5a3.25 3.25 0 016.489-.271c.007.09.011.422.011.513 0 .555-.098.759-.347 1-2.4 2.319-4.213 4.228-5.44 5.414-.372.36-.713.225-.713-.258zm4 8.595h1.487a.75.75 0 110 1.5h-4.49a.75.75 0 010-1.5h1.503V16.75a.75.75 0 111.5 0v3.742zm2.5-8.993A3.25 3.25 0 0112 14.75c-.314 0-.27-.18-.114-.338l3.025-3.084c.283-.289.339-.079.339.172zm5.318-9.21a.75.75 0 011.063 1.058L3.351 21.736a.75.75 0 01-1.063-1.058l18.28-18.387z" />
  </svg>
)

export default MicroOffFilled
