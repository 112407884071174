import * as React from 'react'

const Linegraph = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M5.338 16.5c0-.41-.35-.75-.794-.75-.444 0-.794.34-.794.75 0 .409.351.75.794.75.443 0 .794-.34.794-.75zm1.5 0c0 1.248-1.032 2.25-2.294 2.25S2.25 17.747 2.25 16.5c0-1.248 1.032-2.25 2.294-2.25 1.263 0 2.294 1.001 2.294 2.25zm3.7-4.5c0-.41-.35-.75-.794-.75-.444 0-.794.34-.794.75 0 .409.351.75.794.75.443 0 .793-.34.793-.75zm1.5 0c0 1.248-1.032 2.25-2.294 2.25S7.45 13.247 7.45 12c0-1.248 1.032-2.25 2.294-2.25 1.263 0 2.293 1.001 2.293 2.25zm4.8 1.5c0-.41-.35-.75-.794-.75-.444 0-.794.34-.794.75 0 .409.351.75.794.75.443 0 .793-.34.793-.75zm1.5 0c0 1.248-1.032 2.25-2.294 2.25s-2.294-1.003-2.294-2.25c0-1.248 1.032-2.25 2.294-2.25 1.263 0 2.293 1.001 2.293 2.25zm2-5.5c0-.41-.35-.75-.794-.75-.444 0-.794.34-.794.75 0 .409.351.75.794.75.443 0 .793-.34.793-.75zm-1.2 2.215l-1.525 2.412a.75.75 0 01-1.267-.802l1.478-2.337A2.215 2.215 0 0117.25 8c0-1.248 1.032-2.25 2.294-2.25 1.263 0 2.293 1.001 2.293 2.25 0 1.248-1.03 2.25-2.293 2.25-.138 0-.274-.012-.405-.035zM6.323 16.122a.75.75 0 11-1.004-1.115l2.646-2.383a.75.75 0 011.004 1.114l-2.646 2.384zm4.592-2.986l.45-1.43 3.33 1.045-.45 1.431-3.33-1.046z" />
  </svg>
)

export default Linegraph
