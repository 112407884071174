import * as React from 'react'

const Chat = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21 14H8c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h11l2.293 2.293c.63.63 1.707.184 1.707-.707V16c0-1.1-.9-2-2-2zm-2-4V3c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v11.586c0 .891 1.077 1.337 1.707.707L5.477 12H17c1.1 0 2-.9 2-2zm-4-3H5V6h10v1z" />
  </svg>
)

export default Chat
