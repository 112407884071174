import * as React from 'react'

const PeopleMedicalSpecialist = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16 13.469v2.581a2.5 2.5 0 012 2.45V20h-1v-1.5c0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5 1.5V20h-1v-1.5a2.5 2.5 0 011.992-2.448v-2.838A15.043 15.043 0 0012 12.909a15.113 15.113 0 00-3 .307v3.876A1.496 1.496 0 018.5 20a1.497 1.497 0 01-.508-2.906v-3.623C4.243 14.61 2 17.476 2 22h20c0-4.527-2.246-7.394-6-8.531zM12 11.5a4.75 4.75 0 004.75-4.75v-1a4.75 4.75 0 00-9.5 0v1A4.75 4.75 0 0012 11.5z" />
  </svg>
)

export default PeopleMedicalSpecialist
