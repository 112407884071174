import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useFormatMessage } from '@babylon/intl'
import { ButtonSimple, ButtonVariant, LinkButton } from '@babylon/medkit'

import { Form } from '@/ui'
import Sidebar, { useSidebarContext } from '@/components/Sidebar'
import { useSetShowHeader } from '@/components/Layout'

import StepFormGuide from './StepFormGuide'
import SummaryStep from './SummaryStep'
import messages from './messages'
import styles from './styles.module.scss'

export interface StepType {
  label: string
  component: React.ReactElement
  summary: React.ReactElement
  description?: string
}

interface StepFormProps {
  defaultValues: object
  title: string
  onSubmit: SubmitHandler<any>
  backUrl: string
  steps: StepType[]
  busy?: boolean
}

const StepForm = ({
  defaultValues,
  title,
  steps,
  onSubmit,
  backUrl,
  busy = false,
}: StepFormProps) => {
  const fm = useFormatMessage()
  const history = useHistory()
  const [activeStepIndex, setActiveStepIndex] = useState(0)
  const [isCurrentStepValid, setIsCurrentStepValid] = useState(false)
  const { setShowHeader = () => {} } = useSetShowHeader()

  const {
    setShow: setShowSidebar = () => {},
    setAlign: setAlignSidebar = () => {},
  } = useSidebarContext()

  useEffect(() => setShowSidebar(true), [setShowSidebar])
  useEffect(() => setAlignSidebar('left'), [setAlignSidebar])
  useEffect(() => {
    setShowHeader(false)

    return () => setShowHeader(true)
  }, [setShowHeader])

  const context = useForm({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues,
  })

  const stepsWithSummary = useMemo(
    () => [
      ...steps,
      {
        label: fm(messages.summaryLabel),
        component: (
          <SummaryStep
            steps={steps}
            busy={busy}
            setActiveStepIndex={setActiveStepIndex}
          />
        ),
        description: undefined,
      },
    ],
    [fm, steps, busy, setActiveStepIndex]
  )

  const lastIndex = stepsWithSummary.length - 1

  const previousStep = useCallback(
    () => setActiveStepIndex(activeStepIndex - 1),
    [activeStepIndex, setActiveStepIndex]
  )

  const nextStep = useCallback(() => {
    if (activeStepIndex < lastIndex - 1) {
      setIsCurrentStepValid(false)
    }
    setActiveStepIndex(activeStepIndex + 1)
  }, [activeStepIndex, setActiveStepIndex, lastIndex])

  const buttons = useMemo(
    () =>
      activeStepIndex === lastIndex
        ? [
            <LinkButton key="cancel" onClick={() => history.push(backUrl)}>
              {fm(messages.cancelLabel)}
            </LinkButton>,
            <ButtonSimple
              key="done"
              type="submit"
              disabled={busy}
              isLoading={busy}
              loadingLabel={fm(messages.creatingLabel)}
            >
              {fm(messages.doneLabel)}
            </ButtonSimple>,
          ]
        : [
            <div className={styles.buttonGroup} key="buttonGroup">
              <ButtonSimple
                key="back"
                onClick={previousStep}
                variant={ButtonVariant.secondary}
                disabled={busy || activeStepIndex === 0}
              >
                {fm(messages.previousLabel)}
              </ButtonSimple>
              <LinkButton
                className={styles.cancelLink}
                key="cancel"
                onClick={() => history.push(backUrl)}
              >
                {fm(messages.cancelSetupLabel)}
              </LinkButton>
            </div>,
            <ButtonSimple
              key="next"
              onClick={nextStep}
              disabled={busy || !isCurrentStepValid}
              isLoading={busy}
            >
              {fm(messages.nextLabel)}
            </ButtonSimple>,
          ],
    [
      fm,
      activeStepIndex,
      nextStep,
      previousStep,
      isCurrentStepValid,
      busy,
      lastIndex,
      history,
      backUrl,
    ]
  )

  return (
    <>
      <Form className={styles.form} context={context} onSubmit={onSubmit}>
        <div className={styles.formContent}>
          <h1>{stepsWithSummary[activeStepIndex]?.label}</h1>
          {stepsWithSummary[activeStepIndex]?.description && (
            <h2>{stepsWithSummary[activeStepIndex]?.description}</h2>
          )}
          {React.cloneElement(stepsWithSummary[activeStepIndex]?.component, {
            setIsValid: setIsCurrentStepValid,
          })}
        </div>
        <div className={styles.buttons}>{buttons}</div>
      </Form>
      <Sidebar>
        <StepFormGuide
          title={title}
          steps={stepsWithSummary}
          activeStepIndex={activeStepIndex}
        />
      </Sidebar>
    </>
  )
}

export default StepForm
