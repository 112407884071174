import React, { ReactNode } from 'react'
import cn from 'classnames'

import { colors } from '../../../theme'
import './Text.scss'

interface Props {
  align?: 'left' | 'center' | 'right'
  bold?: boolean
  children: ReactNode
  className?: string
  color?: keyof typeof colors
  ellipsis?: boolean
  italic?: boolean
  size?: 'small' | 'medium' | 'large'
  style?: React.CSSProperties
  tag?:
    | typeof React.Component
    | 'span'
    | 'div'
    | 'p'
    | 'pre'
    | 'code'
    | 'blockquote'
    | 'sup'
  uppercase?: boolean
}

const Text = ({
  align,
  bold,
  className,
  color,
  ellipsis,
  italic,
  size,
  style,
  tag: Component = 'span',
  uppercase,
  ...props
}: Props) => (
  <Component
    className={cn(
      'core-text',
      align && `core-text--align-${align}`,
      bold && 'core-text--bold',
      bold === false && 'core-text--not-bold',
      ellipsis && 'core-text--ellipsis',
      italic && 'core-text--italic',
      italic === false && 'core-text--not-italic',
      uppercase && 'core-text--uppercase',
      uppercase === false && 'core-text--not-uppercase',
      size && `core-text--${size}`,
      className
    )}
    style={{
      ...style,
      color: color ? colors[color] : undefined,
    }}
    {...props}
  />
)

export default Text
