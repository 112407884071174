import * as React from 'react'

const PhoneCircleFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zm1.505-8.356l-.305.38c-1.28-.622-2.148-1.606-2.624-2.965a.14.14 0 01.032-.145l.324-.312a.98.98 0 00.22-1.03l-.46-1.26c-.124-.34-.42-.562-.748-.562H8.809c-.447 0-.809.406-.809.906 0 .04.002.079.007.118.57 4.883 3.134 7.615 7.612 8.117.204.023.381-.157.381-.387v-1.852c0-.39-.224-.738-.555-.86l-1.084-.403a.753.753 0 00-.856.255z" />
  </svg>
)

export default PhoneCircleFilled
