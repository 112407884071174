import * as React from 'react'

const Heart = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.765 4.234c.939-.633 2.03-.984 3.118-.984 2.908 0 5.367 2.683 5.367 5.785 0 3.918-1.914 6.967-5.064 9.22a17.743 17.743 0 01-4.537 2.326 8.454 8.454 0 01-.45.142.75.75 0 01-.399 0 8.889 8.889 0 01-.449-.142 17.743 17.743 0 01-4.537-2.326c-3.15-2.253-5.064-5.302-5.064-9.22 0-3.18 2.31-5.785 5.367-5.785 1.191 0 2.329.363 3.277 1.028a5.576 5.576 0 011.629 1.774 5.896 5.896 0 011.742-1.818zM12.167 19.16a16.261 16.261 0 004.146-2.126c2.788-1.994 4.437-4.62 4.437-8 0-2.301-1.818-4.285-3.867-4.285-.782 0-1.582.258-2.279.727a4.29 4.29 0 00-1.594 1.945c-.17.429-.161.404-.194.511-.046.154-.147.378-.218.597-.302.925-.933.925-1.222-.075-.066-.228-.145-.383-.19-.543-.03-.11-.038-.13-.181-.514a4.04 4.04 0 00-1.472-1.891 4.195 4.195 0 00-2.416-.757C4.93 4.75 3.25 6.645 3.25 9.035c0 3.38 1.649 6.006 4.436 8A16.261 16.261 0 0012 19.216l.167-.055z" />
  </svg>
)

export default Heart
