import * as React from 'react'

const PeopleFamily = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18.5 8.774a2.612 2.612 0 002.612-2.612v-.55a2.612 2.612 0 00-5.225 0v.55A2.613 2.613 0 0018.5 8.774zm-13-2a2.613 2.613 0 002.613-2.612v-.55a2.612 2.612 0 00-5.225-.001v.55A2.612 2.612 0 005.5 6.774zm13 2.775c-1.365.008-2.514.308-3.415.864a3.595 3.595 0 01-1.192 2.452c2.611.742 4.107 2.754 4.107 5.684v4h6v-8c0-3.279-2.139-4.979-5.5-5zm-9.393 3.316a3.606 3.606 0 01-1.219-2.704v-.55c0-.551.134-1.068.356-1.536-.784-.33-1.695-.52-2.744-.526-3.361.021-5.5 1.721-5.5 5v10h5v-4c0-2.93 1.496-4.942 4.107-5.684zm2.393.684c-3.361.021-5.5 1.721-5.5 5v4h11v-4c0-3.279-2.139-4.979-5.5-5zm0-.775a2.613 2.613 0 002.613-2.613v-.55a2.613 2.613 0 00-5.226 0v.55a2.613 2.613 0 002.613 2.613z" />
  </svg>
)

export default PeopleFamily
