import React from 'react'

import { useFormatMessage } from '@babylon/intl'
import { Experience, Variant } from '@/components/Experiences'
import styles from './styles.module.scss'
import messages from './messages'

export default () => {
  const fm = useFormatMessage()
  const year = new Date().getFullYear()

  return (
    <footer className={styles.footer}>
      <Experience name="footerName">
        <Variant name="default">
          {fm(messages.copywriteLabel, { year })}
          {fm(messages.allRightsLabel)}
        </Variant>
        <Variant name="telus">
          {fm(messages.telusCopywriteLabel, { year })}
        </Variant>
      </Experience>
    </footer>
  )
}
