import get from 'lodash/fp/get'
import React, { FC, ReactNode } from 'react'
import { Field, FieldProps } from 'formik'

import Label from '../Label'
import { Checkbox, Text } from '../../foundation'
import { CheckboxProps } from '../../foundation/Checkbox'

import './index.scss'

export interface FormikCheckboxProps extends CheckboxProps {
  id: string
  name: string
  dataTestId?: string
  label?: React.ReactNode
  validate?: (value: any) => undefined | string | Promise<any>
  children?: ReactNode | HTMLElement | string
}

const FormikCheckbox: FC<FormikCheckboxProps> = ({
  id,
  name,
  label,
  validate,
  children,
  dataTestId,
  ...props
}) => (
  <Field name={name} validate={validate}>
    {({
      form: { errors, touched },
      field: { value, onChange, onBlur },
    }: FieldProps) => {
      const fieldName = get(name)
      const displayError = fieldName(touched) && !!fieldName(errors)
      const errorMessage = fieldName(errors)

      return (
        <div
          className="core-form-field core-ui-formik-checkbox"
          data-testid={dataTestId}
        >
          {label && <Label htmlFor={id}>{label}</Label>}

          <Checkbox
            name={name}
            value={String(value)}
            checked={String(value) === 'true'}
            onChange={onChange}
            onBlur={onBlur}
            id={id}
            {...props}
          >
            <Text>{children}</Text>
          </Checkbox>

          {displayError && (
            <span className="core-form-text-field__errorMessage">
              {errorMessage}
            </span>
          )}
        </div>
      )
    }}
  </Field>
)

export default FormikCheckbox
