import React, { ReactNode, CSSProperties } from 'react'
import cn from 'classnames'

import Heading from '../Heading'
import './Card.scss'
import { Tag } from '../../candidates'

interface Props {
  actions?: ReactNode
  className?: string
  children?: ReactNode
  padding?: 'none' | 'sm' | 'lg' | 'xlg'
  style?: CSSProperties
  title?: string
  'data-testid'?: string
  tags?: string[]
}

const Card = ({
  actions,
  children,
  className,
  padding,
  title,
  'data-testid': dataTestId,
  style = {},
  tags,
}: Props) => (
  <div
    className={cn('core-card', className, {
      [`core-card--padding-${padding}`]: padding,
    })}
    style={style}
    data-testid={dataTestId}
  >
    {(title || actions) && (
      <div className="core-card__header">
        {title && (
          <Heading level="h2" className="core-card__title">
            {title}
            {tags?.map((tag) => (
              <Tag
                margin={false}
                key={tag}
                color="light-teal"
                className="core-card__tag"
              >
                {tag}
              </Tag>
            ))}
          </Heading>
        )}

        {actions && <div className="core-card__actions">{actions}</div>}
      </div>
    )}

    {children}
  </div>
)

export default Card
