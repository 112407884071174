import * as React from 'react'

const AccountPrivacy = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.971 11V3a1 1 0 00-1-1H3.002a1 1 0 00-1 1v8.067c-.007.62-.038 3.56 2.315 6.592 2.747 3.539 6.872 5.091 7.67 5.39.797-.299 4.922-1.851 7.67-5.39 2.354-3.032 2.323-5.973 2.316-6.592V11zM9.151 7.25a2.85 2.85 0 015.7 0v.6a2.85 2.85 0 01-5.7 0v-.6zM6.002 17c0-3.577 2.333-5.432 6-5.454 3.668.023 6 1.877 6 5.454h-12zm6-4.455c-2.057.014-4.468.654-4.923 3.455h9.848c-.456-2.801-2.867-3.441-4.924-3.455zm1.85-4.695c0 1.02-.83 1.85-1.85 1.85s-1.85-.83-1.85-1.85v-.6c0-1.02.83-1.85 1.85-1.85s1.85.83 1.85 1.85v.6z"
    />
  </svg>
)

export default AccountPrivacy
