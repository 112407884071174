import React, { Component } from 'react'
import cn from 'classnames'
import get from 'lodash/get'
import { Form, Field } from 'formik'
import { Button, FormikTextField } from '@babylon/core-ui'
import { GPPracticePostcodeLookup } from '..'

import './index.scss'

class UpdatePatientGpDetailsForm extends Component {
  /**
   * Handle postcode look up change
   *
   * @param {Object} result Selected result
   */
  handlePostcodeLookupChange = ({ raw }) => {
    const { values, setValues } = this.props

    setValues({
      ...values,
      surgery_name: raw.name,
      address_first_line: raw.address_first_line,
      address_second_line: raw.address_second_line,
      address_third_line: raw.address_third_line,
      address_post_code: raw.address_post_code,
    })
  }

  render() {
    const { isSubmitting, fetchPostcodeResults, i18nMessages } = this.props

    return (
      <div className="UpdatePatientGpDetailsForm">
        <Form>
          {typeof fetchPostcodeResults === 'function' && (
            <div className="UpdatePatientGpDetailsForm__row">
              <div className="UpdatePatientGpDetailsForm__column">
                <GPPracticePostcodeLookup
                  id="postcode_lookup"
                  label={get(i18nMessages, 'gp_practice_postcode_lookup.label')}
                  labelInfoText={get(
                    i18nMessages,
                    'gp_practice_postcode_lookup.info_text'
                  )}
                  placeholder={get(
                    i18nMessages,
                    'gp_practice_postcode_lookup.placeholder'
                  )}
                  loadingMessage={get(
                    i18nMessages,
                    'gp_practice_postcode_lookup.loading'
                  )}
                  invalidPostcodeMessage={get(
                    i18nMessages,
                    'gp_practice_postcode_lookup.invalid_postcode'
                  )}
                  noResultsFoundMessage={get(
                    i18nMessages,
                    'gp_practice_postcode_lookup.no_results_found'
                  )}
                  fetchPostcodeResults={fetchPostcodeResults}
                  onChange={this.handlePostcodeLookupChange}
                />
              </div>
            </div>
          )}

          <div className="UpdatePatientGpDetailsForm__row">
            <div
              className={cn(
                'UpdatePatientGpDetailsForm__column',
                'UpdatePatientGpDetailsForm__column--half',
                'UpdatePatientGpDetailsForm__column--gpName'
              )}
            >
              <Field
                id="gp_name"
                type="text"
                name="gp_name"
                placeholder={get(i18nMessages, 'gp_name.label', 'Name')}
                label={get(i18nMessages, 'gp_name.placeholder', 'GP Name')}
                component={FormikTextField}
              />
            </div>
          </div>

          <div className="UpdatePatientGpDetailsForm__row">
            <div
              className={cn(
                'UpdatePatientGpDetailsForm__column',
                'UpdatePatientGpDetailsForm__column--half'
              )}
            >
              <Field
                id="surgery_name"
                type="text"
                name="surgery_name"
                placeholder={get(
                  i18nMessages,
                  'surgery_name.placeholder',
                  'Name'
                )}
                label={get(i18nMessages, 'surgery_name.label', 'Surgery Name')}
                component={FormikTextField}
              />
            </div>

            <div
              className={cn(
                'UpdatePatientGpDetailsForm__column',
                'UpdatePatientGpDetailsForm__column--half'
              )}
            >
              <Field
                id="surgery_phone_number"
                type="text"
                name="surgery_phone_number"
                placeholder={get(
                  i18nMessages,
                  'surgery_phone_number.placeholder',
                  'Phone Number'
                )}
                label={get(
                  i18nMessages,
                  'surgery_phone_number.label',
                  'Surgery Phone Number'
                )}
                component={FormikTextField}
              />
            </div>
          </div>

          <div className="UpdatePatientGpDetailsForm__row">
            <div className="UpdatePatientGpDetailsForm__column">
              <Field
                id="address_first_line"
                type="text"
                name="address_first_line"
                placeholder={get(
                  i18nMessages,
                  'address_first_line.placeholder',
                  'Address line 1'
                )}
                label={get(
                  i18nMessages,
                  'address_first_line.label',
                  'Surgery Address'
                )}
                component={FormikTextField}
              />

              <Field
                id="address_second_line"
                type="text"
                name="address_second_line"
                placeholder={get(
                  i18nMessages,
                  'address_second_line.placeholder',
                  'Address line 2'
                )}
                component={FormikTextField}
              />

              <Field
                id="address_third_line"
                type="text"
                name="address_third_line"
                placeholder={get(
                  i18nMessages,
                  'address_third_line.placeholder',
                  'Address line 3'
                )}
                component={FormikTextField}
              />
            </div>
          </div>

          <div className="UpdatePatientGpDetailsForm__row">
            <div
              className={cn(
                'UpdatePatientGpDetailsForm__column',
                'UpdatePatientGpDetailsForm__column--half'
              )}
            >
              <Field
                id="address_post_code"
                type="text"
                name="address_post_code"
                placeholder={get(
                  i18nMessages,
                  'address_post_code.placeholder',
                  'Postcode'
                )}
                label={get(i18nMessages, 'address_post_code.label', 'Postcode')}
                component={FormikTextField}
              />
            </div>
          </div>

          <div className="UpdatePatientGpDetailsForm__row">
            <div className="UpdatePatientGpDetailsForm__column">
              <div className="UpdatePatientGpDetailsForm__buttons">
                <div className="UpdatePatientGpDetailsForm__buttonWrapper">
                  <Button
                    type="reset"
                    intent="secondary"
                    disabled={isSubmitting}
                  >
                    {get(i18nMessages, 'cancel_button.label', 'Cancel')}
                  </Button>
                </div>

                <div className="UpdatePatientGpDetailsForm__buttonWrapper">
                  <Button
                    type="submit"
                    intent="primary"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {get(i18nMessages, 'save_button.label', 'Save')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export default UpdatePatientGpDetailsForm
