import * as React from 'react'

const DeleteFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M4.817 20.114V5.27a.75.75 0 01.75-.75h12.771a.75.75 0 01.75.75v14.843c0 .953-.79 1.74-1.752 1.74H6.57a1.75 1.75 0 01-1.752-1.74zM7.773 8.74v8.52a.75.75 0 101.5 0V8.74a.75.75 0 10-1.5 0zm3.477.023v8.48a.75.75 0 001.5 0v-8.48a.75.75 0 10-1.5 0zm3.5-.034v8.56a.75.75 0 101.5 0V8.73a.75.75 0 10-1.5 0zM8.253 4.52v-.75c0-.968.78-1.753 1.745-1.753h4.038c.96 0 1.746.788 1.746 1.754v.75h4.494a.75.75 0 010 1.5H3.693a.75.75 0 110-1.5h4.56zm1.5 0h4.529v-.75a.255.255 0 00-.246-.253H9.998a.249.249 0 00-.245.254v.75z" />
  </svg>
)

export default DeleteFilled
