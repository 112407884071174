import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ButtonSimple, ButtonVariant } from '@babylon/medkit'

const visible = (isVisible: boolean) =>
  isVisible ? undefined : { display: 'none' }

export enum QuickEditButtonsMode {
  VIEW = 'view',
  EDIT = 'edit',
}

export interface QuickEditButtonsOptions {
  mode: QuickEditButtonsMode
  editLabel?: string
  cancelLabel?: string
  saveLabel?: string
  savingLabel?: string
  saveDisabled?: boolean
  disabled?: boolean
  busy?: boolean
  setMode: (mode: QuickEditButtonsMode) => void
  hideCancel?: boolean
}

const QuickEditButtons = ({
  mode,
  setMode,
  editLabel = 'Edit',
  cancelLabel = 'Cancel',
  saveLabel = 'Save',
  savingLabel = 'Saving',
  saveDisabled,
  disabled,
  busy,
  hideCancel = false,
}: QuickEditButtonsOptions) => {
  const {
    formState: { isDirty, isValid },
  } = useFormContext()

  const saveButtonDisabled = saveDisabled === true || !isDirty || !isValid

  return (
    <div className="bl-buttons">
      <div style={visible(mode === QuickEditButtonsMode.VIEW)}>
        <ButtonSimple
          data-testid="editButton"
          onClick={() => setMode(QuickEditButtonsMode.EDIT)}
          disabled={disabled}
          isLoading={busy}
          loadingLabel={savingLabel}
        >
          {editLabel}
        </ButtonSimple>
      </div>
      <div style={visible(mode === QuickEditButtonsMode.EDIT)}>
        {!hideCancel && (
          <ButtonSimple
            type="reset"
            variant={ButtonVariant.secondary}
            data-testid="cancelButton"
            onClick={() => setMode(QuickEditButtonsMode.VIEW)}
          >
            {cancelLabel}
          </ButtonSimple>
        )}
        <ButtonSimple
          type="submit"
          disabled={saveButtonDisabled}
          isLoading={busy}
          loadingLabel={savingLabel}
          data-testid="saveButton"
          onClick={() => {
            setMode(QuickEditButtonsMode.VIEW)
          }}
        >
          {saveLabel}
        </ButtonSimple>
      </div>
    </div>
  )
}

export default QuickEditButtons
