export type SnackbarTypeType = 'success' | 'error'

interface AddSnackbarProps {
  message: string
  type: SnackbarTypeType
}

interface SnackbarObjectType {
  id: number
  message: string
  type: SnackbarTypeType
  hasTimeout: boolean
  timeout: number
  cancelTimeout: () => void
  startTimeout: () => void
  remove: (snackbar: SnackbarObjectType) => void
}

type ListenerType = (...args: any[]) => void

export interface SnackbarBagType {
  add: (props: AddSnackbarProps) => SnackbarObjectType
  all: () => SnackbarObjectType[]
  removeAll: () => void
  addListener: (callback: ListenerType) => () => void
}

function createSnackbarBag(): SnackbarBagType {
  const defaultTimeout = 5000
  const snackbars = new Set<SnackbarObjectType>()
  const listeners = new Set<ListenerType>()
  let autoId = 0

  const createId = () => {
    autoId += 1

    return autoId
  }

  const all = () => Array.from(snackbars)

  const dispatch = () => {
    listeners.forEach((callback: ListenerType) =>
      setTimeout(callback, 0, all())
    )
  }

  const remove = (snackbar: SnackbarObjectType) => {
    snackbar.cancelTimeout()
    snackbars.delete(snackbar)
    dispatch()
  }

  const addListener = (callback: ListenerType) => {
    listeners.add(callback)

    return () => listeners.delete(callback)
  }

  const removeAll = () => {
    snackbars.forEach((snackbar: SnackbarObjectType) =>
      snackbar.cancelTimeout()
    )
    snackbars.clear()
    dispatch()
  }

  const add = ({ message, type }: AddSnackbarProps) => {
    let timeoutId: any

    const hasTimeout = type === 'success'

    const snackbar: SnackbarObjectType = Object.freeze({
      id: createId(),
      message,
      type,
      hasTimeout,
      timeout: hasTimeout ? defaultTimeout : 0,
      cancelTimeout: () => clearTimeout(timeoutId),
      startTimeout: () => {
        if (snackbar.hasTimeout) {
          timeoutId = setTimeout(remove, snackbar.timeout, snackbar)
        }
      },
      remove,
    })

    snackbar.startTimeout()
    snackbars.add(snackbar)
    dispatch()

    return snackbar
  }

  return {
    add,
    all,
    removeAll,
    addListener,
  }
}

export default createSnackbarBag
