import * as React from 'react'

const NavigationForward = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8.663 21.716l9.052-9.03a.97.97 0 000-1.373L8.663 2.284a.977.977 0 00-1.377 0 .969.969 0 000 1.374L15.649 12l-8.363 8.342a.969.969 0 000 1.374.977.977 0 001.377 0z" />
  </svg>
)

export default NavigationForward
