import { defineMessages } from 'react-intl'

const baseId = 'actions_menu'

const messages = defineMessages({
  helpAndSupportLabel: {
    id: `${baseId}.help_and_support_label`,
    defaultMessage: 'Partner Portal Help and Support',
  },
  privacyLabel: {
    id: `${baseId}.privacy_label`,
    defaultMessage: 'Privacy',
  },
})

export default messages
