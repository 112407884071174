import React, { useContext } from 'react'
import cx from 'classnames'

import { PopoverContext } from './core'

import styles from './styles.module.scss'

interface PopoverMenuProps {
  className?: string
  position?: 'left' | 'right'
  children: React.ReactNode
}

const PopoverMenu = ({
  className,
  position = 'left',
  children,
}: PopoverMenuProps) => {
  const { isOpen } = useContext(PopoverContext)

  return isOpen ? (
    <div className={cx(styles.popoverMenu, styles[position], className)}>
      {children}
    </div>
  ) : (
    <></>
  )
}

export default PopoverMenu
