import React from 'react'

interface PopoverContextType {
  isOpen: boolean
  toggleIsOpen: () => void
}

const PopoverContext = React.createContext<PopoverContextType>({
  toggleIsOpen: () => {},
  isOpen: false,
})

export { PopoverContext }
