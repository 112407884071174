import * as React from 'react'

const BoxFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.622 11.08c-.24.108-.476.476-.476.738v8.121l6.732-3.06c.24-.108.476-.477.476-.739V8.02l-6.732 3.06zm3.36-3.176l1.871-.85-6.285-3.05c-.284-.137-.847-.135-1.133.006l-1.298.64 6.845 3.254zm-1.786.812L8.42 5.494 5.285 7.037l5.058 2.108 2.001.78a.25.25 0 00.194-.005l2.01-.91a.765.765 0 01.007-.003l.641-.291zm6.274-1.891c.238.248.384.605.384 1.035v8.28c0 .85-.58 1.753-1.355 2.105l-6.877 3.126a1.794 1.794 0 01-.185.072l-.351.158a1.75 1.75 0 01-1.385.02l-.42-.172a1.827 1.827 0 01-.102-.04l-.866-.36-1.1-.454a.759.759 0 01-.07-.033l-5.607-2.337c-.798-.332-1.39-1.224-1.39-2.085V7.86c0-.459.167-.836.435-1.09a.747.747 0 01.248-.195l7.944-3.91c.7-.346 1.75-.35 2.45-.01l7.906 3.835a.74.74 0 01.34.335zm-3.187 4.234c-.25.1-.5.306-.71.573a2.783 2.783 0 00-.491.97c-.053.192-.082.284-.082.477 0-.195-.027-.266-.077-.42-.09-.274-.251-.476-.46-.582-.21-.107-.47-.116-.749-.006-.709.28-1.214 1.24-1.214 2.119 0 2.299 2.503 2.81 2.503 2.81s2.5-1.69 2.497-4.639c-.001-.955-.564-1.559-1.217-1.302z" />
  </svg>
)

export default BoxFilled
