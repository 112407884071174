import React from 'react'
import PropTypes from 'prop-types'

import DropdownDisplayValue from './DropdownDisplayValue'

const DropdownSearch = ({
  selectedText,
  value,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  ...rest
}) => {
  return (
    <div className="core-dropdown-select__input-search-wrapper">
      {selectedText ? (
        <DropdownDisplayValue text={selectedText} />
      ) : (
        <span className="core-dropdown-select__input-search-placeholder">
          {placeholder}
        </span>
      )}

      <input
        value={value}
        className="core-dropdown-select__input-field"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        autoComplete="off"
        {...rest}
      />
    </div>
  )
}

DropdownSearch.propTypes = {
  selectedText: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

export default DropdownSearch
