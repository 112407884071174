import { createAction } from '@reduxjs/toolkit'
import { Configurations } from '@babylon/graphql-middleware-types'

export const setConfiguration = createAction<Configurations>(
  'configuration/setConfiguration'
)

export const setConfigurationCategories = createAction<Configurations>(
  'configuration/setConfigurationCategories'
)

export const setMediumConfiguration = createAction<Configurations>(
  'configuration/setMediumConfiguration'
)
