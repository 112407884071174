import * as React from 'react'

const ClinicalRecordsFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M20.102 7.703c.973 0 1.752.783 1.752 1.753V17.2c0 1.737-1.398 3.15-3.125 3.15H4.875a2.739 2.739 0 01-2.73-2.75V5.392c0-.96.788-1.742 1.742-1.742h3.56c.91 0 1.656.708 1.734 1.6h8.543c.968 0 1.755.788 1.755 1.754V7.6a.704.704 0 01-.007.103h.63zM18.73 18.85c.895 0 1.625-.738 1.625-1.65V9.456a.249.249 0 00-.252-.253H7.856a.246.246 0 00-.252.246v8.15c0 .45-.107.875-.298 1.25H18.73zm-3.945-5.838h1.278a.75.75 0 110 1.5h-1.278v1.237a.75.75 0 01-1.5 0v-1.237H11.98a.75.75 0 010-1.5h1.303v-1.247a.75.75 0 011.5 0v1.247z" />
  </svg>
)

export default ClinicalRecordsFilled
