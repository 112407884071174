import * as React from 'react'

const FilesProgress = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M2 3v18a1 1 0 001 1h18a1 1 0 001-1V3a1 1 0 00-1-1H3a1 1 0 00-1 1zm18 17H4V4h1v11.467l3.758-3.758 2.66 2.66 7.752-7.752.707.707-8.459 8.459-2.66-2.66L5 16.881V19h15v1z" />
  </svg>
)

export default FilesProgress
