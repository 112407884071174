import { defineMessages } from 'react-intl'

const baseId = 'shared.hooks'

const messages = defineMessages({
  forbiddenError: {
    id: `${baseId}.forbidden_label`,
    defaultMessage: "You don't have permissions for this action",
  },
})

export default messages
