import * as React from 'react'

const SearchError = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 48 48"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M31.333 31.433a1.525 1.525 0 01.104-.104A16.624 16.624 0 0019.629 3a16.626 16.626 0 1011.704 28.434zm3.193.968l13.037 13.038a1.5 1.5 0 01-2.121 2.122L32.406 34.523a19.626 19.626 0 112.12-2.122zM19.503 17.379l5.303-5.304a1.5 1.5 0 012.121 2.122L21.624 19.5l5.303 5.303a1.5 1.5 0 11-2.121 2.122l-5.303-5.304-5.304 5.304a1.5 1.5 0 01-2.121-2.122L17.38 19.5l-5.303-5.303a1.5 1.5 0 012.121-2.122l5.304 5.304z" />
  </svg>
)

export default SearchError
