import * as React from 'react'

const Directions = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.645 11.25l-.854-.893a.75.75 0 011.085-1.036l2.066 2.16a.75.75 0 01-.014 1.05l-2.065 2.056a.75.75 0 11-1.058-1.063l.777-.774H11a.25.25 0 00-.25.25v1.648a.75.75 0 11-1.5 0V13c0-.966.784-1.75 1.75-1.75h2.645zm-.436-8.07l7.483 7.512a1.75 1.75 0 010 2.47l-7.482 7.516a1.75 1.75 0 01-2.475.006l-7.552-7.517a1.752 1.752 0 010-2.48l7.552-7.513c.713-.681 1.801-.67 2.474.006zm-8.968 8.57a.25.25 0 000 .353l7.552 7.518a.25.25 0 00.353-.001l7.483-7.517a.25.25 0 000-.352l-7.483-7.513a.25.25 0 00-.353 0L4.241 11.75z" />
  </svg>
)

export default Directions
