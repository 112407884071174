import * as React from 'react'

const Phone = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M15.308 14.692a1.75 1.75 0 011.851-.491l2.347.777a1.75 1.75 0 011.2 1.661v2.231c0 1.022-.497 1.692-1.285 1.95-.302.1-.543.122-.963.119-9.749-.976-14.871-6.315-15.164-15.825V5.06c0-.966.783-1.75 1.75-1.75h2.56c.71 0 1.35.43 1.62 1.087l.995 2.43a1.75 1.75 0 01-.478 1.99l-.7.603a.25.25 0 00-.07.28c1.03 2.625 2.91 4.525 5.678 5.725l.66-.733zm3.727 1.71l-2.347-.777a.25.25 0 00-.264.07l-1.014 1.127a.75.75 0 01-.83.196c-3.412-1.333-5.762-3.6-7.005-6.77a1.75 1.75 0 01.488-1.966l.7-.601a.25.25 0 00.068-.285l-.996-2.43a.25.25 0 00-.231-.156h-2.56a.25.25 0 00-.25.25c.269 8.756 4.805 13.484 13.738 14.382.196 0 .311-.01.42-.046.18-.06.254-.16.254-.526v-2.23a.25.25 0 00-.171-.237z" />
  </svg>
)

export default Phone
