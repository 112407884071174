import React from 'react'
import cn from 'classnames'
import {
  useForm,
  SubmitHandler,
  UseFormMethods,
  FormProvider,
} from 'react-hook-form'

import styles from './styles.module.scss'

interface FormProps extends Omit<React.HTMLProps<HTMLFormElement>, 'onSubmit'> {
  context?: UseFormMethods<any>
  onSubmit?: SubmitHandler<any>
  className?: string
  defaultValues?: {}
}

const Form = ({
  context,
  onSubmit,
  className,
  defaultValues = {},
  ...props
}: FormProps) => {
  const newContext = useForm({ defaultValues })
  const formContext = (context || newContext) as UseFormMethods<any>

  return (
    <FormProvider {...formContext}>
      <form
        className={cn(styles.form, className)}
        onSubmit={onSubmit && formContext.handleSubmit(onSubmit)}
        {...props}
      />
    </FormProvider>
  )
}

export default Form
