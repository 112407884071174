import * as React from 'react'

const DirectionsFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.645 11.25H11A1.75 1.75 0 009.25 13v1.648a.75.75 0 101.5 0V13a.25.25 0 01.25-.25h2.582l-.777.774a.75.75 0 001.058 1.063l2.065-2.055a.75.75 0 00.014-1.05L13.876 9.32a.75.75 0 10-1.085 1.036l.854.893zm-.436-8.07l7.483 7.512a1.75 1.75 0 010 2.47l-7.482 7.516a1.75 1.75 0 01-2.475.006l-7.552-7.517a1.752 1.752 0 010-2.48l7.552-7.513c.713-.681 1.801-.67 2.474.006z" />
  </svg>
)

export default DirectionsFilled
