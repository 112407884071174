import React, { useRef, useCallback } from 'react'
import cn from 'classnames'
import { useFormatMessage } from '@babylon/intl'
import { Button, Window } from '@/ui'
import messages from './messages'

import styles from './Preview.module.scss'

const Preview = ({ content, onClose }) => {
  const fm = useFormatMessage()
  const elRef = useRef()
  const handleResize = useCallback((size) => {
    elRef.current.style.width = `${size}${(size !== 'auto' && 'px') || ''}`
  }, [])

  return (
    <Window onClose={onClose} closeButtonLabel={`${fm(messages.close)} ✕`}>
      <div className={styles.preview}>
        <div className={cn('bl-buttons', styles.preset)}>
          <Button className="outline" onClick={() => handleResize(375)}>
            Mobile
          </Button>
          <Button className="outline" onClick={() => handleResize(768)}>
            Tablet
          </Button>
          <Button className="outline" onClick={() => handleResize('auto')}>
            Desktop
          </Button>
        </div>
        <div
          ref={elRef}
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </Window>
  )
}

export default Preview
