import React from 'react'
import { NavLink, LinkProps } from 'react-router-dom'

import { IMedkitComponent } from '..'

import { LinkAnchor } from '.'
import { linkTheme } from './theme'
import getLinkClassNames from './utils/getLinkClassNames'
import isExternalURL from '../utils/isExternalURL'

import styles from './link.module.scss'

export const LinkReactRouter: IMedkitComponent<
  LinkReactRouterProps,
  typeof linkTheme
> = ({
  children,
  className,
  isStandaloneLink,
  to,
  onClick,
  ...props
}: LinkReactRouterProps) =>
  typeof to === 'string' && isExternalURL(to) ? (
    <LinkAnchor className={className} href={to} {...props}>
      {children}
    </LinkAnchor>
  ) : (
    <NavLink
      activeClassName={styles.LinkActive}
      className={getLinkClassNames({ isStandaloneLink, className })}
      onClick={onClick}
      to={to}
      {...props}
    >
      {children}
    </NavLink>
  )

LinkReactRouter.theme = linkTheme

export interface LinkReactRouterProps
  extends React.ButtonHTMLAttributes<HTMLAnchorElement | HTMLButtonElement> {
  children?: React.ReactChild | React.ReactChild[]
  isStandaloneLink?: boolean
  to: string | LinkProps<any>['to']
}

export default LinkReactRouter
