import React from 'react'

function createModalStack(rootNodeId) {
  let autoId = 0
  const elements = new Map()
  const listeners = new Set()
  const dispatch = () => {
    listeners.forEach((callback) => setTimeout(callback, 0))
  }
  const renderModal = (id, element) => {
    elements.set(id, element)
    dispatch()
  }
  const closeModal = (id) => {
    elements.delete(id)
    dispatch()
  }
  const createId = () => {
    autoId += 1

    return autoId
  }
  const addListener = (callback) => {
    listeners.add(callback)

    return () => listeners.delete(callback)
  }
  const createModal = (Component) => {
    const id = createId()
    const close = () => closeModal(id)

    return {
      close,
      render: (props) =>
        renderModal(id, <Component onClose={close} {...props} />),
    }
  }

  const getElements = () => elements

  return {
    addListener,
    createModal,
    getElements,
    getRootNodeId: () => rootNodeId,
  }
}

export default createModalStack
