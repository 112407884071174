import * as React from 'react'

const InternetError = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 48 48"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M2.56 47.56a1.5 1.5 0 01-2.12-2.12l45-45a1.5 1.5 0 012.12 2.12l-45 45zM24 48a7.5 7.5 0 110-15 7.5 7.5 0 010 15zm0-3a4.5 4.5 0 100-9 4.5 4.5 0 000 9zm11.66-16.167a1.5 1.5 0 01-2.12 2.122 13.454 13.454 0 00-4.055-2.784 1.5 1.5 0 111.218-2.742 16.454 16.454 0 014.958 3.404zM22.41 15.05a1.5 1.5 0 11.183 2.994 22.4 22.4 0 00-14.5 6.546 1.5 1.5 0 11-2.125-2.118A25.4 25.4 0 0122.41 15.05zm19.621 7.421a1.5 1.5 0 11-2.122 2.12 22.534 22.534 0 00-4.745-3.63 1.5 1.5 0 011.492-2.604 25.534 25.534 0 015.375 4.114zM30.173 6.556a1.5 1.5 0 11-.538 2.952 31.468 31.468 0 00-27.112 7.95 1.5 1.5 0 11-2.046-2.193 34.468 34.468 0 0129.696-8.709zm17.35 8.71a1.5 1.5 0 01-2.046 2.193 31.428 31.428 0 00-4.654-3.59 1.5 1.5 0 011.602-2.537 34.428 34.428 0 015.098 3.933z" />
  </svg>
)

export default InternetError
