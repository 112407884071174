import React from 'react'
import cn from 'classnames'

import styles from './List.module.scss'

interface ListProps {
  headers?: { name: string; label: string }[]
  rows?: []
  component: any
  emptyMessage?: string
}

const List = ({
  headers = [],
  rows = [],
  component: C,
  emptyMessage = '',
}: ListProps) => {
  return (
    <div className={styles.list}>
      {headers?.length > 0 && (
        <div className={styles.header}>
          {headers.map((i: any) => (
            <div
              key={i.name}
              className={cn(styles.column, { [styles.wide]: i.wide })}
            >
              {i.label}
            </div>
          ))}
        </div>
      )}
      {(!rows || !rows.length) && <span>{emptyMessage}</span>}
      {rows && rows.length > 0 && (
        <div className={styles.content}>
          {rows.map((r, i) => (
            <C key={`item_${i}`} {...r} />
          ))}
        </div>
      )}
    </div>
  )
}

export default List
