import { useContext } from 'react'

import context from './modalContext'

const useModalContext = () => {
  const ctx = useContext(context)
  if (!ctx?.root) {
    throw new Error('Modal can be used inside of a ModalStack')
  }
  return ctx
}

export default useModalContext
