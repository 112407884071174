import * as React from 'react'

const CloseCircle = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11.996 10.795l3.592-3.539a.9.9 0 111.264 1.282l-3.574 3.52 3.574 3.52a.9.9 0 11-1.264 1.283l-3.592-3.54-3.593 3.54A.9.9 0 017.14 15.58l3.573-3.52L7.14 8.54a.9.9 0 011.263-1.283l3.593 3.54zM12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zm0-1.5a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5z" />
  </svg>
)

export default CloseCircle
