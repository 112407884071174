import { createReducer } from '@reduxjs/toolkit'
import { Configurations } from '@babylon/graphql-middleware-types'
import {
  setConfiguration,
  setConfigurationCategories,
  setMediumConfiguration,
} from '../actions/configuration'

const initialState: Configurations = {
  id: '',
  name: '',
  medium_configuration: null,
  categories: null,
  service_types: null,
}

export const configurationReducer = createReducer(initialState, (builder) => {
  builder.addCase(setConfiguration, (state, action) => {
    return {
      ...state,
      id: action.payload.id,
      name: action.payload.name,
      medium_configuration: action.payload.medium_configuration,
      categories: action.payload.categories,
      service_types: action.payload.service_types,
    }
  })
  builder.addCase(setConfigurationCategories, (state, action) => {
    return {
      ...state,
      categories: action.payload.categories,
    }
  })
  builder.addCase(setMediumConfiguration, (state, action) => {
    return {
      ...state,
      medium_configuration: action.payload.medium_configuration,
    }
  })
})
