import { PortalUserRole as GQLPortalUserRole } from '@babylon/graphql-middleware-types'
import userRoles, {
  RoleConfigInterface,
  PermissionsInterface,
  RoleContextInterface,
} from '@/data/userRoles'

export const setPermissions = (roles: GQLPortalUserRole[] = []) =>
  roles
    // Get each user role configurations
    .reduce(
      (acc: RoleConfigInterface[], role: GQLPortalUserRole) =>
        userRoles[role.key] ? [...acc, userRoles[role.key]] : acc,
      []
    )
    // sort user role configurations by power so they can be orderly applied
    .sort((a: RoleConfigInterface, b: RoleConfigInterface) => a.power - b.power)
    // deep merge all user role configuration permissions, starting with the default ones
    .reduce(
      (roles: RoleContextInterface, { permissions }: RoleConfigInterface) =>
        Object.entries(permissions).reduce(
          (acc: any, [context, values]) => ({
            ...acc,
            [context]: { ...acc[context], ...(values as PermissionsInterface) },
          }),
          roles
        ),
      userRoles.default.permissions
    )
