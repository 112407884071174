import * as React from 'react'

const Search = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.13 12.069l6.372 6.372-1.06 1.06-6.373-6.371a7.25 7.25 0 111.06-1.06zM7.5 13.25a5.75 5.75 0 100-11.5 5.75 5.75 0 000 11.5z" />
  </svg>
)

export default Search
