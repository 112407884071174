import * as React from 'react'

const PhoneDown = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16.026 11.74c.189.167.326.417.303.652l-.072.736a.253.253 0 00.153.253l1.464.604c.093.038.2.015.273-.055l1.178-1.178c-5.098-3.668-9.923-3.68-14.705-.015l1.15 1.15a.253.253 0 00.29.046l1.354-.688a.25.25 0 00.136-.237l-.04-.7a.753.753 0 01.446-.727c2.727-1.21 5.378-1.201 7.88.027a.722.722 0 01.19.133m1.944 3.763c-.225 0-.452-.043-.67-.133l-1.463-.604a1.738 1.738 0 01-1.072-1.785l.02-.207c-1.847-.793-3.734-.798-5.747-.02l.008.137a1.763 1.763 0 01-.952 1.69l-1.354.689h-.001a1.748 1.748 0 01-2.03-.323L3.275 13.51c-.197-.196-.291-.481-.271-.823.01-.184.07-.643.414-.915C8.88 7.427 14.61 7.41 20.449 11.718a1.248 1.248 0 01.141 1.89l-1.383 1.383a1.754 1.754 0 01-1.237.513" />
  </svg>
)

export default PhoneDown
