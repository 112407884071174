import * as React from 'react'

const BodyFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M4.76 10.699a1.3 1.3 0 01-.872-1.478l.1-.53a1.3 1.3 0 011.52-1.035l.06.013c2.439.58 4.61.871 6.51.871 1.89 0 3.99-.287 6.3-.862a1.3 1.3 0 011.586.99l.11.52a1.3 1.3 0 01-.878 1.51c-1.856.588-3.307.948-4.361 1.08.124.855.564 3.742 1.262 8.263a1.357 1.357 0 01-1.341 1.564h-.414a1.3 1.3 0 01-1.275-1.044l-.036-.18c-.076-.502-.334-1.723-.77-3.65-.067-.278-.154-.37-.277-.37-.117 0-.196.086-.253.357l-.722 3.675a2.406 2.406 0 01-.097.355 1.3 1.3 0 01-1.222.857h-.648a1.11 1.11 0 01-1.099-1.265l1.208-8.562c-1.161-.14-2.623-.5-4.39-1.08zm9.59-5.666c0 1.247-1.057 2.25-2.35 2.25-1.293 0-2.35-1.003-2.35-2.25 0-1.246 1.057-2.25 2.35-2.25 1.293 0 2.35 1.004 2.35 2.25z" />
  </svg>
)

export default BodyFilled
