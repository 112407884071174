import React from 'react'
import PropTypes from 'prop-types'

import Pill from './Pill'

const getStatus = (arg) => (id) => {
  return Array.isArray(arg) ? arg.includes(id) : arg === id
}

const PillContainer = ({
  pills,
  selected,
  disabled,
  onSelectPill,
  pillsDataTestId,
}) => {
  const isSelected = getStatus(selected)
  const isDisabled = getStatus(disabled)

  return (
    <div className="core-pill-container">
      {pills.map((pill) => (
        <Pill
          key={pill.id}
          id={pill.id}
          onSelectPill={onSelectPill}
          selected={isSelected(pill.id)}
          disabled={isDisabled(pill.id)}
          style={pill.style}
          className={pill.className}
          name={pill.name}
          icon={pill.prefixIcon}
          dataTestId={pillsDataTestId}
        />
      ))}
    </div>
  )
}

PillContainer.propTypes = {
  pills: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      name: PropTypes.node.isRequired,
      style: PropTypes.object,
      className: PropTypes.string,
      id: PropTypes.string.isRequired,
    })
  ),
  selected: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  disabled: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
}

PillContainer.defaultProps = {
  disabled: [],
  selected: [],
}

export default PillContainer
