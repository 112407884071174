import React, { useEffect, useMemo, Dispatch, SetStateAction } from 'react'
import { useFormContext } from 'react-hook-form'

import { useFormatMessage } from '@babylon/intl'
import { TenancyRegion as GQLTenancyRegion } from '@babylon/graphql-middleware-types'

import { Field, MedkitInputWrapper, RegionField, RegionGroup } from '@/ui'

import regionsSearch from './regionsSearch'
import messages from './messages'
import styles from './styles.module.scss'

interface RegionsProps {
  regions: GQLTenancyRegion[]
  onChange?: Dispatch<SetStateAction<boolean>>
}

export interface FilteredRegionType extends GQLTenancyRegion {
  hide?: boolean
  subregions: FilteredRegionType[]
}

const Regions = ({ regions, onChange }: RegionsProps) => {
  const fm = useFormatMessage()
  const { watch } = useFormContext()
  const watchQuery: string = watch('regionsQuery')
  const watchRegions = watch('regions')

  useEffect(
    () => onChange?.(Object.values(watchRegions).some((value) => !!value)),
    [watchRegions, onChange]
  )

  const filteredRegions: FilteredRegionType[] = useMemo(() => {
    if (!watchQuery) {
      return regions
    }

    return regionsSearch({ regions, query: watchQuery })
  }, [regions, watchQuery])

  return (
    <div className={styles.regions}>
      <Field name="regionsQuery" className={styles.search}>
        <label htmlFor="search">{fm(messages.regionsSearchLabel)}</label>
        <MedkitInputWrapper
          id="search"
          autoFocus
          type="search"
          placeholder={fm(messages.regionsSearchPlaceholder)}
        />
      </Field>

      {filteredRegions?.map(({ id, displayName, subregions }) =>
        subregions.length > 0 ? (
          <RegionGroup
            key={id}
            id={id}
            name={displayName}
            subregions={subregions}
          />
        ) : (
          <RegionField key={id} id={id} name={displayName} />
        )
      )}
    </div>
  )
}

export default Regions
