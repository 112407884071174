import React, { useEffect } from 'react'
import Sidebar, { useSidebarContext } from '@/components/Sidebar'
import { useSetShowHeader } from '@/components/Layout'

import StepFormGuide from './StepFormWithSaveGuide'
import styles from './styles.module.scss'

export interface StepTypeWithSave {
  label: string
  component: React.ReactElement
  description?: string
  hideHeader?: boolean
}

interface StepFormWithSaveProps {
  step: number
  title: string
  steps: StepTypeWithSave[]
}

const StepFormWithSave = ({ step, title, steps }: StepFormWithSaveProps) => {
  const { setShowHeader = () => {} } = useSetShowHeader()

  const {
    setShow: setShowSidebar = () => {},
    setAlign: setAlignSidebar = () => {},
  } = useSidebarContext()

  useEffect(() => setShowSidebar(true), [setShowSidebar])
  useEffect(() => setAlignSidebar('left'), [setAlignSidebar])
  useEffect(() => {
    setShowHeader(false)

    return () => setShowHeader(true)
  }, [setShowHeader])

  return (
    <>
      <div className={styles.formContent}>
        {!steps[step]?.hideHeader && <h1>{steps[step]?.label}</h1>}
        {steps[step]?.description && <h2>{steps[step]?.description}</h2>}
        {React.cloneElement(steps[step]?.component)}
      </div>
      <Sidebar>
        <StepFormGuide title={title} steps={steps} activeStepIndex={step} />
      </Sidebar>
    </>
  )
}

export default StepFormWithSave
