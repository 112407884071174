import * as React from 'react'

const Menu = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21.244 18.502a.75.75 0 010 1.5H2.74a.75.75 0 110-1.5h18.504zm-.025-14.426a.75.75 0 010 1.5H2.816a.75.75 0 010-1.5H21.22zm.018 7.174a.75.75 0 010 1.5H2.775a.75.75 0 110-1.5h18.462z" />
  </svg>
)

export default Menu
