export const baseInputTheme = {
  fontFamily: '--medkit-input-font-family',
  height: '--medkit-input-height',
  background: '--medkit-input-background',
  borderColorActive: '--medkit-input-border-color-active',
  borderColorErrorActive: '--medkit-input-border-color-error-active',
  borderRadius: '--medkit-input-border-radius',
  borderWidth: '--medkit-input-border-width',
  boxShadowWidth: '--medkit-input-border-width',
  boxShadowColor: '--medkit-input-box-shadow-color',
  boxShadowColorActive: '--medkit-input-box-shadow-color-active',
  boxShadowColorErrorActive: '-medkit-input-box-shadow-color-error-active',
  margin: '--medkit-input-margin',
}

export const labelTheme = {
  labelColor: '--medkit-input-label-color',
  fontFamily: '-medkit-input-label-font-family',
  fontSize: '--medkit-input-label-font-size',
  margin: '--medkit-input-label-margin',
  height: '--medkit-input-label-height',
}
