import * as React from 'react'

const AppointmentNew = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M19.504 17.997h1.75a.75.75 0 010 1.5h-1.75v1.75a.75.75 0 11-1.5 0v-1.75h-1.75a.75.75 0 110-1.5h1.75v-1.75a.75.75 0 011.5 0v1.75zM3.527 8.25h17.05V5a.25.25 0 00-.25-.25h-3.32v1.092a.75.75 0 11-1.5 0V4.75H8.441v1.038a.75.75 0 01-1.5 0V4.75H3.777a.25.25 0 00-.25.25v3.25zm17.05 1.5H3.527V19c0 .138.112.25.25.25h9.526a.75.75 0 110 1.5H3.777A1.75 1.75 0 012.027 19V5c0-.966.784-1.75 1.75-1.75h3.164v-.449a.75.75 0 111.5 0v.449h7.066v-.449a.75.75 0 111.5 0v.449h3.32c.966 0 1.75.784 1.75 1.75v9.601a.75.75 0 11-1.5 0V9.75z" />
  </svg>
)

export default AppointmentNew
