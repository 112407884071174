/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import { IMedkitComponent } from '..'
import { linkTheme } from './theme'
import isExternalURL from '../utils/isExternalURL'
import isTelURL from '../utils/isTelURL'
import getLinkClassNames from './utils/getLinkClassNames'

export const LinkAnchor: IMedkitComponent<
  LinkAnchorProps,
  typeof linkTheme
> = ({
  children,
  className,
  href,
  isStandaloneLink,
  ...props
}: LinkAnchorProps) => {
  let targetProps

  if (isTelURL(href)) {
    targetProps = {
      rel: 'noreferrer noopener',
    }
  } else if (isExternalURL(href)) {
    targetProps = {
      target: '_blank',
      rel: 'noreferrer noopener',
    }
  }

  return (
    <a
      className={getLinkClassNames({ isStandaloneLink, className })}
      href={href}
      {...targetProps}
      {...props}
    >
      {children}
    </a>
  )
}

LinkAnchor.theme = linkTheme

export interface LinkAnchorProps
  extends React.ButtonHTMLAttributes<HTMLAnchorElement> {
  children?: React.ReactChild | React.ReactChild[]
  href?: string
  isStandaloneLink?: boolean
}

export default LinkAnchor
