import React from 'react'
import PropType from 'prop-types'

import CloseSVG from '../../../../icons/CloseSVG'

const DropdownClearButton = ({ onClick }) => {
  return (
    <button
      type="button"
      className="core-dropdown-select__input-clear-button"
      onClick={onClick}
    >
      <CloseSVG className="core-dropdown-select__input-clear-button__icon" />
    </button>
  )
}

DropdownClearButton.propTypes = {
  onClick: PropType.func,
}

export default DropdownClearButton
