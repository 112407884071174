import React, { useContext } from 'react'

import { LinkButton } from '@babylon/medkit'

import { PopoverContext } from './core'
import styles from './styles.module.scss'

interface PopoverTriggerProps {
  children: React.ReactElement | string
}

const PopoverTrigger = ({ children }: PopoverTriggerProps) => {
  const { toggleIsOpen } = useContext(PopoverContext)

  return (
    <LinkButton className={styles.popoverTrigger} onClick={toggleIsOpen}>
      {children}
    </LinkButton>
  )
}

export default PopoverTrigger
