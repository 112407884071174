import PropTypes from 'prop-types'

import FormikAsyncDropdownSelect from './FormikAsyncDropdownSelect'
import { optionPropType } from '../../candidates/DropdownSelect'

import './index.scss'

/** @deprecated, use FormikSelect instead */
class FormikDropdownSelect extends FormikAsyncDropdownSelect {
  getSelectedOptions(value) {
    const { options } = this.props
    return options.find((o) => o.key === value)
  }

  handleChange = (option, setFieldValue, name) => {
    const value = option ? option.key : null
    setFieldValue(name, value, true)
  }
}

FormikDropdownSelect.propTypes = {
  options: PropTypes.arrayOf(optionPropType),
  searchable: PropTypes.bool,
}

FormikDropdownSelect.defaultProps = {
  options: [],
  searchable: false,
}

export default FormikDropdownSelect
