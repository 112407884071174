import * as React from 'react'

const Star = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11.023 15.882a1.68 1.68 0 011.954 0l3.304 2.362a.18.18 0 00.275-.203l-1.252-3.747a1.68 1.68 0 01.622-1.902l3.24-2.296a.18.18 0 00-.104-.327l-4.04.009a1.68 1.68 0 01-1.599-1.154l-1.252-3.806a.18.18 0 00-.342 0l-1.252 3.806a1.68 1.68 0 01-1.6 1.154l-4.039-.009a.18.18 0 00-.104.327l3.24 2.296c.604.427.857 1.2.622 1.902l-1.252 3.747a.18.18 0 00.275.203l3.304-2.362zm.873 1.22l-3.304 2.362a1.68 1.68 0 01-2.57-1.898l1.251-3.747a.18.18 0 00-.066-.204l-3.24-2.295a1.68 1.68 0 01.974-3.05l4.04.008a.18.18 0 00.171-.123l1.253-3.806a1.68 1.68 0 013.19 0l1.253 3.806a.18.18 0 00.171.123l4.04-.009a1.68 1.68 0 01.974 3.05l-3.24 2.296a.18.18 0 00-.066.204l1.251 3.747a1.68 1.68 0 01-2.57 1.898l-3.303-2.362a.18.18 0 00-.21 0z" />
  </svg>
)

export default Star
