import React from 'react'
import cn from 'classnames'
import { ButtonOption, ButtonSwitchProps } from './types'

import './index.scss'

const getClassName = (selected: boolean, disabled?: boolean) =>
  cn(
    'core-button-switch__button',
    selected && 'core-button-switch--selected',
    disabled && 'core-button-switch--disabled'
  )

const getStatus = (selectedProp: ButtonOption) => (
  option: ButtonOption
): boolean => {
  return option.id ? option.id === selectedProp.id : option === selectedProp
}

const ButtonSwitch = ({
  options,
  selected,
  onSelect,
  className,
}: ButtonSwitchProps) => {
  const isSelected = getStatus(selected)

  return (
    <div className={cn('core-button-switch__container')}>
      <div className={cn('core-button-switch', className)}>
        {options.map((option) => (
          <div
            className={getClassName(isSelected(option), option.disabled)}
            key={option.id}
            onClick={() => onSelect(option)}
            role="button"
            tabIndex={0}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ButtonSwitch
