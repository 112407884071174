import classnames from 'classnames'

type ClassValue =
  | string
  | number
  | ClassDictionary
  | ClassArray
  | undefined
  | null
  | boolean

interface ClassDictionary {
  [id: string]: any
}

interface ClassArray extends Array<ClassValue> {}

// types were copied from the classname module as they were not exported

const cn = (...args: ClassValue[]) => classnames(...args) || undefined

export default cn
