import * as React from 'react'

const Id = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10.655 14.954c-.277-.996-.94-1.43-2.095-1.43-1.156 0-1.858.44-2.2 1.43h4.295zm-5.972.652c.305-2.317 1.658-3.583 3.877-3.583 2.233 0 3.541 1.288 3.725 3.622a.75.75 0 01-.748.809h-6.11a.75.75 0 01-.744-.848zm5.025-5.662c0-.656-.547-1.194-1.229-1.194S7.25 9.288 7.25 9.944c0 .658.547 1.195 1.23 1.195.681 0 1.228-.538 1.228-1.195zm1.5 0c0 1.492-1.224 2.695-2.729 2.695-1.505 0-2.729-1.203-2.729-2.695 0-1.49 1.224-2.694 2.73-2.694 1.504 0 2.728 1.204 2.728 2.694zm7.122-1.868a.75.75 0 110 1.5h-4.356a.75.75 0 110-1.5h4.356zm.01 3a.75.75 0 110 1.5h-4.366a.75.75 0 010-1.5h4.366zm-2.784 3a.75.75 0 110 1.5h-1.582a.75.75 0 010-1.5h1.582zM3.763 5.554a.25.25 0 00-.25.25V18c0 .138.112.25.25.25H20.27a.25.25 0 00.25-.25V5.804a.25.25 0 00-.25-.25H3.763zm0-1.5H20.27c.966 0 1.75.783 1.75 1.75V18a1.75 1.75 0 01-1.75 1.75H3.763A1.75 1.75 0 012.013 18V5.804c0-.967.784-1.75 1.75-1.75z" />
  </svg>
)

export default Id
