import * as React from 'react'

const Tick = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18.823 6.948a.75.75 0 011.06 1.062l-9.868 9.844a.75.75 0 01-1.05.01l-4.72-4.548a.75.75 0 111.04-1.08l4.19 4.037 9.348-9.325z" />
  </svg>
)

export default Tick
