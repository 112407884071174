import React, { useEffect } from 'react'

import SnackbarBagRenderer from './SnackbarBagRenderer'
import { SnackbarBagContext } from './core'
import { SnackbarBagType } from './createSnackbarBag'

interface SnackbarBagProps {
  snackbarBag: SnackbarBagType
  children: React.ReactNode
}

function SnackbarBag({ snackbarBag, children }: SnackbarBagProps) {
  useEffect(() => () => snackbarBag.removeAll(), [snackbarBag])

  return (
    <SnackbarBagContext.Provider value={snackbarBag}>
      {children}
    </SnackbarBagContext.Provider>
  )
}

SnackbarBag.Renderer = SnackbarBagRenderer

export default SnackbarBag
