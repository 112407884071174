import * as React from 'react'

const ArrowRight = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16.216 17.223a.75.75 0 101.068 1.054l5.928-6.01a.75.75 0 00-.001-1.054l-5.928-5.99a.75.75 0 10-1.066 1.055l5.407 5.463-5.408 5.482z" />
  </svg>
)

export default ArrowRight
