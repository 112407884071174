import * as React from 'react'

const PeopleProfile = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 11.5a4.75 4.75 0 004.75-4.75v-1a4.75 4.75 0 00-9.5 0v1A4.75 4.75 0 0012 11.5zm0 1.409C5.889 12.947 2 16.038 2 22h20c0-5.962-3.889-9.053-10-9.091zM18 19h-4v-1.008h4V19z" />
  </svg>
)

export default PeopleProfile
