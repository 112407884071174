import React, { useCallback } from 'react'

import { DragAndDropFileType } from '@/ui'

import { DragAndDropArea } from './DragAndDropArea'
import FilePreview from './FilePreview'

import styles from './styles.module.scss'

interface DragAndDropComponentProps {
  value: DragAndDropFileType | null
  onChange?: (value: any) => any
  acceptedTypes?: string[]
  maxSizeInBytes?: number
  trackValidationError?: () => void
  className?: string
  'data-testid'?: string
  onCancel: () => void
}

function DragAndDropComponent({
  value,
  onChange,
  acceptedTypes,
  maxSizeInBytes,
  trackValidationError,
  className,
  'data-testid': dataTestId,
  onCancel,
  ...props
}: DragAndDropComponentProps) {
  const handleFileRemove = useCallback(() => onCancel(), [onCancel])

  return (
    <>
      {value?.name && (
        <FilePreview
          name={value.name}
          sizeInBytes={value.sizeInBytes}
          onRemove={handleFileRemove}
          className={styles.filePreview}
        />
      )}
      {!value?.base64 && (
        <DragAndDropArea
          value={value}
          onChange={onChange}
          acceptedTypes={acceptedTypes}
          maxSizeInBytes={maxSizeInBytes}
          trackValidationError={trackValidationError}
          className={className}
          data-testid={dataTestId}
          {...props}
        />
      )}
    </>
  )
}

export default DragAndDropComponent
