import React, { useMemo, useCallback, useState, useEffect } from 'react'
import cn from 'classnames'

import styles from './Pagination.module.scss'
import { TableQueryDisplayParamsType } from './TableQuery'

interface PaginationProps {
  page: number
  totalItems: number
  pageSize: number
  onChange: (data: Partial<TableQueryDisplayParamsType>) => void
}

export default ({ page, totalItems, pageSize, onChange }: PaginationProps) => {
  const [pageNumber, setPageNumber] = useState(page.toString())
  const length = Math.ceil(totalItems / pageSize)
  const handlePagination = useCallback(
    ({
      currentTarget: {
        dataset: { page: newPage },
      },
    }) => onChange({ page: +newPage }),
    [onChange]
  )

  useEffect(() => {
    setPageNumber(page.toString())
  }, [page])

  const handlePageInput = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget

      const number = Number(value)
      const validInput =
        Number.isInteger(number) && number > 0 && number <= length

      if (validInput) {
        onChange({ page: +number })
      } else {
        setPageNumber(page.toString())
        onChange({ page: page })
      }
    },
    [onChange, setPageNumber, length, page]
  )

  const renderButton = useCallback(
    ({ value }) => (
      <input
        type="text"
        className={cn(styles.page)}
        value={value}
        data-page={`${value}`}
        data-testid={'page-number-input'}
        onChange={(e) => setPageNumber(e.currentTarget.value)}
        onBlur={handlePageInput}
      />
    ),
    [handlePageInput]
  )

  const renderActionButton = useCallback(
    ({ value, label }) => (
      <button
        key={label}
        type="button"
        className={cn(styles.action)}
        disabled={page === value}
        data-page={`${value}`}
        onClick={handlePagination}
      >
        {label}
      </button>
    ),
    [page, handlePagination]
  )

  const paginationButtons = useMemo(() => {
    return (
      <>
        {renderActionButton({ value: 1, label: '<<' })}
        {renderActionButton({ value: Math.max(1, page - 1), label: '<' })}
        <div className={styles.pageButtons}>
          {renderButton({ value: pageNumber })}
        </div>
        {renderActionButton({ value: Math.min(length, page + 1), label: '>' })}
        {renderActionButton({ value: length, label: '>>' })}
      </>
    )
  }, [length, page, renderActionButton, pageNumber, renderButton])

  return <div className={styles.pagination}>{paginationButtons}</div>
}
