import { createAppointmentCategories } from './createAppointmentCategories'
import { appointmentConfigurationsSlice } from './appointmentConfigurations'
import { deleteAppointmentCategories } from './deleteAppointmentCategories'
import { partnerSlice } from './partner'

export default {
  createAppointmentCategories: createAppointmentCategories.reducer,
  appointmentConfigurations: appointmentConfigurationsSlice.reducer,
  deleteAppointmentCategories: deleteAppointmentCategories.reducer,
  partner: partnerSlice.reducer,
}
