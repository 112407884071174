import { TenancyRegion as GQLTenancyRegion } from '@babylon/graphql-middleware-types'

import { FilteredRegionType } from './Regions'

interface GetFilteredRegionsProps {
  regions: GQLTenancyRegion[]
  query: string
}

const regionsSearch = ({ regions, query }: GetFilteredRegionsProps) =>
  regions.reduce(
    (
      acc: FilteredRegionType[],
      region: GQLTenancyRegion
    ): FilteredRegionType[] => {
      const regexp = new RegExp(query, 'ig')

      if (region.displayName.search(regexp) > -1) {
        return [...acc, region]
      }

      const subregions: FilteredRegionType[] = region.subregions.map(
        (subregion) =>
          subregion.displayName.search(regexp) > -1
            ? { ...subregion }
            : { ...subregion, hide: true }
      )

      const filteredSubregionsCount = subregions.filter(({ hide }) => !hide)
        .length

      return filteredSubregionsCount > 0
        ? [...acc, { ...region, subregions }]
        : acc
    },
    []
  )

export default regionsSearch
