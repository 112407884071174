import React, { FC, ReactNode } from 'react'
import cn from 'classnames'

import './index.scss'

export interface LabelProps {
  classname?: string
  children?: ReactNode
  htmlFor?: string
  id?: string
}

const Label: FC<LabelProps> = ({ classname, children, htmlFor, id }) => (
  <label htmlFor={htmlFor} id={id} className={cn(classname, 'core-form-label')}>
    {children}
  </label>
)

export default Label
