import React, { useEffect } from 'react'
import { IThemeProvider, themes } from './themes'
import { Font } from './Theme.types'

const getFontType = (url: string) => {
  if (url.indexOf('.woff2')) return 'woff2'
  else if (url.indexOf('.woff')) return 'woff'
  else if (url.indexOf('.ttf')) return 'truetype'
  else if (url.indexOf('.eot')) return 'embedded-opentype'

  return 'svg'
}

const addFontFaces = (font?: Font) => {
  let css = ''
  if (font && font.urls) {
    const weights = font.weights || []
    font.urls.forEach((url, i) => {
      const weight = i < weights.length ? weights[i] : 'normal'

      if (css) {
        css += '\n'
      }

      css += `@font-face {
        font-family: '${font.family}';
        src: url("${url}") format("${getFontType(url)}");
        font-weight: ${weight};
      }`
    })
  }

  return css
}

const ThemeProvider: IThemeProvider<IProps> = ({
  theme,
  font,
  root,
  children,
}) => {
  if (window.cssVars && theme) {
    window.cssVars({
      rootElement: document,
      variables: theme,
    })
  }

  useEffect(() => {
    if (!root || !theme) {
      return undefined
    }

    let style = document.getElementById('medkit-theme')

    if (!style) {
      style = document.createElement('style')
      style.id = 'medkit-theme'
      document.head.appendChild(style)
    }

    const variables = Object.entries(theme)
      .map(([key, value]) => `${key}:${value}`)
      .join(';')

    let css = ''

    // If custom fonts are required add the @font-face declarations
    css += addFontFaces(font)
    css += `:root { ${variables} }`

    style.innerHTML = css

    return () => {
      style && style.remove()
    }
  }, [theme, root, font])

  return root ? children : <div style={theme}>{children}</div>
}

ThemeProvider.theme = themes

interface IProps extends React.HTMLProps<HTMLElement> {
  theme?: {
    [key: string]: string
  }
  font?: Font
  root?: boolean
  children: any
}

export default ThemeProvider
