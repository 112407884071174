import * as React from 'react'

const FaceToFaceCircleFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.718 9.282a.75.75 0 00-1.436 0 .75.75 0 000 1.436.75.75 0 001.436 0 .75.75 0 000-1.436zM12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zM9.5 9.101a.75.75 0 00.713-.517l2.834-1.306 2.702 1.282-.014 6.767h-.94v-1.481c0-.69-.56-1.25-1.25-1.25h-.494a.75.75 0 000 1.5h.244v1.481c0 .69.56 1.25 1.25 1.25H16c.683 0 1.234-.555 1.234-1.237l.014-7.185a1.25 1.25 0 00-.714-1.132l-2.951-1.4a1.25 1.25 0 00-1.059-.006L9.427 7.295c-.413.19-.677.602-.677 1.056 0 .415.336.75.75.75zm-2.744 6.927a.635.635 0 00.629.722h4.23a.635.635 0 00.631-.704c-.16-1.464-1.14-2.257-2.722-2.257-1.575 0-2.566.784-2.768 2.24zM9.5 13.25a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5z" />
  </svg>
)

export default FaceToFaceCircleFilled
