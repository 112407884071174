import React, { useContext } from 'react'

import { LinkButton } from '@babylon/medkit'

import { PopoverContext } from './core'
import styles from './styles.module.scss'

interface PopoverActionProps {
  children: React.ReactElement | string
  onClick: () => void
}

const PopoverAction = ({ children, onClick }: PopoverActionProps) => {
  const { toggleIsOpen } = useContext(PopoverContext)
  const handleClick = () => {
    toggleIsOpen()
    onClick()
  }

  return (
    <div className={styles.popoverAction}>
      <LinkButton onClick={handleClick} className={styles.popoverActionLink}>
        {children}
      </LinkButton>
    </div>
  )
}

export default PopoverAction
