import cn from 'classnames'
import React, { useMemo } from 'react'
import { FieldError, useFormContext } from 'react-hook-form'

import FieldContext from './context'
import styles from './styles.module.scss'

interface FieldProps {
  name?: string
  children?: React.ReactNode
  className?: string
  cols?: number
}

const Field = ({ name, children, className, cols }: FieldProps) => {
  const { errors } = useFormContext() || {}
  const error = (name && (errors?.[name] as FieldError)) || null
  const fieldContext = useMemo(() => ({ name }), [name])
  const width = cols == null ? {} : { style: { gridColumnEnd: `span ${cols}` } }

  return (
    <div
      className={cn(
        styles.field,
        {
          [styles.fieldError]: error,
          // NOTE: Because of the way the Field component is done,
          //       we can not pass the error prop to the children.
          // TODO: Pass the error as a prop to the children, so these can
          //       show the error state
          'bl-field-error': error,
        },
        className
      )}
      {...width}
    >
      {name ? (
        <FieldContext.Provider value={fieldContext}>
          {children}
        </FieldContext.Provider>
      ) : (
        children
      )}
      {error?.message && (
        <p className={styles.fieldErrorMessage}>{error.message}</p>
      )}
    </div>
  )
}

export default Field
