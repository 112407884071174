import * as React from 'react'

const Report = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16.026 11.25a.75.75 0 110 1.5H8.017a.75.75 0 110-1.5h8.01zM9.9 3.62c.304-.918 1.152-1.584 2.159-1.584 1.006 0 1.854.666 2.159 1.584h.9a.75.75 0 01.75.75v3.166a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75V4.37A.75.75 0 019 3.62h.9zm8.04 2.46a.75.75 0 110-1.5h.35c.966 0 1.75.783 1.75 1.75v13.938a1.75 1.75 0 01-1.75 1.75H5.748a1.75 1.75 0 01-1.75-1.75V6.33c0-.967.784-1.75 1.75-1.75h.397a.75.75 0 010 1.5h-.397a.25.25 0 00-.25.25v13.938c0 .138.112.25.25.25H18.29a.25.25 0 00.25-.25V6.33a.25.25 0 00-.25-.25h-.35zm-1.914 11.17a.75.75 0 110 1.5H8.017a.75.75 0 110-1.5h8.01zm0-3a.75.75 0 110 1.5H8.017a.75.75 0 110-1.5h8.01zm-2.438-9.13a.75.75 0 01-.75-.75c0-.466-.355-.834-.78-.834-.424 0-.779.368-.779.834a.75.75 0 01-.75.75H9.75v1.666h4.618V5.12h-.78z" />
  </svg>
)

export default Report
