import * as React from 'react'

const CoreSort = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2 18.9l2-2.1a.92.92 0 111.3 1.3l-3.6 3.6a.902.902 0 01-.7.3.9.9 0 01-.6-.3l-3.7-3.6a1.1 1.1 0 010-1.3.9.9 0 011.3 0l2.1 2.1V6.2l-2.1 2a.9.9 0 01-1.3 0 .9.9 0 010-1.2l3.7-3.7a.9.9 0 01.6-.3.9.9 0 01.7.3L21.5 7a.8.8 0 010 1.2.9.9 0 01-1.3 0l-2-2v12.7zM8.9 3h-6a1.1 1.1 0 00-1 1 1.1 1.1 0 001 1h6a1 1 0 000-2zm-6 8h6a1 1 0 010 2h-6a1.1 1.1 0 01-1-1 1.1 1.1 0 011-1zm0 8h6a1 1 0 010 2h-6a1 1 0 010-2z"
    />
  </svg>
)

export default CoreSort
