import * as React from 'react'

const SpeakerFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17.856 7.15a.75.75 0 11.955-1.157c3.951 3.262 3.958 8.74 0 12.013a.75.75 0 11-.956-1.155c3.232-2.674 3.226-7.039 0-9.701zM7.166 8.536l6.113-6.114a.75.75 0 011.28.53v18.096a.75.75 0 01-1.28.53l-6.113-6.114H4.31a2.108 2.108 0 01-2.108-2.107v-2.714c0-1.163.944-2.107 2.108-2.107h2.856zm8.917.904a.75.75 0 11.882-1.213c2.832 2.06 2.829 5.491 0 7.546a.75.75 0 01-.882-1.213c2.005-1.457 2.007-3.66 0-5.12z" />
  </svg>
)

export default SpeakerFilled
