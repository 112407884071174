import * as React from 'react'

const PlayFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8.442 5.394A.75.75 0 007.25 6v12a.75.75 0 001.192.606l8.218-6a.75.75 0 000-1.212l-8.218-6z" />
  </svg>
)

export default PlayFilled
