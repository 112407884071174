import * as React from 'react'

const AppointmentNewFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M20.577 9.75H3.527V19c0 .138.112.25.25.25h9.526a.75.75 0 110 1.5H3.777A1.75 1.75 0 012.027 19V5c0-.966.784-1.75 1.75-1.75h3.164v-.449a.75.75 0 111.5 0v.449h7.066v-.449a.75.75 0 111.5 0v.449h3.32c.966 0 1.75.784 1.75 1.75v9.601a.75.75 0 11-1.5 0V9.75zm-1.073 8.247h1.75a.75.75 0 010 1.5h-1.75v1.75a.75.75 0 11-1.5 0v-1.75h-1.75a.75.75 0 110-1.5h1.75v-1.75a.75.75 0 011.5 0v1.75z" />
  </svg>
)

export default AppointmentNewFilled
