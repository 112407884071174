//TODO - add build step to generate SASS/CSS variables from these values
const colors = {
  'action-green': '#2BBBBB',
  'action-green-type': '#26A6A6',
  'action-green-background': '#E9F8F8',
  'medical-blue': '#4A90E2',
  'medical-blue-type': '#3761BF',
  'medical-blue-background': '#E4EFFB',
  'highlight-violet': '#400099',
  'highlight-violet-type': '#2D0C59',
  'highlight-violet-background': '#ECE6F5',
  'black-type': '#282824',
  'dark-grey-type': '#4a4a4a',
  'grey-type': '#637280',
  'light-grey-type': '#6B7785',
  'grey-type-keyline': '#DDDFE1',
  'grey-background': '#FAFAFA',
  'light-grey': '#BFC3CB',
  white: '#FFFFFF',
  error: '#FF3B2F',
  'error-background': '#FFEBEA',
  neutral: '#FCD920',
  'neutral-background': '#FEFBE9',
  confirmation: '#4BD963',
  'confirmation-background': '#EDFBEF',
  'brand-pink': '#F45DA9',
  'brand-pink-background': '#FFEEF6',
  'brand-orange': '#FF9E01',
  'brand-orange-background': '#FEFBE9',
  'brand-cyan': '#25D9D9',
}

export const colorNames = Object.keys(colors)

export default colors
