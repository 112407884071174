import React from 'react'
import cn from 'classnames'
import { RegisterOptions } from 'react-hook-form'

import { Icon } from '@babylon/medkit'
import { MedkitCheckboxWrapper } from '@/ui'

import styles from './styles.module.scss'

interface ActionConfirmationCardProps {
  message: string | React.ReactElement
  title?: string
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  spaceTop?: boolean
}

const ActionConfirmationCard = ({
  title,
  message,
  rules,
  spaceTop,
}: ActionConfirmationCardProps) => (
  <div className={cn(styles.confirmationCard, { [styles.spaceTop]: spaceTop })}>
    {title && (
      <div className={styles.heading}>
        <Icon
          icon="AlertsAlert"
          title="Alert icon"
          height={32}
          width={32}
          iconClassName={styles.icon}
        />
        <div className={styles.title}>{title}</div>
      </div>
    )}
    {rules && (
      <div className={styles.message}>
        <MedkitCheckboxWrapper
          data-testid="actionConfirmationCheckbox"
          className={styles.confirmationCheckbox}
          rules={rules}
        >
          {message}
        </MedkitCheckboxWrapper>
      </div>
    )}
  </div>
)

export default ActionConfirmationCard
