import * as React from 'react'

const LinegraphFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16.658 11.331l1.166-1.843A2.215 2.215 0 0117.25 8c0-1.248 1.032-2.25 2.294-2.25 1.263 0 2.293 1.001 2.293 2.25 0 1.248-1.03 2.25-2.293 2.25-.138 0-.274-.012-.405-.035l-1.24 1.96c.276.371.438.829.438 1.325 0 1.248-1.03 2.25-2.293 2.25a2.288 2.288 0 01-2.225-1.701l-2.237-.703a2.307 2.307 0 01-1.838.904 2.32 2.32 0 01-1.061-.255l-1.969 1.774c.08.229.123.475.123.731 0 1.248-1.03 2.25-2.293 2.25-1.262 0-2.294-1.003-2.294-2.25 0-1.248 1.032-2.25 2.294-2.25.45 0 .872.128 1.228.349l1.873-1.689A2.205 2.205 0 017.45 12c0-1.248 1.032-2.25 2.294-2.25 1.235 0 2.247.957 2.292 2.166l1.94.61a2.299 2.299 0 012.068-1.276c.212 0 .418.028.614.081z" />
  </svg>
)

export default LinegraphFilled
