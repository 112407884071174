import * as React from 'react'

const UserCircleFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 2.4c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6-4.3-9.6-9.6-9.6zm0 2.88c1.86 0 3.36 1.5 3.36 3.36 0 1.86-1.5 3.36-3.36 3.36-1.86 0-3.36-1.5-3.36-3.36 0-1.86 1.5-3.36 3.36-3.36zm0 14.712a7.489 7.489 0 01-6.24-3.349c.031-2.07 4.16-3.203 6.24-3.203 2.07 0 6.209 1.134 6.24 3.203A7.489 7.489 0 0112 19.992z" />
  </svg>
)

export default UserCircleFilled
