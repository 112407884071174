import * as React from 'react'

const Close = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.06 12.029l6.085 6.06a.75.75 0 01-1.058 1.062l-6.09-6.064-5.986 5.961a.75.75 0 11-1.059-1.063l5.983-5.956-6.03-6.005A.75.75 0 015.963 4.96l6.035 6.01 6.112-6.087a.75.75 0 011.059 1.063L13.06 12.03z" />
  </svg>
)

export default Close
