import * as React from 'react'

const VideoOn = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 18"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M22.5 3.866L17 7.041V2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h13c1.1 0 2-.9 2-2v-5.041l5.5 3.175a1 1 0 001.5-.866V4.732a1 1 0 00-1.5-.866z" />
  </svg>
)

export default VideoOn
