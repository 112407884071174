import * as React from 'react'

const Camera = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M4 7.394a.25.25 0 00-.25.25V19c0 .138.112.25.25.25h16a.25.25 0 00.25-.25V7.644a.25.25 0 00-.25-.25h-2.4a.75.75 0 01-.536-.225l-2.297-2.344a.25.25 0 00-.179-.075H9.431a.25.25 0 00-.18.077L7.02 7.162a.75.75 0 01-.543.232H4zm13.915-1.5H20c.966 0 1.75.784 1.75 1.75V19A1.75 1.75 0 0120 20.75H4A1.75 1.75 0 012.25 19V7.644c0-.966.784-1.75 1.75-1.75h2.157l2.008-2.103a1.75 1.75 0 011.266-.541h5.157c.47 0 .921.19 1.25.525l2.077 2.12zM12 17.25a4.75 4.75 0 110-9.5 4.75 4.75 0 010 9.5zm0-1.5a3.25 3.25 0 100-6.5 3.25 3.25 0 000 6.5z" />
  </svg>
)

export default Camera
