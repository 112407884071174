import * as React from 'react'

const FaceToFace = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 22"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18.5 5.775a2.613 2.613 0 002.613-2.612v-.55a2.613 2.613 0 00-5.225 0v.55A2.612 2.612 0 0018.5 5.775zm-13 0a2.612 2.612 0 002.612-2.612v-.55a2.613 2.613 0 10-5.224 0v.55A2.612 2.612 0 005.5 5.775zm0 .775c-3.361.021-5.5 1.721-5.5 5v10.1h11V11.549c0-3.278-2.139-4.978-5.5-4.999zM24 11.549c0-3.279-2.139-4.979-5.5-4.999-3.361.02-5.5 1.721-5.5 5v3.1h11v-3.101zM12 21.65h12v-6H12v6z" />
  </svg>
)

export default FaceToFace
