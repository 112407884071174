import { useState } from 'react'

import { useModal } from '@/ui'

const NP = Symbol() as any

const useModalAnimation = <T = boolean>(
  defaultState: T = NP
): [T, boolean, () => void, (state?: any) => void, () => void] => {
  const defaultStateValue = defaultState === NP ? (false as any) : defaultState
  const [modalState, showModal, hideModal] = useModal<T>(defaultStateValue)
  const [isVisible, setVisible] = useState<boolean>(!!defaultStateValue)

  const show = (state: T = NP) => {
    showModal(state === NP ? (true as any) : state)
    setTimeout(() => setVisible(state === NP ? true : !!state), 0)
  }

  const hide = () => {
    setVisible(false)
  }

  const handleHide = () => {
    hideModal()
  }

  return [modalState, isVisible, handleHide, show, hide]
}

export default useModalAnimation
