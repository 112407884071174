import { createContext, Dispatch, SetStateAction } from 'react'

export interface SidebarContextType {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  align: 'left' | 'right'
  setAlign: Dispatch<SetStateAction<'left' | 'right'>>
  root: HTMLElement | null
}

const sidebarContext = createContext<Partial<SidebarContextType>>({})

export default sidebarContext
