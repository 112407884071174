import React, { ReactNode } from 'react'
import cn from 'classnames'

import './Divider.scss'

export interface Props {
  className?: string
  align?: string
  children?: ReactNode
}

const Divider = ({ className, align, children }: Props) => (
  <div
    className={cn(
      'core-divider',
      align && `core-divider--${align}`,
      !children && 'core-divider--no-content',
      className
    )}
  >
    <span>{children}</span>
  </div>
)

export default Divider
