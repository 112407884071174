import { useContext } from 'react'

import sidebarContext from './sidebarContext'

const useSidebarContext = () => {
  const ctx = useContext(sidebarContext)

  if (!ctx?.root) {
    throw new Error('Sidebar can not be used outside of a SidebarStack')
  }

  return ctx
}

export default useSidebarContext
