import ReactDom from 'react-dom'
import React, { useContext, useMemo, useEffect } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styles from './styles.module.scss'
import { useForceUpdate } from '@/components/hooks'
import { ModalStackContext } from './core'

function getRenderList(elements, baseZIndex) {
  const animationTimeout = 500
  const renderList = Array.from(elements.entries()).map(
    ([id, element], index) => (
      <CSSTransition
        key={id}
        classNames={styles.modal}
        timeout={animationTimeout}
      >
        <div
          className={styles.modal}
          style={{ zIndex: baseZIndex + index * 2 + 1 }}
          key={id}
        >
          {element}
        </div>
      </CSSTransition>
    )
  )

  if (renderList.length) {
    renderList.unshift(
      <CSSTransition
        key="overlay"
        classNames={styles.overlay}
        timeout={animationTimeout}
      >
        <div
          className={styles.overlay}
          style={{ zIndex: baseZIndex + renderList.length * 2 - 2 }}
          key="overlay"
        />
      </CSSTransition>
    )
  }

  return renderList
}

function getDomNode(id) {
  let domNode = document.querySelector(`#${id}`)

  if (!domNode) {
    domNode = document.createElement('div')
    domNode.setAttribute('id', id)
    document.body.appendChild(domNode)
  }

  return domNode
}

function ModalStackRenderer({ baseZIndex = 1000 }) {
  const forceUpdate = useForceUpdate()
  const modalStack = useContext(ModalStackContext)
  const rootNodeId = modalStack.getRootNodeId()
  const rootNode = useMemo(() => getDomNode(rootNodeId), [rootNodeId])

  useEffect(() => {
    const removeListener = modalStack.addListener(() =>
      // This is just to force re-render.
      forceUpdate()
    )

    return removeListener
  }, [forceUpdate, modalStack])

  return ReactDom.createPortal(
    <TransitionGroup>
      {getRenderList(modalStack.getElements(), baseZIndex)}
    </TransitionGroup>,
    rootNode
  )
}

export default ModalStackRenderer
