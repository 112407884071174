import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import {
  Button,
  FormikDropdownSelect,
  FormikDateRangePicker,
  FormikAsyncDropdownSelect,
  FormikInput,
} from '@babylon/core-ui'

import './index.scss'

const idPattern = /^(\d*|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/
const validateId = (id) => {
  if (id && !id.match(idPattern)) {
    return 'Numeric ID or UUID only'
  }

  return undefined
}

const AllConsultationsFiltersForm = ({
  i18n,
  isSubmitting,
  patientGenderOptions,
  consultationStatusOptions,
  fetchConsumerNetworkOptions,
  fetchClinicianNameOptions,
  locale,
  disableSubmitButton,
}) => (
  <Form name="all-consultations-filter-form">
    <div className="AllConsultationsFiltersForm">
      <div className="AllConsultationsFiltersForm__row">
        <div className="AllConsultationsFiltersForm__column">
          <h2 className="AllConsultationsFiltersForm__columnHeading">
            {i18n.heading}
          </h2>

          <div className="AllConsultationsFiltersForm__dateField">
            <FormikDateRangePicker
              name="dates"
              id="dates"
              label={i18n.dates_label}
              fill
              locale={locale}
              startPlaceholderText={i18n.date_start_placeholder}
              endPlaceholderText={i18n.date_end_placeholder}
              dateRangePickerProps={{
                small: true,
              }}
            />
          </div>

          <FormikInput
            id="consultation_id"
            name="consultation_id"
            label={i18n.consultation_id_label}
            validate={validateId}
          />

          <div
            className="AllConsultationsFiltersForm__statusField"
            data-testid="consultation-status-filter"
          >
            <FormikDropdownSelect
              id="status"
              name="status"
              label={i18n.status_label}
              placeholder={i18n.status_placeholder}
              options={consultationStatusOptions}
            />
          </div>

          <div
            className="AllConsultationsFiltersForm__consumerNetworkField"
            data-testid="consumer-network-dropdown"
          >
            <FormikAsyncDropdownSelect
              id="consumer_network"
              name="consumer_network"
              label={i18n.consumer_network_label}
              placeholder={i18n.consumer_network_placeholder}
              getOptionsAsync={fetchConsumerNetworkOptions}
              asyncLoadingMessage={
                i18n.consumer_network_options_loading_message
              }
              searchable
              onlyFetchOptionsOnce
            />
          </div>
        </div>

        <div className="AllConsultationsFiltersForm__column">
          <h2 className="AllConsultationsFiltersForm__columnHeading">
            {i18n.patient_column_heading}
          </h2>

          <FormikInput
            id="family_name"
            name="family_name"
            label={i18n.patient_family_name_label}
          />

          <FormikInput
            id="given_name"
            name="given_name"
            label={i18n.patient_given_name_label}
          />

          <div
            className="AllConsultationsFiltersForm__genderField"
            data-testid="patient-gender-dropdown"
          >
            <FormikDropdownSelect
              id="gender"
              name="gender"
              label={i18n.patient_gender_label}
              placeholder={i18n.patient_gender_placeholder}
              options={patientGenderOptions}
            />
          </div>

          <FormikInput
            id="patient_id"
            name="patient_id"
            label={i18n.patient_id_label}
            validate={validateId}
          />

          <FormikInput
            id="public_healthcare_id"
            name="public_healthcare_id"
            label={i18n.patient_healthcare_id_label}
          />

          <FormikInput
            id="date_of_birth"
            name="date_of_birth"
            label={i18n.patient_date_of_birth_label}
            placeholder={i18n.patient_date_of_birth_placeholder}
          />
        </div>

        <div className="AllConsultationsFiltersForm__column">
          <h2 className="AllConsultationsFiltersForm__columnHeading">
            {i18n.clinician_column_heading}
          </h2>

          <div
            className="AllConsultationsFiltersForm__clinicianNameField"
            data-testid="clinician-name-dropdown"
          >
            <FormikAsyncDropdownSelect
              id="clinician_name"
              name="clinician_name"
              label={i18n.clinician_name_label}
              getOptionsAsync={fetchClinicianNameOptions}
              asyncLoadingMessage={i18n.clinician_name_options_loading_message}
              asyncSearchInstructionsMessage={
                i18n.clinician_name_options_search_instructions_message
              }
              asyncNoResultsFoundMessage={
                i18n.clinician_name_options_no_results_found_message
              }
              searchable
            />
          </div>

          <FormikInput
            id="clinician_id"
            name="clinician_id"
            label={i18n.clinician_id_label}
            validate={validateId}
          />
        </div>
      </div>

      <div className="AllConsultationsFiltersForm__buttons">
        <div className="AllConsultationsFiltersForm__button">
          <Button
            type="reset"
            intent="secondary"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            <span className="AllConsultationsFiltersForm__buttonText">
              {i18n.reset_filters_button_label}
            </span>
          </Button>
        </div>

        <div className="AllConsultationsFiltersForm__button">
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={disableSubmitButton || isSubmitting}
          >
            <span className="AllConsultationsFiltersForm__buttonText">
              {i18n.apply_filters_button_label}
            </span>
          </Button>
        </div>
      </div>
    </div>
  </Form>
)

AllConsultationsFiltersForm.propTypes = {
  i18n: PropTypes.shape({
    heading: PropTypes.string,
    dates_label: PropTypes.string,
    date_start_placeholder: PropTypes.string,
    date_end_placeholder: PropTypes.string,
    consultation_id_label: PropTypes.string,
    status_label: PropTypes.string,
    status_placeholder: PropTypes.string,
    consumer_network_label: PropTypes.string,
    consumer_network_placeholder: PropTypes.string,
    patient_column_heading: PropTypes.string,
    patient_family_name_label: PropTypes.string,
    patient_given_name_label: PropTypes.string,
    patient_gender_label: PropTypes.string,
    patient_gender_placeholder: PropTypes.string,
    patient_id_label: PropTypes.string,
    patient_healthcare_id_label: PropTypes.string,
    patient_date_of_birth_label: PropTypes.string,
    patient_date_of_birth_placeholder: PropTypes.string,
    clinician_column_heading: PropTypes.string,
    clinician_name_label: PropTypes.string,
    clinician_name_options_loading_message: PropTypes.string,
    clinician_name_options_search_instructions_message: PropTypes.string,
    clinician_name_options_no_results_found_message: PropTypes.string,
    clinician_id_label: PropTypes.string,
    reset_filters_button_label: PropTypes.string,
    apply_filters_button_label: PropTypes.string,
  }),
  patientGenderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  consultationStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  fetchClinicianNameOptions: PropTypes.func,
  fetchConsumerNetworkOptions: PropTypes.func,
  disableSubmitButton: PropTypes.bool,
}

AllConsultationsFiltersForm.defaultProps = {
  i18n: {
    heading: 'Consultation',
    dates_label: 'Dates',
    date_start_placeholder: 'Start date',
    date_end_placeholder: 'End date',
    consultation_id_label: 'Consultation ID',
    status_label: 'Status',
    status_placeholder: 'Select status...',
    consumer_network_label: 'Consumer Network',
    consumer_network_placeholder: 'Select consumer network...',
    consumer_network_options_loading_message: 'Loading...',
    patient_column_heading: 'Patient',
    patient_family_name_label: 'Family name',
    patient_given_name_label: 'Given name',
    patient_gender_label: 'Gender',
    patient_gender_placeholder: 'Select gender...',
    patient_id_label: 'Patient ID',
    patient_healthcare_id_label: 'Healthcare ID',
    patient_date_of_birth_label: 'Date of Birth',
    patient_date_of_birth_placeholder: 'DD/MM/YYYY',
    clinician_column_heading: 'Clinician',
    clinician_name_label: 'Clinician Name',
    clinician_name_options_loading_message: 'Loading...',
    clinician_name_options_search_instructions_message:
      'Please enter a name...',
    clinician_name_options_no_results_found_message:
      'No clinicians found matching that name',
    clinician_id_label: 'Clinician ID',
    reset_filters_button_label: 'Reset filters',
    apply_filters_button_label: 'Apply filters',
    disableSubmitButton: false,
  },
  patientGenderOptions: [
    {
      key: 'male',
      value: 'Male',
    },
    {
      key: 'female',
      value: 'Female',
    },
  ],
  consultationStatusOptions: [
    {
      key: 'created',
      value: 'Upcoming (to be confirmed)',
    },
    {
      key: 'confirmed',
      value: 'Upcoming (confirmed)',
    },
    {
      key: 'completed',
      value: 'Completed',
    },
    {
      key: 'cancelled',
      value: 'Cancelled',
    },
    {
      key: 'no_show',
      value: 'No Show',
    },
  ],
}

export default AllConsultationsFiltersForm
