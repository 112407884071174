import React from 'react'

const CloseButton = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16">
    <g
      id="Urgency-Options-v1"
      transform="translate(-1407.000000, -97.000000)"
      fill="#4A4A4A"
    >
      <g transform="translate(718.000000, 80.000000)">
        <path
          fill="#4A4A4A"
          d="M695.142136,23.1421356 L695.142136,17.1421356 C695.142136,16.5898509 695.589851,16.1421356 696.142136,16.1421356 L698.142136,16.1421356 C698.69442,16.1421356 699.142136,16.5898509 699.142136,17.1421356 L699.142136,23.1421356 L705.142136,23.1421356 C705.69442,23.1421356 706.142136,23.5898509 706.142136,24.1421356 L706.142136,26.1421356 C706.142136,26.6944204 705.69442,27.1421356 705.142136,27.1421356 L699.142136,27.1421356 L699.142136,33.1421356 C699.142136,33.6944204 698.69442,34.1421356 698.142136,34.1421356 L696.142136,34.1421356 C695.589851,34.1421356 695.142136,33.6944204 695.142136,33.1421356 L695.142136,27.1421356 L689.142136,27.1421356 C688.589851,27.1421356 688.142136,26.6944204 688.142136,26.1421356 L688.142136,24.1421356 C688.142136,23.5898509 688.589851,23.1421356 689.142136,23.1421356 L695.142136,23.1421356 Z"
          transform="translate(697.142136, 25.142136) rotate(45.000000) translate(-697.142136, -25.142136)"
        />
      </g>
    </g>
  </svg>
)

export default CloseButton
