import * as React from 'react'

const NavigationUp = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M2.284 16.338l9.03-9.052a.969.969 0 011.373 0l9.029 9.052a.977.977 0 010 1.377.97.97 0 01-1.374 0L12 9.352l-8.342 8.363a.97.97 0 01-1.374 0 .977.977 0 010-1.377z" />
  </svg>
)

export default NavigationUp
