import React, { useReducer, useEffect } from 'react'
import modalReducer, { ModalAction } from './modalReducer'
import Modal from './Modal'
import ModalContext from './ModalContext'
import IModalState from './modalState'
import { IModalProviderProps } from './ModalProvider.types'

function ModalProvider({
  children,
  closeButtonText = 'Close',
  backButtonText = 'Back',
}: IModalProviderProps) {
  const initialState: IModalState = {
    isModalOpen: false,
    closeButtonText,
    backButtonText,
    modalQueue: [],
    isDismissable: true,
    goBack: undefined,
  }

  const [state, dispatch] = useReducer(modalReducer, initialState)
  const value = { state, dispatch }

  useEffect(() => {
    dispatch({
      type: ModalAction.UPDATE_MODAL_PROPS,
      payload: { closeButtonText, backButtonText },
    })
  }, [closeButtonText, backButtonText])

  return (
    <ModalContext.Provider value={value}>
      {children}
      <Modal />
    </ModalContext.Provider>
  )
}

export default ModalProvider
