import React from 'react'
import PropType from 'prop-types'

import { Input } from '../../foundation'

import './index.scss'

const FormikTextField = ({
  id,
  form: { touched, errors },
  field: { value, name, ...restOfFieldProps },
  label,
  placeholder,
}) => {
  const hasError = touched[name] && !!errors[name]

  console.warn(
    'FormikTextField will be deprecated in next release of core-ui. ' +
      'Please use FormikInput instead.'
  )

  return (
    <div className="core-form-text-field">
      {label && (
        <label className="core-form-text-field__label" htmlFor={id}>
          {label}
        </label>
      )}

      <Input
        name={name}
        value={value || ''}
        intent={hasError ? 'error' : null}
        placeholder={placeholder}
        fill
        {...restOfFieldProps}
      />

      {hasError && (
        <span className="core-form-text-field__errorMessage">
          {errors[name]}
        </span>
      )}
    </div>
  )
}

FormikTextField.propTypes = {
  id: PropType.string,
  label: PropType.string,
  placeholder: PropType.string,
}

export default FormikTextField
