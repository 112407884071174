import React, { useMemo, useCallback } from 'react'
import { useQuery } from 'react-apollo'
import { Spinner } from '@babylon/medkit'

import { PageErrorMessage } from '@babylon/cce-shared-components'
import { setPermissions } from './setPermissions'
import { CurrentUserContext } from './core'
import { CurrentUserQuery } from './queries'

import styles from './styles.module.scss'

type Props = {
  children?: React.ReactNode
}

function CurrentUser({ children }: Props) {
  const { loading, error, data } = useQuery(CurrentUserQuery, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  })

  const currentUser = data?.currentPortalUser || null
  const permissions = useMemo(
    () => currentUser && setPermissions(currentUser.roles),
    [currentUser]
  )

  const isPermitted = useCallback(
    (path: string) =>
      path.split('.').reduce((acc, step) => acc[step] || false, permissions),
    [permissions]
  )

  return (
    <>
      {loading && (
        <div className={styles.fullScreenSpinner}>
          <Spinner size={80} className={styles.icon} />
        </div>
      )}
      {error && <PageErrorMessage />}
      {!loading && !error && (
        <CurrentUserContext.Provider value={{ ...currentUser, isPermitted }}>
          {children}
        </CurrentUserContext.Provider>
      )}
    </>
  )
}

export default CurrentUser
