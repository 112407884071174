import * as React from 'react'

const QuestionFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zM9.001 9.792a.67.67 0 00.655.713 1.428 1.428 0 00.233-.014.69.69 0 00.594-.622c.02-.222.074-.42.16-.596a1.406 1.406 0 01.787-.718c.178-.067.375-.102.592-.102.228 0 .433.035.62.104.183.067.338.16.469.278.127.115.225.25.297.411.07.158.106.334.106.534a1.166 1.166 0 01-.197.668 1.898 1.898 0 01-.298.345c-.14.13-.31.268-.511.414-.247.175-.46.345-.635.509a2.852 2.852 0 00-.464.546c-.128.2-.22.416-.273.644a3 3 0 00-.074.685v.611c.121.344.371.515.75.515.38 0 .627-.171.742-.515v-.56c0-.15.013-.28.038-.388a.816.816 0 01.112-.26c.06-.092.145-.19.259-.291a4.89 4.89 0 01.491-.379 5.53 5.53 0 00.677-.546c.198-.19.363-.388.496-.595.137-.214.24-.44.306-.678.066-.234.099-.483.099-.745a2.668 2.668 0 00-.866-1.981 2.917 2.917 0 00-.953-.575A3.331 3.331 0 0012.035 7c-.423 0-.817.07-1.18.214-.36.141-.675.338-.943.588a2.81 2.81 0 00-.64.891 3.09 3.09 0 00-.271 1.1zm2.823 7.594c.26 0 .494-.095.676-.277a.938.938 0 00.278-.676.93.93 0 00-.278-.67.938.938 0 00-.676-.277.93.93 0 00-.67.277.93.93 0 00-.277.67c0 .259.096.494.278.676a.93.93 0 00.67.277z" />
  </svg>
)

export default QuestionFilled
