import { setLocalConf, envFlag, envVar } from '@babylon/babylon-env'
import dotEnvConfig from './.env'

if (process.env.NODE_ENV !== 'production') {
  setLocalConf(dotEnvConfig)
}

const queryParams = {}
const urlQuery = new URLSearchParams(window.location.search)

if (envFlag('ENABLE_FEATURE_QUERY_OVERRIDES')) {
  urlQuery.forEach((value, key) => {
    if (key.match(/^FEATURE_/)) {
      queryParams[key] = value
    }
  })
}

if (envVar('ALLOWED_ENV_QUERY_OVERRIDES')) {
  envVar('ALLOWED_ENV_QUERY_OVERRIDES')
    .split(/,\s*/)
    .forEach((key) => {
      if (urlQuery.has(`ENV_${key}`)) {
        queryParams[key] = urlQuery.get(`ENV_${key}`)
      }
    })
}

Object.assign(global.envVars, queryParams)
