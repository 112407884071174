import * as React from 'react'

const File = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16.026 11.25a.75.75 0 110 1.5H8.017a.75.75 0 110-1.5h8.01zm-.813-4.638h3.668a.712.712 0 110 1.425H14.5a.712.712 0 01-.713-.712V3.158a.712.712 0 111.425 0v3.454zm.813 10.638a.75.75 0 110 1.5H8.017a.75.75 0 110-1.5h8.01zm0-3a.75.75 0 110 1.5H8.017a.75.75 0 110-1.5h8.01zm-.73-10.841H5.718a.281.281 0 00-.292.282v16.513c0 .148.134.282.289.282h12.618a.292.292 0 00.289-.284V6.774L15.297 3.41zM12.024 21.91h-6.31A1.718 1.718 0 014 20.204V3.691c0-.946.763-1.707 1.717-1.707h9.877c.19 0 .373.076.507.212l3.74 3.785a.712.712 0 01.205.5v13.721c0 .943-.775 1.71-1.714 1.71h-6.309z" />
  </svg>
)

export default File
