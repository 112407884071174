import React from 'react'

import Spinner from '../Spinner'

import styles from './button.module.scss'

type ButtonLabelProps = {
  children: React.ReactNode
  loadingLabel?: string
  isLoading?: boolean
}

const ButtonLabel: React.FC<ButtonLabelProps> = ({
  children,
  loadingLabel = 'Loading',
  isLoading,
}: ButtonLabelProps) => (
  <span className={styles.ButtonLabel}>
    {isLoading ? (
      <>
        <Spinner className={styles.ButtonLoadingSpinner} />
        <span className={styles.ButtonLoadingLabel}>{loadingLabel}</span>
      </>
    ) : (
      <>{children}</>
    )}
  </span>
)

export default ButtonLabel
