import React from 'react'

import styles from './HelpText.module.scss'

const HintText: React.FC<{ id?: string }> = ({ children, id }) => (
  <div className={styles.HelpText} id={id}>
    {children}
  </div>
)

export default HintText
