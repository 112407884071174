import React from 'react'

import context from './modalContext'

import styles from './styles.module.scss'

const { Provider } = context

export interface ModalStackProps {
  children: React.ReactNode
}

const ModalStack = ({ children }: ModalStackProps) => {
  let root = document.getElementById('modal-root')

  if (!root) {
    root = document.createElement('div')
    root.setAttribute('id', 'modal-root')
    root.classList.add(styles.modalRoot)
    root.setAttribute('data-testid', 'modal-root')
    const dim = document.createElement('div')
    dim.classList.add(styles.dim)
    dim.setAttribute('data-testid', 'modal-dim')
    root.appendChild(dim)
    document.body.appendChild(root)
  }

  return <Provider value={{ root }}>{children}</Provider>
}

export default ModalStack
