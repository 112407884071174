class ValidationError extends Error {
  errors: Dictionary<any>

  constructor(errors: Dictionary<any>, message: string = 'Validation failed') {
    super(message)
    this.errors = errors
    this.name = 'ValidationError'
  }
}

export default ValidationError
