import * as React from 'react'

const Settings = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14.25 12a2.25 2.25 0 10-4.5 0 2.25 2.25 0 004.5 0zm1.5 0a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zm2.607-7.1a.75.75 0 011.02.274l2.271 3.897a.75.75 0 01-.275 1.028l-1.332.763a7.94 7.94 0 010 2.278l1.331.76a.75.75 0 01.276 1.03l-2.27 3.896a.75.75 0 01-1.02.274l-1.333-.762a8.087 8.087 0 01-2.005 1.143V21a.75.75 0 01-.75.75H9.73a.75.75 0 01-.75-.75v-1.522a8.175 8.175 0 01-2.002-1.142l-1.335.764a.75.75 0 01-1.02-.274L2.351 14.93a.75.75 0 01.276-1.028l1.332-.763a7.922 7.922 0 010-2.276L2.627 10.1a.75.75 0 01-.275-1.028l2.27-3.897A.75.75 0 015.643 4.9l1.332.763A8.106 8.106 0 018.98 4.52V3a.75.75 0 01.75-.75h4.54a.75.75 0 01.75.75v1.524c.72.285 1.395.67 2.002 1.14l1.335-.763zm.1 1.672l-1.142.653a.75.75 0 01-.871-.091 6.69 6.69 0 00-2.403-1.37.75.75 0 01-.52-.713V3.75H10.48v1.299a.75.75 0 01-.521.714c-.905.29-1.72.757-2.403 1.37a.75.75 0 01-.874.092l-1.138-.653-1.516 2.601 1.136.65a.75.75 0 01.36.81 6.434 6.434 0 000 2.735.75.75 0 01-.36.81l-1.135.65 1.515 2.6 1.141-.652a.75.75 0 01.872.092 6.674 6.674 0 002.402 1.368c.31.1.52.389.52.714v1.3h3.041v-1.297a.75.75 0 01.522-.715 6.593 6.593 0 002.402-1.368.75.75 0 01.873-.093l1.139.651 1.515-2.6-1.135-.65a.75.75 0 01-.36-.81 6.445 6.445 0 000-2.737.75.75 0 01.361-.809l1.135-.649-1.516-2.6z" />
  </svg>
)

export default Settings
