export interface PermissionsInterface {
  access?: boolean
  view?: boolean
  edit?: boolean
  create?: boolean
  delete?: boolean
  move?: boolean
  download?: boolean
}

export interface RoleContextInterface {
  partner?: PermissionsInterface
  plan?: PermissionsInterface
  feature?: PermissionsInterface
  userManagement?: PermissionsInterface
  ownAccountAllFields?: PermissionsInterface
  memberManagement?: PermissionsInterface
  reports?: PermissionsInterface
  privacy?: PermissionsInterface
  branding?: PermissionsInterface
  helpAndSupport?: PermissionsInterface
  dataContracts?: PermissionsInterface
  appointmentConfiguration?: PermissionsInterface
}

export interface RoleConfigInterface {
  name: string
  power: number
  permissions: RoleContextInterface
}

export interface RoleConfigDictInterface {
  default: RoleConfigInterface
  [roleId: string]: RoleConfigInterface
}

const userRolesDefault: RoleConfigDictInterface = {
  commercial_administrator: {
    name: 'Commercial Admin',
    power: 16,
    permissions: {
      branding: {
        edit: true,
        delete: true,
      },
      partner: {
        access: true,
        view: true,
        edit: true,
        create: true,
        delete: true,
        move: true,
      },
      plan: {
        view: true,
        create: true,
      },
      privacy: {
        view: true,
      },
      helpAndSupport: {
        view: true,
      },
    },
  },
  general_admin: {
    name: 'General Admin',
    power: 32,
    permissions: {
      branding: {
        edit: true,
        delete: true,
      },
      partner: {
        access: true,
        view: true,
        edit: true,
        create: true,
      },
      plan: {
        view: true,
        edit: true,
        create: true,
      },
      userManagement: {
        view: true,
        edit: true,
        create: true,
      },
      privacy: {
        view: true,
      },
      ownAccountAllFields: {
        view: true,
        edit: true,
      },
      memberManagement: {
        view: true,
        create: true,
        edit: true,
        download: true,
      },
      helpAndSupport: {
        view: true,
      },
      dataContracts: {
        view: true,
        create: true,
        edit: true,
        delete: true,
      },
      appointmentConfiguration: {
        view: true,
      },
    },
  },
  global_admin: {
    name: 'Global Admin',
    power: 64,
    permissions: {
      branding: {
        edit: true,
        delete: true,
      },
      partner: {
        access: true,
        view: true,
        edit: true,
        create: true,
        delete: true,
        move: true,
      },
      plan: {
        view: true,
        edit: true,
        create: true,
        delete: true,
      },
      memberManagement: {
        view: true,
        create: true,
        edit: true,
        delete: true,
        download: true,
      },
      userManagement: {
        view: true,
        edit: true,
        create: true,
      },
      privacy: {
        view: true,
      },
      ownAccountAllFields: {
        view: true,
        edit: true,
      },
      helpAndSupport: {
        view: true,
      },
      dataContracts: {
        view: true,
        create: true,
        edit: true,
        delete: true,
      },
      appointmentConfiguration: {
        view: true,
        create: true,
        edit: true,
        delete: true,
      },
    },
  },
  promotion_csv_manager: {
    name: 'Promo CSV Manager',
    power: 8,
    permissions: {
      partner: {
        access: true,
      },
      memberManagement: {
        view: true,
        create: true,
        edit: true,
        delete: true,
        download: true,
      },
      helpAndSupport: {
        view: true,
      },
      dataContracts: {
        view: true,
        create: true,
        edit: true,
        delete: true,
      },
    },
  },
  saas_general_admin: {
    name: 'SaaS General Admin',
    power: 8,
    permissions: {
      partner: {
        access: true,
      },
      memberManagement: {
        view: true,
        create: true,
        edit: true,
        delete: true,
      },
    },
  },
  saas_configuration_viewer: {
    name: 'Configuration Viewer',
    power: 8,
    permissions: {
      partner: {
        access: true,
        view: true,
      },
      plan: {
        view: true,
      },
      feature: {
        view: true,
      },
      privacy: {
        view: true,
      },
      helpAndSupport: {
        view: true,
      },
      ownAccountAllFields: {
        view: true,
        edit: false,
        create: false,
      },
      appointmentConfiguration: {
        view: true,
      },
    },
  },
  business_intelligence_analyst: {
    name: 'Business Intelligence Analyst',
    power: 8,
    permissions: {
      partner: {
        access: true,
        view: true,
      },
      plan: {
        view: true,
      },
      reports: {
        view: true,
      },
    },
  },
  developer: {
    name: 'Developer',
    power: 8,
    permissions: {
      partner: {
        access: true,
        view: true,
      },
      plan: {
        view: true,
      },
      feature: {
        view: true,
        edit: true,
      },
      privacy: {
        view: true,
      },
      helpAndSupport: {
        view: true,
      },
      appointmentConfiguration: {
        view: true,
        create: true,
        edit: true,
        delete: true,
      },
    },
  },
  marketing: {
    name: 'Marketing',
    power: 8,
    permissions: {
      partner: {
        access: true,
        view: true,
        edit: true,
        create: true,
      },
      plan: {
        view: true,
        edit: true,
      },
      helpAndSupport: {
        view: true,
      },
      appointmentConfiguration: {
        view: true,
      },
    },
  },
  population_health_manager: {
    name: 'Population Health Manager',
    power: 8,
    permissions: {
      appointmentConfiguration: {
        view: true,
      },
    },
  },
  care_coordinator: {
    name: 'Care Coordinator',
    power: 8,
    permissions: {
      appointmentConfiguration: {
        view: true,
      },
    },
  },
  clinops_support_associate: {
    name: 'Clinops Support Associate',
    power: 8,
    permissions: {
      appointmentConfiguration: {
        view: true,
      },
    },
  },
  default: {
    name: 'User',
    power: 0,
    permissions: {
      branding: {
        edit: false,
        delete: false,
      },
      partner: {
        access: false,
        view: false,
        edit: false,
        create: false,
        delete: false,
        move: false,
      },
      plan: {
        view: false,
        edit: false,
        create: false,
        delete: false,
      },
      feature: {
        view: false,
        edit: false,
        create: false,
        delete: false,
      },
      memberManagement: {
        view: false,
        edit: false,
        create: false,
        delete: false,
        download: false,
      },
      userManagement: {
        view: false,
        edit: false,
        create: false,
      },
      ownAccountAllFields: {
        view: false,
        edit: false,
        create: false,
      },
      reports: {
        view: false,
      },
      privacy: {
        view: false,
      },
      helpAndSupport: {
        view: false,
      },
      appointmentConfiguration: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
    },
  },
}

export default userRolesDefault
