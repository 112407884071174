import * as React from 'react'

const SettingsFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18.357 4.9a.75.75 0 011.02.274l2.271 3.897a.75.75 0 01-.275 1.028l-1.332.763a7.94 7.94 0 010 2.278l1.331.76a.75.75 0 01.276 1.03l-2.27 3.896a.75.75 0 01-1.02.274l-1.333-.762a8.087 8.087 0 01-2.005 1.143V21a.75.75 0 01-.75.75H9.73a.75.75 0 01-.75-.75v-1.522a8.175 8.175 0 01-2.002-1.142l-1.335.764a.75.75 0 01-1.02-.274L2.351 14.93a.75.75 0 01.276-1.028l1.332-.763a7.922 7.922 0 010-2.276L2.627 10.1a.75.75 0 01-.275-1.028l2.27-3.897A.75.75 0 015.643 4.9l1.332.763A8.106 8.106 0 018.98 4.52V3a.75.75 0 01.75-.75h4.54a.75.75 0 01.75.75v1.524c.72.285 1.395.67 2.002 1.14l1.335-.763zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z" />
  </svg>
)

export default SettingsFilled
