import * as React from 'react'

const ChildCircleFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zm-.026-5.087c-1.9 0-3.596.581-5.086 1.742 1.826 1.274 3.605 1.911 5.338 1.911s3.344-.637 4.833-1.91c-1.49-1.162-3.185-1.743-5.085-1.743zM12 6.75A4.243 4.243 0 007.75 11 4.243 4.243 0 0012 15.25 4.243 4.243 0 0016.25 11 4.243 4.243 0 0012 6.75zm.334 5.58a.75.75 0 111.114 1.005c-.422.468-.929.735-1.488.735-.58 0-1.107-.272-1.537-.759a.75.75 0 111.123-.994c.163.184.296.253.414.253.093 0 .217-.065.374-.24zM14 11a1 1 0 110-2 1 1 0 010 2zm-3-1a1 1 0 11-2 0 1 1 0 012 0z" />
  </svg>
)

export default ChildCircleFilled
