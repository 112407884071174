import * as React from 'react'

const ActivityFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14.014 6.01a1.885 1.885 0 01-1.881-1.88c0-1.035.846-1.88 1.88-1.88 1.036 0 1.882.845 1.882 1.88s-.846 1.88-1.881 1.88zm-.052 6.74a.65.65 0 00.13.518l1.524 1.925c.315.398.486.89.486 1.397v3.827a1.15 1.15 0 01-1.15 1.15h-.165a1.15 1.15 0 01-1.15-1.15v-3.401a.65.65 0 00-.165-.433l-.713-.799a.489.489 0 00-.83.174c-.352 1.078-.884 1.765-1.603 2.037-.851.32-2.405.186-4.696-.386a1.15 1.15 0 01-.853-1.323l.024-.132a1.15 1.15 0 011.34-.923l2.6.48a.65.65 0 00.757-.518l1.01-5.31a.34.34 0 00-.444-.385l-.587.203a.65.65 0 00-.384.355l-.914 2.105a1.15 1.15 0 01-1.055.692h-.375a.907.907 0 01-.844-1.24l1.252-3.175a1.15 1.15 0 01.534-.596c2.187-1.15 3.925-1.663 5.226-1.526.835.089 1.637.422 1.998.967l.767 1.223c1.085 1.328 1.596 1.732 2.648 1.931.696.132 1.16.433 1.16 1.157 0 .75-.697 1.375-1.235 1.314-1.547-.174-2.743-.9-3.929-2.173l-.364 2.014z" />
  </svg>
)

export default ActivityFilled
