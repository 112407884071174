import React, { ChangeEvent, Component } from 'react'
import cn from 'classnames'

import './index.scss'

type RadioContext = {
  name?: string
  selected?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export type IntentType = 'warning' | 'error' | 'success'

type Props = {
  disabled?: boolean
  id?: string
  className?: string
  checked?: boolean
  value: string
  name?: string | null
  intent?: IntentType
  inline?: boolean
  type?: 'radio' | 'pill'
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Context = React.createContext<RadioContext>({})

class Radio extends Component<Props> {
  getClassName = () => {
    const { disabled, className, intent, inline, type } = this.props
    const baseClassName = type === 'pill' ? 'core-radio-pill' : 'core-radio'

    return cn(
      baseClassName,
      intent ? `${baseClassName}--${intent}` : undefined,
      {
        [`${baseClassName}--checked`]: this.getChecked(),
        [`${baseClassName}--disabled`]: disabled,
        [`${baseClassName}--inline`]: inline,
      },
      className
    )
  }

  getChecked = () => {
    const checked = this.context.selected
      ? this.context.selected === this.props.value
      : this.props.checked

    return checked || false
  }

  getName = () => {
    return this.context.name || this.props.name || null
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const onChange = this.context.onChange || this.props.onChange

    if (onChange) {
      onChange(event)
    }
  }

  render() {
    const { disabled, children, value, type, id } = this.props
    const checked = this.getChecked()
    const name = this.getName()

    return (
      <label className={this.getClassName()}>
        <input
          // @ts-ignore
          id={id || this.id}
          type="radio"
          className="core-radio__input"
          name={name}
          value={value}
          disabled={disabled}
          checked={checked}
          onChange={this.handleChange}
        />
        {type !== 'pill' && <div className="core-radio__element" />}
        <div>{children}</div>
      </label>
    )
  }
}

Radio.contextType = Context

export default Radio
