import React, { useCallback } from 'react'
import { useFormatMessage } from '@babylon/intl'

import { LinkButton } from '@babylon/medkit'
import { Card } from '@/ui'
import { formatFileSize } from '@/utils'

import messages from './messages'

interface FilePreviewProps {
  name: string
  sizeInBytes: number
  onRemove: any
  className: string
}

function FilePreview({
  name,
  sizeInBytes,
  onRemove,
  className,
}: FilePreviewProps) {
  const fm = useFormatMessage()
  const handleCancel = useCallback(() => {
    onRemove()
  }, [onRemove])

  const actionElement = (
    <LinkButton
      type="button"
      onClick={handleCancel}
      data-testid="filePreviewCancel"
    >
      {fm(messages.cancelLabel)}
    </LinkButton>
  )

  return (
    <Card
      icon="FilesNotes"
      title={name}
      className={className}
      message={`${fm(messages.fileSizeLabel)} ${formatFileSize(sizeInBytes)}`}
      action={actionElement}
    />
  )
}

export default FilePreview
