import * as React from 'react'

const VideoClipFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M2.146 18.292V7.799a.75.75 0 01.75-.75h18.208a.75.75 0 01.75.75v10.493c0 .965-.787 1.75-1.755 1.75H3.901a1.752 1.752 0 01-1.755-1.75zM9.825 17c0 .542.607.863 1.055.558l4.767-3.25a.675.675 0 000-1.116l-4.767-3.25a.675.675 0 00-1.055.558V17zm4.243-3.25l-2.893 1.973v-3.946l2.893 1.973zM2.896 8.576a.75.75 0 01-.75-.75V5.778c0-.963.788-1.747 1.755-1.747h16.198c.968 0 1.755.781 1.755 1.747v2.048a.75.75 0 01-.75.75H2.896zm17.458-1.5V5.778a.251.251 0 00-.255-.247H3.901a.253.253 0 00-.255.247v1.298h16.708zM14.813 5.39a.75.75 0 111.5 0V7.5a.75.75 0 11-1.5 0V5.389zm-7.126 0a.75.75 0 011.5 0V7.5a.75.75 0 01-1.5 0V5.389z" />
  </svg>
)

export default VideoClipFilled
