import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  ChangeEvent,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react'
import cx from 'classnames'
import { noop } from 'lodash'

import { useFormatMessage } from '@babylon/intl'
import { Icon } from '@babylon/medkit'

import { formatFileSize, isFileValidType } from '@/utils'
import { DragAndDropFileType } from '@/ui'

import styles from './styles.module.scss'
import messages from './messages'

interface DragAndDropAreaProps {
  value: DragAndDropFileType | null
  onChange?: (value: any) => any
  acceptedTypes?: string[]
  maxSizeInBytes?: number
  trackValidationError?: () => void
  className?: string
  'data-testid'?: string
}

export const DragAndDropAreaErrorContext = createContext<{
  setError: Dispatch<SetStateAction<object | null>>
}>({ setError: noop })

export function DragAndDropArea({
  value,
  onChange,
  acceptedTypes,
  maxSizeInBytes,
  trackValidationError,
  className,
  'data-testid': dataTestId,
}: DragAndDropAreaProps) {
  const fm = useFormatMessage()
  const fileRef: any = useRef(null)
  const [file, setFile] = useState<File | null>(null)
  const [isDragActive, setIsDragActive] = useState(false)
  const { setError } = useContext(DragAndDropAreaErrorContext)

  const handleBrowseClick = () => {
    if (fileRef?.current) {
      fileRef.current.click()
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragActive(true)
  }

  const handleDragLeave = () => setIsDragActive(false)

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    setError(null)
    setFile(event?.dataTransfer?.items?.[0].getAsFile() || null)
  }

  const handleLoad = useCallback(
    (event) => {
      const dataUrl = event.target.result

      if (dataUrl !== value && onChange) {
        onChange({
          name: file?.name,
          sizeInBytes: file?.size,
          base64: dataUrl,
          file,
        })
      }
    },
    [file, onChange, value]
  )

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFile(event?.currentTarget?.files?.[0] || null)
  }

  useEffect(() => {
    if (!file) {
      return
    }

    if (file && acceptedTypes && !isFileValidType(file, acceptedTypes)) {
      if (trackValidationError) {
        trackValidationError()
      }
      setError({
        title: fm(messages.fileInvalidError),
        message: [
          fm(messages.dragAndDropErrorInvalidType, {
            formats: acceptedTypes.join(', '),
          }),
          fm(messages.fileInvalidInfo),
        ],
      })
      return
    }

    if (maxSizeInBytes && file?.size > maxSizeInBytes) {
      if (trackValidationError) {
        trackValidationError()
      }
      setError({
        title: fm(messages.fileInvalidError),
        message: [
          fm(messages.dragAndDropErrorTooBig, {
            size: formatFileSize(file?.size),
            maxSize: formatFileSize(maxSizeInBytes),
          }),
          fm(messages.fileInvalidInfo),
        ],
      })
      return
    }

    const reader = new FileReader()

    reader.addEventListener('load', handleLoad, { once: true })
    reader.readAsDataURL((file as unknown) as Blob)
    // We want the effect to run only when file changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  return (
    <>
      <div
        className={cx(
          styles.dragAndDrop,
          {
            [styles.dropHover]: isDragActive,
          },
          className
        )}
        onDragOver={handleDragOver}
        role="main"
        data-testid={dataTestId}
      >
        <input
          type="file"
          className={styles.input}
          onChange={handleFileChange}
          ref={fileRef}
          value=""
          data-testid="dragAndDropArea"
        />
        <Icon
          icon="FilesAttachment"
          title="Attachment icon"
          height={68}
          width={68}
          className={styles.iconWrapper}
          iconClassName={styles.icon}
        />
        <span className={styles.title}>
          <p>{fm(messages.dragAndDropInfo)}</p>
          <p>{fm(messages.dragAndDropOr)}</p>
          <button
            type="button"
            className={styles.uploadButton}
            onClick={handleBrowseClick}
            data-testid="dragAndDropAreaBrowseButton"
          >
            {fm(messages.browseLabel)}
          </button>
        </span>
        {isDragActive && (
          <div
            className={styles.overlay}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          />
        )}
      </div>
    </>
  )
}
