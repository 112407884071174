import React from 'react'
import { useHistory } from 'react-router-dom'

import { usePersistentStore } from '@/components/hooks'
import { useCurrentUser } from '@/components/CurrentUser'
import { ModalAnimation, useModalAnimation } from '@/components/ModalAnimation'
import { Modal } from '@/ui'

import TimezoneUpdateDialog from './TimezoneUpdateDialog'

function TimezoneUpdate() {
  const history = useHistory()
  const user = useCurrentUser()
  const [state, setState] = usePersistentStore()
  const dateTimeFormatter = new Intl.DateTimeFormat()
  const hasBeenSeen = state.updateTimezone.seen
  const browserTimezone = dateTimeFormatter.resolvedOptions().timeZone
  const hasDifferentTimezone = user?.timezone !== browserTimezone

  const [
    showModal,
    showAnimation,
    handleHide,
    setShowModal,
  ] = useModalAnimation<boolean>(true)

  if (!hasDifferentTimezone) {
    setState({
      updateTimezone: { seen: false },
    })
  }

  const onCancel = () => {
    setState({
      updateTimezone: { seen: true },
    })
    setShowModal(false)
  }

  const onContinue = () => {
    setShowModal(false)
    setState({
      updateTimezone: { seen: true },
    })

    history.push(`/user-management/${user?.uuid}?mode=edit`)
  }

  return (
    <>
      {showModal && !hasBeenSeen && hasDifferentTimezone && (
        <Modal>
          <ModalAnimation show={showAnimation} onHide={handleHide}>
            <TimezoneUpdateDialog
              browserTimezone={browserTimezone}
              onCancel={onCancel}
              onContinue={onContinue}
            />
          </ModalAnimation>
        </Modal>
      )}
    </>
  )
}

export default TimezoneUpdate
