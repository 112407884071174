import * as React from 'react'

const Specialist = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M4.78 20.547h14.317c-.63-3.922-2.93-5.797-7.078-5.797s-6.505 1.879-7.24 5.797zm-1.618.66c.637-5.24 3.659-7.957 8.857-7.957 5.205 0 8.17 2.728 8.676 7.975a.75.75 0 01-.747.822H3.906a.75.75 0 01-.744-.84zM12 11.75a4.75 4.75 0 110-9.5 4.75 4.75 0 010 9.5zm0-1.5a3.25 3.25 0 100-6.5 3.25 3.25 0 000 6.5zm-1.421 10.819l.75-3.702c.162-.801 1.307-.801 1.47 0l.75 3.702a.75.75 0 01-.735.899h-1.5a.75.75 0 01-.735-.9zm2.171-5.829a.75.75 0 01-1.5 0v-1.22a.75.75 0 011.5 0v1.221z" />
  </svg>
)

export default Specialist
