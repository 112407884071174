import * as React from 'react'

const NavigationMoreVertical = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.5 12a1.5 1.5 0 10-3.001.001A1.5 1.5 0 0013.5 12zm0 8a1.5 1.5 0 10-3.001.001A1.5 1.5 0 0013.5 20zm0-16a1.5 1.5 0 10-3.001.001A1.5 1.5 0 0013.5 4z" />
  </svg>
)

export default NavigationMoreVertical
