/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import axios, { AxiosRequestConfig } from 'axios'
import cookies from 'browser-cookies'
import { RequestIdGenerator } from '@babylon/shared-utils'
import {
  BABYLON_AUTH_LEGACY_PORTAL_URL,
  BABYLON_AUTH_GRAPHQL_URL,
  BABYLON_AUTH_LOGOUT_URL,
} from './env-urls'
import mergeInflight from './merge-inflight'

if (!BABYLON_AUTH_LOGOUT_URL) {
  throw new Error('BABYLON_AUTH_LOGOUT_URL must be defined to use babylon-auth')
}

let requestIdGenerator = new RequestIdGenerator({ app: 'auth-lib' })

export const setRequestIdGenerator = (
  requestIdGeneratorOverride: RequestIdGenerator
) => {
  requestIdGenerator = requestIdGeneratorOverride
}

interface TokenInfo {
  type: ClientTypeV2
  issue_time: number
  token_expiry: number
  kong_token_expiry: number
  csrf_token: string
  account_maintenance_required?: boolean
}

export const autologinInfo = (): TokenInfo =>
  JSON.parse(cookies.get('autologin_info') || '{}')

export const clinicalInfo = (): TokenInfo =>
  JSON.parse(cookies.get('clinical_info') || '{}')

interface Options {
  logoutStatusCodes: number[]
  tokenRefreshPoint: number
  cookiePollTime: number
  userActivityThrottle: number
  axiosOpts: () => AxiosRequestConfig
}

const defaultOpts: Options = {
  logoutStatusCodes: [401, 403],
  tokenRefreshPoint: 10 / 30, // 10 mins (assuming a 30 min token)
  cookiePollTime: 1000, // 1 sec
  userActivityThrottle: 2 * 60 * 1000, // 2 minutes
  axiosOpts: () => ({}),
}

let opts: Options = { ...defaultOpts }

export const getAuthConfig = () => opts
export const setAuthConfig = (userOpts = {}) => {
  opts = {
    ...defaultOpts,
    ...userOpts,
  }
}

export const getCsrfHeaders = () => {
  const token = autologinInfo().csrf_token || null
  return token ? { 'X-Security-Token': token } : {}
}

const axiosOpts = () => {
  const csrfHeaders = getCsrfHeaders()
  const userAxiosOpts = opts.axiosOpts()

  return {
    ...userAxiosOpts,
    withCredentials: true,
    headers: {
      'babylon-request-id': requestIdGenerator.generate(),
      ...userAxiosOpts.headers,
      ...csrfHeaders,
    },
  }
}

type ClientType = 'Patient' | 'Portal'
type ClientTypeV2 = 'patient' | 'portal'
type ServerType = 'patient' | 'portal_login'
const serverType = (type: ClientType): ServerType =>
  type === 'Patient' ? 'patient' : 'portal_login'

export const unixEpochTime = (time: number) => Math.floor(time / 1000)

// For debugging
export const readableTime = (unixEpocTime: number) =>
  new Date(unixEpocTime * 1000).toLocaleTimeString()

export const kongTokenIsValid = (
  type: ClientTypeV2,
  loginInfo = autologinInfo()
) =>
  loginInfo &&
  loginInfo.type === type &&
  loginInfo.kong_token_expiry > unixEpochTime(Date.now())

export const refreshTokenIsValid = (
  type: ClientTypeV2,
  loginInfo = autologinInfo()
) =>
  loginInfo &&
  loginInfo.type === type &&
  loginInfo.token_expiry > unixEpochTime(Date.now())

export const clinicalTokenIsValid = (
  _type: ServerType,
  clinInfo = clinicalInfo()
) => clinInfo && clinInfo.token_expiry > unixEpochTime(Date.now())

const kongTokenPastRefreshBoundary = ({
  issue_time,
  kong_token_expiry,
}: TokenInfo) => {
  const tokenDuration = kong_token_expiry - issue_time
  const refreshBoundary = issue_time + tokenDuration * opts.tokenRefreshPoint
  const timeNow = unixEpochTime(Date.now())

  return timeNow > refreshBoundary
}

export const kongTokenRefreshDue = (
  type: ClientTypeV2,
  loginInfo = autologinInfo()
) =>
  refreshTokenIsValid(type, loginInfo) &&
  (!kongTokenIsValid(type, loginInfo) ||
    kongTokenPastRefreshBoundary(loginInfo))

export const login = async (
  email: string,
  password: string,
  type: ClientType,
  loginOpts = { clinical: false, stayLoggedIn: false }
) => {
  if (type !== 'Patient' && type !== 'Portal') {
    throw new Error(
      `Login type of '${type}' not supported: must be either 'Patient' or 'Portal'`
    )
  }
  const endpoint = loginOpts.clinical ? 'clinical-auth' : 'auth'

  try {
    return await axios.post(
      `${BABYLON_AUTH_GRAPHQL_URL}/${endpoint}`,
      {
        email,
        password,
        type: serverType(type),
        stayLoggedIn: loginOpts.stayLoggedIn,
      },
      axiosOpts()
    )
  } catch ({ response = {} }) {
    throw {
      status: response.status || 0,
      ...(response.data || {}),
    }
  }
}

export const loginWithFacebook = mergeInflight(async (token) => {
  try {
    const response = await axios.post(
      `${BABYLON_AUTH_GRAPHQL_URL}/facebook-auth`,
      {
        facebook_access_token: token,
      },
      axiosOpts()
    )
    return response
  } catch (error) {
    const { response } = error
    if (response && response.status === 422) {
      // TODO: inform the user that their credentials are invalid via the UI
      return response.data
    }
    console.error(response)
  }
})

export const refreshKongToken = mergeInflight(async (type: ClientTypeV2) => {
  try {
    if (type === 'portal') {
      return await axios.put(
        `${BABYLON_AUTH_LEGACY_PORTAL_URL}clinical_portal/api/v1/session`,
        null,
        axiosOpts()
      )
    }
    return await axios.post(
      `${BABYLON_AUTH_GRAPHQL_URL}/reauth`,
      null,
      axiosOpts()
    )
  } catch (error) {
    const { response } = error
    if (response && opts.logoutStatusCodes.includes(response.status)) {
      const logoutOpts =
        type === 'portal'
          ? defaultLogoutOpts
          : { ...defaultLogoutOpts, redirect: false }
      return logout(logoutOpts)
    }
    throw error
  }
})

export const getCurrentUser = () =>
  axios.get(`${BABYLON_AUTH_GRAPHQL_URL}/current-user`, axiosOpts())

interface LogoutOpts {
  redirect: boolean
  redirectTo: string
  autoLogout: boolean
  unexpectedLogout: boolean
}

const defaultLogoutOpts: LogoutOpts = {
  redirect: true,
  redirectTo: BABYLON_AUTH_LOGOUT_URL,
  autoLogout: false,
  unexpectedLogout: false,
}

export const logout = mergeInflight(
  async (userLogoutOpts: Partial<LogoutOpts>) => {
    const logoutOpts = {
      ...defaultLogoutOpts,
      ...userLogoutOpts,
    }
    const param = logoutOpts.unexpectedLogout
      ? '?unexpected-logout'
      : logoutOpts.autoLogout
      ? '?auto-logout'
      : ''
    const url = `${BABYLON_AUTH_GRAPHQL_URL}/logout${param}`

    const response = await axios.post(url, null, axiosOpts())

    if (logoutOpts.redirect) {
      location.href = `${logoutOpts.redirectTo}?continue=${location.href}`
    }

    return response
  }
)
