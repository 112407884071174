import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Context } from '../Checkbox/Checkbox'

class CheckboxGroup extends Component {
  handleChange = (event) => {
    const { onChange, value: prevValues } = this.props
    const { value } = event.target

    const nextValues = prevValues.includes(value)
      ? prevValues.filter((v) => v !== value)
      : [...prevValues, value]

    if (onChange) {
      onChange(nextValues)
    }
  }

  render() {
    const {
      tag: Container,
      value,
      className,
      name,
      children,
      testId,
    } = this.props

    return (
      <Container className={className}>
        <Context.Provider
          value={{
            name,
            value,
            onChange: this.handleChange,
            'data-testid': testId,
          }}
        >
          {children}
        </Context.Provider>
      </Container>
    )
  }
}

CheckboxGroup.defaultProps = {
  tag: 'div',
}

CheckboxGroup.propTypes = {
  tag: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  className: PropTypes.string,
}

export default CheckboxGroup
