import { useMemo, useContext } from 'react'

import { useCurrentUser } from '@/components/CurrentUser'
import { UserRolesContext } from './core'

function useRoles() {
  const user = useCurrentUser()
  const userRoles = useContext(UserRolesContext)

  return useMemo(() => {
    const roles = user.roles
      .filter(({ key }) => !!userRoles[key])
      .map(({ key }) => ({
        ...userRoles[key],
        id: key,
      }))
      // Sort the roles by the power in descending order.
      .sort((a, b) => b.power - a.power)

    if (!roles.length) {
      roles.push(userRoles.default)
    }

    return roles
  }, [user.roles, userRoles])
}

export default useRoles
