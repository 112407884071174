import * as React from 'react'

const SelfieFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.244 4.58L14 3.889a.75.75 0 011.243.69h1.675c.967 0 1.75.784 1.75 1.75v13.94a1.75 1.75 0 01-1.75 1.75H7.086a1.75 1.75 0 01-1.75-1.75V6.328c0-.966.784-1.75 1.75-1.75h1.768a.749.749 0 011.271-.643l.645.644h.487a.757.757 0 01-.007-.1V3.295a.75.75 0 111.5 0v1.183a.757.757 0 01-.007.1h.501zm-2.169 8.376c-1.816.308-2.912 1.573-3.16 3.66a.75.75 0 101.49.177c.199-1.677.997-2.411 2.595-2.411 1.59 0 2.36.724 2.518 2.392a.75.75 0 001.494-.14c-.198-2.095-1.272-3.368-3.088-3.678a2.436 2.436 0 001.473-2.247c0-1.34-1.07-2.434-2.397-2.434-1.328 0-2.397 1.093-2.397 2.434 0 1.01.606 1.879 1.472 2.247zM10.5 19.75h3a.75.75 0 100-1.5h-3a.75.75 0 100 1.5zm2.3-12.896a.796.796 0 00-.8-.792c-.442 0-.8.355-.8.792 0 .437.358.792.8.792.442 0 .8-.355.8-.792zm-.8 4.79c-.492 0-.897-.415-.897-.935s.405-.934.897-.934.897.414.897.934-.405.934-.897.934z" />
  </svg>
)

export default SelfieFilled
