import React from 'react'
import PropTypes from 'prop-types'

const angleMap = {
  down: 0,
  left: 90,
  up: 180,
  right: 270,
}

const Chevron = ({ direction = 'down', ...props }) => {
  const angle = angleMap[direction]
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      xmlns="http://www.w3.org/2000/svg"
      transform={angle ? `rotate(${angle})` : null}
      {...props}
    >
      <path d="M12.676.233a.758.758 0 0 1 1.1.008.83.83 0 0 1-.008 1.142L7.536 7.767a.757.757 0 0 1-1.093 0L.231 1.381A.83.83 0 0 1 .225.24a.758.758 0 0 1 1.1-.006L6.99 6.057 12.676.233z" />
    </svg>
  )
}

Chevron.propTypes = {
  direction: PropTypes.oneOf(['down', 'left', 'right', 'up']),
}

export default Chevron
