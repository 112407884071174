import * as React from 'react'

const Pharmacy = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.75 14.434v1.433a.75.75 0 01-1.5 0v-1.433H9.817a.75.75 0 010-1.5h1.433v-1.433a.75.75 0 111.5 0v1.433h1.433a.75.75 0 010 1.5H12.75zm2.201-6.684l5.31-5.282a.75.75 0 111.059 1.064L17.078 7.75h4.114a.75.75 0 110 1.5H2.772a.75.75 0 010-1.5h12.18zm-8.208 12a.75.75 0 110-1.5h10.476a.75.75 0 110 1.5H6.743zM2.75 7.84h18.5v2.66a9.25 9.25 0 01-18.5 0V7.84zm1.5 1.5v1.16a7.75 7.75 0 0015.5 0V9.34H4.25z" />
  </svg>
)

export default Pharmacy
