import * as React from 'react'

const ShareIos = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.75 4.606v12.44a.75.75 0 11-1.5 0V4.606L6.755 9.219A.75.75 0 115.68 8.172l5.783-5.934a.75.75 0 011.074 0l5.783 5.934a.75.75 0 01-1.075 1.047L12.75 4.606zm7.744 12.667a.75.75 0 011.5 0v2.953c0 .962-.789 1.747-1.743 1.747H3.78a1.749 1.749 0 01-1.742-1.747v-2.953a.75.75 0 111.5 0v2.953a.25.25 0 00.242.247h16.472a.253.253 0 00.243-.247v-2.953z" />
  </svg>
)

export default ShareIos
