import * as React from 'react'

const VideoCallFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10 2.933v3.713h-.002a.75.75 0 01-.75.75H3.76V17.75h10.466v-6.677a.75.75 0 011.5 0v9.145a1.75 1.75 0 01-1.75 1.75H4.009a1.75 1.75 0 01-1.75-1.75V3.86c0-.966.784-1.75 1.75-1.75h5.245a.75.75 0 01.746.823zm8.492 3.713V4.629c0-.256.138-.492.36-.619l2.079-1.18a.712.712 0 011.064.619v4.436a.712.712 0 01-1.077.612l-2.078-1.24a.713.713 0 01-.348-.611zM13 2.087h3.166c.967 0 1.75.784 1.75 1.75v3.655a1.75 1.75 0 01-1.75 1.75H13a1.75 1.75 0 01-1.75-1.75V3.837c0-.966.784-1.75 1.75-1.75z" />
  </svg>
)

export default VideoCallFilled
