import * as React from 'react'

const Shield = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.586 2.42c1.614 1.961 4.212 2.232 7.086.269a.75.75 0 011.173.62v11.725l-.028.204c-.286 1.017-1.172 2.465-2.973 3.881-1.457 1.145-3.324 2.072-5.646 2.708a.75.75 0 01-.396 0c-2.322-.636-4.19-1.563-5.646-2.708-1.802-1.416-2.687-2.864-2.973-3.881l-.028-.204V3.308a.75.75 0 011.173-.619c2.89 1.976 5.472 1.693 7.085-.27a.75.75 0 01.58-.273c.238 0 .45.1.593.273zM4.655 4.628v10.289c.062.173.192.456.413.817.456.745 1.114 1.497 2.015 2.205 1.257.988 2.879 1.804 4.917 2.385 2.038-.581 3.66-1.397 4.917-2.385.9-.708 1.56-1.46 2.015-2.205.22-.361.351-.644.413-.817V4.628C16.557 6.054 13.881 5.749 12 3.985c-1.878 1.765-4.546 2.08-7.345.644zm11.607 2.603a.75.75 0 011.057 1.065l-7.29 7.235a.75.75 0 01-1.06-.002l-2.5-2.5a.75.75 0 011.061-1.06l1.972 1.971 6.76-6.709z" />
  </svg>
)

export default Shield
