import * as React from 'react'

const Video = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.767 6.777a.25.25 0 00-.25.25v10.019c0 .138.112.25.25.25h9.288a.25.25 0 00.25-.25V7.027a.25.25 0 00-.25-.25H3.767zm0-1.5h9.288c.967 0 1.75.784 1.75 1.75v10.019a1.75 1.75 0 01-1.75 1.75H3.767a1.75 1.75 0 01-1.75-1.75V7.027c0-.966.784-1.75 1.75-1.75zM20.528 15.52V8.536l-3.115 1.722v3.455l3.115 1.806zm-4.54-1.396V9.837a.71.71 0 01.367-.623l4.541-2.51a.712.712 0 011.057.624v9.428c0 .55-.594.892-1.07.617l-4.54-2.634a.712.712 0 01-.355-.616z" />
  </svg>
)

export default Video
