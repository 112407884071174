import { defineMessages } from 'react-intl'

const baseId = 'step_form'

const messages = defineMessages({
  previousLabel: {
    id: `${baseId}.previous_label`,
    defaultMessage: 'Previous',
  },
  nextLabel: {
    id: `${baseId}.next_label`,
    defaultMessage: 'Next',
  },
  cancelLabel: {
    id: `${baseId}.cancel_label`,
    defaultMessage: 'Cancel',
  },
  cancelSetupLabel: {
    id: `${baseId}.cancel_setup_label`,
    defaultMessage: 'Cancel set up',
  },
  editStepLabel: {
    id: `${baseId}.edit_step_label`,
    defaultMessage: 'Edit',
  },
  doneLabel: {
    id: `${baseId}.done_label`,
    defaultMessage: 'Done',
  },
  creatingLabel: {
    id: `${baseId}.creating_label`,
    defaultMessage: 'Creating',
  },
  summaryLabel: {
    id: `${baseId}.summary_label`,
    defaultMessage: 'Summary',
  },
})

export default messages
