import * as React from 'react'

const AudioClip = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M2.146 18.255V5.775c0-.963.788-1.745 1.755-1.745h16.198c.968 0 1.755.78 1.755 1.744v12.48c0 .963-.788 1.745-1.755 1.745H3.901a1.75 1.75 0 01-1.755-1.744zm1.5 0a.25.25 0 00.255.244h16.198a.252.252 0 00.255-.244V5.775a.25.25 0 00-.255-.245H3.901a.252.252 0 00-.255.244v12.48zm7.438-3.546h-.493c-.374 0-.493.089-.493.362 0 .274.119.363.493.363.235 0 .387-.003.493-.012v-.713zm0-1.56v-3.046c0-.3.172-.574.443-.704l4.776-2.286a.78.78 0 011.117.704v4.969a.78.78 0 01-1.56 0V9.055l-3.216 1.54v5.048c0 .635-.382 1.076-.954 1.247-.301.09-.537.104-1.1.104-1.152 0-2.052-.673-2.052-1.923s.9-1.922 2.053-1.922h.493zm4.268.417c-.374 0-.493.089-.493.363 0 .273.12.362.493.362.235 0 .387-.003.493-.012v-.713h-.493zm0 2.285c-1.153 0-2.053-.673-2.053-1.922 0-1.25.9-1.923 2.053-1.923h1.273c.43 0 .78.349.78.78V14.5c0 .636-.382 1.076-.954 1.247-.301.09-.537.104-1.1.104zM5.25 4.31h1.5v14.94h-1.5V4.31z" />
  </svg>
)

export default AudioClip
