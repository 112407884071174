import React from 'react'
import { NavLink } from 'react-router-dom'

import { useFormatMessage } from '@babylon/intl'
import { Icon } from '@babylon/medkit'
import { usePermissions } from '@/components/hooks'
import UserMenu from '@/components/UserMenu'

import styles from './styles.module.scss'
import messages from './messages'

export default () => {
  const fm = useFormatMessage()
  const isPermitted = usePermissions()
  const viewPrivacy = isPermitted({ privacy: ['view'] })
  const viewHelpAndSupport = isPermitted({ helpAndSupport: ['view'] })

  return (
    <div className={styles.actionsMenu}>
      {viewPrivacy && (
        <NavLink
          to="/privacy"
          isActive={(_, location: any) => location.pathname === '/privacy'}
          activeClassName={styles.active}
          className={styles.link}
        >
          <Icon
            icon="AccountPrivacy"
            title={fm(messages.privacyLabel)}
            className={styles.actionsMenuItem}
          />
        </NavLink>
      )}
      {viewHelpAndSupport && (
        <NavLink
          to="/help-and-support"
          activeClassName={styles.active}
          className={styles.link}
        >
          <Icon
            icon="AlertsInformationFill"
            title={fm(messages.helpAndSupportLabel)}
            className={styles.actionsMenuItem}
          />
        </NavLink>
      )}
      <UserMenu />
    </div>
  )
}
