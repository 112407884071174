import * as React from 'react'

const AlertsHelpFill = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm0 17.5a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm3.159-7.294l-1.231.983c-.651.518-.928.974-.928 1.522V14a1 1 0 01-2 0v-.289c0-1.746 1.265-2.754 1.68-3.086l1.183-.941c.112-.105.308-.36.308-.725 0-.328-.096-.59-.294-.802-.443-.477-1.085-.739-1.813-.739-.742 0-1.437.329-1.908.902a1.224 1.224 0 00-.133.208c-.172.342-.508.57-.89.57h-.04a.981.981 0 01-.888-1.399c.106-.226.239-.439.396-.636a4.458 4.458 0 013.463-1.645c1.291 0 2.455.489 3.277 1.377.542.581.83 1.33.83 2.164a3.026 3.026 0 01-1.012 2.247z" />
  </svg>
)

export default AlertsHelpFill
