import * as React from 'react'

const OpenInNew = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M9.68.5h5.82v5.82h-1.68V3.352l-8.164 8.164-1.172-1.172 8.164-8.164H9.68V.5zm4.14 13.32V8h1.68v5.82c0 .443-.17.834-.508 1.172-.338.339-.729.508-1.172.508H2.18c-.47 0-.873-.156-1.211-.469a1.724 1.724 0 01-.469-1.21V2.18c0-.47.156-.86.469-1.172A1.653 1.653 0 012.179.5H8v1.68H2.18v11.64h11.64z" />
  </svg>
)

export default OpenInNew
