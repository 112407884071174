import get from 'lodash/fp/get'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import React, { Component } from 'react'

import { DropdownSelect, FormField } from '../..'

import './index.scss'

/** @deprecated, use FormikSelect instead */
class FormikAsyncDropdownSelect extends Component {
  getSelectedOptions(value) {
    return value
  }

  handleChange = (option = null, setFieldValue, name) => {
    setFieldValue(name, option, true)
  }

  render() {
    if (process.env.NODE_ENV === 'development') {
      console.warn('DEPRECATED: Please use FormikSelect instead')
    }

    const {
      id,
      name,
      label,
      validate,
      options,
      getOptionsAsync,
      placeholder,
      searchable,
      onlyFetchOptionsOnce,
      asyncLoadingMessage,
      asyncSearchInstructionsMessage,
      asyncNoResultsFoundMessage,
      onChange,
      ...rest
    } = this.props

    return (
      <Field name={name} validate={validate}>
        {({
          form: { errors, touched, setFieldValue },
          field: { value, onBlur },
        }) => {
          const fieldName = get(name)
          const displayError = fieldName(touched) && !!fieldName(errors)
          const errorMessage = fieldName(errors)

          return (
            <FormField
              id={id}
              label={label}
              errorMessage={errorMessage}
              displayError={displayError}
            >
              <DropdownSelect
                name={name}
                selectedOption={this.getSelectedOptions(value)}
                options={options}
                getOptionsAsync={getOptionsAsync}
                placeholder={placeholder}
                onChange={(option) => {
                  this.handleChange(option, setFieldValue, name)

                  if (typeof onChange === 'function') {
                    onChange(option)
                  }
                }}
                onBlur={onBlur}
                hasError={touched[name] && !!errors[name]}
                searchable={searchable}
                onlyFetchOptionsOnce={onlyFetchOptionsOnce}
                asyncLoadingMessage={asyncLoadingMessage}
                asyncSearchInstructionsMessage={asyncSearchInstructionsMessage}
                asyncNoResultsFoundMessage={asyncNoResultsFoundMessage}
                clearable
                {...rest}
              />
            </FormField>
          )
        }}
      </Field>
    )
  }
}

FormikAsyncDropdownSelect.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.node,
    })
  ),
  getOptionsAsync: PropTypes.func,
  searchable: PropTypes.bool,
  onlyFetchOptionsOnce: PropTypes.bool,
  asyncLoadingMessage: PropTypes.string,
  asyncSearchInstructionsMessage: PropTypes.string,
  asyncNoResultsFoundMessage: PropTypes.string,
}

FormikAsyncDropdownSelect.defaultProps = {
  name: '',
  options: [],
  asyncLoadingMessage: 'Loading...',
  asyncSearchInstructionsMessage: 'Please enter a search criteria...',
  asyncNoResultsFoundMessage: 'No results found',
  searchable: false,
  onlyFetchOptionsOnce: false,
}

export default FormikAsyncDropdownSelect
