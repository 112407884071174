import * as React from 'react'

const ShareAndroidFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8.3 12.787l7.82 3.747a2.75 2.75 0 11-.548 1.4L7.505 14.07a2.75 2.75 0 11.147-3.922l7.92-3.827a2.75 2.75 0 11.703 1.326l-7.929 3.83a2.762 2.762 0 01-.046 1.31z" />
  </svg>
)

export default ShareAndroidFilled
