import * as React from 'react'

const MapFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M15 19V5.771a1.75 1.75 0 01-.4-.031L9.15 4.672a.25.25 0 00-.128.008l-.02.007v13.23a1.75 1.75 0 01.496.06l5.35 1.437a.25.25 0 00.152-.008V19zM3.216 5.063L8.54 3.26a1.75 1.75 0 01.898-.06l5.45 1.068a.25.25 0 00.11-.004l4.829-1.243a1.75 1.75 0 012.186 1.695v12.398a1.75 1.75 0 01-1.122 1.633l-5.352 2.059a1.75 1.75 0 01-1.082.056l-5.349-1.437a.25.25 0 00-.135.001L4.267 20.8a1.75 1.75 0 01-2.24-1.68V6.72c0-.75.478-1.417 1.189-1.658z" />
  </svg>
)

export default MapFilled
