import * as React from 'react'

const NavigationBackArrow = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11.707 20.707a.999.999 0 000-1.414L5.414 13H21a1 1 0 000-2H5.414l6.293-6.293a.999.999 0 10-1.414-1.414l-8 8a.997.997 0 000 1.414l8 8a.999.999 0 001.414 0z" />
  </svg>
)

export default NavigationBackArrow
