import React from 'react'

import Header from '@/components/Header'
import Footer from '@/components/Footer'

import Routes from '@/routes'
import SnackbarBag from '@/components/SnackbarBag'
import { SidebarStack } from '@/components/Sidebar'
import TimezoneUpdate from '@/components/TimezoneUpdate'
import { useRoleNames, usePermissions } from '@/components/hooks'
import { trackUserMainRole } from './tracking'
import { LayoutContext, createLayoutContext } from '.'

import styles from './styles.module.scss'

export default () => {
  const [mainRoleName] = useRoleNames()
  const isPermitted = usePermissions()

  trackUserMainRole(mainRoleName)

  return (
    <LayoutContext.Provider value={createLayoutContext()}>
      <div className={styles.layout}>
        <Header />
        <div className={styles.main} id="layout">
          <div className={styles.content}>
            <SidebarStack>
              <Routes />
            </SidebarStack>
          </div>
          <SnackbarBag.Renderer />
          {isPermitted({ userManagement: ['edit'] }) && <TimezoneUpdate />}
        </div>
        <Footer />
      </div>
    </LayoutContext.Provider>
  )
}
