/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import React from 'react'

import { IMedkitComponent } from '..'
import { buttonTheme } from './theme'
import getButtonClassNames from './utils/getButtonClassNames'
import ButtonLabel from './ButtonLabel'
import { ButtonVariant } from '.'

import { IButtonSimpleProps } from './Button.types'

export const ButtonSimple: IMedkitComponent<
  IButtonSimpleProps,
  typeof buttonTheme
> = ({
  children,
  className,
  disabled = false,
  fullWidth = false,
  halfWidth = false,
  isLoading = false,
  loadingLabel,
  onClick,
  type = 'button',
  variant = ButtonVariant.primary,
  ...props
}: IButtonSimpleProps) => {
  const classNames = getButtonClassNames({
    variant,
    fullWidth,
    halfWidth,
    className,
    isLoading,
  })

  return isLoading ? (
    <div className={classNames} role="alert" aria-busy="true" tabIndex={-1}>
      <ButtonLabel isLoading={isLoading} loadingLabel={loadingLabel}>
        {children}
      </ButtonLabel>
    </div>
  ) : (
    <button
      className={classNames}
      disabled={disabled}
      type={type}
      onClick={onClick}
      {...props}
    >
      <ButtonLabel isLoading={isLoading} loadingLabel={loadingLabel}>
        {children}
      </ButtonLabel>
    </button>
  )
}

ButtonSimple.theme = buttonTheme

export default ButtonSimple
