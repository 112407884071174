import * as React from 'react'

const NavigationUpArrow = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.293 11.707a.999.999 0 001.414 0L11 5.414V21a1 1 0 002 0V5.414l6.293 6.293a.999.999 0 101.414-1.414l-8-8a.997.997 0 00-1.414 0l-8 8a.999.999 0 000 1.414z" />
  </svg>
)

export default NavigationUpArrow
