import React, { useCallback } from 'react'
import { RegisterOptions, useFormContext } from 'react-hook-form'

import { cn } from '@/utils'

import { useFieldContext } from '../Field'
import styles from './styles.module.scss'

interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
}

const Checkbox = React.forwardRef(
  ({ className, rules, ...props }: CheckboxProps, ref: any) => {
    const { register } = useFormContext() ?? {}
    const { name } = useFieldContext()
    const fnRef = useCallback(
      (instance: HTMLInputElement | null) => {
        register?.(rules || {})(instance)
        if (typeof ref === 'function') {
          ref(instance)
        } else if (ref && typeof ref === 'object') {
          ref.current = instance
        }
      },
      [ref, register, rules]
    )
    return (
      <span className={cn(styles.checkbox, className)}>
        <input
          type="checkbox"
          id="checkbox_id"
          name={name}
          ref={fnRef}
          {...props}
        />
        <label htmlFor="checkbox_id" />
      </span>
    )
  }
)

export default Checkbox
