import * as React from 'react'

const Activity = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14.014 6.51a2.385 2.385 0 01-2.381-2.38c0-1.31 1.07-2.38 2.38-2.38a2.385 2.385 0 012.382 2.38c0 1.31-1.07 2.38-2.381 2.38zm0-1.5a.885.885 0 00.881-.88.885.885 0 00-.881-.88.885.885 0 00-.881.88c0 .482.398.88.88.88zm-3.512 13.452c-.977.369-2.606.228-4.993-.368a1.65 1.65 0 01-1.224-1.897l.024-.134a1.65 1.65 0 011.923-1.324l2.6.48a.15.15 0 00.175-.12l.958-5.037-.325.111a.15.15 0 00-.088.082l-.915 2.106a1.65 1.65 0 01-1.513.992h-.375a1.407 1.407 0 01-1.31-1.923l1.253-3.176a1.65 1.65 0 01.767-.855c2.266-1.192 4.09-1.73 5.51-1.58.97.102 1.9.49 2.37 1.198l.73 1.173c1.017 1.244 1.447 1.584 2.354 1.756.323.061.582.144.85.318.444.288.717.747.717 1.33 0 1.05-.956 1.905-1.79 1.811-1.381-.155-2.498-.711-3.55-1.65l-.196 1.083a.15.15 0 00.03.12l1.524 1.925a2.75 2.75 0 01.594 1.707v3.827a1.65 1.65 0 01-1.65 1.65h-.165a1.65 1.65 0 01-1.65-1.65v-3.401a.15.15 0 00-.038-.1l-.7-.784c-.396 1.199-1.019 1.999-1.897 2.33zm-4.74-1.996a.15.15 0 00.11.172c2.1.525 3.503.646 4.1.42.394-.148.74-.595 1.007-1.412a1.489 1.489 0 012.526-.528l.713.798c.27.303.42.694.42 1.1v3.401c0 .083.067.15.15.15h.164a.15.15 0 00.15-.15V16.59a1.25 1.25 0 00-.27-.776l-1.524-1.925a1.65 1.65 0 01-.33-1.318l.455-2.518a.75.75 0 011.306-.356c1.154 1.339 2.227 2.06 3.628 2.217-.055-.006.123-.165.123-.32 0-.076-.072-.122-.346-.174-1.342-.254-2.015-.787-3.29-2.356l-.775-1.233c-.155-.234-.7-.46-1.267-.52-1.063-.112-2.628.349-4.655 1.416a.15.15 0 00-.07.077l-1.202 3.049h.239a.15.15 0 00.137-.09l.915-2.105a1.65 1.65 0 01.975-.903l.587-.202a1.34 1.34 0 011.752 1.516l-1.01 5.311a1.65 1.65 0 01-1.92 1.314l-2.6-.48a.15.15 0 00-.175.12l-.024.132z" />
  </svg>
)

export default Activity
