import * as React from 'react'

const Allergy = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18.67 6.778c-.886.881-2.3.903-2.3.903s.022-1.408.908-2.29c.885-.882 2.3-.903 2.3-.903s-.022 1.408-.908 2.29m-1.861 4.938c-1.252-.003-2.266-.986-2.266-.986s1.019-.978 2.27-.975c1.253.003 2.265.986 2.265.986s-1.017.978-2.27.975m-2.925 3.15c-1.252-.003-2.265-.986-2.265-.986s1.018-.979 2.27-.976c1.253.003 2.265.987 2.265.987s-1.017.978-2.27.975m-3.209 3.03c-1.252-.005-2.264-.988-2.264-.988s1.017-.977 2.27-.974c1.252.003 2.265.986 2.265.986s-1.018.978-2.27.975m-3.62-6.729s.984 1.012.984 2.26c0 1.247-.985 2.258-.985 2.258s-.984-1.011-.984-2.259c0-1.247.984-2.259.984-2.259m3.05-3.188s.985 1.011.985 2.259c0 1.247-.985 2.258-.985 2.258s-.984-1.01-.984-2.258.984-2.26.984-2.26m3.17-2.905s.984 1.011.984 2.259c0 1.247-.984 2.258-.984 2.258s-.985-1.011-.985-2.258c0-1.248.985-2.26.985-2.26m7.717-.734L21 3.664A.652.652 0 0020.336 3l-.673.01c-.193.002-1.847.188-2.839.86-.991.674-1.462 1.58-1.51 1.711a9.212 9.212 0 00-1.122-1.71l-.47-.483a.651.651 0 00-.939 0l-.47.483c-.134.138-1.219 1.301-1.419 2.899l-.371-.382c-.206-.211-.581-.166-.84.1l-.47.484C9.08 7.11 7.997 8.274 7.796 9.87l-.37-.38c-.204-.21-.58-.164-.838.104l-.467.485c-.149.153-1.444 1.556-1.444 3.406 0 1.67 1.067 2.972 1.39 3.328l-2.831 2.82a.801.801 0 000 1.132.809.809 0 001.137 0L7.2 17.95c.328.3 1.623 1.382 3.324 1.383 1.852 0 3.248-1.303 3.402-1.452l.482-.47c.267-.258.312-.634.101-.84l-.382-.371c1.598-.2 2.76-1.284 2.9-1.419l.482-.47c.268-.259.312-.635.101-.84l-.381-.37c1.597-.2 2.76-1.284 2.9-1.419l.482-.47a.651.651 0 000-.938c-.57-.637-1.06-1.013-1.45-1.245a3.12 3.12 0 00-.637-.32s.718-.572.583-.512c.175-.126.346-.267.507-.43 1.31-1.308 1.374-3.216 1.377-3.429" />
  </svg>
)

export default Allergy
