import React, { useCallback } from 'react'
import { RegisterOptions, useFormContext } from 'react-hook-form'

import { cn } from '@/utils'

import { useFieldContext } from '../Field'
import styles from './styles.module.scss'

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
}

// This component is deprecated, please use MedkitInputWrapper
const Input = React.forwardRef(
  ({ className, rules, ...props }: InputProps, ref: any) => {
    const { register } = useFormContext() ?? {}
    const { name } = useFieldContext()
    const fnRef = useCallback(
      (instance: HTMLInputElement | null) => {
        register?.(rules || {})(instance)
        if (typeof ref === 'function') {
          ref(instance)
        } else if (ref && typeof ref === 'object') {
          ref.current = instance
        }
      },
      [ref, register, rules]
    )
    return (
      <input
        name={name}
        ref={fnRef}
        className={cn(styles.input, className)}
        {...props}
      />
    )
  }
)

export default Input
