import * as React from 'react'

const ArchiveFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M4.63 6.25h14.657L17.63 3.858a.25.25 0 00-.206-.108H6.443a.25.25 0 00-.208.11L4.63 6.25zm16.72.341c.43.32.71.831.71 1.409v12.268a1.75 1.75 0 01-1.75 1.75H3.762a1.75 1.75 0 01-1.75-1.75V8c0-.408.14-.783.373-1.08l-.007-.005 2.613-3.89a1.75 1.75 0 011.452-.775h10.981a1.75 1.75 0 011.439.753l2.487 3.588zm-10.1 8.845l-1.572-1.614a.75.75 0 10-1.074 1.047l2.859 2.934a.75.75 0 001.074 0l2.86-2.934a.75.75 0 00-1.075-1.047l-1.572 1.614v-4.39a.75.75 0 00-1.5 0v4.39z" />
  </svg>
)

export default ArchiveFilled
