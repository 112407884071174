import * as React from 'react'

const Question = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75zm0-1.5a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5zM9.001 9.792a3.09 3.09 0 01.271-1.099c.157-.342.371-.64.64-.891.268-.25.584-.447.943-.588A3.19 3.19 0 0112.035 7c.42 0 .814.068 1.178.204.365.137.684.329.953.575a2.647 2.647 0 01.866 1.981 2.548 2.548 0 01-.405 1.423 3.316 3.316 0 01-.496.595 5.53 5.53 0 01-.677.546 4.89 4.89 0 00-.491.379c-.114.101-.2.199-.259.291a.816.816 0 00-.112.26 1.74 1.74 0 00-.038.389v.56c-.115.343-.362.514-.741.514-.38 0-.63-.171-.75-.515v-.61a3 3 0 01.073-.686c.054-.228.145-.443.273-.644.122-.19.277-.372.464-.546.175-.164.388-.334.635-.509.201-.146.372-.284.511-.414.127-.118.226-.234.299-.345.07-.106.119-.212.15-.317a1.25 1.25 0 00.046-.351c0-.2-.036-.376-.106-.534a1.215 1.215 0 00-.297-.411 1.421 1.421 0 00-.47-.278 1.775 1.775 0 00-.619-.104c-.217 0-.414.035-.592.102-.179.068-.332.16-.464.28a1.44 1.44 0 00-.324.438 1.701 1.701 0 00-.159.596.69.69 0 01-.594.622 1.503 1.503 0 01-.233.014A.67.67 0 019 9.792zm2.823 7.594a.93.93 0 01-.67-.277.938.938 0 01-.277-.676.93.93 0 01.278-.67.93.93 0 01.67-.277c.258 0 .493.095.675.277a.93.93 0 01.278.67.938.938 0 01-.278.676.938.938 0 01-.676.277z" />
  </svg>
)

export default Question
