import React, { useMemo, useState } from 'react'
import cx from 'classnames'

import sidebarContext, { SidebarContextType } from './sidebarContext'
import styles from './styles.module.scss'

const { Provider } = sidebarContext

export interface SidebarStackProps {
  children: React.ReactNode
}

const SidebarStack = ({ children }: SidebarStackProps) => {
  const [show, setShow] = useState(false)
  const [align, setAlign] = useState<'left' | 'right'>('right')

  let root = document.getElementById('sidebarRoot')

  if (!root) {
    root = document.createElement('div')
    root.setAttribute('id', 'sidebarRoot')
    const layout = document.getElementById('layout')
    if (layout) {
      layout.appendChild(root)
    }
  }

  root.setAttribute(
    'class',
    cx(styles.root, {
      [styles.rootAlignLeft]: align === 'left',
    })
  )

  const value = useMemo(
    (): SidebarContextType => ({
      show,
      setShow,
      root,
      align,
      setAlign,
    }),
    [root, show, align]
  )

  return <Provider value={value}>{children}</Provider>
}

export default SidebarStack
