import * as React from 'react'

const CreditCardFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21 8H3V7a2 2 0 012-2h14a2 2 0 012 2v1zm0 2.5V17a2 2 0 01-2 2H5a2 2 0 01-2-2v-6.5h18zm-5.6 2.75a.75.75 0 00-.75.75v1.5c0 .414.336.75.75.75h2.1a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75h-2.1z" />
  </svg>
)

export default CreditCardFilled
