import { ComponentType } from 'react'

interface IButtonBase {
  className?: string
  children?: React.ReactChild | React.ReactChild[]
  variant?: ButtonVariant
  fullWidth?: boolean
  halfWidth?: boolean
  loadingLabel?: string
}

export interface IButtonSimpleProps extends IButtonBase {
  isLoading?: boolean
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLElement>) => void | undefined
  type?: 'button' | 'submit' | 'reset'
}

export interface IButtonLinkProps extends IButtonBase {
  href: string
  target?: string
}

export interface IButtonReactRouterLinkProps extends IButtonBase {
  to?: string
  component?: ComponentType<any>
}

export enum ButtonVariant {
  primary = 'Primary',
  secondary = 'Secondary',
  destructive = 'Destructive',
}
