import { envUrl } from '@babylon/babylon-env'

export const BABYLON_AUTH_GRAPHQL_URL = envUrl(
  'BABYLON_AUTH_GRAPHQL_URL',
  'https://services-uk/graphql-middleware/graphql',
  {
    local: 'https://localhost:6443/graphql',
    preprod:
      'https://services-uk.preprod.babylontech.co.uk/graphql-middleware/graphql',
    prod: 'https://services/graphql-middleware/graphql',
  }
)

export const BABYLON_AUTH_LEGACY_PORTAL_URL = envUrl(
  'BABYLON_AUTH_LEGACY_PORTAL_URL',
  'https://app-uk/',
  {
    preprod: 'https://app-uk.preprod.babylontech.co.uk/',
    prod: 'https://app.babylonpartners.com/',
  }
)

export const BABYLON_AUTH_LOGOUT_URL = envUrl(
  'BABYLON_AUTH_LOGOUT_URL',
  'https://app-uk/admin/sign_in',
  {
    local: 'https://localhost:3000/',
    preprod: 'https://app-uk.preprod.babylontech.co.uk/admin/sign_in',
    prod: 'https://app.babylonpartners.com/admin/sign_in',
  }
)
