import * as React from 'react'

const List = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21.244 18.52a.75.75 0 010 1.5H8.102a.75.75 0 110-1.5h13.142zm-.025-14.444a.75.75 0 010 1.5H8.061a.75.75 0 110-1.5H21.22zm.018 7.174a.75.75 0 010 1.5H8.063a.75.75 0 110-1.5h13.174zM5 19a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM5 5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0 7a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
  </svg>
)

export default List
