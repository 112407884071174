/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from 'react'
import cx from 'classnames'
import ReactModal from 'react-modal'

import CloseButton from './CloseButton'

import './Modal.scss'

interface Props extends Omit<ReactModal.Props, 'isOpen'> {
  className?: string
  overlayClassName?: string
  label?: string
  open: boolean
  onClose?: () => void
  children: ReactNode
  includeClose?: boolean
}

const Modal = ({
  className,
  overlayClassName,
  includeClose = true,
  label,
  open,
  onClose,
  children,
  ...props
}: Props) => (
  <ReactModal
    className={cx('core-ui-modal', className)}
    overlayClassName={cx('core-ui-modal__overlay', overlayClassName)}
    contentLabel={label}
    isOpen={open}
    onRequestClose={onClose}
    {...props}
  >
    {includeClose && (
      <div className="core-ui-modal__header">
        <div className="core-ui-modal__spacer" />
        <button
          className="core-ui-modal__close-button"
          onClick={onClose}
          type="button"
        >
          <CloseButton />
        </button>
      </div>
    )}
    <div className="core-ui-modal__content">{children}</div>
  </ReactModal>
)

Modal.setAppElement = (appElement: string | HTMLElement) =>
  ReactModal.setAppElement(appElement)

export default Modal
