import * as React from 'react'

const Text = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21.22 6.75a.75.75 0 010 1.5H2.815a.75.75 0 010-1.5H21.22zm.017 4.5a.75.75 0 010 1.5H2.775a.75.75 0 110-1.5h18.462zm-9.734 4.5a.75.75 0 010 1.5H2.74a.75.75 0 110-1.5h8.763z" />
  </svg>
)

export default Text
