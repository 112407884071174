import * as React from 'react'

const EyeClosedFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M20.096 11.575a15.768 15.768 0 00-2.079-1.942l-.092-.071a.75.75 0 01.912-1.191c.05.04.05.04.101.078a17.23 17.23 0 012.277 2.127c.392.44.663.787.808.996a.75.75 0 01-.012.874 16.657 16.657 0 01-.77.926 19.268 19.268 0 01-2.075 1.992c-2.235 1.842-4.648 2.957-7.166 2.957a8.804 8.804 0 01-2.217-.29.75.75 0 11.376-1.451c.616.16 1.23.241 1.841.241 2.114 0 4.221-.974 6.212-2.614a17.721 17.721 0 002.242-2.216 15.855 15.855 0 00-.358-.416zm-16.275.707a22.676 22.676 0 001.69 1.675c.161.143.322.282.484.415a.75.75 0 01-.956 1.156c-.175-.145-.35-.294-.524-.449A24.154 24.154 0 012 12.47a.75.75 0 01-.018-.915 8.26 8.26 0 01.207-.265A19.253 19.253 0 014.83 8.635C7.068 6.794 9.482 5.68 12 5.68c.76 0 1.52.104 2.275.305a.75.75 0 11-.385 1.45A7.34 7.34 0 0012 7.178c-2.114 0-4.223.974-6.217 2.614a17.766 17.766 0 00-2.226 2.194c.08.09.167.189.264.295zm4.846.368a.75.75 0 01-.75-.75c0-2.29 1.824-4.15 4.083-4.15a.75.75 0 01.75.75c0 .21-1.035 1.243-2.018 2.24-.958.972-1.86 1.91-2.065 1.91zm6.666-1.4c.35 0 .75.336.75.75 0 2.29-1.824 4.15-4.083 4.15-.414 0-.75-.424-.75-.75s1.43-1.594 1.903-2.142c.405-.467 1.831-2.008 2.18-2.008zm5.235-8.96a.75.75 0 011.063 1.058L3.351 21.736a.75.75 0 01-1.063-1.058l18.28-18.387z" />
  </svg>
)

export default EyeClosedFilled
