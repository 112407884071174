import React from 'react'
import cn from 'classnames'
import Heading from '../../foundation/Heading'
import Modal from '../Modal'
import { Button } from '../..'

import './index.scss'

const ConfirmationModalHeading = ({ children }) => (
  <Heading className="core-confirmation-modal__heading" level="h2">
    {children}
  </Heading>
)

const ConfirmationModalContent = ({ children }) => (
  <div className="core-confirmation-modal__inner-content">{children}</div>
)

const ConfirmationModal = ({
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  includeCancel = true,
  includeConfirm = true,
  includeClose,
  alignButtons,
  label,
  loading,
  open,
  onClose,
  onConfirm,
  children,
  className,
}) => (
  <Modal
    label={label}
    open={open}
    onClose={onClose}
    includeClose={includeClose}
    className={className}
  >
    <div data-testid="core-confirmation-modal">
      {children}
      <div
        className={cn(
          'core-confirmation-modal__buttons',
          alignButtons && `core-confirmation-modal__buttons--${alignButtons}`
        )}
      >
        {includeConfirm && (
          <Button
            className="core-confirmation-modal__confirm-button"
            loading={loading}
            testId="core-confirmation-dialog-confirm"
            type="button"
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
        )}
        {includeCancel && (
          <Button
            className="core-confirmation-modal__cancel-button"
            disabled={loading}
            testId="core-confirmation-dialog-cancel"
            type="button"
            onClick={onClose}
            intent="secondary"
          >
            {cancelText}
          </Button>
        )}
      </div>
    </div>
  </Modal>
)

ConfirmationModal.Heading = ConfirmationModalHeading
ConfirmationModal.Content = ConfirmationModalContent

export default ConfirmationModal
