import { defineMessages } from 'react-intl'

const baseId = 'regions'

const messages = defineMessages({
  regionsSearchLabel: {
    id: `${baseId}.regions_search_label`,
    defaultMessage: 'Search regions',
  },
  regionsSearchPlaceholder: {
    id: `${baseId}.regions_search_placeholder`,
    defaultMessage: 'E.g. England',
  },
})

export default messages
