/* eslint-disable jsx-a11y/interactive-supports-focus */

import React, { useState, useEffect, useRef } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import Fuse from 'fuse.js'

import DropdownInput from './components/DropdownInput'
import DropdownOptions, { optionPropType } from './components/DropdownOptions'

import './index.scss'

const fuse = memoize((opts) => {
  return new Fuse(opts, {
    keys: ['key', 'value'],
  })
})

const fuseOptions = memoize((opts, val) => {
  return fuse(opts).search(val)
})

/** @deprecated, use Select instead */
const DropdownSelect = ({
  name,
  options,
  selectedOption,
  clearable,
  hasError,
  searchable,
  fill,
  placeholder,
  asyncLoadingMessage,
  asyncSearchInstructionsMessage,
  asyncNoResultsFoundMessage,
  optionHeight,
  onChange,
  onFocus,
  onBlur,
  getOptionsAsync,
  onlyFetchOptionsOnce,
  disabled,
  ...rest
}) => {
  const hasAsyncOptions = typeof getOptionsAsync === 'function'
  const shouldLoadOptionsOnRender =
    (!searchable && hasAsyncOptions) ||
    (onlyFetchOptionsOnce && hasAsyncOptions)

  const [isFocused, setFocus] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchInputFocused, setSearchInputFocus] = useState(false)
  const [asyncLoading, setAsyncLoading] = useState(shouldLoadOptionsOnRender)
  const [asyncErrorMessage, setAsyncErrorMessage] = useState(null)
  const [loadedOptions, setLoadedOptions] = useState([])
  const [dropdownOptions, setDropdownOptions] = useState(
    hasAsyncOptions ? [] : options
  )
  const typingTimeoutRef = useRef(null)
  const showClear = !!(clearable && selectedOption)
  const wrapperRef = useRef()

  const handleOptionSelect = (option) => {
    setFocus(false)
    setSearchInputFocus(false)
    setSearchValue('')

    if (typeof onChange === 'function') {
      onChange(option)
    }
  }

  const handleSearchInputChange = (event) => {
    setSearchValue(event.currentTarget.value)
  }

  const handleSearchInputFocus = () => {
    setSearchInputFocus(true)
  }

  const handleSearchInputBlur = () => {
    setSearchInputFocus(false)
  }

  const handleClearButtonClick = (event) => {
    event.stopPropagation()

    setFocus(false)

    if (onBlur) {
      event.target.name = name
      onBlur(event)
    }

    if (typeof onChange === 'function') {
      onChange(null)
    }
  }

  const handleMouseClickOutsideElement = (event) => {
    if (
      isFocused &&
      wrapperRef &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target)
    ) {
      setFocus(false)

      if (onBlur) {
        event.target.name = name
        onBlur(event)
      }
    }
  }

  const handleInputWrapperClick = (event) => {
    if (disabled) {
      return
    }

    event.preventDefault()
    event.stopPropagation()
    event.target.name = name
    setFocus(!isFocused)

    if (!isFocused) {
      if (onFocus) {
        onFocus(event)
      }
    } else if (onBlur) {
      onBlur(event)
    }
  }

  const getDropdownOptionsMessage = () => {
    const emptyOptions = !dropdownOptions || dropdownOptions.length === 0

    if (asyncLoading) {
      return asyncLoadingMessage
    }

    if (asyncErrorMessage) {
      return asyncErrorMessage
    }

    if (searchable && hasAsyncOptions && !onlyFetchOptionsOnce) {
      if (!searchValue && emptyOptions) {
        return asyncSearchInstructionsMessage
      }

      if (searchValue && emptyOptions) {
        return asyncNoResultsFoundMessage
      }
    }

    return null
  }

  // Update state options when prop options change and clear selected
  useEffect(() => {
    if (!hasAsyncOptions && !searchValue && options !== dropdownOptions) {
      setDropdownOptions(options)
    }
  }, [hasAsyncOptions, options, dropdownOptions, searchValue])

  // Get options on initial load
  // if async and not searchable
  useEffect(() => {
    let isSubscribed = true

    const setupAsyncOptions = async () => {
      if (shouldLoadOptionsOnRender) {
        try {
          const asyncOptions = await getOptionsAsync()
          if (isSubscribed) {
            setDropdownOptions(asyncOptions)

            if (onlyFetchOptionsOnce) {
              setLoadedOptions(asyncOptions)
            }
          }
        } catch (error) {
          if (isSubscribed) {
            setAsyncErrorMessage(error.message)
          }
        }
        if (isSubscribed) {
          setAsyncLoading(false)
        }
      }
    }

    setupAsyncOptions()

    return () => {
      isSubscribed = false
    }
  }, [getOptionsAsync, onlyFetchOptionsOnce, shouldLoadOptionsOnRender])

  useEffect(() => {
    if (searchable) {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current)
      }

      if (hasAsyncOptions && !onlyFetchOptionsOnce) {
        setAsyncLoading(true)
        setAsyncErrorMessage(null)
      }

      typingTimeoutRef.current = setTimeout(async () => {
        if (searchValue) {
          if (hasAsyncOptions) {
            if (onlyFetchOptionsOnce) {
              setDropdownOptions(fuseOptions(loadedOptions, searchValue))
            } else {
              try {
                setDropdownOptions(await getOptionsAsync(searchValue))
              } catch (error) {
                setAsyncErrorMessage(error.message)
                setDropdownOptions([])
              }
              setAsyncLoading(false)
            }
          } else {
            setDropdownOptions(fuseOptions(options, searchValue))
          }
        } else if (hasAsyncOptions) {
          if (onlyFetchOptionsOnce) {
            setDropdownOptions(loadedOptions)
          } else {
            setAsyncLoading(false)
            setDropdownOptions([])
          }
        } else {
          setDropdownOptions(options)
        }
      }, 300)
    }
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current)
      }
    }
  }, [
    getOptionsAsync,
    hasAsyncOptions,
    loadedOptions,
    onlyFetchOptionsOnce,
    options,
    searchValue,
    searchable,
  ])

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseClickOutsideElement)

    return () => {
      document.removeEventListener('mousedown', handleMouseClickOutsideElement)
    }
  })

  return (
    <div
      ref={wrapperRef}
      className={cn('core-dropdown-select', {
        'core-dropdown-select--fill': fill,
        'core-dropdown-select--disabled': disabled,
        'core-dropdown-select--focused': isFocused || searchInputFocused,
        'core-dropdown-select--has-selected-option': selectedOption,
        'core-dropdown-select--show-clear-button': showClear,
        'core-dropdown-select--has-search-value': searchValue,
      })}
    >
      <DropdownInput
        hasError={!!hasError}
        searchValue={searchValue}
        searchable={searchable}
        placeholder={placeholder}
        selectedOption={selectedOption}
        onSearchInputChange={handleSearchInputChange}
        onSearchInputFocus={handleSearchInputFocus}
        onSearchInputBlur={handleSearchInputBlur}
        showClearButton={showClear}
        onClearButtonClick={handleClearButtonClick}
        onClick={handleInputWrapperClick}
        {...rest}
      />

      {(isFocused || searchInputFocused) && (
        <DropdownOptions
          options={dropdownOptions}
          optionHeight={optionHeight}
          message={getDropdownOptionsMessage()}
          onOptionSelect={handleOptionSelect}
        />
      )}
    </div>
  )
}

DropdownSelect.propTypes = {
  asyncLoadingMessage: PropTypes.string,
  asyncNoResultsFoundMessage: PropTypes.string,
  asyncSearchInstructionsMessage: PropTypes.string,
  clearable: PropTypes.bool,
  getOptionsAsync: PropTypes.func,
  hasError: PropTypes.bool,
  onlyFetchOptionsOnce: PropTypes.bool,
  optionHeight: PropTypes.number,
  options: PropTypes.arrayOf(optionPropType),
  placeholder: PropTypes.string,
  searchable: PropTypes.bool,
  selectedOption: optionPropType,
  onChange: PropTypes.func,
}

DropdownSelect.defaultProps = {
  asyncLoadingMessage: 'Loading...',
  asyncNoResultsFoundMessage: 'No results found',
  asyncSearchInstructionsMessage: 'Please enter a search criteria...',
  clearable: false,
  hasError: false,
  onlyFetchOptionsOnce: false,
  optionHeight: 35,
  options: [],
  placeholder: 'Select...',
  searchable: false,
}

export default DropdownSelect
