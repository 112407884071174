import React from 'react'

import { UserRolesContext } from './core'

function UserRoles({ children, userRoles }) {
  return (
    <UserRolesContext.Provider value={userRoles}>
      {children}
    </UserRolesContext.Provider>
  )
}

export default UserRoles
