import React from 'react'
import { NavLink } from 'react-router-dom'

import { BabylonIcon } from '@babylon/icons'
import { Icon } from '@babylon/medkit'

import styles from './NavigationLink.module.scss'

interface Props {
  to: string
  icon?: BabylonIcon
  label: string
  isActive?: (match: any, location: any) => boolean
  onClick: () => void
}

export default ({ to, icon, label, isActive, onClick }: Props) => (
  <NavLink
    to={to}
    onClick={onClick}
    isActive={isActive}
    activeClassName={styles.active}
    className={styles.link}
  >
    {icon && (
      <Icon
        className={styles.iconWrapper}
        iconClassName={styles.icon}
        icon={icon}
        height={30}
        width={30}
        title={label}
      />
    )}
    {label}
  </NavLink>
)
