import React, { useContext } from 'react'

import ExperiencesContext, { ExperienceType } from './ExperiencesContext'
import Variant from './Variant'

interface ExperienceProps {
  name: string
  children: React.ReactElement | React.ReactElement[]
}

export default ({ name, children }: ExperienceProps) => {
  const experiences: ExperienceType = useContext(ExperiencesContext)
  return (
    (experiences[name] && children && (
      <>
        {React.Children.map(
          children,
          (child) =>
            (child.type !== Variant ||
              child.props.name === experiences[name]) &&
            React.cloneElement(child, child.props)
        )}
      </>
    )) ||
    null
  )
}
