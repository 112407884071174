import * as React from 'react'

const PrescriptionFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.788 1.984v5.341c0 .393.318.712.712.712h5.546v12.165c0 .943-.775 1.71-1.714 1.71H5.713A1.718 1.718 0 014 20.203V3.691c0-.946.763-1.707 1.717-1.707h8.07zm1.424 0h.382c.19 0 .373.076.507.212l3.74 3.785a.712.712 0 01.205.5v.131h-4.833V1.984zm-2.305 10.592h1.433a.75.75 0 000-1.5h-1.433V9.644a.75.75 0 00-1.5 0v1.432H9.974a.75.75 0 100 1.5h1.433v1.433a.75.75 0 001.5 0v-1.433zm3.12 4.674h-8.01a.75.75 0 100 1.5h8.01a.75.75 0 100-1.5z" />
  </svg>
)

export default PrescriptionFilled
