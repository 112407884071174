import React, { useContext } from 'react'

import { LinkReactRouter } from '@babylon/medkit'

import styles from './styles.module.scss'
import { PopoverContext } from './core'

interface PopoverLinkProps {
  to: string
  'data-testid'?: string
  children: React.ReactElement | string
}

const PopoverLink = ({
  to,
  children,
  'data-testid': dataTestId,
}: PopoverLinkProps) => {
  const { toggleIsOpen } = useContext(PopoverContext)

  return (
    <div className={styles.popoverAction}>
      <LinkReactRouter
        to={to}
        onClick={toggleIsOpen}
        data-testid={dataTestId}
        className={styles.popoverActionLink}
      >
        {children}
      </LinkReactRouter>
    </div>
  )
}

export default PopoverLink
