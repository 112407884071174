function isFileValidType(file: File, acceptedTypes: string[]): boolean {
  const { type, name } = file
  const ext = name.slice((Math.max(0, name.lastIndexOf('.')) || Infinity) + 1)

  return acceptedTypes
    .map((specifier) => specifier.trim())
    .some((specifier) => {
      if (specifier.startsWith('.')) {
        return specifier === `.${ext}`
      }

      return new RegExp(specifier.replace('*', '.*')).test(type)
    })
}

export default isFileValidType
