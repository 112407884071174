import * as React from 'react'

const SearchFilled = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M15.13 14.069l6.372 6.372-1.06 1.06-6.373-6.371a7.25 7.25 0 111.06-1.06zM9.5 3.75A5.75 5.75 0 003.75 9.5a.75.75 0 001.5 0A4.25 4.25 0 019.5 5.25a.75.75 0 000-1.5zm-5.136 8.338c.028.056.058.112.088.167a.75.75 0 001.316-.72 4.236 4.236 0 01-.065-.123.75.75 0 10-1.339.676z" />
  </svg>
)

export default SearchFilled
