import React, { Component } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import { Tag, Button } from '@babylon/core-ui'

import Chevron from '../../icons/Chevron'
import filterIconSVG from '../../icons/filter-icon.svg'
import sortIconSVG from '../../icons/sort-icon.svg'

import './index.scss'

class FilterPanel extends Component {
  state = {
    sortPopupOpen: false,
  }

  onSortOptionClick = (key) => {
    const { onSortOptionChange } = this.props

    this.setState({
      sortPopupOpen: false,
    })

    if (typeof onSortOptionChange === 'function') {
      onSortOptionChange(key)
    }
  }

  toggleOpen = () => {
    const { open, onOpenChange } = this.props

    if (typeof onOpenChange === 'function') {
      onOpenChange(!open)
    }
  }

  toggleSortPopup = () => {
    this.setState({
      sortPopupOpen: !this.state.sortPopupOpen,
    })
  }

  renderSortButton = () => {
    const { sortOptions } = this.props

    if (
      !sortOptions ||
      !Array.isArray(sortOptions) ||
      sortOptions.length === 0
    ) {
      return false
    }

    return (
      <div
        className={cn(
          'core-ui-filter-panel__header__buttonWrapper',
          'core-ui-filter-panel__header__buttonWrapper--sortButton'
        )}
      >
        <Button
          intent="secondary"
          className="core-ui-filter-panel__header__button"
          onClick={this.toggleSortPopup}
        >
          <img
            src={sortIconSVG}
            alt="Sort icon"
            className="core-ui-filter-panel__header__buttonIcon"
          />
        </Button>

        <div className="core-ui-filter-panel__header__sortOptions">
          <ul className="core-ui-filter-panel__header__sortList">
            {sortOptions.map(({ key, value }) => (
              <li className="core-ui-filter-panel__header__sortItem" key={key}>
                <button
                  type="button"
                  className="core-ui-filter-panel__header__sortButton"
                  onClick={() => this.onSortOptionClick(key)}
                >
                  {value}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }

  render() {
    const { heading, open, filters, buttons, children } = this.props
    const { sortPopupOpen } = this.state

    return (
      <div
        className={cn('core-ui-filter-panel', {
          'core-ui-filter-panel--open': open,
          'core-ui-filter-panel--sortPopupOpen': sortPopupOpen,
          'core-ui-filter-panel--hasFilters': filters.length > 0,
        })}
      >
        <div className="core-ui-filter-panel__wrapper">
          <div className="core-ui-filter-panel__header">
            <div className="core-ui-filter-panel__header__left">
              <button
                type="button"
                className="core-ui-filter-panel__header__openButton"
                onClick={this.toggleOpen}
              >
                <Chevron
                  direction="down"
                  className="core-ui-filter-panel__header__chevron"
                />

                <span className="core-ui-filter-panel__header__heading">
                  {heading}
                </span>
              </button>
            </div>

            <div className="core-ui-filter-panel__header__right">
              <div className="core-ui-filter-panel__header__buttons">
                <div className="core-ui-filter-panel__header__buttonWrapper">
                  <Button
                    data-testid="filter-button"
                    intent="secondary"
                    className="core-ui-filter-panel__header__button"
                    onClick={this.toggleOpen}
                  >
                    <img
                      src={filterIconSVG}
                      alt="Filter icon"
                      className="core-ui-filter-panel__header__buttonIcon"
                    />
                  </Button>
                </div>

                {this.renderSortButton()}
              </div>
            </div>
          </div>

          <div className="core-ui-filter-panel__form">{children}</div>

          <div
            className={cn('core-ui-filter-panel__body', {
              'core-ui-filter-panel__body--hasButtons': !!buttons,
            })}
          >
            <div className="core-ui-filter-panel__filtersWrapper">
              {filters.length > 0 && (
                <div className="core-ui-filter-panel__filtersBox">
                  {filters.map(({ key, text, onRemove }) => (
                    <Tag
                      key={key}
                      color="clinical-green"
                      className="core-ui-filter-panel__filtersBoxTag"
                      closable
                      onClose={
                        typeof onRemove === 'function'
                          ? () => onRemove(key)
                          : null
                      }
                    >
                      {text}
                    </Tag>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

FilterPanel.propTypes = {
  heading: PropTypes.string,
  open: PropTypes.bool.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      onRemove: PropTypes.func,
    })
  ),
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onOpenChange: PropTypes.func,
  onSortOptionChange: PropTypes.func,
}

FilterPanel.defaultProps = {
  heading: 'Filter results by:',
  open: false,
  filters: [],
}

export default FilterPanel
