import { createSlice } from '@reduxjs/toolkit'
import { AppointmentConfigurations } from '@babylon/graphql-middleware-types'
import { ApolloError } from 'apollo-client'

interface State {
  data: AppointmentConfigurations['appointment_configurations']
  loading: boolean
  error?: ApolloError
}

const initialState: State = {
  data: [],
  loading: false,
  error: undefined,
}

export const appointmentConfigurationsSlice = createSlice({
  name: 'appointmentConfigurations',
  initialState,
  reducers: {
    reset: () => initialState,
    storeData: (state, { payload }) => {
      state.data = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})
