import * as React from 'react'

const FaceId = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.104 19.75c-4.226 0-7.647-3.472-7.647-7.75 0-4.278 3.421-7.75 7.647-7.75 4.225 0 7.646 3.472 7.646 7.75 0 4.278-3.42 7.75-7.646 7.75zm0-1.5c3.392 0 6.146-2.796 6.146-6.25s-2.754-6.25-6.146-6.25S5.957 8.546 5.957 12s2.755 6.25 6.147 6.25zm.795-3.543a.75.75 0 011.23.858 2.593 2.593 0 01-4.272-.02.75.75 0 111.238-.846 1.092 1.092 0 001.804.008zm7.611 2.045a.75.75 0 011.5 0v3.508a1.75 1.75 0 01-1.75 1.75h-3.514a.75.75 0 010-1.5h3.514a.25.25 0 00.25-.25v-3.508zM7.266 20.535a.75.75 0 010 1.5H3.758a1.75 1.75 0 01-1.75-1.75v-3.514a.75.75 0 111.5 0v3.514c0 .138.112.25.25.25h3.508zm9.473-17.01a.75.75 0 110-1.5h3.508c.966 0 1.75.783 1.75 1.75v3.514a.75.75 0 01-1.5 0V3.775a.25.25 0 00-.25-.25h-3.508zM3.528 7.28a.75.75 0 01-1.5 0V3.772c0-.966.783-1.75 1.75-1.75h3.514a.75.75 0 110 1.5H3.778a.25.25 0 00-.25.25V7.28zm11.502 5.088c-.663 0-1.2-.53-1.2-1.184 0-.655.537-1.184 1.2-1.184.664 0 1.2.53 1.2 1.184 0 .654-.536 1.184-1.2 1.184zm-4.906-1.184c0 .654-.537 1.184-1.201 1.184-.663 0-1.2-.53-1.2-1.184 0-.655.537-1.184 1.2-1.184.664 0 1.2.53 1.2 1.184z" />
  </svg>
)

export default FaceId
