import React from 'react'

import { IMedkitComponent } from '../..'
import { errorMessagesTheme } from './errorMessagesTheme'

import styles from './errorMessages.module.scss'

type ErrorMessagesProps = {
  fieldName?: string
  formErrors?: string[]
}

const ErrorMessages: IMedkitComponent<
  ErrorMessagesProps,
  typeof errorMessagesTheme
> = ({ fieldName, formErrors = [] }: ErrorMessagesProps) =>
  formErrors ? (
    <div
      id={`${fieldName}-error`}
      aria-live="polite"
      data-testid={`${fieldName}-error`}
      className={styles.errorMessages}
    >
      {formErrors.map((message) => (
        <p key={message} className={styles.errorMessage}>
          {message}
        </p>
      ))}
    </div>
  ) : null

ErrorMessages.theme = errorMessagesTheme

export default ErrorMessages
