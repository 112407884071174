import * as React from 'react'

const Medication = ({
  title,
  titleId,
  ...props
}: {
  title?: string
  titleId?: string
} & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#400099"
    focusable={false}
    aria-hidden
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18 13v6h4v-6h-4zm-1-7c.55 0 1-.45 1-1V2c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v2c1.302 0 2.401.839 2.816 2H17zm0 6h4.976a2.99 2.99 0 00-.855-1.879L18 7h-5v14a2.98 2.98 0 01-.78 2H20a2 2 0 002-2v-1h-5v-8zm-7-7H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zM5 21a1 1 0 110-2 1 1 0 010 2zm0-4a1 1 0 110-2 1 1 0 010 2zm0-4a1 1 0 110-2 1 1 0 010 2zm0-4a1 1 0 110-2 1 1 0 010 2zm4 12a1 1 0 110-2 1 1 0 010 2zm0-4a1 1 0 110-2 1 1 0 010 2zm0-4a1 1 0 110-2 1 1 0 010 2zm0-4a1 1 0 110-2 1 1 0 010 2z" />
  </svg>
)

export default Medication
